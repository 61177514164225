<template>
	<v-card>
		<v-card-text class="pa-0">
			<div class="d-flex flex-row justify-space-between align-center px-4 py-2">
				<div>
					<div class="font-weight-bold caption">Estado de Pago - <span :class="statusColorClass(paymentStatus)">{{paymentStatus.statustext}}</span></div>
					<div class="d-flex flex-row justify-start align-center">
						<div class="d-flex flex-row align-center mr-1">
							<p class="mb-0 caption">
								{{paymentStatus.status == 3 ? 'Monto Aprobado:':'Monto Solicitado Actual:'}}
								<span class="normal font-weight-bold">{{additional.last_revision.currency_info.code}}${{paymentStatus.amount | toThousandFilter}}</span>
							</p>
						</div>
					</div>
					<div class="caption">{{paymentStatus.description}}</div>
				</div>
				<div class="text-right">
					<div class="text-primary-color caption font-weight-bold" style="line-height: 1;">{{paymentStatus.created_by_user.name}}</div>
					<div class="caption text--disabled">Solicitante</div>
				</div>
			</div>
			<v-divider class="mb-1"/>
			<div class="px-4 pb-2 overflow-auto" style="max-height: 400px">
				<div class="my-4" v-if="showNewObservationForm || showModifyPSForm">
					<v-form ref="form" v-model="validform" v-if="showNewObservationForm" transition="slide-y-transition">
						<div class="font-weight-bold mb-2">
							Nueva Observación
							<v-icon color="#515d78" class="mr-1" small>mdi-chevron-down</v-icon>
						</div>
						<v-text-field
							v-model="newObservation.description"
							placeholder="Descripción"
							outlined
							dense
							rounded
							hide-details
							:height="20"
							class="mx-1 custom-input-numbers text-center"
							:rules="requiredRule"
						>
						</v-text-field>
						<div class="d-flex align-center justify-space-between mt-2">
							<v-text-field
								v-model="newObservation.progress"
								placeholder="Avance"
								type="number"
								outlined
								dense
								rounded
								hide-details
								:height="20"
								class="mx-1 custom-input-numbers text-center"
								:rules="[...requiredRule, ...percentRule]"
							>
							</v-text-field>
							<v-text-field
								v-model="newObservation.amount"
								placeholder="Monto"
								type="number"
								outlined
								dense
								rounded
								hide-details
								:height="20"
								class="mx-1 custom-input-numbers text-center"
								:rules="[...requiredRule,...decimalsRule]"
							>
							</v-text-field>
						</div>
						<v-divider class="my-4"></v-divider>
						<div class="d-flex align-center mt-2">
							<v-btn
								class="mx-1"
								rounded
								color="#515d78"
								raised
								small
								outlined
								:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
								@click.stop="cancelForms"
							>
								<p class="mb-0">Cancelar</p>
							</v-btn>
							<v-btn
								class="flex-grow-1 mx-1"
								rounded
								color="#515d78"
								raised
								small
								:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
								@click.stop="sendNewObservation"
							>
								<p class="mb-0 white--text">Enviar Observación</p>
							</v-btn>
						</div>
					</v-form>

					<v-form ref="formModify" v-model="validformModify" v-if="showModifyPSForm" transition="slide-y-transition">
						<div class="font-weight-bold mb-2">
							Modificar Estado de Pago
							<v-icon color="#515d78" class="mr-1" small>mdi-chevron-down</v-icon>
						</div>
						<div class="d-flex align-center justify-space-between mt-2">
							<v-text-field
								v-model="modifyPS.progress"
								placeholder="Avance"
								type="number"
								outlined
								dense
								rounded
								hide-details
								:height="20"
								class="mx-1 custom-input-numbers text-center"
								:rules="[...requiredRule, ...percentRule]"
							>
							</v-text-field>
							<v-text-field
								v-model="modifyPS.amount"
								placeholder="Monto"
								type="number"
								outlined
								dense
								rounded
								hide-details
								:height="20"
								class="mx-1 custom-input-numbers text-center"
								:rules="[...requiredRule,...decimalsRule]"
							>
							</v-text-field>
						</div>
						<v-divider class="my-4"></v-divider>
						<div class="d-flex align-center mt-2">
							<v-btn
								class="mx-1"
								rounded
								color="#515d78"
								raised
								small
								outlined
								:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
								@click.stop="cancelForms"
							>
								<p class="mb-0">Cancelar</p>
							</v-btn>
							<v-btn
								class="flex-grow-1 mx-1"
								rounded
								color="#515d78"
								raised
								small
								:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
								@click.stop="sendUpdatePS"
							>
								<p class="mb-0 white--text">Modificar Estado de Pago</p>
							</v-btn>
						</div>
					</v-form>
				</div>
				<v-timeline
					v-else
					align-top
					dense
				>
					<v-timeline-item
						v-for="(ps, index) in paymentStatus.interactions"
						:key="index"
						small
					>
						<v-card class="px-2 py-1 caption elevation-2">
							<div class="font-weight-normal">
								<strong>{{ ps.created_by_user.name }}</strong> | <small>{{ ps.updated_at | toDateTime }}</small>
							</div>
							<v-divider class="mb-1"/>
							<div>{{ ps.description }}</div>
							<v-row align="center">
								<v-col cols="6">
									<div class="d-flex flex-row align-center mr-1">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="#515d78" class="mr-1" small
													v-bind="attrs"
													v-on="on">mdi-progress-check</v-icon>
											</template>
											<span>Porcentaje de avance informado</span>
										</v-tooltip>
										<v-progress-linear
											:value="ps.progress*100"
											color="primary"
											height="12"
											rounded
										>
											<small :class="ps.progress < 0.43 ? '':'white--text'">{{ Math.ceil(ps.progress*100) }}%</small>
										</v-progress-linear>
									</div>
								</v-col>
								<v-col cols="6">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row align-center mt-1"
													v-bind="attrs" v-on="on">
												<p class="mb-0 normal font-weight-bold">{{additional.last_revision.currency_info.code}}$</p>
												<p class="mb-0 mr-2 normal font-weight-bold">{{ps.amount}}</p>
												<v-progress-circular
													:size="20"
													:width="6"
													:value="ps.amount*100/additional.last_revision.total_requested"
													color="#3EBFA3"
												/>
											</div>
										</template>
										<span>Monto negociado para el estado de pago</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card>
					</v-timeline-item>
				</v-timeline>
			</div>
			<div class="px-4 pb-2" v-if="!showNewObservationForm && !showModifyPSForm">
				<v-divider class="mb-1"/>
				<div class="d-flex align-center justify-space-between">
					<v-btn
						class="mx-2"
						rounded
						color="#515d78"
						outlined
						raised
						x-small
						:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
						:disabled="paymentStatus.status == 3"
						@click.stop="showNewObservationForm = !showNewObservationForm"
					>
						<p class="mb-0">Agregar Observación</p>
					</v-btn>
					<v-btn
						class="mx-2"
						rounded
						color="#515d78"
						raised
						small
						:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
						v-if="additional.userprojectjobtype.job_type_id !== 4"
						@click.stop="showModifyPSForm = !showModifyPSForm"
					>
						<p class="mb-0 white--text">Modificar</p>
					</v-btn>
					<!-- <div v-if="additional.userprojectjobtype.job_type_id == 4">
						<v-btn
							class="mx-2"
							rounded
							color="#3EBFA3"
							raised
							small
							:loading="$store.adicionalesStore.state.listado.status === 99 || loading"
							v-if="paymentStatus.status != 3"
							@click.stop="approvePaymentStatus"
						>
							<p class="mb-0 white--text">Aprobar</p>
						</v-btn>
						<v-chip
							color="#3EBFA3"
							small
							dark
							v-else>
							Estado de Pago Aprobado
						</v-chip>
					</div> -->
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import {setStatusToPaymentStatus, newPaymentStatusInteraction, updatePaymentStatus} from '@/helpers/api/additionals'
export default {
	name: 'PaymentStatusInteraction',
	props: {
		additional: {
			type: Object,
			required: true
		},
		paymentStatus: {
			type: Object,
			required: true
		}
	},
	mounted(){
		console.log('PaSsss: ', this.additional)
		console.log('PaSsss2: ', this.paymentStatus)
	},
	data: () => ({
		loading: false,
    validform: true,
		validformModify: true,
		showNewObservationForm: false,
		showModifyPSForm: false,
		newObservation: {
			description: null,
			progress: null,
			amount: null
		},
		modifyPS: {
			progress: null,
			amount: null
		},
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
		percentRule: [
			v => v && v.toString().match(/^(100(\.0{1,2})?|[1-9]?\d(\.\d{1,2})?)$/) || 'El porcentaje de avance debe estar entre 0 y 100'
		],
		decimalsRule: [
			v => v && v.toString().match(/^(\d+(\.\d{0,2})?|\.?\d{1,2})$/) || 'El monto puede tener 2 decimales, formato XXXXXX.XX'
		],
	}),
	methods: {
		statusColorClass(additional){
			if([3, 6].includes(additional.status)){ return 'normal' }
			else if([1, 2].includes(additional.status)){ return 'medium' }
			return 'high'
		},
		cancelForms(){
			this.showNewObservationForm = false
			this.showModifyPSForm = false
			this.newObservation = {
				description: null,
				progress: null,
				amount: null
			}
			this.modifyPS = {
				progress: null,
				amount: null
			}
		},
		async approvePaymentStatus(){
			this.loading = true
			const data = {
				additional_payment_status_id: this.paymentStatus.id,
				status: 3
			}
			await setStatusToPaymentStatus(data)
			this.getAdditionalList()
			this.loading = false
		},
		async sendNewObservation(){
      if(this.$refs.form.validate()){
				this.loading = true
				const data = {
					additional_payment_status_id: this.paymentStatus.id,
					description: this.newObservation.description,
					progress: this.newObservation.progress/100,
					amount: this.newObservation.amount
				}
				await newPaymentStatusInteraction(data)
				this.getAdditionalList()
				this.cancelForms()
				this.loading = false
			}
		},
		async sendUpdatePS(){
      if(this.$refs.formModify.validate()){
				this.loading = true
				const data = {
					additional_payment_status_id: this.paymentStatus.id,
					progress: this.modifyPS.progress/100,
					amount: this.modifyPS.amount
				}
				await updatePaymentStatus(data)
				this.getAdditionalList()
				this.cancelForms()
				this.loading = false
			}
		},
    getAdditionalList(){
      let project_id = null
      if(this.$store.projectsStore.state.selectedProject){
        project_id = this.$store.projectsStore.state.selectedProject.id
      }
      else{
        const id = localStorage.getItem('selectedProjectID')
        project_id = parseInt(id)
      }
      if(project_id){
        this.$store.adicionalesStore.dispatch('getAdditionals', {project_id})
      }
    },
	}
}
</script>

<style scoped>
	.text-primary-color{
		color: #515d78;
	}
	.high{
		color: #F4516C;
	}
	.medium{
		color: #fb8c00;
	}
	.normal{
		color: #3EBFA3;
	}
</style>