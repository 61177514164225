<template>
  <div class="popup" v-if="message">
    <div class="popup-content">
      <div class="pop-up-close" @click="close()">&times;</div>
      <h3 class="pb-2">Solicitud de Cierre</h3>
      <!-- <h2>Permission: {{ message.permission }}</h2> -->

      <p v-if="message && message.permission">
        ¿Está seguro de que quiere aprobar el proceso de cierre para
        <span style="font-weight: bold">{{ message.value }}</span>
        <br><br>
        <span v-if="message.status==2" border-color="deep-purple accent-4" elevation="2">
          <i><b>*</b> Este es la última validación requerida, si continuas, <b>Finalizará la RDI</b>.</i>
        </span>
      </p>

      <p v-if="message && message.permission == false">
        Para realizar la acción, debes tener el permiso de : 
        <span style="font-weight: bold">{{ message.value }}</span>
        <br><br>
        <span>
         Solicita este permiso al administrador del sistema.
        </span>
      </p>

      <div class="d-flex" style="justify-content: space-around" v-if="message && message.permission">
        <button class="close-btn" @click="confirm()">Sí</button>
        <button class="close-btn" @click="close()">Cancelar</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: Object,
  },
  data() {
    return {
      showPopup: true,
      popupMessage: "",
    };
  },
  methods: {
    close() {
      this.$emit("close");
    },
    confirm() {
      
      this.$emit("confirmed", this.message);
      this.close();
    },
  },
};
</script>

<style scoped>
.popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  padding: 32px 16px 120px;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  color: black;
  padding: 40px;
  position: relative;
  width: 100%;
  border-radius: 5px;
  box-shadow: 0px 5px 5px rgb(0, 0, 0, 0.2);

  .pop-up-close {
    position: absolute;
    display: flex;
    height: 20px;
    width: 20px;
    justify-content: center;
    align-items: center;
    top: 3px;
    right: 3px;
    font-size: 2rem;
    color: grey;
    cursor: pointer;
  }
}

.close-btn {
  margin-top: 6px;
  width: 30%;
  padding: 3px;
  background-color: #515d78;
  color: white;
  border-radius: 5px;
}
</style>
