<template>
  <v-container fluid>
    <v-row class="row1">
      <v-col class="col-info" cols="12" sm="4">
        <ProjectDetailInfo />
      </v-col>
      <v-col class="col-consult" cols="12" sm="4">
        <ProjectDetailCardConsultas :info="$store.projectsStore.state.selectedConsultasInfo" />
      </v-col>
      <v-col class="col-cardRDI" cols="12" sm="4">
        <ProjectDetailCardRDI :info="$store.projectsStore.state.selectedRdiInfo" />
      </v-col>
    </v-row>

    <!-- Row2 -->
    <v-row class="row2">
      <v-col cols="12" sm="12" xs="3">
        <div>
          <v-card fill-height style="width: 100%; min-height: 100%">
            <OpenWeather></OpenWeather>
          </v-card>
        </div>
      </v-col>

    </v-row>
    <!-- Row3 -->
    <v-row align="start" >
      <v-col cols="12" sm="12">
        <v-card class="project-list-card">
          <v-card-text>
            <div class="d-flex align-center">
              <v-icon class="mr-2">mdi-folder</v-icon>
              <div class="text-left">Proyectos</div>
            </div>
            <v-row >
              <v-col class="col-listItemHome" v-for="(project, projectIndex) in $store.projectsStore.state.projectsList" :key="projectIndex" cols="12" sm="4">
                <ProjectListItemHome :info="project" :projectIndex="projectIndex" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  // TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import ProjectDetailCardConsultas from "./ProjectDetailCardConsultas";
// import ProjectDetailCardAvance from "./ProjectDetailCardAvance";
// import ProjectDetailCardAdicionales from "./ProjectDetailCardAdicionales";
import ProjectDetailCardRDI from "./ProjectDetailCardRDI";
import ProjectDetailInfo from "./ProjectDetailInfo";
import ProjectListItemHome from "@/components/Views/Projects/ProjectListItemHome";
import Chart from "chart.js";
import { updateProjectBoard } from "@/helpers/api/project";
import OpenWeather from "../../../Globals/Helpers/OpenWeather.vue";
// import WeatherModal from "@/components/Globals/Modals/Weather/WeatherModal.vue";
// import pdfjsLib from 'pdfjs-dist/legacy/build/pdf';

export default {
  name: "ProjectDetail",
  components: {
    ProjectDetailCardConsultas,
    // ProjectDetailCardAvance,
    // ProjectDetailCardAdicionales,
    ProjectDetailCardRDI,
    ProjectListItemHome,
    ProjectDetailInfo,
    OpenWeather,
    // WeatherModal,
    // TiptapVuetify
  },
  data: () => ({
    loading: false,
    projectStatus: "",
    editingProjectBoard: false,
    extensions: [
      History,
      // Table,
      // TableCell,
      // TableHeader,
      // TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    dataFetched: false, // Initialize as false
    userInput: "",
    iframeSrc: "",
    modalOpen: false, // Control the modal's visibility
    snackbar: false,
    snackbarTimeout: 3000, // 3000 milliseconds (3 seconds)
    editingPermission: false,
  }),
  // mounted() {
  // },
  async mounted() {
    //this.textCenter();
    // await this.fetchWeather();
  },
  created() {
    // Load the saved URL from localStorage when the component is created
    this.iframeSrc = localStorage.getItem("savedIframeSrc") || "";
  },

  methods: {
    chunkProjects(projects, chunkSize) {
      const result = [];
      for (let i = 0; i < projects.length; i += chunkSize) {
        result.push(projects.slice(i, i + chunkSize));
      }
      return result;
    },

    editProjectBoard() {
      if (this.$store.projectsStore.state.selectedProject.status_board) {
        this.projectStatus = this.$store.projectsStore.state.selectedProject.status_board.status_board;
      }
      this.editingProjectBoard = true;
    },
    async updateBoard() {
      this.loading = true;
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        status_board: this.projectStatus,
      };
      const board = await updateProjectBoard(data);
      if (board.code == 200) {
        let project = { ...this.$store.projectsStore.state.selectedProject };
        project.status_board = board.status;
        this.$store.projectsStore.commit("setSelectedProject", project);
      }
      this.editingProjectBoard = false;
      this.loading = false;
    },
    textCenter() {
      Chart.pluginService.register({
        beforeDraw: function (chart) {
          var width = chart.chart.width;
          var height = chart.chart.height;
          var ctx = chart.chart.ctx;

          ctx.restore();
          var fontSize = (height / 50).toFixed(2);
          ctx.font = fontSize + "em sans-serif";
          ctx.textBaseline = "middle";

          let text = "";
          if (
            chart.config.options.elements &&
            chart.config.options.elements.center &&
            chart.config.options.elements.center.text
          ) {
            text = chart.config.options.elements.center.text;
          }
          var textX = Math.round((width - ctx.measureText(text).width) / 2);
          var textY = height / 1.8;

          ctx.fillText(text, textX, textY);
          ctx.save();
        },
      });
    },
    updateIframeSrc() {
      // Extract the URL from the iframe input using regex
      const iframeSrcRegex = /src="([^"]*)"/;
      const match = this.userInput.match(iframeSrcRegex);

      if (match && match[1]) {
        this.iframeSrc = match[1];
        localStorage.setItem("savedIframeSrc", this.iframeSrc);
        this.modalOpen = false;
      } else {
        alert("El iframe es inválido");
      }
    },
    openModal() {
      // Open the modal
      this.modalOpen = true;
      const axios = require("axios");
      const options = {
        method: "GET",
        url: "https://forecast9.p.rapidapi.com/",
        headers: {
          "X-RapidAPI-Key": "d829d42704msha49bd4a49e5f5f9p1253e9jsnb98c7d629780",
          "X-RapidAPI-Host": "forecast9.p.rapidapi.com",
        },
      };

      try {
        const response = axios.request(options);
        console.log(response.data);
      } catch (error) {
        console.error(error);
      }
    },
    closeModal() {
      // Close the modal
      this.modalOpen = false;
    },
    sendGreetings() {
      console.log("sending congrats");
      this.snackbar = true;
    },
    showEditingPermission() {
      this.editingPermission = !this.editingPermission;
    },
  },
};
</script>

<style>
.row1 {
  min-height: 400px;
}

.row2 {
  max-height: 330px;
}

.text-primary {
  color: #515d78;
}

.button-project-detail-card {
  border-radius: 8px;
  position: relative;
  z-index: 1;
  height: auto !important;
}

.button-project-detail-card:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(0deg, rgba(2, 0, 36, 0.45) 0%, rgba(0, 0, 0, 0) 100%);
  z-index: 2;
}

.button-project-detail-card span.v-btn__content {
  z-index: 3;
}

.icon-general-project-status {
  position: absolute;
  top: 5px;
  left: 5px;
  padding: 2px;
  background-color: #34bfa3;
  border-radius: 100%;
  border: 2px solid white;
}

#edit-project-status .tiptap-vuetify-editor__content {
  min-height: 150px;
  padding: 0;
}

#edit-project-status .tiptap-vuetify-editor__content .ProseMirror {
  padding: 15px;
  margin: 0 !important;
  height: 100%;
  min-height: 150px;
}

#edit-project-status .tiptap-vuetify-editor__content .ProseMirror p {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.status-board-info p {
  margin-bottom: 0;
}

.status-board-info table {
  width: 100%;
  border-collapse: collapse;
  margin: 5px 0px;
}

.status-board-info table td {
  border: 1px solid grey;
  padding: 4px;
}

/* FAVRIC CSS*/
.map-card {
  text-align: center;
  height: 300px;
}

.weather-card {
  text-align: center;
  height: 200px;

  .weather-title {
    font-size: 14px;
    color: #515d78;
    display: flex;
    justify-content: center;
  }
}

.v-card {
  border-radius: 4px !important;
}

.update-button,
.update-btn {
  background-color: #007bff;
  color: #fff;
  padding: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.modal-dialog {
  display: flex;
  justify-content: center;
  align-items: center;
}

.update-card {
  background-color: #f0f0f0;
  padding: 20px;
  border-radius: 5px;
}

.iframe-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.top-card {
  border-radius: 4px !important;
  height: 190px;
  background-color: #515d78 !important;
}

.bottom-card {
  border-radius: 10px !important;
  height: 190px;
  background-color: #d9d9d9 !important;
}

.spacer {
  height: 20px;
}

.weather-img {
  margin-top: 10px;
  width: 120px;
}

.weather-temp {
  margin-top: 30px;
  font-size: 2rem;
  font-weight: bold;
  color: white;
}

.weather-description {
  margin-top: -10px;
  color: white;
  font-size: 1rem;
}

.group-icon {
  font-size: 70px !important;
}

.group-top-col {
  text-align: center;
}

.group-bottom-col {
  /* margin-left: 40px; */
  padding-top: 20px;
}

.group-bottom-text {
  margin-top: -20px !important;
}

.larger-text {
  font-weight: bold;
  font-size: 2rem;
  margin-top: 20px !important;
}

.birthday-card {
  border-radius: 10px !important;
  height: 390px;
}

.birthdays-title {
  color: #5a6680;
  /* Color for the title */
  font-size: 1.5em;
  /* Adjust the font size as needed */
  font-weight: bold;
  margin-bottom: 10px;
  /* Add some space below the title */
}

.person-name {
  color: #5a6680;
  font-size: 1.1em;
  /* Adjust the font size as needed */
  font-weight: bold;
}

.person-date {
  color: #5a6680;
  font-size: 0.9em;
  margin-top: -40px;
}

.send-greetings-btn {
  border-radius: 4px;
  background-color: #5a6680 !important;
  /* Button color */
  color: white !important;
  /* Text color */
}

.project-list-card {
  border-radius: 4px !important;
  padding: 20px;
}

.project-row {
  margin-bottom: 20px;
  /* Adjust margin as needed */
}

.project-name {
  font-size: 1.5em;
  /* Adjust font size as needed */
  font-weight: bold;
}

.larger-row {
  margin-top: 20px;
  /* Add space between the rows */
}

.project-card {
  height: 220px;
  border-radius: 5px !important;
  background-color: #f8f8f8 !important;
  border-left: 9px #515d78 solid !important;
}

.weather-forecast-button {
  position: absolute;
  bottom: 8px;
  /* Adjust the distance from the bottom */
  right: 8px;
  /* Adjust the distance from the right */
}

.editingPermissionModal {
  position: fixed;
  overflow-y: auto;
  top: 0;
  left: 0;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  justify-content: center;
  align-items: center;
}

.editing-content {
  background-color: white;
  color: black;
  padding: 10px;
  position: relative;
  width: 800px;
  padding: 20px 20px 20px 20px;
  border-radius: 5px;
  box-shadow: 0px 5px 5px rgb(0, 0, 0, 0.2);

  .button-close {
    position: absolute;
    display: flex;
    top: 10px;
    right: 2px;
  }
}

.blue-squares {
  border-style: solid;
  border-width: 2px;
  border-radius: 4px;
  color: #1976d2;
  padding: 10px 0px 0px 10px;
  margin-bottom: 15px;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }
}

.btn-editing-permission {
  display: flex;
  justify-content: end;

  .btn-canceled {
    border-radius: 35px;
    background-color: white !important;
    color: #515d78;
  }

  .btn-confirmed {
    border-radius: 35px;
    background-color: #515d78 !important;
    color: white;
  }
}

@media (min-width:600px) and (max-width: 800px) {
  .col-12 {
    width: 700px;
  }
  .col-info{
    max-width: 100%;
  }
  .col-consult{
    min-width: 100%;
  }
  .col-cardRDI{
    min-width: 100%;
  }
  .editingPermissionModal {
    overflow: scroll;
  }

  .editing-content {
    width: 90%;
    height: 80%;
    overflow: scroll;
  }

  .button-close {
    font-size: 20px;
  }
  .col-listItemHome{
    max-width: 100%;
  }
}

@media  (min-width:800px) and (max-width: 900px) {
  .col-listItemHome{
    max-width: 50%;
    min-width: 50%;
  }
}
</style>
