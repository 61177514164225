<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.usersStore.state.editUserModal"
      max-width="800"
      scrollable
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-account-plus-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Editar Usuario</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Nombre</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.name"
                    label="Nombre"
                    placeholder="Ingrese nombre del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Imagen de Perfil</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-file-input
                    v-model="newUserData.avatar"
                    show-size
                    outlined
                    dense
                    accept=".png,.jpg,.jpeg"
                    placeholder="Seleccione imagen de perfil"
                    prepend-inner-icon="mdi-image"
                    prepend-icon=""
                  ></v-file-input>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Email</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.email"
                    label="Email"
                    placeholder="Ingrese email del usuario"
                    outlined
                    required
                    disabled
                    dense
                    :rules="emailRules"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Teléfono</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.phone"
                    label="Teléfono"
                    placeholder="Ingrese teléfono del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="3" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Dirección</h2>
                </v-col>
                <v-col cols="12" md="9" class="py-0">
                  <v-text-field
                    v-model="newUserData.address"
                    label="Dirección"
                    placeholder="Ingrese dirección del usuario"
                    outlined
                    required
                    dense
                    :rules="requiredRule"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#515d78"
                      raised
                      dark
                      :loading="loadingData"
                      @click="sendForm"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {updateUser} from '@/helpers/api/user'
import {getProjectsByUser} from '@/helpers/api/project'

export default {
  name: 'EditUserModal',
  data: () => ({
    validform: true,
    newUserData: {
      user_id: null,
      name: null,
      email: null,
      phone: null,
      address: null,
      default_job: 2,
      avatar: null,
    },
    loadingData: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    emailRules: [
      v => !!v || 'Email es obligatorio',
      v => /.+@.+\..+/.test(v) || 'Email debe ser válido',
    ],
  }),
  mounted(){
    const projectID = this.$store.projectsStore.state.selectedProject.id
    this.$store.usersStore.dispatch('jobTypesGet', projectID)
    if(!this.$store.projectsStore.state.projectsList){
      this.getProjectList()
    }
    this.setUserInfo()
  },
  methods: {
    setUserInfo(){
      if(this.$store.usersStore.state.selectedUserInfo){
        this.newUserData = {
          user_id: this.$store.usersStore.state.selectedUserInfo.id,
          name: this.$store.usersStore.state.selectedUserInfo.name,
          email: this.$store.usersStore.state.selectedUserInfo.email,
          phone: this.$store.usersStore.state.selectedUserInfo.info.phone,
          address: this.$store.usersStore.state.selectedUserInfo.info.address,
          default_job: this.$store.usersStore.state.selectedUserInfo.projectjob.job_type_id,
          avatar: null,
        }
      }
    },
    closeModal(){
      this.$store.usersStore.commit('setSelectedUserInfo', null)
      this.$store.usersStore.commit('setEditUserModal', false)
      this.newUserData = {
        user_id: null,
        name: null,
        email: null,
        phone: null,
        address: null,
        default_job: 2,
        avatar: null,
      }
      this.validform = true
      this.loadingData = false
    },
    async getProjectList(){
      this.loading = true
      const projects = await getProjectsByUser()
      if(projects.code === 200){
        this.$store.projectsStore.commit('setProjectsList', projects.projects)
      }
      this.loading = false
    },
    async sendForm(){
      if(this.$refs.form.validate()){
        this.loadingData = true
        const formData = new FormData()
        Object.keys(this.newUserData).forEach((key) => {
          if(this.newUserData[key]){
            formData.append(key, this.newUserData[key])
          }
        })
        const user = await updateUser(formData)
        if(user.code === 200){
          const data = {
            project_id: this.$store.projectsStore.state.selectedProject.id,
            start: 0,
            limit: 20
          }
          this.$store.usersStore.dispatch('getUsersList', data)
          this.closeModal()
        }
        this.loadingData = false
      }
    }
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #515d78;
  }
  .info-icon{
    background-color: #515d78;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #515d78;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  /deep/ .custom-input-numbers .v-input__slot{padding:0!important;}
  /deep/ .custom-input-numbers .v-input__prepend-inner,
  /deep/ .custom-input-numbers .v-input__append-inner,
  /deep/ .custom-text-input .v-input__append-inner{margin-top:0!important;}
  /deep/ .custom-input-numbers input {text-align: center }
</style>