<template>
	<v-container fluid class="px-0">
		<v-row v-if="$store.rdiStore.state.rdiGraphsDataCountBySpecialty.status === 99">
			<v-col>
				<div class="graph-container rounded-lg pa-3 text-center py-8">
					<v-progress-circular
						:size="70"
						:width="4"
						color="#515d78"
						indeterminate
					></v-progress-circular>
					<p class="mb-0 mt-5 text--disabled">Cargando Información para Gráficos</p>
				</div>
			</v-col>
		</v-row>
		<v-row v-else>
			<v-col>
				<div class="graph-container rounded-lg pa-3 text-center">
					<h3 class="light-blue-text">N° de RDIs por Especialidad</h3>
					<p class="text--disabled caption">Se muestran RDI creadas, pendiendes y finalizadas</p>
					<HorizontalBarChart
						v-if="$store.rdiStore.state.rdiGraphsDataCountBySpecialty.data"
						:chartdata="{
							labels: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.specialties,
							datasets: [
								{
									backgroundColor: '#EAB261',
									label: 'Total',
									data: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.rdis_count
								},
								{
									backgroundColor: '#F45771',
									label: 'Pendientes',
									data: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.rdis_pending
								},
								{
									backgroundColor: '#34BFA3',
									label: 'Finalizadas',
									data: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.rdis_ended
								}
							]
						}"
						:options="{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							scales: {
								yAxes: [{
									ticks: {
										beginAtZero: true
									}
								}]
							}
						}"
					/>
				</div>
			</v-col>
			<v-col>
				<div class="graph-container rounded-lg pa-3 text-center">
					<h3 class="light-blue-text">Tiempo de Solución Promedio</h3>
					<p class="text--disabled caption">Para RDI según especialidad, expresado en días</p>
					<BarChart
						v-if="$store.rdiStore.state.rdiGraphsDataCountBySpecialty.data"
						:chartdata="{
							labels: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.specialties,
							datasets: [
								{
									backgroundColor: '#515d78',
									label: 'Dias',
									data: $store.rdiStore.state.rdiGraphsDataCountBySpecialty.data.time_average
								}
							]
						}"
						:options="{
							responsive: true,
							maintainAspectRatio: false,
							legend: {
								display: false
							},
							scales: {
								yAxes: [{
									ticks: {
										beginAtZero: true
									}
								}]
							}
						}"
					/>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

import BarChart from '@/components/Charts/Bar'
import HorizontalBarChart from '@/components/Charts/HorizontalBar'

export default {
	name: 'RdiGraphs',
	components: {BarChart, HorizontalBarChart},
	data: () => ({
		loading: false
	}),
	mounted(){
	},
	methods: {
	}
};
</script>

<style scoped>
  .light-blue-text{color: #515d78;}
	.graph-container{
		background: white;
		border: 1px solid #515d78;
	}
	.listing-loading-bar{
		position: absolute;
		top: 0;
	}
</style>