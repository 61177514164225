const endpoints = {
	login: '/auth/login',
	resetPassRequest: '/auth/resetpassrequest',
	resetPass: '/auth/resetpass',
	checkToken: '/auth/checktoken',
	checkFirstTokenAndChangePassword: '/auth/checkFirstTokenAndChangePassword',
	userInfo: '/user/details',
	usersList: '/user/list',
	setCapabilitiesByUserProject: '/user/setCapabilitiesByUserProject',
	getCapabilitiesByUserProject: '/user/getCapabilitiesByUserProject',
	setCapabilitiesByUserCompany: '/user/setCapabilitiesByUserCompany',
	getCapabilitiesByUserCompany: '/user/getCapabilitiesByUserCompany',
	removeUserFromProject: '/user/removeUserFromProject',
	setUserProjects: '/user/setUserProjects',
	getJobTypes: '/user/getJobTypes',
	createUser: '/user/create',
	updateUser: '/user/update',
	getUserByEmail: '/user/getUserByEmail',
	saveUserProjectOptions: '/user/setUserProjectOptions',
	createCompany: '/company/create',
	getCompaniesByUserID: '/company/getCompaniesByUserID',
	createProject: '/project/create',
	updateProject: '/project/update',
	getProjectTypes: '/project/getProjectTypes',
	getProjectByID: '/project/getProjectByID',
	searchUsersByEmail: '/project/searchUsersByEmail',
	getProjectsByUser: '/project/getProjectsByUser',
	getClosedProjectsByUser: '/project/getClosedProjectsByUser',
	getProjectsByUserID: '/project/getProjectsByUserID',
	getProjectSubjects: '/project/getProjectSubjects',
	updateProjectBoard: '/project/updateProjectBoard',
	closeProject: '/project/closeProject',
	openProject: '/project/openProject',
	createTask: '/tasks/create',
	listTasks: '/tasks/list',
	nextCreatedTasks: '/tasks/nextMyCreatedTasks',
	nextPendingTasks: '/tasks/nextMyPendingTasks',
	nextEndedTasks: '/tasks/nextMyEndedTasks',
	listTasksByUser: '/tasks/listTasksByUser',
	updateTask: '/tasks/update',
	markTaskAsRead: '/tasks/markTaskAsRead',
	listMyTasks: '/tasks/listMyTasks',
	getTareaByID: '/tasks/getTareaByID',
	addInteraction: '/tasks/addInteraction',
	getPublicUrl: '/tasks/getPublicUrl',
	closeTask: '/tasks/closeTask',
	deleteTask: '/tasks/deleteTask',
	sendTaskMail: '/tasks/sendTaskMail',
	addParticipant: '/tasks/addParticipant',
	removeParticipant: '/tasks/removeParticipant',
	downloadPDFTask: '/tasks/getTaskPDF',
	changeResponsable: '/tasks/changeResponsable',
	changeVisualizationStatus: '/tasks/changeVisualizationStatus',
	fileUpload: '/files/fileUpload',
	getProjectStorageSize: '/files/getProjectStorageSize',
	getZippedFiles: '/files/getZippedFiles',
	getDashboard: '/dashboard/getDashboard',
	getDashboardRDI: '/dashboard/getDashboardRDI',
	createRdi: '/rdi/create',
	listRdi: '/rdi/list',
	updateRdi: '/rdi/update',
	deleteRdi: '/rdi/deleteRdi',
	getRdiByID: '/rdi/getRdiByID',
	addRDIInteraction: '/rdi/addInteraction',
	closeRDI: '/rdi/closeRDI',
	markRdiAsRead: '/rdi/markRdiAsRead',
	downloadPDF: '/rdi/getRdiPDF',
	getRDIGraphs: '/rdi/getRDIGraphs',
	sendRDIMail: '/rdi/sendRDIMail',
	changeVisualizationStatusRdi: '/rdi/changeVisualizationStatus',
	exportStatusRdiByProject: '/exports/exportStatusRdiByProject',
	exportTasksByProject: '/exports/exportTasksByProject',
	projectUserExport: '/exports/projectUserExport',
}
export {endpoints}