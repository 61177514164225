<template>
  <div class="text-center">
    <v-dialog
      v-model="$store.usersStore.state.editPermisosEmpresaUserModal"
      max-width="800"
      scrollable
      persistent
    >
      <v-card
        :loading="loading_user_companies"
        :disabled="loading_user_companies"
      >
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2"
                  >mdi-account-plus-outline</v-icon
                >
                <p class="mb-0 font-weight-bold text-primary title">
                  Editar Permisos Empresa Usuario:
                  {{ $store.usersStore.state.selectedUserInfo.name }}
                </p>
              </div>
              <v-btn
                class="mx-2"
                fab
                dark
                small
                color="#F4516C"
                @click="closeModal"
              >
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-col cols="12" class="py-0">
              <h2 class="mb-0 caption">
                Aquí puedes editar los permisos nivel empresa que tendrán los
                usuarios al ingresar a la plataforma.
              </h2>
            </v-col>
            <v-form ref="form" v-model="validform" class="mt-6">
              <v-row>
                <v-col cols="12" class="py-0">
                  <h2 class="mb-0 subtitle-1 font-weight-bold">Empresa</h2>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0">
                  <div class="d-flex flex-row">
                    <v-select
                      v-model="selected_company"
                      :items="user_companies"
                      :loading="loading_user_companies"
                      item-text="name"
                      item-value="id"
                      label="Seleccione Empresa"
                      single-line
                      hint="Selecciona la empresa en la que deseas editar los permisos del usuario"
                      persistent-hint
                      outlined
                      dense
                      required
                      @change="getCapabilities"
                    ></v-select>
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="#515d78"
                      @click="
                        $store.projectsStore.commit('setNewCompanyModal', true)
                      "
                    >
                      <v-icon dark>mdi-plus</v-icon>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
              <v-row v-if="showAlert">
                <v-col cols="12" class="py-0 mt-3">
									<v-alert dense outlined type="warning" class="mb-0 " icon="mdi-alert-outline">
										<p class="caption mb-0">Este usuario actualmente no tiene asignada la empresa seleccionada, al confirmar estos permisos se asignará a la empresa automáticamente.</p>
									</v-alert>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="py-0 my-3">
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" class="py-0">
                  <div class="permisos-type-container">
                    <h2
                      class="mb-0 subtitle-1 font-weight-bold light-blue-text"
                    >
                      Permisos Nivel Empresa
                    </h2>
                    <v-row>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="permisos.create_company"
                          label="Crear Empresas"
                          hide-details
                          dense
                        ></v-checkbox>
                        <v-checkbox
                          v-model="permisos.edit_company"
                          label="Editar Empresa"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="permisos.create_project"
                          label="Crear Proyectos"
                          hide-details
                          dense
                        ></v-checkbox>
                        <v-checkbox
                          v-model="permisos.edit_project"
                          label="Editar Proyectos"
                          hide-details
                          dense
                        ></v-checkbox>
                        <v-checkbox
                          v-model="permisos.status_project"
                          label="Modificar Estado Proyectos"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                      <v-col cols="12" sm="4">
                        <v-checkbox
                          v-model="permisos.set_user_projects"
                          label="Editar Proyectos Usuarios"
                          hide-details
                          dense
                        ></v-checkbox>
                        <v-checkbox
                          v-model="permisos.set_user_permissions"
                          label="Editar Permisos Usuarios"
                          hide-details
                          dense
                        ></v-checkbox>
                      </v-col>
                    </v-row>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <div class="d-flex flex-row justify-end">
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="white"
                      raised
                      @click="closeModal"
                    >
                      <p class="mb-0 text-primary">Cancelar</p>
                    </v-btn>
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#515d78"
                      raised
                      dark
                      :loading="loadingData"
                      @click="setCapabilities"
                    >
                      <p class="mb-0">Confirmar</p>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { setCapabilitiesByUserCompany, getCapabilitiesByUserCompany } from "@/helpers/api/user";
import { getCompaniesByUserID } from "@/helpers/api/company";

export default {
  name: "EditPermisosEmpresaUserModal",
  data: () => ({
    validform: true,
    user_companies: [],
    projects_id: [],
    selected_company: null,
    loading_user_companies: true,
    loading_project_list: false,
    permisos: {
			edit_company: false,
			edit_project: false,
			create_company: false,
			create_project: false,
			status_project: false,
			set_user_projects: false,
			set_user_permissions: false,
		},
    loadingData: false,
		showAlert: false,
  }),
  mounted() {
    this.getUserCompanies();
  },
  methods: {
    setJobTypeSelected(select) {
      if (select && select.default_capabilities) {
        this.permisos = select.default_capabilities;
      }
    },
    async getUserCompanies() {
      this.loading_user_companies = true;
      const companies = await getCompaniesByUserID({
        user_id: this.$store.usersStore.state.selectedUserInfo.id,
      });
      if (companies.code == 200) {
        this.user_companies = companies.companies.map((item) => item.company)
        this.selected_company = this.user_companies[0].id
        this.projects_id = companies.companies.map((item) => {
          return item.id;
        });
				
        await this.getCapabilities();
      }
      this.loading_user_companies = false;
    },
    async setCapabilities() {
			this.loadingData = true
      const data = {
        user_id: this.$store.usersStore.state.selectedUserInfo.id,
        company_id: this.selected_company,
        capabilities: this.permisos,
      };
      const capabilities = await setCapabilitiesByUserCompany(data);
      if (capabilities.code == 200) {
        const datas = {
          message:
            "Se han actualizado los permisos del usuario para la empresa seleccionada.",
          title: "Usuario Actualizado",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", datas);
      }
			this.loadingData = false
    },
    getCapabilities() {
      return new Promise((resolve) => {
				this.showAlert = false
        const data = {
          user_id: this.$store.usersStore.state.selectedUserInfo.id,
          company_id: this.selected_company,
        }
        getCapabilitiesByUserCompany(data)
          .then((capabilities) => {
						if(capabilities.user_company){
							this.permisos = Object.assign(
								this.permisos,
								capabilities.user_company.capabilities
							);
						}
						else{
							this.showAlert = true
							const datas = {
								message:
									"Este usuario actualmente no tiene asignada la empresa seleccionada, al guardar estos permisos se le asignará a esa empresa automáticamente.",
								title: "Alerta",
								created: new Date(),
								type: "warning",
								icon: "mdi-alert-outline",
								color: "#fb8c00",
							};
							this.$store.globalStore.dispatch("addMessage", datas);
						}
            resolve(true)
          })
          .catch(() => {
            resolve(false)
          })
      })
    },
    closeModal() {
      this.$store.usersStore.commit("setSelectedUserInfo", null);
      this.$store.usersStore.commit("setEditPermisosEmpresaUserModal", false);
      this.permisos = {
        edit_company: false,
        edit_project: false,
        create_company: false,
        create_project: false,
        set_user_projects: false,
        set_user_permissions: false,
      };
      this.validform = true;
      this.loadingData = false;
			this.showAlert = false
    },
  },
};
</script>

<style scoped>
.info-container {
  color: #606060;
}
.text-primary {
  color: #515d78;
}
.info-icon {
  background-color: #515d78;
  border-radius: 25px;
  padding: 3px;
  width: 35px;
  height: 35px;
}
.info-icon.small-icon {
  width: 25px;
  height: 25px;
}
.info-img {
  width: 100%;
  max-height: 50px;
  object-fit: contain;
}
.adjuntos-container {
  background-color: #efefef;
  max-height: 95px;
  overflow-y: scroll;
}
.interaction-container {
  width: 100%;
  background-color: #e5e9f2;
  border-radius: 5px;
  position: relative;
  padding: 12px;
}
.user-avatar.small {
  width: 25px;
  height: 25px;
}
.light-blue-text {
  color: #515d78;
}
.permisos-type-container {
  border: 1px solid #515d78;
  border-radius: 4px;
  padding: 10px;
}
/deep/ .permisos-type-container .v-input--checkbox label.v-label {
  font-size: 14px;
}
</style>