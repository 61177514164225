<template>
	<v-container fluid class="px-0" id="listado-consultas">

		<v-card class="mb-2 py-1">
			<v-card-text class="header-table-card py-2">
				<v-row>
					<v-col cols="1">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;" @click="toggleOrder">
							<v-icon color="#515d78" small class="mr-1">{{getIconOrder()}}</v-icon>
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Número</p>
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;" @click="toggleOrder">
							<v-icon color="#515d78" small class="mr-1">{{getIconOrder()}}</v-icon>
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Detalles</p>
						</v-btn>
					</v-col>
					<!-- <v-col cols="2">
						<v-menu v-model="specialtyMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Especialidad</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="specialtyFilter" v-if="$store.projectsStore.state.selectedProject">
									<v-list-item
										style="min-height: auto;"
										v-for="(user, index) in $store.projectsStore.state.selectedProject.specialties"
										:key="index"
										:value="user.id">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{user.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<div class="d-flex justify-center" style="width: 100%" v-else>
									<v-progress-circular
										:size="20"
										:width="2"
										color="#515d78"
										indeterminate
									></v-progress-circular>
								</div>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2" @click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col> -->
					<v-col cols="2">
						<v-menu v-model="subjectMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" style="height: auto; flex: 1;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Título Adicional</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="subjectFilter" v-if="$store.adicionalesStore.state.additionalTypes">
									<v-list-item
										style="min-height: auto;"
										v-for="(type, index) in $store.adicionalesStore.state.additionalTypes"
										:key="index"
										:value="type.id">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{type.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<div class="d-flex justify-center" style="width: 100%" v-else>
									<v-progress-circular
										:size="20"
										:width="2"
										color="#515d78"
										indeterminate
									></v-progress-circular>
								</div>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2"
										:loading="!$store.projectsStore.state.projectSubjects"
										@click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
					<v-col cols="2" class="px-0">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Adicional</p>
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-menu v-model="estadoMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" style="height: auto; flex: 1;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Estado</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Estado</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="estadoFilter">
									<v-list-item
										style="min-height: auto;"
										v-for="(status, index) in [{value: 1, name: 'Vigente'}, {value: 2, name: 'Aceptado'}, {value: 3, name: 'Solicita Anular'}, {value: 4, name: 'Cancelado'}]"
										:key="index"
										:value="status.value">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{status.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2" @click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
					<v-col cols="2">
						<v-menu v-model="responsableMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" style="height: auto; flex: 1;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Responsable</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="responsableFilter">
									<v-list-item
										style="min-height: auto;"
										v-for="(resp, index) in [{value: 2, name: 'Constructora'}, {value: 1, name: 'ITO'}]"
										:key="index"
										:value="resp.value">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{resp.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2" @click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
				</v-row>
			</v-card-text>
			<v-progress-linear
				v-if="$store.adicionalesStore.state.listado.status === 99"
				class="listing-loading-bar"
				indeterminate
				color="#515d78"
			></v-progress-linear>
		</v-card>
		<div v-if="$store.adicionalesStore.state.listado.status === 1 || $store.adicionalesStore.state.listado.count > 0" class="listing-scroll">
			<v-card class="tarea-card mb-2" v-for="(additional, index) in $store.adicionalesStore.state.listado.data" :key="index"
			style="overflow: hidden;"
			@click.stop="viewAdditional(additional.id, $options.filters.seenByMe(additional.seen_by_me))">
				<v-card-text class="header-table-card py-2"
					:class="additional.status === 3 ? 'status-ended':''">
					<v-row align="center">
						<v-col cols="1">
							<div class="d-flex flex-column">
								<div class="d-flex flex-row icon-container">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top number"
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 white--text text-task-number caption">{{additional.number}}</p>
											</div>
										</template>
										<span>Adicional número {{additional.number}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top d-flex justify-center align-center" :class="$options.filters.seenByMeColor(additional.seen_by_me)"
												v-bind="attrs"
												v-on="on"
												@click.stop="changeVisualizationStatus(additional)">
												<v-icon color="white"
													:class="$options.filters.seenByMeIconAnimation(additional.seen_by_me)"
													v-if="!isLoadingChangeVisualizationStatus(additional.id)"
													small>mdi-bell</v-icon>
												<v-progress-circular
													v-else
													:width="2"
													:size="16"
													color="white"
													indeterminate
												></v-progress-circular>
											</div>
										</template>
										<span>{{$options.filters.seenByMeText(additional.seen_by_me)}} Adicional</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="caption font-weight-bold" style="margin-right: 1px">
								<small>Código:</small> {{additional.code}}
							</div>
							<div class="caption" style="margin-right: 1px">
								<small>Especialidades</small>
							</div>
							<div v-for="(specialty, index) in additional.specialties"
								:key="index" class="light-blue-text caption font-weight-bold d-inline-block" style="margin-right: 1px">
								<small>{{specialty.specialty.name}}</small><span v-if="index + 1 < additional.specialties.length">,</span>
							</div>
						</v-col>
						<v-col cols="3">
							<div class="text-primary-color font-weight-bold">
								{{additional.title}}
							</div>
						</v-col>
						<v-col cols="2" class="px-0">
							<div class="light-blue-text caption font-weight-bold" style="margin-right: 1px">
								{{getAdditionalType(additional.type)}}
							</div>
							<div class="d-flex flex-row justify-start align-center">
								<div class="d-flex flex-row align-center mr-1">
									<v-icon color="#515d78" class="mr-1" small>mdi-currency-usd</v-icon>
									<p class="mb-0 caption">
										Monto Actual:
										<span class="normal font-weight-bold">{{additional.last_revision.currency_info.code}}${{additional.last_revision.total_requested | toThousandFilter}}</span>
									</p>
								</div>
							</div>
							<div class="d-flex flex-row justify-start align-center">
								<div class="d-flex flex-row align-center mr-1">
									<v-icon color="#515d78" class="mr-1" small>mdi-clipboard-edit-outline</v-icon>
									<p class="mb-0 caption">Revisión: <span class="normal font-weight-bold">{{additional.last_revision.revision}}</span></p>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="d-flex flex-row align-center justify-start">
								<div class="d-flex flex-column flex-grow-1">
									<p class="mb-0 body-2 text--secondary caption">{{ additional.statustext }}</p>
									<p class="mb-0 body-2 text--secondary caption">Espera: <b>{{ additional.waitingtime }}</b></p>
									<div class="d-flex flex-row align-center justify-start mt-1">
										<div class="d-flex flex-row mr-1 rounded">
											<v-icon small color="#515d78" class="mr-1">mdi-calendar-range-outline</v-icon>
											<p class="mb-0 text--secondary caption">{{additional.created_at | toDate}}</p>
										</div>
										<div class="d-flex flex-row mr-1 rounded">
											<v-icon small color="#515d78" class="mr-1">mdi-clock-time-seven-outline</v-icon>
											<p class="mb-0 text--secondary caption">{{additional.created_at | toHour}}</p>
										</div>
									</div>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="status-container d-flex flex-row align-center">
								<v-img :src="setLights(additional.lightstatus)"
									max-height="50"
									max-width="35"
									contain
									class="mr-1"/>
								<div>
									<div>
										<v-tooltip bottom v-if="additional.status != 5">
											<template v-slot:activator="{ on, attrs }">
												<div class="d-flex flex-column mr-1 rounded px-1"
													v-bind="attrs"
													v-on="on">
													<div class="d-flex flex-row mr-1">
														<v-icon small :color="responseStatus(additional) ? '#fb8c00':'#3EBFA3'" class="mr-1">mdi-account-outline</v-icon>
														<p class="mb-0 font-weight-bold"
															:class="responseStatus(additional) ? 'medium':'normal'">{{additional.response_status | additionalResponsableUserType}}</p>
													</div>
													<p class="d-flex mb-0 caption align-center" v-if="additional.waitingresponsetime != 0">
														<v-icon x-small color="#515d78" class="mr-1">mdi-clock-time-seven-outline</v-icon>
														{{ additional.waitingresponsetime }}
													</p>
												</div>
											</template>
											<span>Responsable Actual - Hace: {{ additional.waitingresponsetime }}</span>
										</v-tooltip>
									</div>
									<div>
										<v-tooltip bottom v-if="additional.ended_at">
											<template v-slot:activator="{ on, attrs }">
												<div class="d-flex flex-row mr-1 rounded px-1 bg-normal mt-1"
													v-bind="attrs"
													v-on="on">
													<v-icon small color="white" class="mr-1">mdi-calendar-range-outline</v-icon>
													<p class="mb-0 caption white--text">{{additional.ended_at | toDate('YYYY-MM-DD HH:mm:ss')}}</p>
												</div>
											</template>
											<span>Fecha término: {{additional.ended_at | toDate('YYYY-MM-DD HH:mm:ss')}}</span>
										</v-tooltip>
									</div>
								</div>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-row align="center">
				<v-col cols="12">
					<v-pagination
						v-model="page"
						color="#515d78"
						:length="Math.ceil($store.rdiStore.state.listadoRDITotal/50)"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-card class="mb-2 py-2" v-for="i in 5" :key="i">
				<v-card-text class="py-2">
					<v-row>
						<v-col cols="1">
							<div class="d-flex flex-row">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-center align-center">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="3">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
		
	</v-container>
</template>

<script>
import moment from 'moment'
import {changeVisualizationStatus} from '@/helpers/api/additionals'

export default {
	name: 'ListadoRDI',
	components: {},
	props: {
		ended:{
			type: Boolean,
			default: false
		}
	},
  computed: {
    selectedProject() {
      return this.$store.projectsStore.state.selectedProject
    }
  },
  watch: {
    selectedProject(newData) {
      if(newData){
				this.getProjectSubjects()
      }
    }
  },
	data: () => ({
		specialtyFilter: [],
		specialtyMenu: false,
		subjectFilter: [],
		subjectMenu: false,
		responsableFilter: [],
		responsableMenu: false,
		estadoMenu: false,
		estadoFilter: [1, 2, 3, 4],
		page: 1,
	}),
	mounted(){
    if(this.$store.projectsStore.state.projectSubjects.length == 0){
      this.getProjectSubjects()
    }
	},
	methods: {
		getAdditionalType(additionalType){
			const found = this.$store.adicionalesStore.state.additionalTypes.find(type => type.id === additionalType)
			if(found){
				return found.name
			}
			return '--'
		},
		async changeVisualizationStatus(rdi){
			if(!this.isLoadingChangeVisualizationStatus(rdi.id)){
				let newStatus = 0
				if(!rdi.seen_by_me){
					newStatus = 1
				}
				else if(rdi.seen_by_me.seen_status < 2){
					newStatus = rdi.seen_by_me.seen_status + 1
				}
				else if(rdi.seen_by_me.seen_status === 2){
					newStatus = 0
				}

				this.$store.rdiStore.commit('addLoadingChangeVisualizationStatus', rdi.id)
				const data = {
					rdi_id: rdi.id,
					status: newStatus
				}
				const statusUpdate = await changeVisualizationStatus(data)
				if(statusUpdate.code === 200){
					// Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
					rdi.seen_by_me.seen_status = newStatus
				}
				this.$store.rdiStore.commit('removeLoadingChangeVisualizationStatus', rdi.id)
			}
		},
		isLoadingChangeVisualizationStatus(rdiID){
			const found = this.$store.rdiStore.state.loadingChangeVisualizationStatus.find((rdi_id) => rdi_id === rdiID)
			return found
		},
		clearFilters(){
			this.creatorFilter = []
			this.responsableFilter = []
		},
    async getProjectSubjects(){
			if(this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.id){
				this.$store.projectsStore.dispatch('getProjectSubjects', this.$store.projectsStore.state.selectedProject.id)
			}
    },
		getIconOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 3){
				return 'mdi-arrow-down-bold'
			}
			else if(this.$store.rdiStore.state.listingOrder === 2 || this.$store.rdiStore.state.listingOrder === 4){
				return 'mdi-arrow-up-bold'
			}
			return 'mdi-swap-vertical-bold'
		},
		toggleOrder(){
			this.$store.rdiStore.commit('setListingOrder')
			this.getAdditionalList()
		},
		getNameOrder(){
			if(this.$store.rdiStore.state.listingOrder === 1 || this.$store.rdiStore.state.listingOrder === 2){
				return 'Prioridad'
			}
			else if(this.$store.rdiStore.state.listingOrder === 3 || this.$store.rdiStore.state.listingOrder === 4){
				return 'Número'
			}
		},
		async getAdditionalList(){
			let project_id = null
			const page = this.page
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.adicionalesStore.dispatch('getAdditionals',
					{
						project_id, page,
						statuses: this.estadoFilter,
						filtered_specialties: this.specialtyFilter,
						filtered_responsable: this.responsableFilter
					})
			}
		},
		getTareaPriorityText(rdi){
			if(rdi.priority === 3){
				return 'Inmediata'
			}
			else if(rdi.priority === 2){
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(rdi){
			if(!rdi.critical_date){ return false }
			const now = moment()
			const rdi_date = moment(rdi.critical_date)
			return rdi_date.diff(now, 'days') <= 2
		},
		setLights(data){
			return require('@/assets/Svg/VistaListado/Semaforo'+data+'.svg')
		},
		filterList(){
			this.getAdditionalList()
			this.specialtyMenu = false
			this.subjectMenu = false
			this.responsableMenu = false
		},
		async viewAdditional(additional_id, seen_by_me){
			this.$store.adicionalesStore.dispatch('getAdditionalByID', {additional_id, seen_by_me})
		},
		changePage(){
			this.page
			this.getAdditionalList()
		},
		responseStatus(rdi){
			if(rdi.response_status == 1){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id == 4
			}
			else if(rdi.response_status == 2){
				return this.$store.projectsStore.state.selectedProject && this.$store.projectsStore.state.selectedProject.job_type && this.$store.projectsStore.state.selectedProject.job_type.id != 4
			}
			return false
		}
	}
};
</script>

<style scoped>
	#listado-consultas{align-self: stretch;}
	.text-primary-color{
		color: #515d78;
	}
	.light-blue-text{color: #515d78;}
	.bg-normal{ background-color: #3EBFA3; }
	.inner-text{
		font-size: 10px;
	}
	.status-container{
		position: relative;
	}
	.icon-top.icon-status{
		position: absolute;
		width: 20px;
		height: 20px;
		left: -20px;
	}
	.icon-top{
		padding: 2px;
		margin: 0 2px;
		border-radius: 100%;
	}
	.high{
		color: #F4516C;
	}
	.medium{
			color: #fb8c00;
		}
	.normal{
			color: #3EBFA3;
		}
	.icon-top.high{
		background-color: #F4516C;
	}
	.icon-top.medium{
		background-color: #fb8c00;
	}
	.icon-top.normal{
		background-color: #3EBFA3;
	}
	.icon-top.primary{
		background-color: #515d78;
	}
	.icon-top.light-blue{
		background-color: #515d78;
	}
	.counter-tareas{
		background-color: #F4516C;
		border-radius: 100%;
		padding: 5px;
		width: 30px;
		height: 30px;
		line-height: 20px;
		color: white;
		font-size: 20px;
		text-align: center;
	}
	.status-icon{
		border: 1px solid #515d78;
		border-radius: 100%;
	}
	/deep/ .status-icon .inner{
		border: 1px solid #f2f2f2;
		border-radius: 100%;
		width: 14px;
		height: 14px;
	}
	/deep/ .status-icon .inner.danger{
		background-color: #F4516C;
	}
	/deep/ .header-table-card .table-header-button:hover:before{
		opacity: 0;
	}
	.text-tarea-field{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3; /* number of lines to show */
		-webkit-box-orient: vertical;
		max-height: 50px;
	}
	/deep/ .text-tarea-field h1,/deep/ .text-tarea-field h2,/deep/ .text-tarea-field h3{
		font-size: 1em!important;
	}
	.icon-container{flex-wrap: wrap;}
	.icon-container .icon-top{
		flex: 1 0 30%;
		width: 24px;
		max-width: 24px;
		height: 24px;
		max-height: 24px;
		margin-bottom: 2px;
	}
	.icon-container .icon-top.number{
		background-color: #515d78;
		text-align: center;
		line-height: 20px;
	}
	.text-task-number.caption{ font-size: 10px!important; }
	.icon-container .icon-top.total{
		background-color: #515d78;
		text-align: center;
		line-height: 20px;
	}
	.critical-date-container{
		background-color: #515d78;
	}
	.listing-loading-bar{
		position: absolute;
		bottom: 0;
	}
	.header-table-card{
		border: 1px solid white;
	}
	.status-ended{
		border: 1px solid #4caf50;
	}
/*	.listing-scroll{
		overflow-y: scroll;
		height: 100%;
	}*/
	.tarea-card{
		position: relative;
	}
	.incognito-container{
		width: 0;
		height: 0;
		border-top: 33px solid #616161;
		border-right: 33px solid transparent;
		border-radius: 0!important;
		position: absolute;
		left: 0px;
	}
	.incognito-container .v-icon{
		position: absolute;
		top: -32px;
		left: 1px;
	}
	.float-total-icon{
    position: absolute;
    top: 0;
    right: 5px;
    bottom: 0;
	}
	.near-deadline{
		background-color: #fb8c00;
	}
</style>