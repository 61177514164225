<template>
	<v-container class="d-flex flex-column align-start" fluid fill-height
		:class="this.$store.consultasStore.state.selectedType === 3 || this.$store.consultasStore.state.selectedType === 4 ? 'scrollable-list-bak':''">
		<!-- <FullHeader /> -->
		<ConsultasContainer />
	</v-container>
</template>

<script>
// import FullHeader from '../../Globals/Headers/FullHeader'
import ConsultasContainer from './ConsultasContainer'

export default {
	name: 'ConsultasIndex',
	components: {
		// FullHeader, 
		ConsultasContainer
	},
	data: () => ({
		loading: false
	}),
	mounted(){
	},
	methods: {
	}
};
</script>


<style>
	.scrollable-list{
		flex: 1 1 auto;
		height: 100%;
	}
	.scrollable-list #consultas-container{
		flex: 1 1 auto;
		height: 100%;
	}
</style>