import {endpoints} from './endpoints'
import request from '../request'
// import stores from '../../stores/index.js'

export function create(data, onUploadProgress = null) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createRdi,
			method: 'post',
			data,
			onUploadProgress
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function list(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.listRdi,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function updateRdi(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateRdi,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getRdiByID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getRdiByID,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function addInteraction(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.addRDIInteraction,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function closeRDI(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.closeRDI,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function markRdiAsRead(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markRdiAsRead,
			method: 'post',
			data,
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function downloadPDF(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.downloadPDF,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function getRDIGraphs(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getRDIGraphs,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function sendRDIMail(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.sendRDIMail,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function deleteRdi(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.deleteRdi,
			method: 'post',
			data,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function changeVisualizationStatus(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.changeVisualizationStatusRdi,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}