<template>
    <div>
        <!-- <div v-for="item in filesAndFolders" :key="item.guid">
            <div v-if="item.type === 'folder'">
                {{ item.filename }}
                <TreeView :filesAndFolders="item.files"></TreeView>
            </div>
            <div v-else>
                {{ item.filename }}
            </div>
        </div> -->

        <!-- Item -->
        <span c v-for="item in filesAndFolders" :key="item.guid">

            <!-- <div v-if="item.type === 'folder' && 1 == 2" class="fk-folder">

                <div class="item-folder">{{ item.filename }}</div>
                <TreeView :filesAndFolders="item.files"></TreeView>
            </div> -->

            <!-- Item Thumb  -->
            <div v-if="item.type == 'file' && isValidExtension(item.extension)">
                <v-card class="fv-container-item" >
                    <v-row class="fv-body-item">
                        <div @click="emitItemModalEvent(item)" class="fv-body-item-bg" v-if="item.thumbnail"
                            :style="{ 'background-image': 'url(' + item.thumbnail + ')' }"></div>
                        <!-- <img class="preview-img"
                            style="background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQvb4vSTKl-UeoCbvEG0IowMdUOsZFTdCCypQ&usqp=CAU')"
                            @click="showImageDialog" /> -->

                        <div class="fv-icon-item">{{ item.extension }}</div>
                        <div v-if="checkPermissions(['del_docs'])" class="container-fv-icon-del" @click="delEvent(item)">
                            <div class="fv-icon-del" >
                                <v-icon class="white--text">mdi-close</v-icon>
                            </div>
                        </div>

                    </v-row>

                    <v-row class="fv-text-item" @click="emitItemModalEvent(item)">
                        <p class="fv-text">{{ item.shortname }} </p>
                    </v-row>
                </v-card>
            </div>


        </span>


        <!-- <v-dialog v-model="imageDialog" width="70%" scrollable persistent>
            <v-card class="container-media">
                <v-card-title>Arquitectura</v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <embed :src="pdfUrl" type="application/pdf" style="width: 100%; height: 800px;">
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-btn icon class="weather-modal-close-button" @click="closeModal">
                    <v-icon class="red--text">mdi-close</v-icon>
                </v-btn>
            </v-card>

        </v-dialog> -->


    </div>




</template>

<script>
export default {
    name: "TreeView",
    props: {
        filesAndFolders: Array
    },
    data: () => ({
        imageDialog: false,
        pdfUrl: ''
    }),
    methods: {
        isValidExtension(extension) {
            const allowedExtensions = ['pdf', 'jpg', 'jpeg', 'xls', 'xlsx'];
            return allowedExtensions.includes(extension.toLowerCase());
        },
        delEvent(item) {
            console.log('emit:', item);
        },
        // Emits
        emitItemModalEvent(item) {
            console.log('emit:', item);
            this.$emit('open-item-modal', item);
        },
    },
    async mounted() {
        console.log('TreeView Data', this.filesAndFolders)

    },
};
</script>

<style scoped>
/* Estilos CSS para el árbol de directorios */
.container-media {
    width: 100%;
    height: 100%;
}

.card-background {
    background-color: white;
    border-radius: 4px;
}

.textPlanning {
    font-size: 1.1rem;
    font-weight: bold;
    color: #454d61;
}

.card-title {
    font-weight: 700;
    font-size: 18px;
    color: #454d61;
}

.btn-right {
    border-radius: 25px;
    background-color: #525d78 !important;
    color: white;
    font-size: 10px;
}

.btn-plus {
    border-radius: 15px;
    background-color: #525d78 !important;
    color: white;
    font-size: 10px;
}

.card-detail {
    padding: 12px;
}

.container-fv {
    position: relative;
    width: 100%;
    height: 500px;
    /* Establece la altura deseada */
    max-width: 100%;
    /* Asegura que no supere el ancho del contenedor padre */
    overflow: hidden;
    /* Oculta el contenido que excede el tamaño del contenedor */
}


.panel {
    margin-bottom: 15px;
}

.row-detail {
    padding: 12px;
    display: flex;
    align-items: center;
    /* border-bottom: 1px solid #e8e8e8; */
}


.fv-container-item {
    position: relative;
    width: 200px;
    height: auto;
    display: inline-block;
    box-sizing: border-box;
    display: inline-block;
    margin: 6px;
    border-radius: 6px;
    border-left: 4px #454d61 solid !important;
    cursor: pointer;
    overflow: hidden;
    opacity: 0.9;
    transition: box-shadow 0.3s ease;
    /* Transición suave de box-shadow durante 0.5 segundos */

    .fv-body-item {
        position: relative;
        width: 100%;
        height: 150px;
        margin: 0;
        background-image: url('https://storage.googleapis.com/bucket-db-favric/ms_bim/bg-default-bin.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;

        .fv-body-item-bg {
            width: 100% !important;
            height: 100%;
            background-size: cover !important;
            background-position: center !important;
            background-repeat: no-repeat !important;
            transition: transform 0.3s ease;

            &:hover {
                transform: scale(1.1);
            }
        }

        .fv-icon-item {
            position: absolute;
            right: 0;
            bottom: 0;
            width: auto;
            height: 25px;
            margin: 0 4% 1% 0;
            text-transform: uppercase;
            font-weight: 800;
            opacity: 0.5;
            letter-spacing: 0.3px;
            font-size: 1rem;
        }

        .container-fv-icon-del {
            position: absolute;
            right: 0;
            top: 0;
            width: auto;
            margin: 0;
            height: 50px;
            pointer-events: all;
            cursor: pointer;

            .fv-icon-del {
                height: 25px;
                /* margin: 0 4% 1% 0; */
                text-transform: uppercase;
                opacity: 0.5;
                letter-spacing: 0.3px;
                font-size: 1rem;
                background-color: #2b2d33;
                opacity: 0.6;
                border-radius: 50%;
                pointer-events: all;
                margin: 6px;
            }
        }
    }

    .fv-text-item {
        display: flex;
        margin: 0;
        height: 38px;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        /* Centra verticalmente el contenido */

        .fv-text {
            font-size: 11.5px;
            font-weight: 500;
            color: #2b2d33;
            padding: 3px 3px 0 3px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            /* Número de líneas que quieres mostrar */
            line-height: 1.2em;
            /* Ajusta según el diseño */
            max-height: 2.8em;
            /* line-height * número de líneas */
            text-overflow: ellipsis;
            text-align: center;
            margin: 0;
        }
    }

    &:hover {
        box-shadow: 0 3px 4px rgba(0, 0, 0, 0.4);
        opacity: 1;
    }
}














/* 
.fk-folder {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: auto;
    align-items: flex-start;
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    white-space: nowrap;
    padding: 20px;
} */



.preview-img {
    padding-top: 15px;
    width: 200px;
    cursor: pointer;
}

.popup-image {
    height: 100%;
    width: auto;
}
</style>