<template>
<div class="project-drawer-container" :class="$store.menuStore.state.sidebarStatus ? 'opened':'closed'">
	<v-icon dark class="toggle-button pa-1" v-if="!$vuetify.breakpoint.mobile" @click="toggleMenu">mdi-expand-all-outline</v-icon>
	<v-navigation-drawer
		v-model="$store.menuStore.state.sidebarStatus"
		absolute
		temporary
		right
		app
		:width="$store.menuStore.state.sidebarStatus ? '350':'0'"
	>
		<v-icon class="toggle-inner-button pa-1" @click="toggleMenu">mdi-close-box</v-icon>
		<div class="pa-6 pb-0">
			<SearchBarHeader v-if="$vuetify.breakpoint.mobile"/>
			<v-btn
				text
				color="#3EBFA3"
				class="text-capitalize px-0 my-5 justify-start"
				block
				left
				v-if="checkCompanyLevelPermissions(['edit_project'])"
				@click="() => {
					this.$store.projectsStore.commit('setNewProjectModal', true)
					this.toggleMenu()
				}"
			>
				<v-icon color="white" class="new-project-icon mr-3">mdi-plus-circle-outline</v-icon>
				Nuevo Proyecto
			</v-btn>
			<div>
				<p class="text--secondary caption">Por favor seleccione proyecto antes de continuar</p>
				<v-select
					v-if="$store.projectsStore.state.projectsList"
					:items="$store.projectsStore.state.projectsList"
					:loading="!$store.projectsStore.state.projectsList"
					item-text="name"
					item-value="id"
					:value="$store.projectsStore.state.selectedProject"
					label="Seleccione Proyecto"
					single-line
					hint="Esta acción cambiará el proyecto activo en el sistema"
					persistent-hint
					outlined
					dense
					required
					return-object
					@change="changeProject"
				></v-select>
			</div>
		</div>
		
		<v-expansion-panels class="sidebar-projects">
			<v-expansion-panel>
				<v-expansion-panel-header>
					<p class="mb-0" style="color: #515d78">Opciones del Proyecto</p>
				</v-expansion-panel-header>
				<v-expansion-panel-content>
					<p class="mb-0 caption">Estas opciones son por proyecto, por lo que si tienes varios, procura modificarlas en cada uno</p>
					<v-checkbox
						v-model="project_options.daily_reports"
						hide-details
						class="my-0"
						label="Recibir reportes diarios"
					>
						<template v-slot:label>
							<p class="mb-0 caption">Recibir reportes diarios</p>
						</template>
					</v-checkbox>
					<v-checkbox
						v-model="project_options.notification_sound"
						hide-details
						class="my-0"
						label="Desactivar sonido notificaciones"
					>
						<template v-slot:label>
							<p class="mb-0 caption">Sonido notificaciones</p>
						</template>
					</v-checkbox>
					<v-btn
						class="mx-0 mt-2"
						color="#515d78"
						block
						dark
						small
						:loading="savingOptions"
						@click="saveOptions"
					>
						<p class="mb-0">Guardar Opciones</p>
					</v-btn>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>
		<v-divider class="mb-2"></v-divider>

		<!-- <div>
			<div>
				<h4 class="px-6">Crear</h4>
				
				<v-expansion-panels class="sidebar-projects">
					<v-expansion-panel>
						<v-expansion-panel-header>
							<p class="mb-0" style="color: #515d78"> <v-icon class="mr-2" color="#515d78">mdi-clipboard-text-outline</v-icon> Tareas</p>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list class="py-0" dense>
								<v-list-item link class="menu-item-inner d-flex align-center" v-if="checkPermissions(['create_tasks'])"
									@click="() => {
										this.$store.consultasStore.commit('setCreateTareaModal', true)
										this.toggleMenu()
									}">
									<v-icon class="menu-item-icon">mdi-plus</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Crear Tarea</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toTareas(2)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Mis Tareas</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toTareas(3)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Tareas Pendientes</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toTareas(4)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Tareas Finalizadas</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toTareas(5)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Tareas por Usuario</p>
								</v-list-item>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
					<v-expansion-panel>
						<v-expansion-panel-header>
							<p class="mb-0" style="color: #515d78"> <v-icon class="mr-2" color="#515d78">mdi-file-document-multiple-outline</v-icon> RDI</p>
						</v-expansion-panel-header>
						<v-expansion-panel-content>
							<v-list class="py-0" dense>
								<v-list-item link class="menu-item-inner d-flex align-center" v-if="checkPermissions(['create_rdi'])"
									@click="() => {
										this.$store.rdiStore.commit('setCreateRDIModal', true)
										this.toggleMenu()
									}">
									<v-icon class="menu-item-icon">mdi-plus</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">Crear RDI</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toRDI(2)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">RDI Pendientes</p>
								</v-list-item>
								<v-list-item link class="menu-item-inner d-flex align-center" @click="toRDI(3)">
									<v-icon class="menu-item-icon">mdi-eye-outline</v-icon>
									<p class="mb-0 ml-2 menu-item-text body-2">RDI Finalizadas</p>
								</v-list-item>
							</v-list>
						</v-expansion-panel-content>
					</v-expansion-panel>
				</v-expansion-panels>
			</div>
		</div> -->
	</v-navigation-drawer>
</div>
</template>

<script>
import SearchBarHeader from '@/components/Globals/Headers/SearchBarHeader'

export default {
	name: 'ProjectSidebarIndex',
	components: {SearchBarHeader},
	data: () => ({
		initialSelectedProject: null,
		savingOptions: false,
		project_options: {
			daily_reports: true,
			notification_sound: true
		}
	}),
  computed: {
    selectedProject() {
      return this.$store.projectsStore.state.selectedProject
    },
  },
  watch: {
    selectedProject(project) {
      if(project){
				this.project_options = {
					daily_reports: project.useroptions ? project.useroptions.daily_reports:false,
					notification_sound: project.useroptions ? project.useroptions.notification_sound:false
				}
      }
    }
  },
	mounted(){
	},
	methods: {
		async saveOptions(){
			this.savingOptions = true
			const data = Object.assign({},
			this.project_options,
			{project_id: this.$store.projectsStore.state.selectedProject.id})
			await this.$store.userStore.dispatch('setUserProjectOptions', data)
			this.savingOptions = false
		},
		toggleMenu(){
			this.$store.menuStore.commit('setSidebarStatus', !this.$store.menuStore.state.sidebarStatus)
		},
		toTareas(taskType){
			this.$store.consultasStore.commit('setSelectedType', taskType)
			if(this.$route.name != 'Consultas'){
				this.$router.push({name: 'Consultas'})
			}
			else{
				const project_id = this.$store.projectsStore.state.selectedProject.id
				this.$store.consultasStore.dispatch('getMyTasks', {project_id})
			}
			this.toggleMenu()
		},
		toRDI(taskType){
			this.$store.rdiStore.commit('setSelectedType', taskType)
			if(this.$route.name != 'Rdi'){
				this.$router.push({name: 'Rdi'})
			}
			else{
				const project_id = this.$store.projectsStore.state.selectedProject.id
				this.$store.rdiStore.dispatch('getRDI', {project_id})
			}
			this.toggleMenu()
		},
		async changeProject(project){
			const projectID = project.id
			const companyID = project.company_id
			this.$store.userStore.commit('setCompanyPermissions', null)
			await this.$store.projectsStore.dispatch('getSelectedProject', projectID)
			if(this.$store.userStore.state.userCompanies.length > 0){
				const found = this.$store.userStore.state.userCompanies.find((company) => {
					return company.company_id = companyID
				})
				if(found){
					this.$store.userStore.commit('setCompanyPermissions', found.capabilities)
					this.$store.userStore.commit('setSelectedCompany', found)
				}
			}
			if(projectID){
				this.$store.consultasStore.dispatch('getMyTasks', {project_id: projectID})
				this.$store.rdiStore.dispatch('getRDI', {project_id: projectID})
			}
			this.$store.projectsStore.dispatch('getDashboardInfo')
			this.$store.fileManagerStore.dispatch('getProjectStorageSize', { project_id: projectID })
			this.getProjectUserList()
		},
		getProjectUserList(){
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				start: 0,
				limit: 20
			}
			this.$store.usersStore.dispatch('getUsersList', data)
		},
	}
};
</script>

<style scoped>
	.toggle-button{
		position: absolute!important;
		top: 0;
		right: 0;
		background: #515d78;
	}
	.toggle-inner-button{
		position: absolute!important;
		top: 0;
		right: 0;
	}
	.new-project-icon{
		background-color: #3EBFA3;
		padding: 4px;
		border-radius: 100%;
	}
	.sidebar-projects .v-expansion-panel::before{
		box-shadow: none;
	}
	.sidebar-projects .v-expansion-panel .v-expansion-panel-content .v-expansion-panel-content__wrap{
		padding: 0;
	}
	.menu-item-inner:hover, .menu-item-inner.active{
		background-color: #B1BCD8;
	}
	.menu-item-inner:hover{color: white;}
	.menu-item-inner:hover:before, .menu-item-inner.active:before{
		content: '';
		color: white;
		width: 5px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		background-color: #515d78;
		opacity: 1!important;
	}
	.project-drawer-container.closed{
		width: 0!important;
	}
</style>
