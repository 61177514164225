<template>
  <v-btn icon @click="toggleFavorite">
    <v-icon :color="isFavorite ? 'yellow darken-3' : 'grey lighten-1'">mdi-star</v-icon>
  </v-btn>
</template>

<script>
export default {
  name: 'SwitchIcon',
  props: {
    initial_state: {
      type: Boolean,
      default: false // Valor predeterminado si initial_state no se proporciona
    }
  },
  data() {
    return {
      isFavorite: this.initial_state // Usamos initial_state para inicializar isFavorite
    };
  },
  watch: {
    initial_state(newVal) {
      this.isFavorite = newVal;
    }
  },
  methods: {
    toggleFavorite() {
      this.isFavorite = !this.isFavorite;
      this.isFavorite = this.isFavorite ? '1' : '0';
      this.$emit('favorite', this.isFavorite);
    }
  }
};
</script>

<style scoped></style>
