var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"pa-0":"","my-2":"","fill-height":""}},[_c('v-card',{staticClass:"fill-height pa-0 project-card"},[_c('v-card-text',{staticClass:"pa-0"},[_c('v-row',{staticClass:"img-title",staticStyle:{"overflow":"hidden"}},[(_vm.info.project_image != '')?_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('img',{staticClass:"img-info-project",staticStyle:{"border-top-left-radius":"4px","border-top-right-radius":"4px"},attrs:{"src":_vm.info.project_image},on:{"error":_vm.handleImageError}})]):_vm._e()],1),(_vm.info)?_c('v-row',{staticClass:"project-detail-card"},[_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('v-row',{staticClass:"info-title d-flex",staticStyle:{"padding-top":"15px","padding-left":"15px"}},[_c('v-col',{staticClass:"laot-circle-blue",attrs:{"cols":"1","sm":"1","xs":"12"}}),_c('v-col',{staticStyle:{"margin-left":"-10px"},attrs:{"cols":"11","sm":"11","xs":"12"}},[_c('div',{staticClass:"text-proyect"},[_c('span',[_vm._v("Abrir BIM "),_c('v-icon',[_vm._v("mdi-map")])],1),_c('p',{staticClass:"mb-0 font-weight-bold",staticStyle:{"font-size":"1em"}},[_vm._v(" "+_vm._s(_vm.info.name)+" ")]),_c('p',{staticClass:"font-weight-regular",staticStyle:{"font-size":"0.8em","color":"#606060"}},[_vm._v(" Empresa LaOT ")])])])],1)],1)],1):_vm._e(),_c('v-row',{staticClass:"advanced-circle"},[_c('span',{staticClass:"chart-span"},[_vm._v("Tarea:")]),_vm._l((_vm.circleGraphs),function(graph,index){return _c('span',{key:index,staticStyle:{"height":"45px","width":"45px"}},[_c('span',[_c('DoughnutChart',{attrs:{"chartdata":{
						datasets: [
							{
								backgroundColor: [graph.color, '#D9D9D9'],
								data: [graph.value, 100 - graph.value],
							}
						]
					},"centerText":graph.center_text,"options":{
						tooltips: { enabled: false },
						cutoutPercentage: 75,
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							center: {
								text: graph.center_text
							}
						}
					}}})],1)])})],2),_c('v-row',{staticClass:"advanced-circle"},[_c('span',{staticClass:"chart-span"},[_vm._v("Rdi:")]),_vm._l((_vm.circleGraphsRDI),function(graph,index){return _c('span',{key:index,staticStyle:{"height":"45px","width":"45px"}},[_c('span',[_c('DoughnutChart',{attrs:{"chartdata":{
						datasets: [
							{
								backgroundColor: [graph.color, '#D9D9D9'],
								data: [graph.value, 100 - graph.value],
							}
						]
					},"centerText":graph.center_text,"options":{
						tooltips: { enabled: false },
						cutoutPercentage: 75,
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							center: {
								text: graph.center_text
							}
						}
					}}})],1)])})],2)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }