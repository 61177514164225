<template>
	<v-list class="menu-item py-0" dense>
		<MenuItem class="menuItem"
			v-for="(item, index) in $store.menuStore.state.sidebarMenu"
			:key="index"
			:item="item"
			/>
	</v-list>
</template>

<script>
import MenuItem from './Item'

export default {
	name: 'MenuIndex',
	components: { MenuItem },
	data: () => ({}),
};
</script>

<style>
	.menuItem {
		padding-left: 10px;
		padding-right: 10px;
	}
</style>