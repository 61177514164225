<template>
	<div class="text-center">
		<v-dialog v-model="$store.usersStore.state.editPermisosUserModal" max-width="800" scrollable persistent>
			<v-card :loading="$store.usersStore.state.loadingJobTypes || loading_user_projects"
				:disabled="$store.usersStore.state.loadingJobTypes || loading_user_projects">
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center">
								<v-icon color="white" small class="info-icon mr-2">mdi-account-plus-outline</v-icon>
								<p class="mb-0 font-weight-bold text-primary title">Editar Permisos Proyecto Usuario:
									{{ $store.usersStore.state.selectedUserInfo.name }}</p>
							</div>
							<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
								<v-icon dark>mdi-close</v-icon>
							</v-btn>
						</div>
						<v-col cols="12" class="py-0">
							<h2 class="mb-0 caption">Aquí puedes editar los permisos que tendrán los usuarios al
								ingresar a la plataforma.</h2>
							<h2 class="mb-0 caption">Si bien los cargos tienen permisos preestablecidos, aquí puedes
								modificarlos independientemente de eso.</h2>
						</v-col>
						<v-form ref="form" v-model="validform" class="mt-6">
							<v-row>
								<v-col cols="12" class="py-0">
									<h2 class="mb-0 subtitle-1 font-weight-bold">Proyecto</h2>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="py-0">
									<div class="d-flex flex-row">
										<v-select v-model="selected_project" :items="user_projects"
											:loading="loading_user_projects" item-text="name" item-value="id"
											label="Seleccione Proyecto" single-line
											hint="Selecciona el proyecto en el que deseas editar los permisos del usuario"
											persistent-hint outlined dense required
											@change="getCapabilities"></v-select>
										<v-btn class="mx-2" fab dark x-small color="#515d78"
											@click="$store.projectsStore.commit('setNewProjectModal', true)">
											<v-icon dark>mdi-plus</v-icon>
										</v-btn>
									</div>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" class="py-0 my-3">
									<v-divider></v-divider>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" class="py-0">
									<h2 class="mb-0 subtitle-1 font-weight-bold">Permisos</h2>
									<h2 class="mb-0 caption">Los permisos se asignan por proyecto.</h2>
								</v-col>
								<v-col cols="12" sm="6" class="py-0">
									<v-select :items="$store.usersStore.state.jobTypes"
										:loading="$store.usersStore.state.loadingJobType" item-text="name"
										item-value="id" label="Seleccione Cargo" single-line
										hint="Asigna permisos por defecto de acuerdo al tipo de usuario" persistent-hint
										outlined dense required return-object @change="setJobTypeSelected"></v-select>
								</v-col>
							</v-row>
							<v-row>
								<v-col cols="12" sm="6" class="py-0">
									<div class="permisos-type-container">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-blue-text">Usuarios</h2>
										<v-checkbox v-model="permisos.create_users" label="Crear Usuarios" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.edit_users" label="Editar Usuarios" hide-details
											dense></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-blue-text">RDI</h2>
										<v-checkbox v-model="permisos.create_rdi" label="Crear RDI" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.view_rdi" label="Visualizar RDI" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.interact_rdi" label="Interactuar RDI" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.end_rdi" label="Finalizar RDI" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.validator_creator" label="Validador Creador"
											hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.validator_builder" label="Validador Constructora"
											hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.validator_inspect" label="Validador Inspección"
											hide-details dense></v-checkbox>
									</div>
									<div class="permisos-type-container mt-3">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-blue-text">Planimetría</h2>
										<v-checkbox v-model="permisos.upload_bim" label="Subir BIM" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.upload_doc" label="Subir Documentos" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.add_favorite_bim" label="Asignar Favorito"
											hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.del_docs" label="Eliminar Archivos" hide-details
											dense></v-checkbox>
									</div>
								</v-col>
								<v-col cols="12" sm="6" class="py-0">
									<div class="permisos-type-container">
										<h2 class="mb-0 subtitle-1 font-weight-bold light-blue-text">
											Consultas/Restricciones</h2>
										<v-checkbox v-model="permisos.create_tasks" label="Crear Tareas" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.assign_responsable_task"
											label="Asignar Responsable" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.change_responsable_task"
											label="Cambiar el responsable" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.change_code" label="Cambiar Código" hide-details
											dense></v-checkbox>
										<v-checkbox v-model="permisos.change_priority_task" label="Cambiar Prioridad"
											hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.change_critical_date_task"
											label="Cambiar Fecha Crítica" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_tasks_listing"
											label="Visualizar Listado de Vigentes" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.view_personal_tasks"
											label="Visualizar Tareas Personales" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.interact_tasks" label="Interactuar Tareas"
											hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.interact_tasks_others"
											label="Interactua en tareas de otros" hide-details dense></v-checkbox>
										<v-checkbox v-model="permisos.end_tasks" label="Finalizar Tareas" hide-details
											dense></v-checkbox>
									</div>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<div class="d-flex flex-row justify-end">
										<v-btn class="mx-2 my-1" rounded color="white" raised @click="closeModal">
											<p class="mb-0 text-primary">Cancelar</p>
										</v-btn>
										<v-btn class="mx-2 my-1" rounded color="#515d78" raised dark
											:loading="loadingData" @click="setCapabilities">
											<p class="mb-0">Confirmar</p>
										</v-btn>
									</div>
								</v-col>
							</v-row>
						</v-form>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import { setCapabilitiesByUserProject, getCapabilitiesByUserProject } from '@/helpers/api/user'
import { getProjectsByUserID, getProjectsByUser } from '@/helpers/api/project'

export default {
	name: 'EditPermisosUserModal',
	data: () => ({
		validform: true,
		user_projects: [],
		projects_id: [],
		selected_project: null,
		loading_user_projects: true,
		loading_project_list: false,
		permisos: {
			create_users: false,
			edit_users: false,
			create_tasks: false,
			assign_responsable_task: false,
			change_responsable_task: false,
			change_code: false,
			view_tasks_listing: false,
			view_personal_tasks: false,
			interact_tasks: false,
			interact_tasks_others: false,
			end_tasks: false,
			create_rdi: false,
			view_rdi: false,
			interact_rdi: false,
			end_rdi: false,
			validator_creator: false,
			validator_builder: false,
			validator_inspect: false,
			upload_bim: false,
			upload_doc: false,
			add_favorite_bim: false,
			del_docs: false
		},
		loadingData: false,
	}),
	mounted() {
		const projectID = this.$store.projectsStore.state.selectedProject.id
		this.$store.usersStore.dispatch('jobTypesGet', projectID)
		this.getUserProjects(projectID)
	},
	methods: {
		async getProjectList() {
			if (!this.$store.projectsStore.state.projectsList) {
				this.loading_project_list = true
				const projects = await getProjectsByUser()
				if (projects.code === 200) {
					this.$store.projectsStore.commit('setProjectsList', projects.projects)
				}
				this.loading_project_list = false
			}
		},
		setJobTypeSelected(select) {
			if (select && select.default_capabilities) {
				this.permisos = select.default_capabilities
			}
		},
		async getUserProjects(projectID = null) {
			this.loading_user_projects = true
			const projects = await getProjectsByUserID({ user_id: this.$store.usersStore.state.selectedUserInfo.id })
			if (projects.code == 200) {
				this.user_projects = projects.projects
				let posIndex = 0;
				if (projectID) {
					posIndex = this.user_projects.findIndex(project => project.id === projectID);
					console.log('Position Project:', posIndex);
				}

				this.selected_project = projects.projects[posIndex].id
				this.projects_id = projects.projects.map(item => { return item.id })
				await this.getCapabilities()
			}
			this.loading_user_projects = false
		},
		async setCapabilities() {
			const data = {
				user_id: this.$store.usersStore.state.selectedUserInfo.id,
				project_id: this.selected_project,
				capabilities: this.permisos
			}
			const capabilities = await setCapabilitiesByUserProject(data)
			let datas = '';
			if (capabilities.code == 200) {
				datas = {
					message: 'Se han actualizado los permisos del usuario para el proyecto seleccionado.',
					title: 'Usuario Actualizado',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.getProjectRefresh();
			} else {
				datas = {
					message: 'Ocurrio un error al actualizar, intentar mas tarde o avisar al administrador del sistema.',
					title: 'Usuario NO Actualizado',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C',
				}
			}
			this.$store.globalStore.dispatch('addMessage', datas);
			this.closeModal();
		},
		getCapabilities() {
			this.clearOptions();
			return new Promise((resolve) => {
				const data = {
					user_id: this.$store.usersStore.state.selectedUserInfo.id,
					project_id: this.selected_project
				}
				getCapabilitiesByUserProject(data)
					.then((capabilities) => {
						this.permisos = Object.assign(this.permisos, capabilities.user_project.capabilities)
						resolve(true)
					})
					.catch(() => { resolve(false) })
			})
		},
		closeModal() {
			this.$store.usersStore.commit('setSelectedUserInfo', null)
			this.$store.usersStore.commit('setEditPermisosUserModal', false)
			this.clearOptions();
			this.validform = true
			this.loadingData = false
		},
		clearOptions() {
			this.permisos = {
				create_users: false,
				edit_users: false,
				create_tasks: false,
				assign_responsable_task: false,
				change_responsable_task: false,
				change_code: false,
				view_tasks_listing: false,
				view_personal_tasks: false,
				interact_tasks: false,
				interact_tasks_others: false,
				end_tasks: false,
				create_rdi: false,
				view_rdi: false,
				interact_rdi: false,
				end_rdi: false,
				validator_creator: false,
				validator_builder: false,
				validator_inspect: false,
				upload_bim: false,
				upload_doc: false,
				add_favorite_bim: false,
				del_docs: false
			}
		},
		async getProjectRefresh() {
			const projectID = this.$store.projectsStore.state.selectedProject.id
			await this.$store.projectsStore.dispatch('getSelectedProject', projectID)
			console.log('getSelectedProject')
		},
	}
};
</script>

<style scoped>
.info-container {
	color: #606060;
}

.text-primary {
	color: #515d78;
}

.info-icon {
	background-color: #515d78;
	border-radius: 25px;
	padding: 3px;
	width: 35px;
	height: 35px;
}

.info-icon.small-icon {
	width: 25px;
	height: 25px;
}

.info-img {
	width: 100%;
	max-height: 50px;
	object-fit: contain;
}

.adjuntos-container {
	background-color: #EFEFEF;
	max-height: 95px;
	overflow-y: scroll;
}

.interaction-container {
	width: 100%;
	background-color: #E5E9F2;
	border-radius: 5px;
	position: relative;
	padding: 12px;
}

.user-avatar.small {
	width: 25px;
	height: 25px;
}

.light-blue-text {
	color: #515d78;
}

.permisos-type-container {
	border: 1px solid #515d78;
	border-radius: 4px;
	padding: 10px;
}

/deep/ .permisos-type-container .v-input--checkbox label.v-label {
	font-size: 14px;
}
</style>

<!-- , "validator_creator": true, "validator_builder": true, "validator_inspect": true, "upload_bim": true, "upload_doc": true, "add_favorite_bim": true, "del_docs": true, "change_code": true -->