<template>
	<v-container fluid pa-0 fill-height>
		<v-card style="width: 100%; border-radius: 10px; height: 100%">
			<v-card-text>
				<div class="btn-new-task">
					<v-btn tonal class="new-task" style="height: 15px; " v-if="checkPermissions(['create_tasks'])"
						@click="$store.consultasStore.commit('setCreateTareaModal', true)">
						<p class="mb-0 caption text-capitalize">Agregar Tarea</p>
						
					</v-btn>
				</div>
				<v-row>
					<div class="d-flex head-consult">
						<v-col class="pr-5" cols="1">
							<v-icon style="font-size: 25px; color: #525d78">mdi-chart-bar</v-icon>
						</v-col>
						<v-col class="d-flex align-center title-consult" v-if="info">
							<router-link tag="p" class="mb-0 font-weight-bold pointer-cursor"
								:to="{ name: 'Consultas' }">{{ info.title }}</router-link>
						</v-col>
					</div>

				</v-row>
				<v-row class="project-detail-card" v-if="info">
					<v-col cols="5" xs="12">
						<v-row>
							<v-col cols="3">
								<div class="icon-task">
									<v-icon style="font-size: 50px; color: #525d78">mdi-lightning-bolt-outline</v-icon>
								</div>
							</v-col>
							<v-col class="fast-average" cols="8">
								<div class="speed-solution">
									<p class="mb-0">Velocidad de Solución Promedio</p>
								</div>
								<div class="val-speed">
									<p class="mb-0 font-weight-bold"><span class="headline">{{ info.speed_solution.value
						| oneDecimalsNumber }}</span> {{ info.speed_solution.suffix }}</p>
								</div>
							</v-col>
						</v-row>
						<!-- <router-link tag="p" class="mb-0 font-weight-bold pointer-cursor" :to="{ name: 'Consultas' }">{{info.title}}</router-link>
						<p class="mb-0">Velocidad de Solución Promedio</p>
						<p class="mb-0 font-weight-bold"><span class="headline">{{info.speed_solution.value | oneDecimalsNumber}}</span> {{info.speed_solution.suffix}}</p> -->
					</v-col>
					<v-col class="total-graph-consult" cols="7" xs="12">
						<v-row>
							<v-col cols="4" class="px-1" v-for="(graph, index) in info.circle_graphs" :key="index">
								<DoughnutChart :chartdata="{
						datasets: [
							{
								backgroundColor: [graph.color, '#D9D9D9'],
								data: [5, 5],
							}
						]
					}" :centerText="graph.center_text" :options="{
						tooltips: { enabled: false },
						cutoutPercentage: 75,
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							center: {
								text: graph.center_text
							}
						}
					}" />
							</v-col>
						</v-row>
						<div class="d-block pt-2 graph">
							<div class="d-flex align-center" style="flex: 1; width: 100%;">
								<p class="mb-0 font-weight-bold mr-1">{{ info.total_title }}</p>
								<div id="record-circle">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon
												:color="$options.filters.getColorStatusModulo(info.semaforo_consultas)"
												small v-bind="attrs" v-on="on">mdi-record-circle</v-icon>
										</template>
										<p class="caption mb-0">Estado general del módulo: <span
												class="font-weight-bold">{{ $options.filters.getTextStatusModulo(info.semaforo_consultas) }}</span>
										</p>
									</v-tooltip>
								</div>
							</div>
						</div>
					</v-col>
				</v-row>
				<v-row class="project-detail-card pt-10" v-else>
					<v-col cols="5">
						<v-skeleton-loader type="list-item-two-line"></v-skeleton-loader>
						<v-skeleton-loader type="heading"></v-skeleton-loader>
					</v-col>
					<v-col cols="7">
						<v-skeleton-loader type="list-item"></v-skeleton-loader>
						<v-skeleton-loader type="avatar,avatar,avatar" class="d-flex"></v-skeleton-loader>
					</v-col>
				</v-row>
				<v-divider class="my-2"></v-divider>
				<v-row class="project-detail-card" v-if="info">
					<v-col class="d-flex pr-6" cols="1" style="align-items: center;">
						<v-avatar>
							<div class="avg-time">
								<v-img src="../../../../assets/Svg/ListadoConsulta/avg-time.svg"></v-img>
							</div>
						</v-avatar>
					</v-col>
					<v-col class="average" cols="4">
						<div class="title-average">
							<!-- <p class="mb-0 font-weight-bold">{{info.adicionales.title}}</p> -->
							<p class="mb-0">Tiempo promedio de espera</p>
							<p class="mb-0 font-weight-bold">
								<span class="headline">{{ info.adicionales.speed_solution.value |
						oneDecimalsNumber }}</span>
								{{ info.adicionales.speed_solution.suffix }}
								<span class="caption" v-if="info.adicionales.speed_solution_last">/
									<span>{{ info.adicionales.speed_solution_last.value | oneDecimalsNumber }}</span>
									{{ info.adicionales.speed_solution_last.suffix }}</span>
							</p>
						</div>
					</v-col>
					<v-col class="col-barchart" cols="6">
						<BarChart class="barchart" style="max-height: 100px;" :chartdata="{
						labels: ['S4', 'S3', 'S2', 'S1'],
						datasets: [
							{
								backgroundColor: '#EAB261',
								data: info.adicionales.graphs.created_by_week
							},
							{
								backgroundColor: '#34BFA3',
								data: info.adicionales.graphs.solved_by_week
							}
						]
					}" :options="{
						responsive: true,
						maintainAspectRatio: false,
						legend: {
							display: false
						},
						tooltips: {
							callbacks: {
								label: function (tooltipItem) {
									return tooltipItem.yLabel;
								}
							}
						},
						scales: {
							yAxes: [{
								ticks: {
									beginAtZero: true
								}
							}]
								}
							}" />
						<!-- <p class="mb-0 font-weight-bold">{{info.adicionales.total_title}} <v-icon :color="info.adicionales.general_status" small>mdi-record-circle</v-icon></p>
						<div class="d-flex align-center" v-for="(graph, index) in info.adicionales.graphs" :key="index">
							<p class="mb-0">{{graph.title}}</p>
							<v-progress-linear :value="graph.value" :color="graph.color" rounded class="ml-2" style="flex: 1;"></v-progress-linear>
						</div> -->

					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from '@/components/Charts/Doughnut'
import BarChart from '@/components/Charts/Bar'
export default {
	name: 'ProjectDetailCardConsultas',
	components: { DoughnutChart, BarChart },
	props: ['info'],
	data: () => ({
		//
	}),
	mounted() {
	}
};
</script>

<style scoped>
.pointer-cursor {
	cursor: pointer
}

.project-detail-card,
.project-detail-card p {
	/* color: #2D4B95; */
	color: #5a6680;
}

.new-task {
	background-color: #525d78 !important;
	border-radius: 25px;
	padding: 17px 17px !important;
}

.text-capitalize {
	color: white;
	font-size: 0.7em !important;
}

.btn-new-task {
	position: absolute;
	top: 5px;
	right: 3px;
	display: flex;
	justify-content: end;
	width: fit-content;
}

.icon-task {
	position: relative;
	left: -10px;
	top: 20px;
}

.head-consult {
	
}

.fast-average {
	font-size: 0.83em;
	padding-left: 20px;
}

.title-consult {
	max-width: 100%;
	width: 115px;
}

.speed-solution {
	width: 65px;
}

.total-graph-consult {
	max-width: 100%;
	margin-top: -15px;
}

.graph {
	width: 100%;
}

.average {
	font-size: 0.83em;
	padding-left: 20px;
}

.title-average {
	width: 80px;
}

.avg-time {
	margin-left: -10px;
}

@media (max-width: 600px) {

	.title-consult {
		width: 100%;
	}

	.btn-new-task {
		justify-content: end !important;
		padding-bottom: 7px;
	}

	.speed-solution {
		width: 85px !important;
	}

	#record-circle {
		top: initial !important;
		right: initial !important;
	}

}

@media (min-width: 700px) and (max-width: 800px) {
	.btn-new-task {
		padding-bottom: 7px;
	}

	.new-task {
		min-width: 0px !important;
		padding: 10px !important;
		padding-top: 15px !important;
		padding-bottom: 15px !important;
	}

	.icon-task {
		top: 20px;
	}

	.fast-average {
		max-width: 25%;
		padding-left: 12px;
	}

	.speed-solution {
		width: 160px;
	}
	.total-graph-consult{
		max-width: 50%;
	}

	#record-circle {
		position: static;
		top: -10px;
		right: 20px;
	}

	.average {
		max-width: 80%;
	}

	.title-average {
		width: 120px;
	}

	.graph {
		width: 155px;
	}

	.col-barchart{
		max-width: 58%;
	}
	.barchart {
		max-width: 100%;
	}
}

@media (min-width:800px) and (max-width:900px) {
	.fast-average{
		max-width: 15%;
	}
	.speed-solution {
		width: 160px;
	}
	.val-speed{
		width: 60px;
	}

	.graph {
		width: 155px;
	}

	.title-average {
		width: 150px;
	}

	.total-graph-consult{
		min-width: 100%;
	}
	.col-barchart{
		min-width: 100%;
	}
	.barchart{
		width: 130px;
	}
}
</style>