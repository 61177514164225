<template>
	<v-container fluid pa-0 mt-8 class="flex-grow-1" align-center justify-center>
		<v-row align="center" no-gutters style="height: 100%"
			v-if="$store.projectsStore.state.selectedProject == 1">
				<div class="info-container d-flex flex-column text-center align-center py-1" style="width: 100%">
					<v-progress-circular
						:size="50"
						:width="5"
						color="#515d78"
						indeterminate
					></v-progress-circular>
					<p class="mb-0 body-2 ml-2">Cargando información del proyecto</p>
				</div>
		</v-row>
		<v-row align="center" no-gutters
			v-else-if="!$store.projectsStore.state.selectedProject && ($store.projectsStore.state.projectsList && $store.projectsStore.state.projectsList.length == 0)">
				<div class="info-container d-flex flex-column text-center align-center py-1" style="width: 100%">
					<p class="mb-0 body-2 ml-2">No hay proyectos asociados a tu cuenta</p>
					<p class="mb-0 body-2 ml-2 caption">Crea tu primer proyecto</p>
					<v-btn
						class="mx-2 my-1"
						rounded
						color="#34BFA3"
						dark
						@click="() => $store.projectsStore.commit('setNewProjectModal', true)"
					>
						<v-icon dark small>mdi-square-edit-outline</v-icon>
						<p class="mb-0 ml-2">Nuevo Proyecto</p>
					</v-btn>
				</div>
		</v-row>
	</v-container>
</template>

<script>
export default {
	name: 'NoSelectedProject',
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
