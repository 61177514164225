<template>
  <v-card class="top-card" v-if="weatherData.weather">
    <v-container class="ow-widget">
      <v-row class="ow-row ow-city-name">
        <v-col class="pb-0" cols="12">{{ weatherData.name }}</v-col>
      </v-row>
      <v-row class="ow-row" cols="12">
        <v-col class="ow-temp" cols="12" sm="2">
          <div
            :class="[
              'wi',
              getWeatherIconClass(weatherData.weather[0].icon),
              'ow-ico',
              'ow-ico-current',
              'pull-left',
            ]"
          ></div>
          <div class="ow-temp-current pull-left">
            {{ Math.round(weatherData.main.temp) }}&deg;C
          </div>
        </v-col>

        <v-col cols="12" sm="3" xs="12" class="ow-details-day">
          <!-- <div class="ow-current-desc pull-left">
					
					</div> -->
          <div>
            Presión:
            <span class="ow-pressure pb-2">{{ weatherData.main.pressure }}</span> hPa
          </div>
          <div>
            Humedad: <span class="ow-humidity pb-2">{{ weatherData.main.humidity }}</span
            >%
          </div>
          <div>
            Viento: <span class="ow-wind">{{ weatherData.wind.speed }}</span> km/h
          </div>
        </v-col>

        <v-col cols="12" sm="7">
          <div class="ow-row ow-forecast">
            <div
              v-for="(day, index) in forecastData"
              :key="index"
              class="ow-forecast-item"
            >
              <div class="ow-day">{{ getDayOfWeek(day.date) }}</div>
              <div
                :class="[
                  'wi',
                  getWeatherIconClass(day.icon),
                  'ow-ico',
                  'ow-ico-forecast',
                ]"
              ></div>
              <div class="ow-forecast-temp">
                <span class="max">{{ Math.round(day.temp_max) }}&deg;C</span>
                <span class="min">{{ Math.round(day.temp_min) }}&deg;C</span>
              </div>
            </div>
          </div>
        </v-col>
      </v-row>
      <!-- <div class="ow-row">
				
			</div> -->
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: "OpenWeather",
  data() {
    return {
      weatherData: {},
      forecastData: [],
    };
  },
  mounted() {
    this.initWeatherWidget("Santiago");
  },
  methods: {
    initWeatherWidget(cityName) {
      const apiKey = "67f7327d33078152583ee8acc8355184";
      const weatherUrl = "https://api.openweathermap.org/data/2.5/weather";
      const forecastUrl = "https://api.openweathermap.org/data/2.5/forecast";
      const units = "metric";

      Promise.all([
        this.getWeatherData(weatherUrl, cityName, apiKey, units),
        this.getForecastData(forecastUrl, cityName, apiKey, units),
      ])
        .then(([weather, forecast]) => {
          this.weatherData = weather;
          this.forecastData = forecast;
          console.log("weather", weather);

          console.log("forecast", forecast);
        })
        .catch((error) => {
          console.error("Error fetching weather data:", error);
        });
    },
    getWeatherData(url, cityName, apiKey, units) {
      return this.makeRequest(url, { q: cityName, APPID: apiKey, units });
    },
    getForecastData(url, cityName, apiKey, units) {
      return this.makeRequest(url, { q: cityName, APPID: apiKey, units })
        .then((response) =>
          response.list.filter(
            (item) => !item.dt_txt.startsWith(new Date().toJSON().slice(0, 10))
          )
        )
        .then((filteredForecast) => this.processForecastData(filteredForecast));
    },
    makeRequest(url, params) {
      const paramString = Object.entries(params)
        .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
        .join("&");

      return fetch(`${url}?${paramString}`)
        .then((response) => {
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
          return response.json();
        })
        .catch((error) => {
          console.error("Error making request:", error);
          throw error;
        });
    },
    processForecastData(forecast) {
      const forecastByDay = {};
      forecast.forEach((item) => {
        const date = item.dt_txt.substr(0, 10);
        if (!forecastByDay[date]) {
          forecastByDay[date] = {
            date,
            temp_max: item.main.temp_max,
            temp_min: item.main.temp_min,
            icon: item.weather[0].icon,
          };
        } else {
          forecastByDay[date].temp_max = Math.max(
            forecastByDay[date].temp_max,
            item.main.temp_max
          );
          forecastByDay[date].temp_min = Math.min(
            forecastByDay[date].temp_min,
            item.main.temp_min
          );
        }
      });
      return Object.values(forecastByDay);
    },
    getDayOfWeek(dateString) {
      const daysOfWeek = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
      const date = new Date(dateString);
      return daysOfWeek[date.getDay()];
    },
    getWeatherIconClass(icon) {
      const iconMapping = {
        "01d": "wi-day-sunny",
        "01n": "wi-night-clear",
        "02d": "wi-day-cloudy",
        "02n": "wi-night-alt-cloudy",
        "03d": "wi-cloud",
        "03n": "wi-cloud",
        "04d": "wi-cloudy",
        "04n": "wi-cloudy",
        "09d": "wi-showers",
        "09n": "wi-showers",
        "10d": "wi-day-rain",
        "10n": "wi-night-alt-rain",
        "11d": "wi-thunderstorm",
        "11n": "wi-thunderstorm",
        "13d": "wi-snow",
        "13n": "wi-snow",
        "50d": "wi-fog",
        "50n": "wi-fog",
      };
      return iconMapping[icon] || "wi-na";
    },
  },
};
</script>

<style scoped>
#demo {
  /* position: relative;
	overflow: hidden; */
  /* max-width: 500px; */
  width: 100%;
  margin: 0 auto 0 auto;
  background-color: #44b2e2;
}

/* Clases */
.ow-row {
  /* position: relative;
  overflow: hidden;
  margin-bottom: 4%; */
  margin: 0;
}

.ow-row:last-child {
  margin-bottom: 0;
}

.ow-ico {
  line-height: 1.1em;
}

.ow-widget {
  font-family: "Helvetica Neue", Helvetica, sans-serif;
  font-size: 12px;
  color: #ffffff;
  padding: 0 1%;
}

.pull-left {
  float: left;
}

.pull-right {
  float: right;
}

.ow-city-name {
  font-size: 24px;
  letter-spacing: 1px;
  line-height: 1.111em;
  font-weight: bold;
}

.ow-ico-current {
  font-size: 50px;
  width: 2em;
  padding-top: 5px;
  display: flex;
  justify-content: center;
}

.ow-temp-current {
  font-size: 45px;
  margin: 2% 5%;
}

.ow-current-desc {
  line-height: 1.9em;
}

.ow-details {
  line-height: 1.5em;
}

.ow-details-day {
  display: grid;
  text-align: center;
}

.ow-forecast-item {
  float: left;
  /* width: calc(5% - 1px); */
  width: 130px;
  text-align: center;
  border-right: 1px solid white;
  font-weight: 500;
}

.ow-forecast-item:last-child {
  border-right: 0;
}

.ow-forecast-item {
  width: 19%;
}

.ow-ico-forecast {
  font-size: 30px;
  position: relative;
  margin: 10px 0;
}

.ow-forecast-temp span {
  display: inline-block;
  margin: 0 5px;
}

.ow-forecast {
  margin-left: -40px;
}

@media (min-width: 600px) and (max-width: 890px) {
  .ow-forecast-item {
    width: 16% !important;
  }
  .ow-forecast {
    margin-left: 20px !important;
  }
  .ow-details-day {
    width: 500px;
  }
  .ow-temp-current {
    font-size: 30px;
    padding-left: 10px;
    padding-top: 10px;
  }
}

@media (max-width: 600px) {
  .top-card {
    height: auto;
  }
  .ow-forecast{
    margin-left: 0;
  }
  .col-12 {
    display: flex;
    justify-content: center;
  }
  .ow-city-name .col-12 {
    padding-bottom: 0px;
  }
  .ow-temp-current {
    font-size: 30px;
    padding-top: 0;
    padding-bottom: 0;
    margin-left: 0;
  }
  .ow-ico-current {
    font-size: 40px;
    padding-top: 5px;
    align-items: center;
  }
  .ow-details-day {
    justify-content: space-around;
  }
}
</style>
