<template>

  <v-container class="d-flex flex-column align-start pa-8" pa-0 fluid>

    <v-expansion-panels class="expansion-panel-parent" :value="opened">
      <v-expansion-panel class="expansion-panel-child">
        <v-expansion-panel-header class="expansion-panel-header">
          <div style="display: flex; align-items: center">
            <v-icon class="mr-2">mdi-strategy</v-icon>
            <v-progress-linear :active="loaderUpload" :indeterminate="loaderUpload" color="blue-grey" absolute
              bottom></v-progress-linear>
            <span class="card-title">Visualizador BIM.</span>

          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content class="expansion-panel-content">
          <v-row style="display: flex; justify-content: flex-end; margin: 8px">
            <div class="d-flex select-import-tools">
              <v-col>
                <v-select :disabled="loaderUpload" v-if="select" v-model="select" :items="items" item-text="name"
                  item-value="guid" label="Select" @change="onItemSelected" persistent-hint return-object single-line>
                </v-select>
              </v-col>

              <v-col v-if="checkPermissions(['upload_bim'])">
                <input name="fileImportBim" ref="fileImportBim"  style="display: none;" type="file"
                  @change="handleFileUploadBim" accept=".zip"/>
                <v-btn class="btn-right" :disabled="loaderUpload" small @click.stop="openFileInput()">Agregar Modelo</v-btn>
              </v-col>
            </div>
          </v-row>
          <v-row v-if="errorMessage || loaderProgress">
            <v-col>
              <v-alert v-if="errorMessage" type="warning" variant="outlined" prominent>
                {{ errorMessage }}
              </v-alert>
              <div class="loader-text" v-if="loaderProgress">
                {{ loaderText }}
              </div>
              <v-progress-linear v-if="loaderProgress" v-model="numProgress" color="blue-grey" height="25">
                <template v-slot:default="{ value }">
                  <strong>{{ Math.ceil(value) }}%</strong>
                </template>
              </v-progress-linear>
            </v-col>
          </v-row>
          <v-row class="card-detail">
            <div class="container-fv" :class="{ 'height100': !urn, 'height500': urn }">
              <!-- <div id="forgeViewer"></div> -->
              <ViewBimItem :urn="urn"></ViewBimItem>
            </div>
          </v-row>
          <div class="guid" v-if="select">
            GUID:{{ select.guid }}-{{ this.$store.projectsStore.state.selectedProject.id }}
          </div>
          <div class="favorite" v-if="select && checkPermissions(['add_favorite_bim'])">
            <span>Marcar como Principal </span>
            <SwitchIcon :initial_state="select.is_favorite" @favorite="handleFavorite"></SwitchIcon>
          </div>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-container>
</template>

<script>
// import PdfViewer from '../../Globals/Helpers/PdfViewer.vue';
import SwitchIcon from '../ui/SwitchIcon.vue'
// import { getAutodeskAccessToken } from '../../../../helpers/api/autodesk/index'; // Ajusta la ruta según tu estructura de carpetas
import { uploadFileBim, getStatus, getFilesParams, updateFile } from '../../../../helpers/api/bim/index'; // Ajusta la ruta según tu estructura de carpetas
import ViewBimItem from './ViewBimItem.vue'
// import { ViewDocs } from './components/ViewDocs'

export default {
  name: "ViewBim",
  props: ["info"],
  components: {
    SwitchIcon, ViewBimItem
  },
  data: () => ({
    textPlanning: "Planimetria",
    favorite: false,
    opened: 0,
    text: "",
    imageDialog: false,
    accessToken: null,
    loaderUpload: false,
    errorMessage: null,
    isRender: false,
    loaderProgress: false,
    loaderText: '',
    numProgress: 20,
    select: null,
    items: [],
    docs: null,
    selectedItem: null,
    loadExternalScript: null,
    urn: null
  }),
  async mounted() {
    this.loaderUpload = true;
    setTimeout(() => {
      this.getRenders();
    }, 2000);
  },
  methods: {
    handleFavorite(isFavorite) {
      console.log('favorite', isFavorite, this.select.guid);
      const formData = new FormData();
      formData.append('is_favorite', isFavorite);
      console.log('formData', formData);
      this.handleUpdateFile(this.select.guid, formData);
      // Manejar la lógica para el favorito aquí
    },
    onItemSelected() {
      console.log("Selected item:", this.select);
      this.renderAutodesk(this.select.external_urn, 'onItemSelected')
      // Aquí puedes hacer lo que necesites con el item seleccionado
    },
    openFileInput() {
      console.log('openFileInput');
      if (this.$refs.fileImportBim) {
        this.$refs.fileImportBim.click();
      }
    },
    async handleUpdateFile(guid, form) {
      console.log('handleUpdateFile', guid, form);
      try {
        this.loaderUpload = true;
        this.errorMessage = false;
        const response = await updateFile(guid, form);
        console.log('Respuesta de updateFile:', response);
        this.loaderUpload = false;
        this.getRenders(true);
      } catch (error) {
        console.error('Error al cargar favorito:', error);
        this.loaderUpload = false;
        this.errorMessage = 'Error al cargar favorito: ' + String(error)
      }
    },
    async handleFileUploadBim(event) {
      console.log('handleFileUploadBim', event);
      const file = event.target.files[0];
      if (file) {
        try {
          this.loaderUpload = true;
          this.errorMessage = false;
          this.loaderText = 'Cargando modelo ...';
          const response = await uploadFileBim(file, this.$store.projectsStore.state.selectedProject.id);
          this.loaderText = 'Renderizando modelo ...';
          if (response) {
            if (response) {
              this.getStatusBim(response.external_urn)
            } else {
              this.loaderUpload = false;
            }
          }
          console.log('Respuesta de uploadFile:', response);
        } catch (error) {
          console.error('Error al subir el archivo:', error);
          this.loaderUpload = false;
          this.errorMessage = 'Error al subir el archivo: ' + String(error)
        }
      }
    },
    async getStatusBim(urn) {
      console.log('get status', urn);
      this.loaderUpload = true;
      try {
        let params = {
          'urn': urn
        };
        let status = await getStatus(params);
        if (status) {
          let progress = this.extractNumber(status.progress);
          console.log('#progress', progress);
          setTimeout(() => {
            if (progress == null) {
              this.errorMessage = null;
              this.loaderUpload = false;
              this.loaderProgress = false;
              this.numProgress = 0;
              this.loaderText = '';
              this.renderAutodesk(urn);
              return true;
            }
            if (progress < 100) {
              this.loaderProgress = true;
              this.numProgress = progress;
              this.getStatusBim(urn);
              this.getRenders(false);
            }

            if (progress == 0) {
              this.loaderText = 'Preparando renderizado ...';
              // this.loaderProgress = true;
            }
            if (progress > 0 && progress <= 75) {
              this.loaderText = 'Renderizando modelo ...';
              // this.loaderProgress = true;
            }
            if (progress > 75 && progress <= 95) {
              this.loaderText = 'Renderizando modelo. Queda muy poco ...';
            }
            if (progress > 95 && progress <= 99) {
              this.loaderText = 'Renderizando modelo. Finalizando ...';
            }
          }, 1000);
        }
        // this.loaderUpload = false;
        // console.log('status response', status);
      } catch (error) {
        this.loaderUpload = false;
        this.errorMessage = 'Error fetching files: ' + String(error);
        console.error('Error fetching files:', error);
      }
    },
    renderAutodesk(urn = null, instance=null) {
      console.log('VB.renderAutodesk', urn, instance);
      if (urn == null) {
        return false;
      }
      this.isRender = true;
      this.urn = urn;
    },
    extractNumber(text) {
      const match = text.match(/\d+/);
      if (match) {
        return parseInt(match[0]);
      }
      return null;
    },
    async getRenders(render = true) {
      console.log('data docs');
      this.loaderUpload = true;
      try {
        let params = {
          'external_id': this.$store.projectsStore.state.selectedProject.id,
          'role': 'model',
          'ordering': '-is_favorite,-id'
        };
        this.items = await getFilesParams(params);
        console.log('items', this.items);
        if (this.items) {
          for (let item of this.items) {
            if (item.is_favorite) {
              this.select = item;
            }
          }
          if (this.select == null) {
            this.select = this.items[0];
          }
          if (render) {
            let external_urn = null;
            try {
              external_urn = this.select.external_urn
            } catch (e) {
              console.log(e);
            }

            if (external_urn) {
              this.renderAutodesk(this.select.external_urn)
            }
          }

        }

        this.loaderUpload = false;
        console.log('renders', this.select, this.items);
      } catch (error) {
        this.loaderUpload = false;
        this.errorMessage = 'Error fetching files: ' + String(error);
        console.error('Error fetching files:', error);
      }
    }
  },
};
</script>

<style scoped>
.container-media {
  width: 100%;
  height: 100%;
}

.loader-text {
  font-size: 0.8rem;
  color: black;
  text-align: center;
  width: 100%;
}

.card-background {
  background-color: white;
  border-radius: 4px;
}

.textPlanning {
  font-size: 1.1rem;
  font-weight: bold;
  color: #454d61;
}

.card-title {
  font-weight: 700;
  font-size: 18px;
  color: #454d61;
}

.btn-right {
  border-radius: 25px;
  background-color: #525d78 !important;
  color: white;
  font-size: 10px;
}

.btn-plus {
  border-radius: 15px;
  background-color: #525d78 !important;
  color: white;
  font-size: 10px;
}

.card-detail {
  padding: 12px;
}

.container-fv {
  position: relative;
  width: 100%;
  height: 500px;
  /* Establece la altura deseada */
  max-width: 100%;
  /* Asegura que no supere el ancho del contenedor padre */
  overflow: hidden;
  /* Oculta el contenido que excede el tamaño del contenedor */
}

.height500{
  height: 500px;
}

.height100{
  height: 100px;
}


.select-import-tools {
  align-items: center;

  * {
    text-align: right;
  }
}

.favorite {
  display: flex;
  align-items: center;
  justify-content: right;

  span {
    font-size: 0.85rem;
    color: #454d61;
    font-weight: 500;
  }
}

.panel {
  margin-bottom: 15px;
}

.row-detail {
  padding: 12px;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
}

.color-square-ar {
  width: 12px;
  height: 20px;
  background-color: #61679e;
}

.color-square-sa {
  width: 12px;
  height: 20px;
  background-color: #e2b46e;
}

.color-square-el {
  width: 12px;
  height: 20px;
  background-color: #46aa62;
}

.text-title {
  margin-left: 10px;
  font-size: 18px;
}

.content-detail {
  padding-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
}

.projects-card {
  width: 230px;
  height: 160px;
  margin: 15px;
  padding: 10px 10px 30px 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border-left: 5px #454d61 solid !important;
}

.project-detail-card {
  display: flex;
  justify-content: center;
  align-items: center;
  color: #525d78;
}

.preview-img {
  padding-top: 15px;
  width: 200px;
  cursor: pointer;
}

.text-row {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.text-area {
  font-size: 13px;
  font-weight: bold;
  color: #454d61;
}

.popup-image {
  height: 100%;
  width: auto;
}

.weather-modal-close-button {
  position: absolute;
  top: 8px;
  /* Adjust the distance from the top */
  right: 8px;
  /* Adjust the distance from the right */
}
</style>
