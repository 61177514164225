import {endpoints} from './endpoints'
import request from '../../request'
import axios from 'axios';


export function getAutodeskAccessToken() {
  const clientId = 'jOMM88Bb6LmC0OpRGXtAtybA9cGw3Pg4';
  const clientSecret = 'cOnLblhF13vT4kuL';
  const apiUrl = 'https://developer.api.autodesk.com/authentication/v2/token';

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Accept': 'application/json',
    'Authorization': `Basic ${Buffer.from(`${clientId}:${clientSecret}`).toString('base64')}`
  };

  const data = new URLSearchParams();
  data.append('grant_type', 'client_credentials');
  data.append('scope', 'code:all data:write data:read bucket:create bucket:delete bucket:read');

  return new Promise((resolve, reject) => {
    axios.post(apiUrl, data, { headers })
      .then(response => {
        resolve(response.data.access_token);
      })
      .catch(error => {
        reject(error);
      });
  });
}


export function search(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.search,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getNotifications(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getNotifications,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function markNotificationAsReaded(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markNotificationAsReaded,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getReminders() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getReminders,
			method: 'post'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getSystemCurrencies() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSystemCurrencies,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}