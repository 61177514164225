<template>
  <v-container class="d-flex flex-column align-start pa-8" pa-0 fluid>
    <v-container class="planning">
      <v-row class="pa-4">
        <v-col>
          <v-avatar class="icon-control">
            <v-img style="margin-top: 10px;" src="../../../assets/Svg/ControlAvance/tactic_control_avance.svg"></v-img>
          </v-avatar>
          <span class="textPlanning">{{ textPlanning }}</span>
        </v-col>

        <v-col class="d-flex justify-end align-end">
          <v-btn @click="handleclick" class="btn-right" small
            >Agregar Planificación</v-btn
          >
        </v-col>
      </v-row>

      <!-- Show Card -->
      <div class="d-flex">
        <!-- this row show the quantity of cars -->
        <v-row>
          <!-- Card 1 -->
          <v-col class="pa-0">
            <v-card :class="{ 'favorite-card': favorite }" class="projects-card">
              <v-row class="project-detail-card">
                <v-col class="pa-0" cols="1" sm="2">
                  <!-- icon -->
                  <v-avatar class="mt-6 ml-2" style="border-radius: 0">
                    <v-img
                      src="../../../assets/Svg/ControlAvance/_Projectpowerpoint-microsoft.svg"
                    ></v-img>
                  </v-avatar>
                  <v-btn icon class="star-button" @click="toggleFavorite">
                    <v-icon :color="favorite ? '#E75858' : 'grey'">mdi-star</v-icon>
                  </v-btn>
                </v-col>

                <v-col cols="10" sm="">
                  <!-- details -->
                  <div>
                    <p class="mb-0 title-card">001 - Programa obra Tarapacá</p>
                  </div>

                  <div>
                    <p class="mb-0" style="color: #c0c3c7">program-tarapaca-final.mpp</p>
                  </div>

                  <div class="d-flex">
                    <p class="mb-0" style="color: #c0c3c7">Fecha subida:</p>
                    <p style="color: #454d61; font-weight: bold">20 Noviembre 2023</p>
                  </div>

                  <div
                    class="d-flex pa-3"
                    style="background-color: #eaeaea !important; border-radius: 7px"
                  >
                    <v-row>
                      <v-col d-block>
                        <p>Fecha Inicio</p>
                        <p>20 Octubre 2023</p>
                      </v-col>

                      <v-col> </v-col>
                    </v-row>
                  </div>

                  <div class="cont-card-buttons">
                    <div class="pr-1">
                      <v-btn class="card-button"> <v-icon>mdi-eye</v-icon></v-btn>
                    </div>
                    <div class="pl-1 d-flex justify-center">
                      <v-btn class="card-button">
                        <v-icon>mdi-poll</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- card 2 -->
          <v-col class="pa-0">
            <v-card class="projects-card">
              <v-row class="project-detail-card">
                <v-col class="pa-0" cols="1" sm="2">
                  <!-- image -->
                  <v-avatar class="mt-6 ml-2" style="border-radius: 0">
                    <v-img
                      src="../../../assets/Svg/ControlAvance/_Projectpowerpoint-microsoft.svg"
                    ></v-img>
                  </v-avatar>
                </v-col>

                <v-col cols="10" sm="">
                  <!-- details -->
                  <div>
                    <p class="mb-0 title-card">002 - Programa obra Tarapacá</p>
                  </div>

                  <div>
                    <p class="mb-0" style="color: #c0c3c7">program-tarapaca-final.mpp</p>
                  </div>

                  <div class="d-flex">
                    <p class="mb-0" style="color: #c0c3c7">Fecha subida:</p>
                    <p style="color: #454d61; font-weight: bold">20 Noviembre 2023</p>
                  </div>

                  <div
                    class="d-flex pa-3"
                    style="background-color: #eaeaea !important; border-radius: 7px"
                  >
                    <v-row>
                      <v-col d-block>
                        <p>Fecha Inicio</p>
                        <p>20 Octubre 2023</p>
                      </v-col>

                      <v-col> </v-col>
                    </v-row>
                  </div>

                  <div class="cont-card-buttons">
                    <div class="pr-1">
                      <v-btn class="card-button"> <v-icon>mdi-eye</v-icon></v-btn>
                    </div>
                    <div class="pl-1">
                      <v-btn class="card-button">
                        <v-icon>mdi-poll</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          <!-- card 3 -->
          <v-col class="pa-0">
            <v-card class="projects-card">
              <v-row class="project-detail-card">
                <v-col class="pa-0" cols="1" sm="2">
                  <!-- image -->
                  <v-avatar class="mt-6 ml-2" style="border-radius: 0">
                    <v-img
                      src="../../../assets/Svg/ControlAvance/_Projectpowerpoint-microsoft.svg"
                    ></v-img>
                  </v-avatar>
                </v-col>

                <v-col cols="10" sm="">
                  <!-- details -->
                  <div>
                    <p class="mb-0 title-card">003 - Programa obra Tarapacá</p>
                  </div>

                  <div>
                    <p class="mb-0" style="color: #c0c3c7">program-tarapaca-final.mpp</p>
                  </div>

                  <div class="d-flex">
                    <p class="mb-0" style="color: #c0c3c7">Fecha subida:</p>
                    <p style="color: #454d61; font-weight: bold">20 Noviembre 2023</p>
                  </div>

                  <div
                    class="d-flex pa-3"
                    style="background-color: #eaeaea !important; border-radius: 7px"
                  >
                    <v-row>
                      <v-col d-block>
                        <p>Fecha Inicio</p>
                        <p>20 Octubre 2023</p>
                      </v-col>

                      <v-col> </v-col>
                    </v-row>
                  </div>

                  <div class="cont-card-buttons">
                    <div class="pr-1">
                      <v-btn class="card-button"> <v-icon>mdi-eye</v-icon></v-btn>
                    </div>
                    <div class="pl-1">
                      <v-btn class="card-button">
                        <v-icon>mdi-poll</v-icon>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>

          
        </v-row>
      </div>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "PlanningDetail",
  props: ["info"],
  data: () => ({
    textPlanning: "Planificaciones",
    favorite: false,
  }),
  methods: {
    toggleFavorite() {
      this.favorite = !this.favorite;
    },
  }
};
</script>

<style scoped>
.planning {
  background-color: white;
  border-radius: 4px;
}

.textPlanning {
  font-size: 1.1rem;
  font-weight: bold;
  color: #454d61;
}

.icon-control {
  width: 39px;
  min-width: 40px;
  border-radius: 0;
}

.btn-right {
  border-radius: 25px;
  background-color: #525d78 !important;
  color: white;
  font-size: 10px;
}

/* Details cards */
.projects-card {
  margin: 15px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 4px;
  border-left: 5px #454d61 solid !important;
  transition: border-left 0.3s;  /* Transición para suavizar el cambio de color */
}

.favorite-card {
  border-left: 5px #E75858 solid !important;
}


.project-detail-card,
.project-detail-card {
  display: flex;
  margin-left: 5px;
  margin-right: 5px;
  color: #525d78;
}

.title-card {
  font-size: 20px;
  font-weight: bold;
}

.cont-card-buttons {
  display: flex;
  justify-content: end;
  padding-top: 10px;
  padding-bottom: 5px;
}

.card-button {
  border-radius: 8px;
  padding: 0px !important;
  padding-left: 0px !important;
  background-color: #e2e7f0 !important;
}

/*Out default padding for general buttons, It's a problem in responsive*/
button.v-btn.v-btn--is-elevated.v-btn--has-bg.theme--light.v-size--default {
  padding-left: 0;
}

.star-button {
  position: absolute;
  top: 5px;
  right: 5px;
}

</style>
