<template>
        <v-card style="/*height: 900px;*/ overflow: scroll; border-radius: 16px">
          <v-row style="display: flex">
            <v-col cols="12" sm="6" style="padding-right: 0px; margin-left: 8px; /*overflow: auto;*/ max-height: 900px">
              <MinimizeCloseBtn :closeModal="closeModal" />
              <v-card-text>
                <div>
                  <div class="head-fixed">               
                    <div
                      class="d-flex"
                      style="width: 100%"
                      :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'"
                    >
                      <div class="row" style="width: 100%; margin-top: 10px">
                        <p class="lead-text">
                          Proyecto /
                          {{ $store.consultasStore.state.currentTarea.project.name }}
                        </p>
                      </div>  
                                     
                    </div>
                    <div
                      class="d-flex mt-6"
                      style="width: 100%"
                      :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'"
                    >
                      <div class="row" style="width: 100%">
                        <v-row>
                          <v-col cols="auto">
                            <v-icon color="success">mdi-square</v-icon>
                          </v-col>
                          <v-col class="d-flex">
                            <p class="icon-text">
                              Nº {{ $store.consultasStore.state.currentTarea.number }} /
                              {{ $store.consultasStore.state.currentTarea.title }}
                            </p>                        
                          </v-col>
                          <v-col cols="auto">
                            <!-- indicators -->      
                                  <div class="ma-2 ml-0">
                                    <v-chip style="background-color: #e4e4e4;">
                                        <p style="color:#8B8B8B;margin: 0;">{{ $store.consultasStore.state.currentTarea.code }}</p>
                                    </v-chip>
                                  </div>                                                       
                                  <div class="d-flex">                              
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="white"
                                          class="icon-top success"
                                          v-if="
                                            $store.consultasStore.state.currentTarea
                                              .status == 3
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          >mdi-star</v-icon
                                        >
                                        </template>
                                      <span
                                        >Tarea finalizada el
                                        {{
                                          $store.consultasStore.state.currentTarea.ended_at
                                            | toDateTime
                                        }}</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="white"
                                          class="icon-top"
                                          :class="
                                            !$store.consultasStore.state.currentTarea.seen
                                              ? 'high'
                                              : 'success'
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          >mdi-check</v-icon
                                        >
                                      </template>
                                      <span
                                        >El responsable
                                        {{
                                          !$store.consultasStore.state.currentTarea.seen
                                            ? "no"
                                            : ""
                                        }}
                                        ha visualizado esta tarea</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip
                                      bottom
                                      v-if="
                                        checkIfNearDeadline(
                                          $store.consultasStore.state.currentTarea
                                        )
                                      "
                                    >
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="white"
                                          class="icon-top medium"
                                           v-bind="attrs"
                                          v-on="on"
                                          small
                                          >mdi-flag-outline</v-icon
                                        >
                                      </template>
                                      <span
                                        >Se acerca la fecha crítica
                                        {{
                                          $store.consultasStore.state.currentTarea
                                            .critical_date | toDate
                                        }}</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="white"
                                          class="icon-top dark-grey"
                                          v-if="
                                            $store.consultasStore.state.currentTarea.private
                                          "
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          >mdi-incognito</v-icon
                                        >
                                      </template>
                                      <span
                                        >Tarea privada, sólo visible para el creador y el
                                        responsable</span
                                      >
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                          color="white"
                                          class="icon-top"
                                          v-bind="attrs"
                                          v-on="on"
                                          :class="
                                            getTareaPriorityColor(
                                              $store.consultasStore.state.currentTarea
                                            )
                                          "
                                          small
                                          v-if="
                                            $store.consultasStore.state.currentTarea
                                               .waiting_status != 1
                                          "
                                          >mdi-exclamation</v-icon
                                        >
                                      </template>
                                      <span
                                        >Prioridad
                                        {{
                                          getTareaPriorityText(
                                            $store.consultasStore.state.currentTarea
                                          )
                                        }}</span
                                      >
                                    </v-tooltip>
                                  </div>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
    
                    <div
                      class="d-flex"
                      style="width: 100%"
                      :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'"
                    >
                      <v-row >
                        <v-col class="task-buttons pt-5">
                          <!-- new buttons -->
                          <InteractionButtons :closeModal="closeModal" />
                          <!-- new buttons -->
                        </v-col>
    
                        <!-- Responsible -->
                        <v-col class="task-buttons" style="margin-top: -20px;" v-if="showDropdownHeader">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                fab                          
                                small
                                color="#f5f5f5"
                                class="item-task"                                 
                                v-on="on"                                                                            
                              >
                                <v-icon
                                  color="#54607a"
                                  v-bind="attrs"
                                  v-on="on"                                    
                                  medium                                    
                                >mdi-account-edit-outline</v-icon>
                              </v-btn>
                            </template>
                            
                            <p class="mb-0">
                              <b>Creador:</b>
                              {{
                                $store.consultasStore.state.currentTarea.created_by_user
                                .name
                              }}                      
                            </p>                        
                          </v-tooltip>
                          
                          <v-menu 
                            offset-y 
                            :close-on-content-click="false"
                            v-if="checkPermissions(['change_responsable_task'])"
                            @input="checkUsers()"
                          >
                            <template v-slot:activator="{ on: onMenu }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    fab                                                  
                                    small
                                    color="#f5f5f5"
                                    class="item-task"
                                    v-bind="attrs"
                                    v-on="{ ...onMenu, ...onTooltip }"
                                    :loading="loadingChangeUser"                                                                          
                                  >
                                    <v-icon
                                      color="#54607a"
                                      v-bind="attrs"
                                      v-on="on"                                    
                                      medium                                    
                                    >mdi-swap-horizontal-bold</v-icon>
                                  </v-btn>
                                </template>
                                <p class="mb-0">
                                  <b>Responsable:</b>
                                  {{
                                    $store.consultasStore.state.currentTarea
                                      .responsable_user.name
                                  }}                        
                                </p>
                              </v-tooltip>
                            </template>                      
                            
                            <v-list dense class="py-0">
                              <v-list-item
                                dense
                                class="mb-0"
                                style="min-height: 25px; background: #515d78"
                              >
                                <p
                                  class="caption mb-0 font-weight-bold white--text"
                                >
                                  Cambiar Responsable
                                </p>
                              </v-list-item>
                              <div
                                v-if="
                                  $store.usersStore.state.loadingUserListForItems
                                "
                                class="d-flex flex-column align-center justify-center"
                              >
                                <v-progress-linear
                                  indeterminate
                                  color="#515d78"
                                ></v-progress-linear>
                                <v-icon class="mt-3 mb-0">mdi-account-group</v-icon>
                                <p class="caption">Cargando usuarios</p>
                              </div>
                              <v-list-item-group
                                v-else
                                color="#515d78"
                                class="overflow-y-auto mt-1"
                                style="max-height: 300px"
                              >
                                <v-list-item
                                  v-for="(user, index) in $store.usersStore.state
                                      .usersListForItems.users"
                                    v-show="
                                      $store.consultasStore.state.currentTarea
                                        .responsable != user.id
                                    "
                                    :key="index"
                                    dense
                                    class="px-0 py-0"
                                    style="width: 100%"
                                    @click="changeUser(user.id)"
                                >
                                  <div style="width: 100%">
                                    <div
                                      class="d-flex flex-row align-center px-4 pt-2"
                                    >
                                      <img
                                        :src="user.info.avatar"
                                        class="notification-img"
                                      />
                                      <div class="px-4">
                                        <p
                                          class="mb-0 caption font-weight-bold"
                                          style="color: #707070"
                                        >
                                          {{ user.name }} -
                                          <span style="color: #515d78">{{
                                            user.projectjob.userjobtype.name
                                          }}</span>
                                        </p>
                                        <p
                                          class="mb-0 caption"
                                          style="color: #707070"
                                        >
                                          {{ user.email }}
                                        </p>
                                      </div>
                                    </div>
                                    <v-divider class="mt-2"></v-divider>
                                  </div>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-menu> 
                          
                          <!-- Participants -->
                          <v-menu 
                            offset-y 
                            :close-on-content-click="false"
                            v-if="$store.consultasStore.state.currentTarea"                        
                          >
                            <template v-slot:activator="{ on: onMenu }">
                              <v-tooltip bottom>
                                <template v-slot:activator="{ on: onTooltip }">
                                  <v-btn
                                    fab                                                  
                                    small
                                    color="#f5f5f5"
                                    class="item-task"
                                    v-bind="attrs"
                                    v-on="{ ...onMenu, ...onTooltip }"
                                    :loading="loadingChangeUser"                                                                          
                                  >
                                    <v-icon
                                      color="#54607a"
                                      v-bind="attrs"
                                      v-on="on"                                    
                                      medium                                    
                                    >mdi-eye</v-icon>
                                  </v-btn>
                                </template>
                                <p class="mb-0">
                                  <b>Participantes</b>                                                    
                                </p>
                              </v-tooltip>
                            </template>
                            
                            <v-list dense class="py-0">
                              <v-list-item-group
                                color="#515d78"
                                class="overflow-y-auto"
                                style="max-height: 300px"
                              >
                                <v-list-item
                                  dense
                                  class="mb-0"
                                  style="min-height: 25px; background: #515d78"
                                >
                                  <div
                                    class="d-flex flex-row align-center justify-space-between"
                                    style="width: 100%"
                                  >
                                    <p
                                      class="caption mb-0 font-weight-bold white--text"
                                    >
                                      Participantes
                                    </p>
                                    <v-btn
                                      icon
                                      dark
                                      x-small
                                      color="white"
                                      @click="addParticipantModal = true"
                                    >
                                      <v-icon dark>mdi-plus-circle</v-icon>
                                    </v-btn>
                                  </div>
                                </v-list-item>
                                <v-list-item
                                  v-if="
                                    $store.consultasStore.state.currentTarea
                                      .participants &&
                                    $store.consultasStore.state.currentTarea
                                      .participants.length === 0
                                  "
                                  dense
                                  class="px-0 py-0"
                                  style="width: 100%"
                                >
                                  <div
                                    class="d-flex flex-column align-center justify-center pa-2"
                                  >
                                    <v-icon class="my-1"
                                      >mdi-close-octagon-outline</v-icon
                                    >
                                    <p class="body-2 caption mb-0">
                                      No se han agregado participantes aún
                                    </p>
                                  </div>
                                </v-list-item>
                                <v-list-item
                                  v-else
                                  v-for="(user, index) in $store.consultasStore
                                    .state.currentTarea.participants"
                                  v-show="
                                    $store.consultasStore.state.currentTarea
                                      .responsable != user.id
                                  "
                                  :key="index"
                                  dense
                                  class="px-0 py-0"
                                  style="width: 100%"
                                >
                                  <div style="width: 100%">
                                    <div
                                      class="d-flex flex-row align-center justify-space-between px-4 pt-2"
                                    >
                                      <div class="d-flex flex-row align-center">
                                        <img
                                          :src="user.basic_info.avatar"
                                          class="notification-img"
                                        />
                                        <div class="px-4">
                                          <p
                                            class="mb-0 caption font-weight-bold"
                                            style="color: #707070"
                                          >
                                            {{ user.name }}
                                          </p>
                                          <p
                                            class="mb-0 caption"
                                            style="color: #707070"
                                          >
                                            {{ user.email }}
                                          </p>
                                        </div>
                                      </div>
                                      <v-btn
                                        icon
                                        dark
                                        x-small
                                        color="#515d78"
                                        class="ml-2"
                                        :loading="loadingRemoveParticipant"
                                        @click="removeParticipant(user)"
                                      >
                                        <v-icon dark>mdi-delete-outline</v-icon>
                                      </v-btn>
                                    </div>
                                    <v-divider class="mt-2"></v-divider>
                                  </div>
                                </v-list-item>
                              </v-list-item-group>
                            </v-list>
                          </v-menu>
                          
                          
                        <v-tooltip bottom >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              fab                          
                              small
                              color="#f5f5f5"
                              class="item-task"
                              v-bind="attrs"                                                                             
                            >
                              <v-icon
                                color="#54607a"
                                v-bind="attrs"
                                v-on="on"                                    
                                medium                                    
                              >mdi-calendar-month-outline</v-icon>
                            </v-btn>
                          </template>
    
                          <p class="mb-0">                        
                            <b>Fecha Emisión:</b>
                            {{
                              $store.consultasStore.state.currentTarea.created_at
                                | toDateTime
                            }}
                          </p>
    
                          <p
                            class="mb-0"
                            v-if="
                              $store.consultasStore.state.currentTarea.status == 3 &&
                              $store.consultasStore.state.currentTarea.ended_at
                            "
                          >                      
                            <b>Finalizada:</b>
                            {{
                              $store.consultasStore.state.currentTarea.ended_at
                                | toDateTime
                            }}
                          </p>
    
                          <p
                            class="mb-0"                        
                            v-if="
                              $store.consultasStore.state.currentTarea.critical_date
                            "
                          >                                
                            <b>Fecha Crítica:</b>
                            {{
                              $store.consultasStore.state.currentTarea.critical_date
                                | toDate
                            }}
                          </p>
    
                          <p class="mb-0">                        
                            <b>Tiempo de espera:</b>
                            {{ $store.consultasStore.state.currentTarea.waitingtime }}
                          </p>                     
                        </v-tooltip>                       
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                  
                  <div class="content-scrolled">
                    <v-row class="mt-5">
                      <v-expansion-panels class="expansion-panel-parent" :value="opened">
                        <v-expansion-panel class="expansion-panel-child">
                          <v-expansion-panel-header class="expansion-panel-header">
                            <div class="flex">
                              <span style="font-weight: 700; font-size: 17px; color: #454d61"
                                >Descripción</span
                              >
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="expansion-panel-content">
                            <!-- Content of the Dropdown Card -->
                            <div
                              v-html="$store.consultasStore.state.currentTarea.description"
                            ></div>
                            <div
                              v-if="
                                $store.consultasStore.state.currentTarea.checklists.length > 0
                              "
                              class="mb-1"
                            >
                              <h4>SubTareas</h4>
                              <div class="parent-subtareas">
                                <v-checkbox
                                  v-for="(subtarea, index) in $store.consultasStore.state
                                    .currentTarea.checklists"
                                  :key="index"
                                  v-model="subtarea.completed"
                                  :label="subtarea.description"
                                  dense
                                  hide-details
                                  class="mx-2 subtareas-checkbox"
                                  @change="changedChecklist"
                                ></v-checkbox>
                              </div>
                            </div>
                            <MediaGallery
                              v-if="
                                $store.consultasStore.state.currentTarea.attachments.filter(
                                  (item) => item.file.pathmedia
                                ).length > 0
                              "
                              :images="
                                $store.consultasStore.state.currentTarea.attachments
                                  .filter((item) => item.file.pathmedia)
                                  .map((item) => item.file.pathmedia)
                              "
                            />
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
    
                    <v-row class="mt-5">
                      <v-expansion-panels class="expansion-panel-parent">
                        <v-expansion-panel class="expansion-panel-child">
                          <v-expansion-panel-header class="expansion-panel-header">
                            <div class="flex">
                              <span style="font-weight: 700; font-size: 17px; color: #454d61"
                                >Adjuntos</span
                              >
                            </div>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content class="expansion-panel-content">
                            <!-- Content of the Dropdown Card -->
                            <v-col cols="12" v-if="showDropdownAdj">
                              <div class="d-flex justify-space-between">
                                <!-- <p class="mb-0 title black--text">Adjuntos</p> -->
                                <v-btn
                                  v-if="
                                    $store.consultasStore.state.currentTarea.attachments
                                      .length > 1
                                  "
                                  class="mx-2 my-1"
                                  rounded
                                  color="#515d78"
                                  dark
                                  x-small
                                  :loading="loadingFilePath"
                                  @click="
                                    () =>
                                      downloadAttachments(
                                        $store.consultasStore.state.currentTarea.attachments
                                      )
                                  "
                                >
                                  <v-icon dark small>mdi-paperclip</v-icon>
                                  <p class="mb-0 ml-2">Descargar Todo</p>
                                </v-btn>
                              </div>
                              <div
                                class="adjuntos-container"
                                v-if="
                                  $store.consultasStore.state.currentTarea.attachments.filter(
                                    (item) => !item.file.pathmedia
                                  ).length > 0
                                "
                              >
                                <div
                                  class="d-flex flex-row align-center justify-space-between my-2 py-1 px-2"
                                  :class="index % 2 != 0 ? 'bg-dark-list' : ''"
                                  :key="index"
                                  v-for="(
                                    attachment, index
                                  ) in $store.consultasStore.state.currentTarea.attachments.filter(
                                    (item) => !item.file.pathmedia
                                  )"
                                >
                                  <p class="mb-0">{{ attachment.file.name }}</p>
                                  <p class="mb-0 text-primary">
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          class="mx-1 my-1"
                                          color="#515d78"
                                          dark
                                          small
                                          icon
                                          :loading="loadingFilePath"
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="getFilesUrl(attachment, true)"
                                        >
                                          <v-icon
                                            color="white"
                                            small
                                            class="info-icon small-icon"
                                            >mdi-open-in-new</v-icon
                                          >
                                        </v-btn>
                                      </template>
                                      <span>Visualizar en navegador</span>
                                    </v-tooltip>
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                          class="mx-1 my-1"
                                          color="#515d78"
                                          dark
                                          x-small
                                          :icon="$vuetify.breakpoint.mobile"
                                          :loading="loadingFilePath"
                                          v-bind="attrs"
                                          v-on="on"
                                          @click="getFilesUrl(attachment, true, true)"
                                        >
                                          <v-icon
                                            color="white"
                                            small
                                            class="bg-light-blue mr-2"
                                            :class="
                                              $vuetify.breakpoint.mobile ? 'info-icon' : ''
                                            "
                                            >mdi-cloud-download-outline</v-icon
                                          >
                                          <p class="mb-0" v-if="!$vuetify.breakpoint.mobile">
                                            Descargar
                                          </p>
                                        </v-btn>
                                      </template>
                                      <span>Descargar archivo</span>
                                    </v-tooltip>
                                  </p>
                                </div>
                              </div>
                              <p v-else>No hay adjuntos</p>
                            </v-col>
                          </v-expansion-panel-content>
                        </v-expansion-panel>
                      </v-expansion-panels>
                    </v-row>
                  </div>
                 
                </div>
              </v-card-text>
            </v-col>
            <v-col cols="12" sm="6" class="interaction-section" style="position: sticky; top: 0; width: 200px">
              <div class="interaction-rectangle">
                <span>Interacción</span>                
              </div>
              <div class="interaction-card">
                <div class="scrollable-content">
                  <!-- Interaction Zone -->
                  <v-row align="center">
                    <v-col cols="12">
                      <v-row
                        align="center"
                        class="mt-2"
                        v-if="
                          $store.consultasStore.state.currentTarea.interactions.length < 1
                        "
                      >
                        <v-col cols="12" class="text-center"> No hay interacciones </v-col>
                      </v-row>
                      <Interactions
                        :interactions="
                          filteredSearch(
                            this.$store.consultasStore.state.currentTarea.interactions
                          )
                        "
                        :searchText="interactionSearch"
                        @open-responder="openResponderHandler"
                      />
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div class="response-view" v-if="showResponderDiv">
                <div class="response-header">
                  <p
                    class="mb-0 text-primary message"
                    :class="$vuetify.breakpoint.mobile ? 'caption' : ''"
                  >
                    Estás respondiendo el mensaje de
                  </p>
                  <div>
                    <v-btn icon @click="showDropdown = !showDropdown">
                      <v-icon>{{
                        showDropdown ? "mdi-arrow-down" : "mdi-arrow-up"
                      }}</v-icon>
                    </v-btn>
                    <v-btn icon @click="closeResponderDiv">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </div>
                </div>
                <span class="message" style="font-weight: bold"
                  >{{ selectedInteraction.user.name }} |
                  {{ selectedInteraction.created_at | toDateTime }}</span
                >
  
                <div v-if="showDropdown">
                  <div
                    v-html="
                      $options.filters.highligthSearch(
                        selectedInteraction.message,
                        searchText
                      )
                    "
                  ></div>
                </div>
              </div>
              <v-row
                align="center"
                class="mt-2"
                v-if="
                  $store.consultasStore.state.currentTarea.status != 3 &&
                  checkInteractPermission(['interact_tasks'])
                "
              >
                <v-col cols="12">
                  <!-- <v-btn-toggle v-model="response_type" mandatory color="#515d78">
                      <v-btn
                        small
                        @click="is_response = false"
                        :class="$vuetify.breakpoint.mobile ? 'caption' : ''"
                        >Interacción</v-btn
                      >
                    </v-btn-toggle> -->
                  <tiptap-vuetify
                    v-model="message"
                    :extensions="extensions"
                    id="interaction-texteditor"
                  />
                  <v-alert
                    type="warning"
                    dense
                    icon="mdi-alert-outline"
                    class="mt-1"
                    v-if="is_response"
                  >
                    <div class="d-flex flex-row align-center">
                      <p class="mb-0 caption mr-1">
                        Estás respondiendo de forma oficial esta tarea, si sólo quieres
                        realizar una interacción presiona el botón.
                      </p>
                      <v-btn
                        color="white"
                        outlined
                        small
                        @click="
                          () => {
                            is_response = false;
                            response_type = 0;
                          }
                        "
                      >
                        Interacción
                      </v-btn>
                    </div>
                  </v-alert>
                </v-col>
              </v-row>
              <v-row align="center" v-if="files.length > 0">
                <v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
                  <div
                    v-for="(file, index) in files"
                    :key="index"
                    class="ma-1"
                    style="flex: 1 0 30%"
                  >
                    <div
                      v-if="filesPreview[index] && filesPreview[index].src"
                      class="preview-img-container"
                    >
                      <v-btn
                        icon
                        color="#515d78"
                        x-small
                        class="button-preview-img"
                        @click="removeAttachment(index)"
                      >
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      <img :src="filesPreview[index].src" class="preview-img" />
                    </div>
                    <div v-else class="d-flex flex-row align-center justify-start">
                      <v-btn icon color="#515d78" x-small @click="removeAttachment(index)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                      {{ file.name }}
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row
                align="center"
                class="btn-response"
                v-if="
                  $store.consultasStore.state.currentTarea.status != 3 &&
                  checkInteractPermission(['interact_tasks'])
                "
              >
                <v-col cols="12">
                  <div
                    class="d-flex justify-space-between"
                    :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'"
                  >
                    <v-btn
                      class="mx-2 my-1"
                      rounded
                      color="#525D78"
                      dark
                      v-if="checkInteractPermission(['interact_tasks'])"
                      :loading="loading"
                      @click="preAddInteraction()"
                    >
                      <p class="mb-0">{{ confirmButtonText }}</p>
                      <v-icon right>mdi-upload</v-icon>
                    </v-btn>
                    <div
                      class="d-flex"
                      :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'"
                    >
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="white"
                        raised
                        v-if="checkInteractPermission(['interact_tasks'])"
                        @click="onButtonClick"
                      >
                        <p class="mb-0 text-primary">Adjuntar Archivo</p>
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged"
                      />
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="white"
                        raised
                        v-if="
                          checkPermissions(['end_tasks']) ||
                          (isMyTask() && $store.consultasStore.state.currentTarea.private)
                        "
                        @click="startCloseTasks"
                      >
                        <p class="mb-0 text-primary">Finalizar Tarea</p>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <AcceptCancelModal
            :acceptRejectModal="acceptRejectModal"
            :acceptRejectModalData="acceptRejectModalData"
            :closeModal="closeModalAcceptCancel"
          />
          <AddParticipantModal
            :addParticipantModal="addParticipantModal"
            :closeModal="closeAddParticipantModal"
          />
        </v-card>
  </template>
  
  <script>
  import {
    TiptapVuetify,
    Heading,
    Bold,
    Italic,
    Strike,
    Underline,
    Code,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Link,
    Blockquote,
    HardBreak,
    HorizontalRule,
    History,
  } from "tiptap-vuetify";
  import moment from "moment";
  import { v4 as uuidv4 } from "uuid";
  import EventBus from "@/helpers/event_bus";
  import AcceptCancelModal from "@/components/Globals/Modals/General/AcceptCancelModal";
  import AddParticipantModal from "@/components/Globals/Modals/General/AddParticipantModal";
  import {
    addInteraction,
    getTareaByID,
    getPublicUrl,
    closeTask,
    updateTask,
    changeResponsable,
    removeParticipant,
  } from "@/helpers/api/tasks";
  import { getRdiByID } from "@/helpers/api/rdi";
  import { getZippedFiles } from "@/helpers/api/fileUploads";
  import MediaGallery from "@/components/Globals/Helpers/MediaGallery";
  import Interactions from "@/components/Globals/Helpers/Interactions";
  import InteractionButtons from "@/components/Globals/Modals/Tasks/Parts/InteractionButtons";
  import MinimizeCloseBtn from "@/components/Globals/Modals/Tasks/Parts/MinimizeCloseBtn";
  import event_bus from "@/helpers/event_bus.js";
  
  export default {
    name: "TareaModal",
    components: {
      TiptapVuetify,
      AcceptCancelModal,
      AddParticipantModal,
      MediaGallery,
      InteractionButtons,
      Interactions,
      MinimizeCloseBtn,
    },
    computed: {
      currentTarea() {
        return this.$store.consultasStore.state.currentTarea;
      },
    },
    watch: {
      currentTarea(newData) {
        if (newData && !newData.loading && newData.id) {
          setTimeout(() => {
            this.pasteListener();
          }, 500);
        }
      },
    },
    data: () => ({
      dialog: true,
      showMore: false,
      interactionSearch: "",
      extensions: [
        History,
        // Table,
        // TableCell,
        // TableHeader,
        // TableRow,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
      message: "",
      response_type: 0,
      need_reload: false,
      files: [],
      filesPreview: [],
      loadingFilePath: false,
      is_response: false,
      loading: false,
      changeInChecklists: false,
      loadingChangeUser: false,
      acceptRejectModal: false,
      acceptRejectModalData: null,
      addParticipantModal: false,
      loadingRemoveParticipant: false,
      loadingEdit: false,
      cancelTimer: 4,
      inCancelTime: false,
      cancelInterval: null,
      confirmButtonText: "Responder",
      showResponderDiv: false,
      selectedInteraction: null,
      showDropdown: true,
      showDropdownHeader: true,
      showDropdownDescription: true,
      showDropdownAdj: true,
      opened: 0,
    }),
    mounted() {
      this.setListeners();
    },
    beforeDestroy() {
      EventBus.$off("completedFileUpload");
    },
    methods: {
      checkUsers() {
        if (
          !this.$store.usersStore.state.loadingUserListForItems &&
          this.$store.usersStore.state.usersListForItems.project_id !==
            this.$store.consultasStore.state.currentTarea.project_id
        ) {
          this.getUserListforItems();
        }
      },
      getUserListforItems() {
        const data = {
          project_id: this.$store.consultasStore.state.currentTarea.project_id,
          start: 0,
          limit: 9999,
        };
        this.$store.usersStore.dispatch("getUsersListForItems", data);
      },
      filteredSearch(items) {
        if (this.interactionSearch && this.interactionSearch !== "" && items) {
          const filtered = items.filter((item) => {
            return item.message
              .toLowerCase()
              .includes(this.interactionSearch.toLowerCase());
          });
          return filtered;
        }
        return items;
      },
      checkInteractPermission(permissionToCheck) {
        if (!this.checkPermissions(permissionToCheck)) {
          return false;
        } else {
          return this.checkPermissions(["interact_tasks_others"]) || this.isUserPart();
        }
      },
      isUserPart() {
        const isCreator =
          this.$store.userStore.state.userInfo.id ===
          this.$store.consultasStore.state.currentTarea.created_by_user.id;
        const isResponsable =
          this.$store.userStore.state.userInfo.id ===
          this.$store.consultasStore.state.currentTarea.responsable_user.id;
        const isParticipant = this.$store.consultasStore.state.currentTarea.participants.some(
          (participant) => {
            return participant.id === this.$store.userStore.state.userInfo.id;
          }
        );
        return isCreator || isResponsable || isParticipant;
      },
      pasteListener() {
        const textEditor = document.querySelector("#interaction-texteditor .ProseMirror");
        if (textEditor) {
          textEditor.addEventListener("paste", this.pasteListenerHandler);
        }
      },
      async pasteListenerHandler(event) {
        const dT = event.clipboardData || window.clipboardData;
        const file = dT.files[0];
        if (file) {
          this.files.push(file);
          if (file.type.includes("image")) {
            const base64File = await this.toBase64(file);
            this.filesPreview.push({ src: base64File });
          } else {
            this.filesPreview.push({ noImage: true });
          }
        }
      },
      changedChecklist() {
        this.changeInChecklists = true;
      },
      async updateTaskInfo(taskInfo) {
        await updateTask(taskInfo);
      },
      setListeners() {
        EventBus.$on("completedFileUpload", async (payload) => {
          if (
            payload &&
            payload.task_id &&
            this.$store.consultasStore.state.currentTarea &&
            payload.task_id == this.$store.consultasStore.state.currentTarea.id
          ) {
            const tarea = await getTareaByID({ task_id: payload.task_id });
            if (tarea.code === 200) {
              let tareaData = tarea.task;
              this.$store.consultasStore.commit("setCurrentTarea", tareaData);
            }
          }
        });
      },
      setClassInteraction(interaction) {
        let final_class = this.isMyInteraction(interaction.user) ? "right" : "left";
        final_class += interaction.is_response ? " response" : "";
        return final_class;
      },
      isMyTask() {
        return (
          this.$store.consultasStore.state.currentTarea.created_by ==
          this.$store.userStore.state.userInfo.id
        );
      },
      isMyInteraction(interactionUser) {
        return interactionUser.id == this.$store.userStore.state.userInfo.id;
      },
      async getConsultasList() {
        let project_id = null;
        if (this.$store.projectsStore.state.selectedProject) {
          project_id = this.$store.projectsStore.state.selectedProject.id;
        } else {
          const id = localStorage.getItem("selectedProjectID");
          project_id = parseInt(id);
        }
        if (project_id) {
          this.$store.consultasStore.dispatch("getMyTasks", { project_id });
        }
      },
      getTareaPriorityColor(tarea) {
        if (tarea.priority === 3) {
          return "high";
        } else if (tarea.priority === 2) {
          return "medium";
        }
        return "normal";
      },
      getTareaPriorityText(tarea) {
        if (tarea.priority === 3) {
          return "Inmediata";
        } else if (tarea.priority === 2) {
          return "Media";
        }
        return "Estándar";
      },
      checkIfNearDeadline(tarea) {
        if (!tarea.critical_date) {
          return false;
        }
        const now = moment();
        const tarea_date = moment(tarea.critical_date);
        return tarea_date.diff(now, "days") <= 2;
      },
      getColorStatus() {
        if (this.$store.consultasStore.state.currentTarea.status === 1) {
          return "#3EBFA3";
        }
        return "#F4516C";
      },
      closeModal() {
        const reload =
          this.need_reload ||
          !this.$store.consultasStore.state.currentTarea.prev_seen_by_me;
        if (this.changeInChecklists) {
          const taskInfo = { ...this.$store.consultasStore.state.currentTarea };
          this.updateTaskInfo(taskInfo);
        }
        this.$store.consultasStore.commit("setCurrentTarea", null);
        if (reload) {
          this.getConsultasList();
        }
        this.changeInChecklists = false;
        this.need_reload = false;
        this.loading = false;
        this.files = [];
        this.filesPreview = [];
        this.showResponderDiv = false;
      },
      onButtonClick() {
        this.$refs.uploader.click();
      },
      async onFileChanged(e) {
        const newFile = e.target.files[0];
        this.files.push(newFile);
        if (newFile.type.includes("image")) {
          const base64File = await this.toBase64(newFile);
          this.filesPreview.push({ src: base64File });
        } else {
          this.filesPreview.push({ noImage: true });
        }
      },
      removeAttachment(index) {
        this.files.splice(index, 1);
        this.filesPreview.splice(index, 1);
      },
      toBase64(file) {
        return new Promise((resolve) => {
          var reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = function () {
            resolve(reader.result);
          };
          reader.onerror = function () {
            resolve(null);
          };
        });
      },
      async getFilesUrl(attachments, singleFile = false, download = false) {
        if (singleFile) {
          attachments = [attachments];
        }
        this.loadingFilePath = true;
        const files = await getPublicUrl({ attachments, force_download: download });
        if (files.code === 200) {
          files.files.forEach((file) => {
            window.open(file.url);
          });
        }
        this.loadingFilePath = false;
      },
      async preAddInteraction() {
        if (this.inCancelTime) {
          clearInterval(this.cancelInterval);
          this.confirmButtonText = "Confirmar";
          this.inCancelTime = false;
          return null;
        }
        if (this.message === "" || !this.message) {
          const datas = {
            message: "Por favor escribe un mensaje. Este campo no puede estar vacio.",
            title: "Error",
            created: new Date(),
            type: "success",
            icon: "mdi-alert-circle",
            color: "#fb8c00",
          };
          this.$store.globalStore.dispatch("addMessage", datas);
          return false;
        }
  
        this.inCancelTime = true;
        this.cancelTimer = 4;
        this.confirmButtonText = "Cancelar (" + this.cancelTimer + ")";
        this.cancelInterval = setInterval(() => {
          this.cancelTimer--;
          this.confirmButtonText = "Cancelar (" + this.cancelTimer + ")";
          if (this.cancelTimer == 0) {
            this.inCancelTime = false;
            this.confirmButtonText = "Responder";
            clearInterval(this.cancelInterval);
            this.addInteraction();
          }
        }, 1000);
      },
      async addInteraction() {
        this.loading = true;
        const biggerFiles = [];
        const formData = new FormData();
        formData.append("task_id", this.$store.consultasStore.state.currentTarea.id);
        formData.append("message", this.message);
        formData.append("is_response", this.is_response);
        this.files.forEach((file) => {
          if (file && file.size < 1000) {
            formData.append("files[]", file);
          } else if (file) {
            biggerFiles.push({
              type: 2,
              payload: {
                task_id: this.$store.consultasStore.state.currentTarea.id,
                project_id: this.$store.projectsStore.state.selectedProject.id,
              },
              description: this.$store.consultasStore.state.currentTarea.title,
              total: file.size,
              uuid: uuidv4(),
              file,
            });
          }
        });
        const interaction = await addInteraction(formData);
        if (interaction.code === 200) {
          if (biggerFiles.length > 0) {
            biggerFiles.map((file) => {
              file.payload = Object.assign(file.payload, {
                tr_interaction_id: interaction.interaction,
                project_id: this.$store.projectsStore.state.selectedProject.id,
              });
              this.$store.globalStore.commit("setFilesToUploadCenter", file);
            });
          }
          this.need_reload = true;
          this.message = "";
          this.files = [];
          this.filesPreview = [];
          let tareaData = interaction.task;
          this.$store.consultasStore.commit("setCurrentTarea", tareaData);
        }
        this.loading = false;
      },
      closeModalAcceptCancel(status = false) {
        this.acceptRejectModal = false;
        if (status) {
          this.closeTask();
        }
        this.acceptRejectModalData = null;
      },
      startCloseTasks() {
        this.acceptRejectModalData = {
          title:
            "¿Cerrar la tarea Nº " +
            this.$store.consultasStore.state.currentTarea.number +
            "?",
          content_a: "Por favor confirma si deseas cerrar la tarea indicada.",
        };
        this.acceptRejectModal = true;
      },
      async closeTask() {
        this.message += "<p><b>Consulta cerrada por usuario</b></p>";
        this.addInteraction();
        const task = await closeTask({
          task_id: this.$store.consultasStore.state.currentTarea.id,
        });
        if (task.code === 200) {
          let currentTarea = this.$store.consultasStore.state.currentTarea;
          currentTarea.status = 3;
          this.$store.consultasStore.commit("setCurrentTarea", currentTarea);
          this.$store.notificationsStore.dispatch("getReminders");
          this.$store.projectsStore.dispatch("getDashboardInfo");
          this.need_reload = true;
          this.message = "";
          this.files = [];
          this.filesPreview = [];
        }
      },
      async changeUser(userID) {
        this.loadingChangeUser = true;
        const data = {
          task_id: this.$store.consultasStore.state.currentTarea.id,
          user_id: userID,
        };
        const taskUpdate = await changeResponsable(data);
        if (taskUpdate.code == 200) {
          this.$store.consultasStore.commit("setCurrentTarea", taskUpdate.task);
          this.getConsultasList();
        }
        this.loadingChangeUser = false;
      },
      async viewTarea(rdi_id, seen_by_me) {
        this.$store.rdiStore.commit("setCurrentRDI", { loading: true });
        const rdi = await getRdiByID({ rdi_id });
        if (rdi.code === 200) {
          let rdiData = rdi.rdi;
          rdiData.prev_seen_by_me = seen_by_me;
          this.$store.rdiStore.commit("setCurrentRDI", rdiData);
        }
      },
      async downloadAttachments(attachments) {
        this.loadingFilePath = true;
        const toZip = attachments.map((item) => item.file);
        const files = await getZippedFiles({ files_to_zip: toZip });
        if (files.code == 200) {
          window.open(files.fileurl);
        }
        this.loadingFilePath = false;
      },
      downloadFile(dataurl, filename) {
        var a = document.createElement("a");
        a.href = dataurl;
        a.setAttribute("download", filename);
        a.setAttribute("target", "_blank");
        a.click();
      },
      closeAddParticipantModal() {
        this.addParticipantModal = false;
      },
      async removeParticipant(participant) {
        this.loadingRemoveParticipant = true;
        const task = await removeParticipant({
          user_id: participant.id,
          task_id: participant.laravel_through_key,
        });
        this.loadingRemoveParticipant = false;
        if (task.code === 200) {
          let tareaData = task.task;
          this.$store.consultasStore.commit("setCurrentTarea", tareaData);
        }
      },
      openResponderHandler(selectedInteraction) {
        this.selectedInteraction = selectedInteraction;
        this.showResponderDiv = true;
      },
      closeResponderDiv() {
        this.showResponderDiv = false;
      },
      handleRecentbtn() {
        event_bus.$emit("recentBtnClicked");
      },
    },
  };
  </script>
  
  <style scoped>
  .info-container {
    color: #606060;
  }
  
  .text-primary {
    color: #515d78;
  }
  
  .bg-primary {
    background-color: #515d78;
  }
  
  .bg-light-blue-text {
    background-color: #515d78;
  }
  
  .info-icon {
    background-color: #515d78;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  
  .info-icon.small-icon {
    width: 25px;
    height: 25px;
  }
  
  .info-img {
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  
  .adjuntos-container {
    background-color: #efefef;
    max-height: 165px;
    overflow-y: scroll;
  }
  
  .interaction-container {
    width: 100%;
    background-color: #e5e9f2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  
  .interaction-container.response {
    background-color: #cae7cc;
  }
  
  .user-avatar.small {
    width: 25px;
    height: 25px;
  }
  
  .light-blue-text {
    color: #515d78;
  }
  
  .interaction-container .icon-arrow {
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  
  .interaction-container.right .icon-arrow {
    left: -46px;
  }
  
  .interaction-container.left .icon-arrow {
    right: -46px;
  }
  
  .icon-top {
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  
  .icon-top.high {
    background-color: #f4516c;
  }
  
  .icon-top.medium {
    background-color: #fb8c00;
  }
  
  .icon-top.normal {
    background-color: #3ebfa3;
  }
  
  .icon-top.primary {
    background-color: #515d78;
  }
  
  .icon-top.light-blue {
    background-color: #515d78;
  }
  
  .icon-top.dark-grey {
    background-color: #616161;
  }
  
  .parent-subtareas {
    display: flex;
    flex-wrap: wrap;
  }
  
  .parent-subtareas .subtareas-checkbox {
    flex: 1 0 30%;
  }
  
  .notification-img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  
  .preview-img-container {
    position: relative;
  }
  
  .preview-img {
    width: 50px;
    height: 50px;
    border-radius: 4px;
    object-fit: contain;
  }
  
  .button-preview-img {
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
  
  .bg-dark-list {
    background-color: rgba(128, 128, 128, 0.15);
  }
  
  .user-avatar.mini {
    width: 30px;
    height: 30px;
    border-radius: 30px;
  }
  
  /* .header-modals{} */
  .search-input {
    position: relative;
    background-color: white;
    border-color: red;
    max-width: 200px;
    max-height: 30px;
  }
  
  /deep/ .search-input input {
    margin-left: 10px;
    padding: 0;
    line-height: 14px;
    font-size: 14px;
  }
  
  /deep/ .search-input .v-input__slot {
    min-height: 30px !important;
  }
  
  /deep/ .search-input.v-text-field--outlined.v-input--dense .v-label {
    top: 6px;
    font-size: 14px;
  }
  
  /deep/ .search-input.v-text-field--outlined.v-input--dense .v-input__append-inner {
    margin-top: 3px;
  }
  
  .search-button {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 32px;
    height: 32px;
  }
  
  /* Extra Favric CSS */
  .sticky-card-title {
    position: sticky;
    top: 0;
    padding: 35px;
    background-color: white;
    z-index: 3;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .title-adjust {
    font-size: 0.85rem;
    line-height: 1.4rem;
    color: #606060;
    font-weight: 400;
    padding-bottom: 10px;
  }
  
  .scrollable-content {
    /*overflow-y: auto;*/
    padding-bottom: 30px;
  }
  
  .message {
    font-size: 17px;
  }
  
  .response-view {
    height: auto;
    padding: 20px;
    padding-bottom: 20px;
    margin-top: 10px;
    background-color: #efefef;
    border-radius: 8px;
    border: 1px solid #c7d1dd;
    
    .message {
      font-size: 12px;
    }
  }
  
  .response-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .drop-down-section {
    margin-top: 10px;
    display: flex;
    width: 100%;
    height: 25px;
    justify-content: left;
    padding-right: 20px;
    padding-left: 12px;
    padding-bottom: 10px;
  
    .nameHeader {
      margin-top: 5px;
      font-size: 20px;
      color: #515d78;
    }
  }
  
  .divider-section {
    margin-top: 30px;
  }
  
  .btn-response {
    margin-top: -13px;
    margin-bottom: -16px;
  }
  
  /* this line separate the two columns for interactions */
  /* .separate-line {
    border-right: 1px solid rgba(0, 0, 0, 0.19);
  } */
  
  /* new design  */
  .lead-text {
    position: absolute;
    top: 37px;
    left: 20px;
    width: 230px;
    margin-top: -10px;
    font-size: 16px;
    font-weight: 400;
    color: #8b8b8b;
    padding: 3px;
  }
  
  .icon-text {
    font-weight: 700;
    font-size: 18px;
    color: #515d78;
    text-align: left;
    margin-left: -12px;
    margin-top: 1px;
  }
  
  .task-buttons {
    width: 100%;
    display: flex;
  }
  
  .end-task {
    margin-top: 10px;
    margin-right: -10px;
    display: flex;
    justify-content: end;
  }
  
  .endTask-btn {
    height: 40px !important;
    color: #454d61;
    box-shadow: none;
    border-radius: 50px;
  }
  
  .v-expansion-panel {
    min-height: 60px;
  }
  
  /* interacion zone */
  
  .interaction-section {
    padding: 35px;
    padding-top: 0;
  }
  
  .recent-btn-row {
    margin: 0px;
    display: flex;
    justify-content: end;
  }
  
  .recent-btn {
    padding: 5px;
    box-shadow: none;
    border-radius: 50px;
    color: #454d61;
  }
  
  .interaction-rectangle {
    margin-top: 15px;
    height: 59.6px;
    border-radius: 8px 8px 0 0;
    border: 1px solid #c7d1dd;
    background-color: #f4f5f7;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 18px;
    display: flex;
    align-items: center;
    color: #454d61;
    padding: 0 15px; /* Adjust padding as needed */
  }
  
  .interaction-card {
    height: 460px;
    border-radius: 0 0 8px 8px;
    margin-top: -1px;
    border: 1px solid #c7d1dd;
    background-color: #fbfbfb;
    color: #454d61;
    padding: 15px;
    overflow: scroll;
  }
  
  .expansion-panel-parent {
    background-color: transparent !important;
    border-radius: 4px;
  }
  
  .expansion-panel-child {
    background-color: #fbfbfb !important;
    border-radius: 4px;
    border: 1px solid #c7d1dd;
    background-color: #fbfbfb;
  }
  
  .expansion-panel-header {
    height: 59.6px;
    border-radius: 8px 8px 8px 8px;
    border-bottom: 1px solid #c7d1dd;
    background-color: #f4f5f7;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #454d61;
    padding: 0 15px; /* Adjust padding as needed */
  }
  
  .expansion-panel-content {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .item-task {
    width: 40px;
    height: 40px;
    border-radius: 4px;
    margin: 5px;
    box-shadow: none;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .v-expansion-panels {
    z-index: 0;
  }
  .head-fixed{
    padding-bottom: 0px;
  }
  .content-scrolled{
    overflow: auto;
    max-height: 400px;
    padding: 0px 15px 15px 15px;    
    margin-left: -10px;
    margin-right: 15px;
}
  </style>