<template>
	<v-container fluid class="px-0">

		<v-row align="start">
			<v-col cols="12" sm="6" md="4" v-for="(userTasks, index) in $store.consultasStore.state.tareasbyUser.data"
				:key="index">
				<v-card class="mb-2 py-2 creadas">
					<v-card-text class="title-card py-2">
						<div class="d-flex flex-row align-center justify-space-between">
							<div>
								<p class="title-section mb-0 caption">Responsable</p>
								<div class="d-flex flex-row align-center">
									<h3 class="title-section py-0 mr-2">{{ userTasks.tasks[0].responsable_user.name }}
									</h3>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="#515d78" v-bind="attrs" v-on="on"
												small>mdi-information</v-icon>
										</template>
										<div>
											<p class="mb-0 font-weight-bold">Indicadores últimos 5 días</p>
											<p class="mb-0 caption">Velocidad de solución:
												{{ userTasks.indicators.solution_speed | oneDecimalsNumber }}/día</p>
											<p class="mb-0 caption">Tiempo de espera promedio de mis tareas:
												{{ userTasks.indicators.waiting_time |
				oneDecimalsNumber }} días</p>
										</div>
									</v-tooltip>
								</div>
							</div>
							<div class="d-flex">
								<div class="counter-tareas caption">
									<span
										v-if="$store.consultasStore.state.tareasbyUser.status === 1">{{ userTasks.tasks.length }}</span>
									<v-progress-circular v-else :size="12" :width="2" color="white"
										indeterminate></v-progress-circular>
								</div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon color="#515d78" v-bind="attrs" v-on="on" @click="showUserTasks(index)">
											{{ showedUserTasks.includes(index) ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
										</v-icon>
									</template>
									<span>Ver tareas del usuario</span>
								</v-tooltip>
							</div>
						</div>
					</v-card-text>
				</v-card>
				<div class="tareas-user-container" v-if="showedUserTasks.includes(index)">
					<v-card class="creadas mb-1" v-for="(tarea, index) in userTasks.tasks" :key="index"
						@click="viewTarea(tarea.id, tarea.seen_by_me)" link
						:class="tarea.status === 3 ? 'status-ended' : ''">
						<v-tooltip bottom v-if="tarea.private">
							<template v-slot:activator="{ on, attrs }">
								<div class="incognito-container" v-bind="attrs" v-on="on">
									<v-icon color="white" x-small>mdi-incognito</v-icon>
								</div>
							</template>
							<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
						</v-tooltip>
						<v-card-text class="tarea-card py-2 px-2">
							<v-row align="center" no-gutters>
								<v-col cols="2">
									<div class="d-flex flex-row icon-container">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top number" v-bind="attrs" v-on="on">
													<p class="mb-0 white--text text-task-number caption">
														{{ tarea.number }}</p>
												</div>
											</template>
											<span>Tarea número {{ tarea.number }}</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top d-flex justify-center align-center"
													:class="$options.filters.seenByMeColor(tarea.seen_by_me)"
													v-bind="attrs" v-on="on"
													@click.stop="changeVisualizationStatus(tarea)">
													<v-icon color="white"
														:class="$options.filters.seenByMeIconAnimation(tarea.seen_by_me)"
														v-if="!isLoadingChangeVisualizationStatus(tarea.id)"
														small>mdi-bell</v-icon>
													<v-progress-circular v-else :width="2" :size="16" color="white"
														indeterminate></v-progress-circular>
												</div>
											</template>
											<span>{{ $options.filters.seenByMeText(tarea.seen_by_me) }}</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top"
													:class="!tarea.seen ? 'high' : 'success'" v-bind="attrs" v-on="on"
													small>mdi-check</v-icon>
											</template>
											<span>El responsable {{ !tarea.seen ? 'no' : '' }} ha visualizado esta
												tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top medium" v-bind="attrs" v-on="on"
													small v-if="checkIfNearDeadline(tarea)">mdi-flag-outline</v-icon>
											</template>
											<span>Se acerca la fecha crítica {{ tarea.critical_date | toDate }}</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="7">
									<div class="d-flex flex-row">
										<p class="mb-0">{{ tarea.title }}</p>
										<div v-if="tarea.status === 3">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon class="icon-top" color="#34BFA3" v-bind="attrs" v-on="on"
														small>mdi-check-circle</v-icon>
												</template>
												<span>Tarea Finalizada</span>
											</v-tooltip>
										</div>
									</div>
									<p class="inner-text text-creadas-color mb-0">
										<v-chip v-if="tarea.code" x-small
											outlined>
											{{ tarea.code }}
										</v-chip>
									</p>
									<p class="inner-text text-creadas-color mb-0">
										Creado por: {{ tarea.created_by_user.name }} |
										Responsable: {{ tarea.responsable_user.name }}
									</p>
								</v-col>
								<v-col cols="3" class="text-right">
									<div class="d-flex flex-row align-center justify-end">
										<div v-if="tarea.critical_date">
											<p class="inner-text text-creadas-color mb-0 font-weight-bold">TE:
												{{ tarea.waitingtime }}</p>
										</div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top" v-bind="attrs" v-on="on"
													:class="getTareaPriorityColor(tarea)" small>mdi-exclamation</v-icon>
											</template>
											<span>Prioridad {{ getTareaPriorityText(tarea) }}</span>
										</v-tooltip>
									</div>
									<p class="inner-text text-creadas-color mb-0 font-weight-bold"
										v-if="!tarea.critical_date">TE:
										{{ tarea.waitingtime }}</p>
									<p class="mb-0 inner-text">
										<v-icon x-small>mdi-calendar-range-outline</v-icon>
										{{ tarea.created_at | toDate }}
									</p>
									<v-tooltip bottom v-if="tarea.critical_date">
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row align-center justify-end" v-bind="attrs"
												v-on="on">
												<v-icon x-small color="#515d78"
													class="mr-1">mdi-calendar-range-outline</v-icon>
												<p class="mb-0 inner-text light-blue-text">{{ tarea.critical_date |
				toDate }}</p>
											</div>
										</template>
										<span>Fecha crítica: {{ tarea.critical_date | toDate }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</div>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
import moment from 'moment'
import { getTareaByID, changeVisualizationStatus } from '@/helpers/api/tasks'

export default {
	name: 'TareasByUser',
	components: {},
	data: () => ({
		showedUserTasks: []
	}),
	mounted() {
	},
	methods: {
		async changeVisualizationStatus(task) {
			if (!this.isLoadingChangeVisualizationStatus(task.id)) {
				let newStatus = 0
				if (!task.seen_by_me) {
					newStatus = 1
				}
				else if (task.seen_by_me.seen_status < 2) {
					newStatus = task.seen_by_me.seen_status + 1
				}
				else if (task.seen_by_me.seen_status === 2) {
					newStatus = 0
				}

				this.$store.consultasStore.commit('addLoadingChangeVisualizationStatus', task.id)
				const data = {
					task_id: task.id,
					status: newStatus
				}
				const statusUpdate = await changeVisualizationStatus(data)
				if (statusUpdate.code === 200) {
					// Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
					let newTaskData = Object.assign({}, task)
					newTaskData.seen_by_me.seen_status = newStatus
					this.$store.consultasStore.dispatch('updateTaskLocally', newTaskData)
				}
				this.$store.consultasStore.commit('removeLoadingChangeVisualizationStatus', task.id)
			}
		},
		isLoadingChangeVisualizationStatus(taskID) {
			const found = this.$store.consultasStore.state.loadingChangeVisualizationStatus.find((task_id) => task_id === taskID)
			return found
		},
		showUserTasks(index) {
			const foundIndex = this.showedUserTasks.findIndex(item => item == index)
			if (foundIndex == -1) {
				this.showedUserTasks.push(index)
			}
			else {
				this.showedUserTasks.splice(foundIndex, 1)
			}
		},
		getTareaPriorityColor(tarea) {
			if (tarea.priority === 3) {
				return 'high'
			}
			else if (tarea.priority === 2) {
				return 'medium'
			}
			return 'normal'
		},
		getTareaPriorityText(tarea) {
			if (tarea.priority === 3) {
				return 'Inmediata'
			}
			else if (tarea.priority === 2) {
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(tarea) {
			if (!tarea.critical_date) { return false }
			const now = moment()
			const tarea_date = moment(tarea.critical_date)
			return tarea_date.diff(now, 'days') <= 2
		},
		async viewTarea(task_id, seen_by_me) {
			this.$store.consultasStore.commit('setCurrentTarea', { loading: true })
			const tarea = await getTareaByID({ task_id })
			if (tarea.code === 200) {
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		}
	}
};
</script>

<style scoped>
.text-primary-color {
	color: #515d78;
}

.light-blue-text {
	color: #515d78;
}

/deep/ .creadas .title-section {
	color: #515d78;
}

/deep/ .creadas .tarea-card {
	border-left: 3px solid #515d78;
}

/deep/ .creadas.status-ended .tarea-card {
	border-radius: 0 !important;
	border-left: 3px solid #4caf50;
}

/deep/ .creadas .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #515d78;
}

/deep/ .pendientes .title-section {
	color: #dcd614;
}

/deep/ .pendientes .tarea-card {
	border-left: 3px solid #dcd614;
}

/deep/ .pendientes .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #dcd614;
}

/deep/ .finalizadas .title-section {
	color: #40C2A8;
}

/deep/ .finalizadas .tarea-card {
	border-left: 3px solid #40C2A8;
}

/deep/ .finalizadas .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #40C2A8;
}

.text-creadas-color {
	color: #515d78;
}

.inner-text {
	font-size: 10px;
}

.icon-top {
	padding: 2px;
	margin: 0 2px;
	border-radius: 100%;
}

.icon-top.high {
	background-color: #F4516C;
}

.icon-top.medium {
	background-color: #fb8c00;
}

.icon-top.normal {
	background-color: #3EBFA3;
}

.icon-top.primary {
	background-color: #515d78;
}

.icon-top.light-blue {
	background-color: #515d78;
}

.counter-tareas {
	background-color: #515d78;
	border-radius: 100%;
	padding: 5px;
	width: 30px;
	height: 30px;
	line-height: 20px;
	color: white;
	font-size: 20px;
	text-align: center;
}

.icon-container {
	flex-wrap: wrap;
}

.icon-container .icon-top {
	flex: 1 0 30%;
	width: 24px;
	max-width: 24px;
	height: 24px;
	max-height: 24px;
	margin-bottom: 2px;
}

.text-task-number.caption {
	font-size: 10px !important;
}

.icon-container .icon-top.number {
	background-color: #515d78;
	text-align: center;
	line-height: 20px;
}

.critical-date-container {
	background-color: #515d78;
}

.incognito-container {
	width: 0;
	height: 0;
	border-top: 25px solid #616161;
	border-right: 25px solid transparent;
	border-radius: 0 !important;
	position: absolute;
	left: 3px;
}

.incognito-container .v-icon {
	position: absolute;
	top: -22px;
	left: 1px;
}

.tareas-user-container {
	max-height: 400px;
	overflow-y: scroll;
	margin-bottom: 10px;
}
</style>