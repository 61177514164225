var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.displayedInteractions),function(interaction,index){return _c('v-row',{key:index,staticClass:"my-2 p-2",attrs:{"align":"start","no-gutters":""}},[(!_vm.$vuetify.breakpoint.mobile)?_c('v-col',{class:_vm.isMyInteraction(interaction.user) ? 'text-left' : 'text-right',attrs:{"cols":"2","order":_vm.isMyInteraction(interaction.user) ? 'first' : 'last'}}):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"interaction-container",class:_vm.setClassInteraction(interaction)},[_c('div',{staticClass:"d-flex flex-row justify-space-between"},[_c('div',[(interaction.is_response)?_c('p',{staticClass:"mb-0 text-primary font-weight-bold"},[_vm._v(" Respuesta Oficial ")]):_vm._e(),_c('div',{staticClass:"d-flex"},[_c('img',{staticClass:"user-avatar mini mr-2",attrs:{"src":interaction.user.info.avatar}}),_c('div',[_c('p',{staticClass:"text-primary",class:_vm.$vuetify.breakpoint.mobile ? 'caption' : '',staticStyle:{"margin-top":"3px","margin-bottom":"-7px"}},[_vm._v(" "+_vm._s(interaction.user.name)+" ")]),_c('small',{staticClass:"text-primary-time caption"},[_vm._v(_vm._s(_vm._f("toDateTime")(interaction.created_at))+" ")])])]),_c('div',{staticClass:"d-flex flex-row align-center mt-1"},[_c('p',{staticClass:"mb-0 text-primary caption"}),_vm._l((interaction.seen_by),function(user,index){return _c('div',{key:index,staticClass:"d-flex flex-row align-center text-primary caption mx-2"},[_c('img',{staticClass:"user-avatar small mr-1",attrs:{"src":user.avatar}}),_vm._v(" "+_vm._s(user.name)+" ")])})],2)])]),_c('div',{staticClass:"my-3 pl-13"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.highligthSearch(interaction.message, _vm.searchText))}}),(
                interaction.attachments.filter((item) => item.file.pathmedia).length > 0
              )?_c('MediaGallery',{attrs:{"images":interaction.attachments
                  .filter((item) => item.file.pathmedia)
                  .map((item) => item.file.pathmedia)}}):_vm._e()],1),(
              interaction.attachments
                .filter((item) => !item.file.pathmedia)
                .map((item) => !item.file.pathmedia).length > 0
            )?_c('div',{staticClass:"my-3 pl-13"},[_c('div',{staticClass:"d-flex align-center mb-1"},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(interaction.attachments.length)+" Documentos Adjuntos: ")]),(interaction.attachments.length > 1)?_c('v-btn',{staticClass:"mx-2 my-1",attrs:{"rounded":"","color":"#515d78","dark":"","x-small":"","loading":_vm.loadingFilePath},on:{"click":() => _vm.downloadAttachments(interaction.attachments)}},[_c('v-icon',{attrs:{"dark":"","small":""}},[_vm._v("mdi-paperclip")]),_c('p',{staticClass:"mb-0 ml-2"},[_vm._v("Descargar Todo")])],1):_vm._e()],1),_vm._l((interaction.attachments.filter(
                (item) => !item.pathmedia
              )),function(attachment,index){return _c('div',{key:index},[_c('div',{staticClass:"d-flex flex-row align-center",staticStyle:{"margin-left":"-7px"}},[_c('p',{staticClass:"mb-0 text-primary mr-2",staticStyle:{"min-width":"60px"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"#515d78","dark":"","x-small":"","icon":"","loading":_vm.loadingFilePath},on:{"click":function($event){return _vm.getFilesUrl(attachment, true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-open-in-new")])],1)]}}],null,true)},[_c('span',[_vm._v("Visualizar en navegador")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"mx-1 my-1",attrs:{"color":"#515d78","dark":"","x-small":"","icon":"","loading":_vm.loadingFilePath},on:{"click":function($event){return _vm.getFilesUrl(attachment, true, true)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-cloud-download-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Descargar archivo")])])],1),_c('p',{staticClass:"mb-0 caption"},[_vm._v(_vm._s(attachment.file.name))])]),(
                  index <
                  interaction.attachments.filter((item) => !item.pathmedia).length - 1
                )?_c('v-divider'):_vm._e()],1)}),(_vm.showMore)?_c('div',[_c('v-divider',{staticClass:"my-3"}),_c('div',{staticClass:"bottom-file-container"},_vm._l((interaction.attachments),function(attachment,index){return _c('div',{key:index,staticClass:"d-flex flex-row"},[_c('v-btn',{staticClass:"mx-2 my-1",attrs:{"color":"#515d78","dark":"","small":"","text":"","loading":_vm.loadingFilePath},on:{"click":function($event){return _vm.getFilesUrl(attachment, true)}}},[_c('v-icon',{attrs:{"dark":"","color":"#515d78"}},[_vm._v("mdi-file-document-outline")]),_c('p',{staticClass:"mb-0"},[_vm._v(_vm._s(attachment.file.name))])],1)],1)}),0)],1):_vm._e()],2):_vm._e()])])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }