<template>
  <div class="text-center" v-if="$store.rdiStore.state.currentRDI">
    <v-dialog v-model="$store.rdiStore.state.currentRDI" class="model-main" persistent>
      <v-card v-if="$store.rdiStore.state.currentRDI.loading">
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title">Cargando RDI</p>
              </div>
              <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
                <v-icon dark>mdi-close</v-icon>
              </v-btn>
            </div>
            <v-row align="center" class="mt-2">
              <v-col cols="12" md="12">
                <div class="d-flex flex-column text-center align-center py-1" style="width: 100%">
                  <v-progress-circular :size="50" :width="5" color="#515d78" indeterminate></v-progress-circular>
                  <p class="mb-0 body-2 ml-2">Cargando información</p>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>

      <!-- Vue components RDIMobile -->
      <RDIModalMobile v-else-if="$vuetify.breakpoint.mobile" />

      <v-card v-else>
        <div style="display: flex">
          <v-col cols="6" sm="6">
            <v-card-text>
              <div class="head-fixed">
                <div class="d-flex" style="width: 100%"
                  :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'">
                  <div class="row" style="width: 100%;">
                    <p class="lead-text">
                      Proyecto /
                      {{ $store.rdiStore.state.currentRDI.project.name }}
                    </p>
                  </div>

                  <div class="d-flex indicators-box">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" class="icon-top success"
                          v-if="$store.rdiStore.state.currentRDI.status == 3" v-bind="attrs" v-on="on"
                          medium>mdi-star</v-icon>
                      </template>
                      <span>RDI finalizada el {{ $store.rdiStore.state.currentRDI.ended_at | toDateTime }}</span>
                    </v-tooltip>

                    <v-tooltip bottom v-if="checkIfNearDeadline($store.rdiStore.state.currentRDI)">
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" class="icon-top medium" v-bind="attrs" v-on="on"
                          small>mdi-flag-outline</v-icon>
                      </template>
                      <span>Se acerca la fecha crítica {{ $store.rdiStore.state.currentRDI.critical_date | toDate
                        }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" class="icon-top success"
                          v-if="$store.rdiStore.state.currentRDI.status == 3" v-bind="attrs" v-on="on"
                          medium>mdi-star</v-icon>
                      </template>
                      <span>RDI finalizada el
                        {{ $store.rdiStore.state.currentRDI.ended_at | toDateTime }}</span>
                    </v-tooltip>

                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon color="white" class="icon-top" v-bind="attrs" v-on="on"
                          :class="getRdiPriorityColor($store.rdiStore.state.currentRDI)" small
                          v-if="$store.rdiStore.state.currentRDI.waiting_status != 1">mdi-exclamation</v-icon>
                      </template>
                      <span>Prioridad
                        {{ getRdiPriorityText($store.rdiStore.state.currentRDI) }}</span>
                    </v-tooltip>
                  </div>
                </div>
                <div class="d-flex dates-box">
                  <span class="mb-0 mr-2">
                    Creado por:
                    <b>{{ $store.rdiStore.state.currentRDI.created_by_user.email }}</b>
                  </span>
                  <span class="mb-0 mr-2">
                    Fecha Emisión:
                    <b>{{ $store.rdiStore.state.currentRDI.created_at | toDateTime }}</b>
                  </span>
                  <span class="mb-0 mr-2">
                    Tiempo de espera:
                    <b>{{ $store.rdiStore.state.currentRDI.created_at | waitingTime }}</b>
                  </span>

                  <span class="mb-1" v-if="$store.rdiStore.state.currentRDI.critical_date">
                    Fecha Crítica:
                    <b>{{ $store.rdiStore.state.currentRDI.critical_date | toDate }}</b>
                  </span>

                  <span class="mb-1"
                    v-if="$store.rdiStore.state.currentRDI.status == 3 & $store.rdiStore.state.currentRDI.ended_at">
                    Fecha Solución:
                    <b>{{ $store.rdiStore.state.currentRDI.ended_at | toDateTime }}</b>
                  </span>
                </div>
                <div class="d-flex mt-1" style="width: 100%; height: 30px;"
                  :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'">
                  <div class="row" style="width: 100%">
                    <v-row>
                      <v-col cols="auto" class="created-avatar">
                        <!-- <AvatarUser :user="$store.userStore.state.userInfo"></AvatarUser> -->
                        <v-icon color="success">mdi-square</v-icon>
                      </v-col>
                      <v-col>
                        <p class="icon-text">
                          {{ $store.rdiStore.state.currentRDI.title }} - RDI Nº
                          {{ $store.rdiStore.state.currentRDI.number }}
                        </p>
                        <p class="mb-0 text-primary body-1 text-wrap"
                          :class="$vuetify.breakpoint.mobile ? 'caption d-flex flex-row align-center' : ''"
                          v-if="$store.rdiStore.state.currentRDI.task_relation">
                          Tarea Asociada Nº
                          {{ $store.rdiStore.state.currentRDI.task_relation.task.number }} -
                          {{ $store.rdiStore.state.currentRDI.task_relation.task.title }}
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn fab dark x-small color="#515d78" class="ml-2 mr-2"
                                style="width: 25px; height: 25px" v-bind="attrs" v-on="on" @click="viewRdi(
    $store.rdiStore.state.currentRDI.task_relation.task.id,
    $options.filters.seenByMe(
      $store.rdiStore.state.currentRDI.task_relation.task
        .seen_by_me
    )
  )
    ">
                                <v-icon dark x-small>mdi-arrow-right-bold</v-icon>
                              </v-btn>
                            </template>
                            <span>Visualizar Tarea</span>
                          </v-tooltip>
                        </p>
                      </v-col>
                    </v-row>
                  </div>
                </div>

                <div class="d-flex" style="width: 100%; padding-top: 8px"
                  :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'">
                  <v-row class="d-flex">
                    <v-col class="task-buttons">
                      <!-- new buttons -->
                      <InteractionButtons :closeModal="closeModal" />
                      <!-- new buttons -->
                    </v-col>

                    <v-col class="task-buttons" style="justify-content: end">
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small color="#f5f5f5" class="item-task" v-bind="attrs">
                            <v-icon color="#54607a" v-bind="attrs" v-on="on" medium>mdi-calendar-month-outline</v-icon>
                          </v-btn>
                        </template>
                        
                      </v-tooltip> -->

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small color="#f5f5f5" class="item-task" v-bind="attrs">
                            <v-icon color="#54607a" v-bind="attrs" v-on="on" medium>mdi-account-tie</v-icon>
                          </v-btn>
                        </template>
                        <!-- Content -->
                        <p class="mb-1 mr-0">
                          <b>Especialidades</b>
                        </p>
                        <v-chip v-for="(specialty, index) in $store.rdiStore.state.currentRDI
    .specialties" :key="index" color="#fbfbfb" light small class="mx-3">
                          <small>{{ specialty.specialty.name }}</small>
                        </v-chip>
                      </v-tooltip>

                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn fab small color="#f5f5f5" class="item-task" v-bind="attrs">
                            <v-icon color="#54607a" v-bind="attrs" v-on="on" medium>mdi-book-account-outline</v-icon>
                          </v-btn>
                        </template>
                        <!-- Content -->
                        <p class="mb-0">
                          <b>Materia:</b>
                          {{ $store.rdiStore.state.currentRDI.subject.name }}
                        </p>
                      </v-tooltip>
                    </v-col>

                    <!-- BUTTON FINISH TASK
                    <v-col class="end-task" cols="4">
                      <v-btn
                        class="endTask-btn"
                        v-if="checkPermissions(['end_rdi'])"
                        @click="startCloseRDI"
                      >
                        Finalizar RDI
                        <span class="material-symbols-outlined"> tab_close_right </span>
                      </v-btn>
                    </v-col> -->
                  </v-row>
                </div>
              </div>

              <div class="content-scrolled">
                <v-row class="mt-5">
                  <v-expansion-panels class="expansion-panel-parent" v-model="panel" multiple>
                    <v-expansion-panel class="expansion-panel-child">
                      <v-expansion-panel-header class="expansion-panel-header">
                        <div class="flex">
                          <span>Descripción</span>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="expansion-panel-content">
                        <v-row class="mt-2">
                          <v-col v-if="$store.rdiStore.state.currentRDI.doc_ref">
                            <b>Planos de referencia: </b>
                            <i>{{ $store.rdiStore.state.currentRDI.doc_ref }}</i>
                          </v-col>
                          <v-col cols="12">
                            <div v-html="$store.rdiStore.state.currentRDI.description"></div>
                            <!-- <MediaGallery v-if="$store.rdiStore.state.currentRDI.attachments.filter(
    (item) => item.file.pathmedia
  ).length > 0
    " :images="$store.rdiStore.state.currentRDI.attachments
    .filter((item) => item.file.pathmedia)
    .map((item) => item.file.pathmedia)
    " /> -->
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
                <v-row class="mt-5">
                  <v-expansion-panels class="expansion-panel-parent" :value="opened">
                    <v-expansion-panel class="expansion-panel-child">
                      <v-expansion-panel-header class="expansion-panel-header">
                        <div class="flex">
                          <span>Adjuntos</span>
                        </div>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content class="expansion-panel-content">
                        <v-row align="center" class="mt-2">
                          <v-col cols="12">
                            <div class="d-flex justify-space-between">
                              <p class="mb-0 title black--text"></p>
                              <v-btn v-if="$store.rdiStore.state.currentRDI.attachments.length > 1
    " class="mx-2 my-1" rounded color="#515d78" dark x-small :loading="loadingFilePath"
                                @click="() =>
    downloadAttachments(
      $store.rdiStore.state.currentRDI.attachments
    )
    ">
                                <v-icon dark small>mdi-paperclip</v-icon>
                                <p class="mb-0 ml-2">Descargar Todo</p>
                              </v-btn>
                            </div>
                            <div class="adjuntos-container" v-if="$store.rdiStore.state.currentRDI.attachments">
                              <div class="d-flex flex-row align-center justify-space-between my-2 py-1 px-2"
                                :class="index % 2 != 0 ? 'bg-dark-list' : ''" :key="index" v-for="(
                                  attachment, index
                                ) in $store.rdiStore.state.currentRDI.attachments">
                                <p class="mb-0 text-filename">{{ attachment.file.name }}</p>
                                <p class="mb-0 text-primary">
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="mx-1 my-1" color="#515d78" dark small icon
                                        :loading="loadingFilePath" v-bind="attrs" v-on="on"
                                        @click="getFilesUrl(attachment, true)">
                                        <v-icon color="white" small
                                          class="info-icon small-icon">mdi-open-in-new</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Visualizar en navegador</span>
                                  </v-tooltip>
                                  <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn class="mx-1 my-1" color="#515d78" dark small icon
                                        :loading="loadingFilePath" v-bind="attrs" v-on="on"
                                        @click="getFilesUrl(attachment, true, true)">
                                        <v-icon color="white" small class="small-icon info-icon">mdi-download</v-icon>

                                      </v-btn>
                                    </template>
                                    <span>Descargar archivo</span>
                                  </v-tooltip>
                                </p>
                              </div>
                            </div>
                            <p v-else>No hay adjuntos</p>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-row>
              </div>

            </v-card-text>
          </v-col>
          <v-col cols="6" sm="6" class="interaction-section">
            <MinimizeCloseBtn :closeModal="closeModal" />
            <!-- <v-row class="recent-btn-row">
              <v-btn class="recent-btn text-lowercase" @click="handleRecentbtn">
                Más recientes primero
                <span class="material-symbols-outlined ml-1"> low_priority </span>
              </v-btn>
            </v-row> -->
            <div class="interaction-rectangle">
              <span>Interacción</span>
            </div>
            <div class="interaction-card" id="interaction-card">
              <div class="scrollable-content" ref="interactionContainer">
                <!-- Interaction Zone -->
                <v-row align="center" class="mt-2" v-if="$store.rdiStore.state.currentRDI.interactions.length < 1">
                  <v-col cols="12" class="text-center"> No hay interacciones </v-col>
                </v-row>
                <!-- <Interactions :interactions="filteredSearch($store.rdiStore.state.currentRDI.official_interactions)
    " :searchText="interactionSearch" />
                <Interactions :interactions="filteredSearch($store.rdiStore.state.currentRDI.interactions)
    " :searchText="interactionSearch" /> -->
                <Interactions
                  :interactions="mergeAndSort($store.rdiStore.state.currentRDI.interactions, $store.rdiStore.state.currentRDI.official_interactions)"
                  :searchText="interactionSearch" />
              </div>
            </div>
            <!-- response buttons -->
            <br />
            <v-row align="center" v-if="$store.rdiStore.state.currentRDI.status != 3 &&
    checkInteractPermission(['interact_rdi'])
    ">
              <v-col cols="12">
                <v-btn-toggle v-model="response_type" mandatory>
                  <v-btn small @click="
    is_response = false;
  displayTabRDI();
  " :class="$vuetify.breakpoint.mobile ? 'caption' : ''">Interacción</v-btn>
                  <v-btn small @click="
    is_response = true;
  displayTabRDI();
  " :class="$vuetify.breakpoint.mobile ? 'caption' : ''">Respuesta Oficial</v-btn>
                  <v-btn small @click="RequestClosing()" :class="$vuetify.breakpoint.mobile">Solicitar Cierre</v-btn>
                </v-btn-toggle>

                <tiptap-vuetify v-model="message" :extensions="extensions"
                  :class="is_response ? 'is_response_interaction' : ''" id="interaction-texteditor"
                  v-if="hideTextEditor" />
                <div v-if="displayRequest" class="mt-4">
                  <v-card>
                    <v-row class="d-flex" style="justify-content: center; padding-top: 15px; height: 100px">
                      <v-row class="d-flex" style="width: 100%; margin-top: ">
                        <v-col class="title-column">
                          <p class="title-text">CREADOR</p>
                        </v-col>
                        <v-col class="title-column">
                          <p class="title-text">CONSTRUCTORA</p>
                        </v-col>
                        <v-col class="title-column">
                          <p class="title-text">INSPECCIÓN</p>
                        </v-col>
                      </v-row>



                      <v-row class="d-flex" style="width: 100%; margin-top: -30px">
                        <v-col class="d-flex pa-0" style="justify-content: center">
                          <v-btn icon
                            :class="[($store.rdiStore.state.currentRDI.steep_1_user_by) ? 'star-button' : 'star-button-deactivated', { 'not-allowed': ($store.rdiStore.state.currentRDI.steep_1_user_by) }]"
                            @click="!($store.rdiStore.state.currentRDI.steep_1_user_by) ? togglePopUp('steep_1_user_by') : null">
                            <v-icon v-if="!loader_steep1" class="icon-color">mdi-check</v-icon>
                            <v-progress-circular v-if="loader_steep1" width="2" size="16" color="white"
                              indeterminate></v-progress-circular>
                          </v-btn>

                        </v-col>

                        <v-col class="d-flex pa-0" style="justify-content: center">
                          <v-btn icon
                            :class="[($store.rdiStore.state.currentRDI.steep_2_user_by) ? 'star-button' : 'star-button-deactivated', { 'not-allowed': ($store.rdiStore.state.currentRDI.steep_2_user_by) }]"
                            @click="!($store.rdiStore.state.currentRDI.steep_2_user_by) ? togglePopUp('steep_2_user_by') : null">
                            <v-icon v-if="!loader_steep2" class="icon-color">mdi-check</v-icon>
                            <v-progress-circular v-if="loader_steep2" width="2" size="16" color="white"
                              indeterminate></v-progress-circular>
                          </v-btn>
                        </v-col>

                        <v-col class="d-flex pa-0" style="justify-content: center">
                          <v-btn icon
                            :class="[($store.rdiStore.state.currentRDI.steep_3_user_by) ? 'star-button' : 'star-button-deactivated', { 'not-allowed': ($store.rdiStore.state.currentRDI.steep_3_user_by) }]"
                            @click="!($store.rdiStore.state.currentRDI.steep_3_user_by) ? togglePopUp('steep_3_user_by') : null">
                            <v-icon v-if="!loader_steep3" class="icon-color">mdi-check</v-icon>
                            <v-progress-circular v-if="loader_steep3" width="2" size="16" color="white"
                              indeterminate></v-progress-circular>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-row>
                  </v-card>
                </div>
                <!-- <div>validator_creator: {{ checkInteractPermission(['validator_creator']) }}</div>
                <div>validator_builder: {{ checkInteractPermission(['validator_builder']) }}</div>
                <div>validator_inspect: {{ checkInteractPermission(['validator_inspect']) }}</div> -->
                <v-alert dense outlined text icon="mdi-comment-alert-outline" type="warning" class="mt-1"
                  v-if="is_response">
                  <p class="mb-0 caption">
                    Estás respondiendo la RDI como respuesta oficial, esta interacción
                    quedará marcada como importante. Esto quitará los check de validación de cierre, para ser nuevamente validado.
                  </p>
                </v-alert>

                <div id="popClosing">
                  <ClosingPopUp v-show="popUp" @close="closePopUp()" :message="popupMessage"
                    @confirmed="handleConfirmation" />
                </div>
              </v-col>
            </v-row>
            <v-row align="center" v-if="files.length > 0">
              <v-col cols="12" class="flex-row d-flex justify-start flex-wrap">
                <div v-for="(file, index) in files" :key="index" class="ma-1" style="flex: 1 0 30%">
                  <div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
                    <v-btn icon color="#515d78" x-small class="button-preview-img" @click="removeAttachment(index)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <img :src="filesPreview[index].src" class="preview-img" />
                  </div>
                  <div v-else class="d-flex flex-row align-center justify-start">
                    <v-btn icon color="#515d78" x-small @click="removeAttachment(index)">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                    {{ file.name }}
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row align="center" v-if="$store.rdiStore.state.currentRDI.status != 3 &&
    checkInteractPermission(['interact_rdi'])
    ">
              <v-col cols="12">
                <div class="d-flex justify-space-between"
                  :class="$vuetify.breakpoint.mobile ? 'flex-column' : 'flex-row'">

                  <v-btn class="mx-2 my-1" rounded color="white" raised v-if="checkInteractPermission(['interact_rdi'])"
                    @click="onButtonClick">
                    <p class="mb-0 text-primary">Adjuntar Archivo</p>
                  </v-btn>
                  <input ref="uploader" class="d-none" type="file" @change="onFileChanged" />
                  <v-btn class="mx-2 my-1" rounded color="#525D78" dark v-if="checkInteractPermission(['interact_rdi'])"
                    :loading="loading" @click="preAddInteraction()">
                    <p class="mb-0">{{ confirmButtonText }}</p>
                    <v-icon right>mdi-upload</v-icon>
                  </v-btn>

                </div>
              </v-col>
            </v-row>
          </v-col>
        </div>

        <!-- n -->
        <!-- n -->
        <!-- n -->
        <!-- n -->
        <!-- n -->
      </v-card>
    </v-dialog>
    <AcceptCancelModal :acceptRejectModal="acceptRejectModal" :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel" />
    <SendContentByEmailModal :sendContentByEmailModal="sendByEmailModal" :closeModal="() => (sendByEmailModal = false)"
      :sendingType="2" />
  </div>
</template>

<script>
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import EventBus from "@/helpers/event_bus";
import { getPublicUrl, getTareaByID } from "@/helpers/api/tasks";
import {
  addInteraction,
  getRdiByID,
  closeRDI,
  updateRdi,
  deleteRdi,
  downloadPDF,
} from "@/helpers/api/rdi";
import { getZippedFiles } from "@/helpers/api/fileUploads";
// import MediaGallery from "@/components/Globals/Helpers/MediaGallery";
import Interactions from "@/components/Globals/Helpers/Interactions";
import SendContentByEmailModal from "@/components/Globals/Modals/General/SendContentByEmailModal";
import AcceptCancelModal from "@/components/Globals/Modals/General/AcceptCancelModal.vue";
import MinimizeCloseBtn from "@/components/Globals/Modals/Rdis/Parts/MinimizeCloseBtn";
import InteractionButtons from "@/components/Globals/Modals/Rdis/Parts/InteractionButtons";
import event_bus from "@/helpers/event_bus.js";
import ClosingPopUp from "./Parts/RequestClosingPopUp.vue";
import RDIModalMobile from "./RDIModalMobile"
// import AvatarUser from "../../../Layout/Ui/AvatarUser.vue";

export default {
  name: "RDIModal",
  components: {
    TiptapVuetify,
    // MediaGallery,
    SendContentByEmailModal,
    AcceptCancelModal,
    Interactions,
    MinimizeCloseBtn,
    InteractionButtons,
    ClosingPopUp,
    RDIModalMobile,
    // AvatarUser
  },
  computed: {
    currentRDI() {
      return this.$store.rdiStore.state.currentRDI;
    },
    gotOficialResponse() {
      return this.$store.rdiStore.state.currentRDI.interactions.some((item) => {
        return item.is_response;
      });
    },
  },
  watch: {
    currentRDI(newData) {
      console.log('rdi', newData);
      if (newData && !newData.loading && newData.id) {
        setTimeout(() => {
          this.pasteListener();
        }, 500);
      }
    },
  },
  data: () => ({
    return: {
      expandedPanels: ["description"],
    },
    displayRequest: false,
    hideTextEditor: true,
    dialog: true,
    showMore: false,
    interactionSearch: "",
    extensions: [
      History,
      // Table,
      // TableCell,
      // TableHeader,
      // TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [
        Heading,
        {
          options: {
            levels: [1, 2, 3],
          },
        },
      ],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak,
    ],
    message: "",
    response_type: 0,
    need_reload: false,
    files: [],
    filesPreview: [],
    loadingFilePath: false,
    is_response: false,
    loading: false,
    changeInChecklists: false,
    loadingChangeUser: false,
    loadingPDFDownload: false,
    sendByEmailModal: false,
    cancelTimer: 4,
    inCancelTime: false,
    cancelInterval: null,
    confirmButtonText: "Responder",
    loadingEdit: false,
    acceptRejectModal: false,
    acceptRejectModalData: null,
    popUp: false,
    panel: [0],
    opened: 0,
    steep_1_user_by: false,
    steep_2_user_by: false,
    steep_3_user_by: false,
    popupMessage: null,
    loader_steep1: false,
    loader_steep2: false,
    loader_steep3: false,
  }),
  mounted() {
    this.setListeners();
    // this.$nextTick(() => {

    // });
    // this.scrollToBottomInteraction();
  },
  beforeDestroy() {
    EventBus.$off("completedFileUpload");
  },
  methods: {
    scrollToBottomInteraction() {
      // console.log('scrollToBottomInteraction')
      const container = this.$refs.interactionContainer;

      setTimeout(() => {
        if (container) {
          const divConScroll = document.getElementById('interaction-card');
          divConScroll.scrollTop = container.scrollHeight;
        } else {
          this.scrollToBottomInteraction();
        }
      }, 200);

    },
    closePopUp() {
      console.log('closePopUp');
      this.popUp = !this.popUp;
    },
    togglePopUp(key) {
      console.log('steep user by:', key);
      let obj = {
        'key': key,
        'value': '',
        'status': this.getNumberCheck(),
        'permission': false
      }
      if (key == "steep_1_user_by") {
        obj.value = "Creador";
        let user_create_permission = false;
        if((this.$store.userStore.state.userInfo.id == this.$store.rdiStore.state.currentRDI.created_by) || this.checkInteractPermission(['validator_creator'])){
          user_create_permission = true;
        }
        console.log('is_creator', user_create_permission)
        // console.log('users', this.$store.userStore.state.userInfo.id,this.$store.rdiStore.state.currentRDI.created_by)
        obj.permission = user_create_permission;
      } else if (key == "steep_2_user_by") {
        obj.value = "Constructora";
        obj.permission = this.checkInteractPermission(['validator_builder']);
      } else if (key == "steep_3_user_by") {
        obj.value = "Inspección";
        obj.permission = this.checkInteractPermission(['validator_inspect']);
      }

      console.log('message', obj)

      this.popupMessage = obj;
      this.popUp = !this.popUp;
    },
    handleConfirmation(event) {
      console.log('handleConfirmation', event)
      if (event) {
        if (event.key == "steep_1_user_by") {
          this.steep_1_user_by = true;
          this.loader_steep1 = true;
        } else if (event.key == "steep_2_user_by") {
          this.steep_2_user_by = true;
          this.loader_steep2 = true;
        } else if (event.key == "steep_3_user_by") {
          this.steep_3_user_by = true;
          this.loader_steep3 = true;
        }
      }
      let rdi = this.$store.rdiStore.state.currentRDI.id;
      let params = {
        'id': rdi,
        [event.key]: this.$store.userStore.state.userInfo.id
      }
      console.log('params rdi', rdi, params)
      this.modifyCheckRdi(params);
    },
    async modifyCheckRdi(params) {
      this.loadingEdit = true;
      console.log("modifyRdi: ", params);
      const rdiUpdate = await updateRdi(params);
      console.log('rdi update:', rdiUpdate)
      if (rdiUpdate.code == 200) {
        this.$store.rdiStore.commit("setCurrentRDI", rdiUpdate.rdi);
        this.loaderOut();
        this.getRDIList();
        const data = {
          message: "Se ha validado correctamente la RDI",
          title: "RDI Validada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
        if (this.getNumberCheck() == 3) {
          // finaliza RDI
          this.closeRDI();
        }
      }
      this.loadingEdit = false;
    },
    loaderOut() {
      this.loader_steep1 = false;
      this.loader_steep2 = false;
      this.loader_steep3 = false;
    },
    getNumberCheck() {
      let count = 0;
      if (this.$store.rdiStore.state.currentRDI.steep_1_user_by !== null) count++;
      if (this.$store.rdiStore.state.currentRDI.steep_2_user_by !== null) count++;
      if (this.$store.rdiStore.state.currentRDI.steep_3_user_by !== null) count++;
      return count;
    },
    checkInteractPermission(permissionToCheck) {
      return this.checkPermissions(permissionToCheck);
    },
    changeInSubscription(value) {
      this.editRdiForm.subscribe_status_mail = value;
    },
    isUserPart() {
      return (
        this.$store.userStore.state.userInfo.id ===
        this.$store.rdiStore.state.currentRDI.created_by_user.id ||
        this.$store.userStore.state.userInfo.id ===
        this.$store.rdiStore.state.currentRDI.responsable_user.id
      );
    },
    isMyRdi() {
      return (
        this.$store.userStore.state.userInfo.id ===
        this.$store.rdiStore.state.currentRDI.created_by_user.id
      );
    },
    filteredSearch(items) {
      console.log('filteredSearch', items);
      if (this.interactionSearch && this.interactionSearch !== "" && items) {
        const filtered = items.filter((item) => {
          return item.message
            .toLowerCase()
            .includes(this.interactionSearch.toLowerCase());
        });
        return filtered;
      }
      return items;
    },
    mergeAndSort(arr1, arr2) {
      // Concatenate the two arrays
      const mergedArray = [...arr1, ...arr2];
      // Sort the merged array from highest to lowest based on the "id" field
      // mergedArray.sort((a, b) => b.id - a.id);
      mergedArray.sort((a, b) => a.id - b.id);
      let filtered = this.filteredSearch(mergedArray);
      this.scrollToBottomInteraction();
      return filtered;
    },
    pasteListener() {
      const textEditor = document.querySelector("#interaction-texteditor .ProseMirror");
      if (textEditor) {
        textEditor.addEventListener("paste", this.pasteListenerHandler);
      }
    },
    async pasteListenerHandler(event) {
      const dT = event.clipboardData || window.clipboardData;
      const file = dT.files[0];
      if (file) {
        this.files.push(file);
        if (file.type.includes("image")) {
          const base64File = await this.toBase64(file);
          this.filesPreview.push({ src: base64File });
        } else {
          this.filesPreview.push({ noImage: true });
        }
      }
    },
    changedChecklist() {
      this.changeInChecklists = true;
    },
    setListeners() {
      EventBus.$on("completedFileUpload", async (payload) => {
        if (
          payload &&
          payload.rdi_id &&
          this.$store.rdiStore.state.currentRDI &&
          payload.rdi_id == this.$store.rdiStore.state.currentRDI.id
        ) {
          const rdi = await getRdiByID({ rdi_id: payload.rdi_id });
          if (rdi.code === 200) {
            let rdiData = rdi.rdi;
            this.$store.rdiStore.commit("setCurrentRDI", rdiData);
          }
        }
      });
    },
    async getRDIList() {
      let project_id = null;
      if (this.$store.projectsStore.state.selectedProject) {
        project_id = this.$store.projectsStore.state.selectedProject.id;
      } else {
        const id = localStorage.getItem("selectedProjectID");
        project_id = parseInt(id);
      }
      if (project_id) {
        this.$store.rdiStore.dispatch("getRDI", { project_id });
      }
    },
    getRdiPriorityColor(rdi) {
      if (rdi.priority === 3) {
        return "high";
      } else if (rdi.priority === 2) {
        return "medium";
      }
      return "normal";
    },
    getRdiPriorityText(rdi) {
      if (rdi.priority === 3) {
        return "Inmediata";
      } else if (rdi.priority === 2) {
        return "Media";
      }
      return "Estándar";
    },
    checkIfNearDeadline(rdi) {
      if (!rdi.critical_date) {
        return false;
      }
      const now = moment();
      const rdi_date = moment(rdi.critical_date);
      return rdi_date.diff(now, "days") <= 2;
    },
    getColorStatus() {
      if (this.$store.rdiStore.state.currentRDI.status === 1) {
        return "#3EBFA3";
      }
      return "#F4516C";
    },
    closeModal() {
      const reload =
        this.need_reload || !this.$store.rdiStore.state.currentRDI.prev_seen_by_me;
      this.$store.rdiStore.commit("setCurrentRDI", null);
      if (reload) {
        this.getRDIList();
      }
      this.need_reload = false;
      this.loading = false;
      this.files = [];
      this.filesPreview = [];
    },
    onButtonClick() {
      this.$refs.uploader.click();
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0];
      this.files.push(newFile);
      if (newFile.type.includes("image")) {
        const base64File = await this.toBase64(newFile);
        this.filesPreview.push({ src: base64File });
      } else {
        this.filesPreview.push({ noImage: true });
      }
    },
    removeAttachment(index) {
      this.files.splice(index, 1);
      this.filesPreview.splice(index, 1);
    },
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result);
        };
        reader.onerror = function () {
          resolve(null);
        };
      });
    },
    async getFilesUrl(attachments, singleFile = false, download = false) {
      if (singleFile) {
        attachments = [attachments];
      }
      this.loadingFilePath = true;
      const files = await getPublicUrl({ attachments, force_download: download });
      if (files.code === 200) {
        files.files.forEach((file) => {
          window.open(file.url);
        });
      }
      this.loadingFilePath = false;
    },
    async downloadAttachments(attachments) {
      this.loadingFilePath = true;
      const toZip = attachments.map((item) => item.file);
      console.log('toZIP', toZip);
      const files = await getZippedFiles({ files_to_zip: toZip });
      if (files.code == 200) {
        // this.downloadFile(files.fileurl, 'archivo.zip')
        window.open(files.fileurl);
      }
      this.loadingFilePath = false;
    },
    async preAddInteraction() {
      if (this.inCancelTime) {
        clearInterval(this.cancelInterval);
        this.confirmButtonText = "Confirmar";
        this.inCancelTime = false;
        return null;
      }
      if (this.message === "" || !this.message) {
        const datas = {
          message: "Por favor escribe un mensaje. Este campo no puede estar vacio.",
          title: "Error",
          created: new Date(),
          type: "success",
          icon: "mdi-alert-circle",
          color: "#fb8c00",
        };
        this.$store.globalStore.dispatch("addMessage", datas);
        return false;
      }

      this.inCancelTime = true;
      this.cancelTimer = 4;
      this.confirmButtonText = "Cancelar (" + this.cancelTimer + ")";
      this.cancelInterval = setInterval(() => {
        this.cancelTimer--;
        this.confirmButtonText = "Cancelar (" + this.cancelTimer + ")";
        if (this.cancelTimer == 0) {
          this.inCancelTime = false;
          this.confirmButtonText = "Responder";
          clearInterval(this.cancelInterval);
          this.beforeAddInteraction();
        }
      }, 1000);
    },
    beforeAddInteraction(closing = false) {
      return new Promise((resolve) => {
        let promises = [];
        if (!closing && (this.message === "" || !this.message)) {
          const datas = {
            message: "Por favor escribe un mensaje. Este campo no puede estar vacio.",
            title: "Error",
            created: new Date(),
            type: "success",
            icon: "mdi-alert-circle",
            color: "#fb8c00",
          };
          this.$store.globalStore.dispatch("addMessage", datas);
          resolve(false);
          return false;
        } else if (closing && (this.message === "" || !this.message)) {
          promises.push(
            this.addInteraction(
              this.$store.rdiStore.state.currentRDI,
              "RDI cerrada por usuario",
              false,
              []
            )
          );
          resolve(false);
          return false;
        }
        promises.push(
          this.addInteraction(
            this.$store.rdiStore.state.currentRDI,
            this.message,
            this.is_response,
            this.files
          )
        );
        if (closing) {
          promises.push(
            this.addInteraction(
              this.$store.rdiStore.state.currentRDI,
              "RDI cerrada por usuario",
              false,
              []
            )
          );
        }

        Promise.all(promises)
          .then(function () {
            resolve(true);
          })
          .catch(function () {
            resolve(false);
          });
      });
    },
    async addInteraction(rdi, message, is_response, files) {
      return new Promise((resolve) => {
        this.loading = true;
        const biggerFiles = [];
        const formData = new FormData();
        formData.append("rdi_id", rdi.id);
        formData.append("message", message);
        formData.append("is_response", is_response);
        files.forEach((file) => {
          if (file && file.size < 1000) {
            formData.append("files[]", file);
          } else if (file) {
            biggerFiles.push({
              type: 4,
              payload: {
                rdi_id: rdi.id,
                project_id: this.$store.projectsStore.state.selectedProject.id,
              },
              description: rdi.title,
              total: file.size,
              uuid: uuidv4(),
              file,
            });
          }
        });
        addInteraction(formData)
          .then((interaction) => {
            if (interaction.code === 200) {
              if (biggerFiles.length > 0) {
                biggerFiles.map((file) => {
                  file.payload = Object.assign(file.payload, {
                    rdi_interaction_id: interaction.interaction,
                    project_id: this.$store.projectsStore.state.selectedProject.id,
                  });
                  this.$store.globalStore.commit("setFilesToUploadCenter", file);
                });
              }
              this.need_reload = true;
              this.message = "";
              this.files = [];
              this.filesPreview = [];
              let rdiData = interaction.rdi;
              this.$store.rdiStore.commit("setCurrentRDI", rdiData);
              resolve(true);
            } else {
              resolve(false);
            }
            this.loading = false;
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    async modifyRdi() {
      this.loadingEdit = true;
      this.editRdiForm.id = this.$store.rdiStore.state.currentRDI.id;
      const rdiUpdate = await updateRdi(this.editRdiForm);
      if (rdiUpdate.code == 200) {
        this.$store.rdiStore.commit("setCurrentRDI", rdiUpdate.rdi);
        this.getRDIList();
        const data = {
          message: "Se ha modificado correctamente la RDI",
          title: "RDI modificada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
      this.loadingEdit = false;
    },
    closeModalAcceptCancel(status = false) {
      this.acceptRejectModal = false;
      if (status && this.acceptRejectModalData && this.acceptRejectModalData.type === 1) {
        this.closeRDI();
      }
      if (status && this.acceptRejectModalData && this.acceptRejectModalData.type === 2) {
        this.deleteRdi();
      }
      this.acceptRejectModalData = null;
    },
    startCloseRDI() {
      this.acceptRejectModalData = {
        title: "¿Cerrar la RDI Nº " + this.$store.rdiStore.state.currentRDI.number + "?",
        content_a: "Por favor confirma si deseas cerrar la Rdi indicada.",
        type: 1, //1: Close, 2: delete
      };
      if (!this.gotOficialResponse && (this.message == "" || !this.is_response)) {
        this.acceptRejectModalData.content_b =
          "Ten en cuenta que estás cerrando la RDI sin una respuesta oficial que indique algún estado de cierre. Si deseas agregar una respuesta oficial cancela e ingrésala antes de finalizar.";
      }
      this.acceptRejectModal = true;
    },
    async closeRDI() {
      await this.beforeAddInteraction(true);
      const rdi = await closeRDI({ rdi_id: this.$store.rdiStore.state.currentRDI.id });
      if (rdi.code === 200) {
        let currentRDI = { ...this.$store.rdiStore.state.currentRDI };
        currentRDI.status = 3;
        this.$store.rdiStore.commit("setCurrentRDI", currentRDI);
        this.$store.notificationsStore.dispatch("getReminders");
        this.$store.projectsStore.dispatch("getDashboardInfo");
        this.need_reload = true;
        this.message = "";
        this.files = [];
        this.filesPreview = [];
      }
    },
    startDeleteRdi() {
      this.acceptRejectModalData = {
        title:
          "¿Eliminar la RDI Nº " + this.$store.rdiStore.state.currentRDI.number + "?",
        content_a: "Por favor confirma si deseas elliminar la RDI indicada.",
        type: 2, //1: Close, 2: delete
      };
      this.acceptRejectModal = true;
    },
    async deleteRdi() {
      const task = await deleteRdi({ task_id: this.$store.rdiStore.state.currentRDI.id });
      if (task.code === 200) {
        this.getRDIList();
        this.$store.notificationsStore.dispatch("getReminders");
        this.$store.rdiStore.commit("setCurrentRDI", null);
        const data = {
          message: "Se ha eliminado correctamente la RDI",
          title: "RDI eliminada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
    },
    minimizeTask() {
      const data = {
        task_id: this.$store.rdiStore.state.currentRDI.id,
        title: this.$store.rdiStore.state.currentRDI.title,
        type: "RDI",
        type_id: 2,
        number: this.$store.rdiStore.state.currentRDI.number,
      };
      this.$store.globalStore.commit("addTaskToTasksHandler", data);
      this.closeModal();
    },
    async viewRdi(task_id, seen_by_me) {
      this.$store.consultasStore.commit("setCurrentTarea", { loading: true });
      const tarea = await getTareaByID({ task_id });
      if (tarea.code === 200) {
        let tareaData = tarea.task;
        tareaData.prev_seen_by_me = seen_by_me;
        this.$store.consultasStore.commit("setCurrentTarea", tareaData);
      }
    },
    async downloadPDF() {
      this.loadingPDFDownload = true;
      const pdf_url = await downloadPDF({
        rdi_id: this.$store.rdiStore.state.currentRDI.id,
      });
      if (pdf_url.code == 200) {
        window.open(pdf_url.pdf_url);
      }
      this.loadingPDFDownload = false;
    },
    handleRecentbtn() {
      event_bus.$emit("recentBtnClicked");
    },
    RequestClosing() {
      this.is_response = false;
      this.displayRequest = true;
      this.hideTextEditor = false;
    },
    displayTabRDI() {
      this.displayRequest = false;
      this.hideTextEditor = true;
    },
    toggleFavorite() {
      this.favorite = !this.favorite;
    },
  },
};
</script>

<style scoped>
.info-container {
  color: #606060;
}

.text-filename {
  font-size: 0.7rem;
  font-weight: 500;
  color: #464f64;
}

.text-primary {
  color: #515d78;
  white-space: nowrap;
}

.info-icon {
  background-color: #515d78;
  border-radius: 25px;
  padding: 3px;
  width: 35px;
  height: 35px;
}

.info-icon.small-icon {
  width: 25px;
  height: 25px;
}

.info-img {
  width: 100%;
  max-height: 50px;
  object-fit: contain;
}

.adjuntos-container {
  background-color: #efefef;
  /* max-height: 95px;
  overflow-y: scroll; */
}

.interaction-container {
  width: 100%;
  background-color: #e5e9f2;
  border-radius: 5px;
  position: relative;
  padding: 12px;
}

.interaction-container.response {
  background-color: #cae7cc;
}

.user-avatar.small {
  width: 25px;
  height: 25px;
}

.light-blue-text {
  color: #515d78;
}

.bg-light-blue-text {
  background-color: #515d78;
}

.interaction-container .icon-arrow {
  font-size: 80px;
  position: absolute;
  top: 20px;
  line-height: inherit;
}

.interaction-container.right .icon-arrow {
  left: -46px;
}

.interaction-container.left .icon-arrow {
  right: -46px;
}

.icon-top {
  padding: 2px;
  margin: 0 2px;
  border-radius: 100%;
}

.icon-top.high {
  background-color: #f4516c;
}

.icon-top.medium {
  background-color: #fb8c00;
}

.icon-top.normal {
  background-color: #3ebfa3;
}

.icon-top.primary {
  background-color: #515d78;
}

.icon-top.light-blue,
.bg-light-blue {
  background-color: #515d78;
}

.parent-subtareas {
  display: flex;
  flex-wrap: wrap;
}

.parent-subtareas .subtareas-checkbox {
  flex: 1 0 30%;
}

.notification-img {
  width: 30px;
  height: 30px;
  border-radius: 100%;
}

.preview-img-container {
  position: relative;
}

.preview-img {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  object-fit: contain;
}

.button-preview-img {
  position: absolute;
  top: -1px;
  left: -1px;
  background-color: white;
}

/deep/ .is_response_interaction .tiptap-vuetify-editor__content {
  border: 2px solid #3ebfa3;
}

.is_response_interaction_bottom_text {
  color: #3ebfa3;
}

/* .header-modals{} */
.search-input {
  position: relative;
  background-color: white;
  border-color: red;
  max-width: 200px;
  max-height: 30px;
}

/deep/ .search-input input {
  margin-left: 10px;
  padding: 0;
  line-height: 14px;
  font-size: 14px;
}

/deep/ .search-input .v-input__slot {
  min-height: 30px !important;
}

/deep/ .search-input.v-text-field--outlined.v-input--dense .v-label {
  top: 6px;
  font-size: 14px;
}

/deep/ .search-input.v-text-field--outlined.v-input--dense .v-input__append-inner {
  margin-top: 3px;
}

.search-button {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  width: 32px;
  height: 32px;
}

/* Extra Favric CSS */
.sticky-card-title {
  position: sticky;
  top: 0;
  padding: 35px;
  background-color: white;
  z-index: 3;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.title-adjust {
  font-size: 0.85rem;
  line-height: 1.4rem;
  color: #606060;
  font-weight: 400;
  padding-bottom: 10px;
}

.scrollable-content {
  overflow-y: auto;
  padding-bottom: 30px;
}

.message {
  font-size: 17px;
}

.response-view {
  height: auto;
  padding: 20px;
  padding-bottom: 20px;
  margin-top: 10px;
  background-color: #efefef;
  border-radius: 8px;
  border: 1px solid #c7d1dd;

  .message {
    font-size: 12px;
  }
}

.response-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.drop-down-section {
  margin-top: 10px;
  display: flex;
  width: 100%;
  height: 25px;
  justify-content: left;
  padding-right: 20px;
  padding-left: 12px;
  padding-bottom: 10px;

  .nameHeader {
    margin-top: 5px;
    font-size: 20px;
    color: #515d78;
  }
}

.divider-section {
  margin-top: 30px;
}

.btn-response {
  margin-top: -13px;
  margin-bottom: -16px;
}

/* this line separate the two columns for interactions */
/* .separate-line {
  border-right: 1px solid rgba(0, 0, 0, 0.19);
} */

/* new design  */
.lead-text {
  font-size: 0.8rem;
  font-weight: 500;
  color: #8b8b8b;
  padding: 3px;
}

.icon-text {
  font-weight: 700;
  font-size: 18px;
  color: #515d78;
  text-align: left;
  margin-left: -12px;
  margin-top: 1px;
}

.task-buttons {
  margin-top: 5px;
  display: flex;
  margin-left: -17px;
}

.end-task {
  margin-top: 10px;
  margin-right: -10px;
  display: flex;
  justify-content: end;
}

.endTask-btn {
  height: 40px !important;
  color: #454d61;
  box-shadow: none;
  border-radius: 50px;
}

.v-expansion-panel {
  min-height: 60px;
}

/* interacion zone */

.interaction-section {
  padding: 35px 35px 15px 10px;
}

.recent-btn-row {
  margin: 0px;
  margin-top: 10px;
  display: flex;
  justify-content: end;
}

.recent-btn {
  padding: 5px;
  box-shadow: none;
  border-radius: 50px;
  color: #454d61;
}

.interaction-rectangle {
  margin-top: 10px;
  height: 40px;
  border-radius: 8px 8px 0 0;
  border: 1px solid #c7d1dd;
  background-color: #f4f5f7;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  display: flex;
  align-items: center;
  color: #454d61;
  padding: 0 15px;
  /* Adjust padding as needed */
}

.interaction-card {
  height: 385px;
  border-radius: 0 0 8px 8px;
  margin-top: -1px;
  border: 1px solid #c7d1dd;
  background-color: #fbfbfb;
  color: #454d61;
  padding: 15px;
  overflow: scroll;
}

.expansion-panel-parent {
  background-color: transparent !important;
  border-radius: 4px;
}

.expansion-panel-child {
  background-color: #fbfbfb !important;
  border-radius: 4px;
  border: 1px solid #c7d1dd;
  background-color: #fbfbfb;
}

.expansion-panel-header {
  height: 40px;
  min-height: 30px;
  background-color: #f4f5f7;
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  color: #454d61;
  padding: 0 15px;
  /* Adjust padding as needed */
}

.expansion-panel-content {
  margin-top: 0;
  margin-bottom: 20px;
}

.item-task {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 5px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.requestClosing {
  display: flex;
  justify-content: center;
}

.item-task {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 5px;
  padding: 5px;
  box-shadow: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tittleClosingRDI {
  display: flex;
  justify-content: center;
  margin-bottom: 0px;
  padding: 0;
  padding-top: 30px;
}

.title-column {
  text-align: center;
}

.title-text {
  color: #454d60;
  font-weight: bold;
  font-size: 14px;
}

.star-button {
  background-color: #4caf50;
}

.star-button-deactivated {
  background-color: gray;
}

.icon-color {
  color: white !important;
}

.head-fixed {
  padding-bottom: 0px;
  /* overflow: hidden */
}

.content-scrolled {
  /* overflow: auto;*/
  max-height: 100%;
  /* margin-top: 10px; */
}

.not-allowed {
  cursor: not-allowed !important;
}

.content-scrolled {
  overflow: auto;
  max-height: 473px;
  padding-right: 11px;
  /* margin-top: 10px; */
}

.indicators-box {
  height: 20px;
}

.chipy {
  height: 20px;
  margin: 0 8px;
  font-size: 0.8rem;
  font-weight: 500;
}

/deep/ .v-dialog {
  max-width: 974px !important;
  max-height: 90% !important;
  margin: 10% 0;
  overflow: hidden auto;
  font-size: 0.8rem;

  .title-text {
    font-size: 0.7rem;
  }

  .text-primary {
    font-size: 0.9rem;
    margin: 1px
  }

  .v-btn--icon.v-size--small {
    height: 15px;
    width: 15px;
  }

  /* margin keditor */
  .tiptap-vuetify-editor .ProseMirror {
    outline: 0 !important;
    margin: 5px !important;
  }

  .v-btn__content {
    p {
      font-size: 0.6rem !important;
      font-weight: 600;
    }
  }

  .v-label {
    font-size: 13px;
    font-weight: 500;
  }

  .row {
    margin: 0;
  }

  .v-application .mt-6 {
    margin-top: 0px !important;
  }

  .created-avatar {
    /* padding: 4px; */
    /* padding: 0; */
  }

  .dates-box {
    font-size: 0.7rem;
    color: #606060;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    span {
      white-space: nowrap;

      b {
        font-weight: 500;
      }
    }
  }
}
</style>
