<template>
	<v-container fluid id="consultas-container" class="d-flex flex-column">
		<v-btn-toggle style="width: 100%;" v-if="!$vuetify.breakpoint.mobile">
			<v-btn class="custom-button-consultas"
				:class="this.$store.rdiStore.state.selectedType === 1 ? 'selected':''" tile
				:disabled="!checkPermissions(['create_rdi'])"
				@click="$store.rdiStore.commit('setCreateRDIModal', true)">
				<v-icon>mdi-notebook-plus-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva RDI</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.rdiStore.state.selectedType === 2 ? 'selected':''" tile
				:disabled="!checkPermissions(['view_rdi'])"
				@click="changeListing(2)">
				<v-icon>mdi-view-list-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">RDIs Pendientes</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.rdiStore.state.selectedType === 3 ? 'selected':''" tile
				:disabled="!checkPermissions(['view_rdi'])"
				@click="changeListing(3)">
				<v-icon>mdi-text-box-check-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">RDI Finalizadas</p>
			</v-btn>
			<v-btn class="custom-button-consultas" :class="this.$store.rdiStore.state.selectedType === 4 ? 'selected':''" tile
				:disabled="!checkPermissions(['view_rdi'])"
				@click="changeListing(4)">
				<v-icon>mdi-chart-areaspline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Gráficos</p>
			</v-btn>
			<!-- <v-btn class="custom-button-consultas" :class="selected === 5 ? 'selected':''" tile
				@click="selected = 5">
				<v-icon>mdi-account-group-outline</v-icon>
				<p class="mb-0 ml-2 font-weight-bold text-capitalize">Por Usuario</p>
			</v-btn> -->
			<div class="d-flex align-center justify-end flex-grow-1 px-3">
				<v-btn rounded small color="#AAAAAA"
					:disabled="loadingExport"
					@click="excelExport()">
					<div class="d-flex align-center" v-if="loadingExport">
						<v-progress-circular indeterminate color="white" :width="2" :size="15"></v-progress-circular>
						<p class="mb-0 ml-2 white--text">Preparando Excel</p>
					</div>
					<div class="d-flex align-center" v-else>
						<v-icon color="white">mdi-microsoft-excel</v-icon>
						<p class="mb-0 ml-2 white--text">Exportar a Excel</p>
					</div>
				</v-btn>
			</div>
		</v-btn-toggle>
    <v-menu offset-y v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
					rounded
					color="#515d78"
					dark
					small
					block
          v-bind="attrs"
          v-on="on"
        >
          Seleccione Acción
        </v-btn>
      </template>
      <v-list>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.rdiStore.state.selectedType === 1 ? 'selected':''" tile
						:disabled="!checkPermissions(['create_rdi'])"
						@click="$store.rdiStore.commit('setCreateRDIModal', true)">
						<v-icon>mdi-notebook-plus-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Nueva RDI</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.rdiStore.state.selectedType === 2 ? 'selected':''" tile
						:disabled="!checkPermissions(['view_rdi'])"
						@click="changeListing(2)">
						<v-icon>mdi-view-list-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">RDIs Pendientes</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.rdiStore.state.selectedType === 3 ? 'selected':''" tile
						:disabled="!checkPermissions(['view_rdi'])"
						@click="changeListing(3)">
						<v-icon>mdi-text-box-check-outline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">RDI Finalizadas</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn class="custom-button-consultas"
						block
						:class="this.$store.rdiStore.state.selectedType === 4 ? 'selected':''" tile
						:disabled="!checkPermissions(['view_rdi'])"
						@click="changeListing(4)">
						<v-icon>mdi-chart-areaspline</v-icon>
						<p class="mb-0 ml-2 font-weight-bold text-capitalize">Gráficos</p>
					</v-btn>
        </v-list-item>
        <v-list-item>
					<v-btn color="#AAAAAA"
						block
						:disabled="loadingExport"
						@click="excelExport()">
						<div class="d-flex align-center" v-if="loadingExport">
							<v-progress-circular indeterminate color="white" :width="2" :size="15"></v-progress-circular>
							<p class="mb-0 ml-2 white--text">Preparando Excel</p>
						</div>
						<div class="d-flex align-center" v-else>
							<v-icon color="white">mdi-microsoft-excel</v-icon>
							<p class="mb-0 ml-2 white--text">Exportar a Excel</p>
						</div>
					</v-btn>
        </v-list-item>
      </v-list>
    </v-menu>
		<ListadoRDI ref="listadoRDI"
			v-if="(this.$store.rdiStore.state.selectedType === 2 || this.$store.rdiStore.state.selectedType === 3) && !$vuetify.breakpoint.mobile"
			:ended="this.$store.rdiStore.state.selectedType === 3"/>
		<ListadoRDIMobile ref="listadoRDI"
			v-if="(this.$store.rdiStore.state.selectedType === 2 || this.$store.rdiStore.state.selectedType === 3) && $vuetify.breakpoint.mobile"
			:ended="this.$store.rdiStore.state.selectedType === 3"/>
		<RdiGraphs v-if="this.$store.rdiStore.state.selectedType === 4" />
	</v-container>
</template>

<script>
import ListadoRDI from './ListadoRDI'
import ListadoRDIMobile from './ListadoRDIMobile'
import RdiGraphs from './RdiGraphs'
import {exportStatusRdiByProject} from '@/helpers/api/exports'

export default {
	name: 'RdiContainer',
	components: {ListadoRDI, ListadoRDIMobile, RdiGraphs},
	data: () => ({
		createTareaModal: false,
		loadingExport: false
	}),
	mounted(){
		this.getRDIList()
		// if(!this.checkPermissions(['view_rdi'])){
		// 	this.$store.rdiStore.commit('setSelectedType', 0)
		// }
	},
	methods: {
		changeListing(selected){
			if(this.$store.rdiStore.state.selectedType != selected){
				this.$store.rdiStore.commit('setSelectedType', selected)
				if(selected == 4){
					this.getRdiGraphsData()
				}
				else{
					this.getRDIList()
				}
				this.$refs.listadoRDI && this.$refs.listadoRDI.clearFilters()
			}
		},
		getRdiGraphsData(){
			let project_id = null
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.rdiStore.dispatch('getRdiGraphsData', {project_id})
			}
		},
		async getRDIList(){
			// if(this.checkPermissions(['view_rdi'])){
				let project_id = null
				if(this.$store.projectsStore.state.selectedProject){
					project_id = this.$store.projectsStore.state.selectedProject.id
				}
				else{
					const id = localStorage.getItem('selectedProjectID')
					project_id = parseInt(id)
				}
				if(project_id){
					this.$store.rdiStore.dispatch('getRDI', {project_id})
				}
			// }
		},
		async excelExport(){
			this.loadingExport = true
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const exportRdi = await exportStatusRdiByProject(data)
			if(exportRdi.code == 200){
				window.open(exportRdi.url);
			}
			this.loadingExport = false
		}
	},
};
</script>

<style scoped>
	.text-primary{color: #515d78;}
	.custom-button-consultas.v-btn{
		background-color: white;
		color: #515d78;
		opacity: 1!important;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon,
	/deep/ .custom-button-consultas.v-btn p{
		color: #515d78!important;
		font-size: 13px;
	}
	/deep/ .custom-button-consultas.v-btn .v-icon{font-size: 20px;}
	/deep/ .custom-button-consultas.v-btn:hover .v-icon,
	/deep/ .custom-button-consultas.v-btn:hover p,
	/deep/ .custom-button-consultas.v-btn.selected .v-icon,
	/deep/ .custom-button-consultas.v-btn.selected p{
		color: white!important;;
	}
	.custom-button-consultas.selected,
	.custom-button-consultas:hover{
		background-color: #515d78!important
	}

</style>