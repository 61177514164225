<template>
  <div style="min-width: 168px" class="d-flex align-center order-0 order-sm-1">
    <v-menu offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              fab
              dark
              x-small
              color="#f5f5f5"
              class="item-task"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <span class="material-symbols-outlined"> edit_note </span>
            </v-btn>
          </template>
          <span>Modificar RDI</span>
        </v-tooltip>
      </template>
      <div style="background: white">
        <div class="bg-light-blue-text py-1 px-2">
          <p class="caption mb-0 font-weight-bold white--text">Modificar RDI.</p>
        </div>
        <div class="pa-2">
          <div v-if="checkPermissions(['change_priority_task'])">
            <p class="mb-0 caption light-blue-text font-weight-bold">Cambiar Prioridad</p>
            <div class="d-flex flex-row">
              <v-btn
                class="mx-2 my-1"
                color="#F4516C"
                dark
                small
                :outlined="editRdiForm.priority === 3"
                @click="
                  () => {
                    editRdiForm.priority != 3
                      ? (editRdiForm.priority = 3)
                      : (editRdiForm.priority = null);
                  }
                "
                v-if="$store.rdiStore.state.currentRDI.priority != 3"
              >
                <p class="mb-0">! Inmediata</p>
              </v-btn>
              <v-btn
                class="mx-2 my-1"
                color="warning"
                dark
                small
                :outlined="editRdiForm.priority === 2"
                @click="
                  () => {
                    editRdiForm.priority != 2
                      ? (editRdiForm.priority = 2)
                      : (editRdiForm.priority = null);
                  }
                "
                v-if="$store.rdiStore.state.currentRDI.priority != 2"
              >
                <p class="mb-0">! Media</p>
              </v-btn>
              <v-btn
                class="mx-2 my-1"
                color="#3EBFA3"
                dark
                small
                :outlined="editRdiForm.priority === 1"
                @click="
                  () => {
                    editRdiForm.priority != 1
                      ? (editRdiForm.priority = 1)
                      : (editRdiForm.priority = null);
                  }
                "
                v-if="$store.rdiStore.state.currentRDI.priority != 1"
              >
                <p class="mb-0">! Estándar</p>
              </v-btn>
            </div>
          </div>
          <div v-if="checkPermissions(['change_critical_date_task'])">
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Agregar/modificar Fecha Crítica
            </p>
            <v-menu v-model="datePicker" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="editRdiForm.critical_date"
                  label="Fecha Crítica"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  hide-details
                  class="remove-bottom-details date-limit-input mt-2"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="editRdiForm.critical_date"
                @change="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Estado Visualización
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Maneja con que estado quieres ver la campana asociada a esta
                  tarea.</span
                >
              </v-tooltip>
            </p>
            <div>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="success"
                :outlined="
                  $options.filters.seenByMeColor(currentRDI.seen_by_me) !== 'success'
                "
                :loading="isLoadingChangeVisualizationStatus(currentRDI.id)"
                @click.stop="changeVisualizationStatus(1)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="warning"
                :outlined="
                  $options.filters.seenByMeColor(currentRDI.seen_by_me) !== 'warning'
                "
                :loading="isLoadingChangeVisualizationStatus(currentRDI.id)"
                @click.stop="changeVisualizationStatus(2)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="high"
                :outlined="
                  $options.filters.seenByMeColor(currentRDI.seen_by_me) !== 'high'
                "
                :loading="isLoadingChangeVisualizationStatus(currentRDI.id)"
                @click.stop="changeVisualizationStatus(0)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Suscribir a RDI
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Si te suscribes a una RDI recibirás notificaciones en tu correo cuando
                  ocurran cambios importantes: Prioridad, Interacciones, Fechas, Cierre.
                </span>
              </v-tooltip>
            </p>
            <div>
              <v-switch
                class="my-0"
                dense
                v-model="editRdiForm.subscribe_status"
                @change="changeInSubscription"
                hide-details
              >
                <template slot="label">
                  <p class="mb-0 caption">
                    {{
                      editRdiForm.subscribe_status
                        ? "Recibirás notificaciones"
                        : "No recibirás notificaciones"
                    }}
                  </p>
                </template>
              </v-switch>
              <v-switch
                class="my-0"
                dense
                v-model="editRdiForm.subscribe_status_mail"
                :disabled="!editRdiForm.subscribe_status"
                hide-details
              >
                <template slot="label">
                  <p class="mb-0 caption">
                    {{
                      editRdiForm.subscribe_status_mail
                        ? "Recibirás correos de esta RDI"
                        : "No recibirás correos de esta RDI"
                    }}
                  </p>
                </template>
              </v-switch>
            </div>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Recordatorio
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Selecciona uno o más días de la semana en los que quieras que te
                  recordemos esta tarea. Aparecerá un recordatorio al ingresar a tu panel
                  LaOT.</span
                >
              </v-tooltip>
            </p>
            <div>
              <v-menu
                v-model="datePickerRemider"
                offset-y
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark small block color="#515d78" v-bind="attrs" v-on="on">
                    <v-icon dark small>mdi-clipboard-edit-outline</v-icon>
                    <p class="mb-0 ml-2">Seleccionar Días</p>
                  </v-btn>
                </template>
                <div class="grey lighten-4 pa-2">
                  <v-btn
                    v-for="(day, index) in weekDays"
                    :key="index"
                    dark
                    x-small
                    color="#515d78"
                    :outlined="!isWeekDaySelected(index)"
                    class="mx-1"
                    @click="addWeekDay(index)"
                  >
                    <p class="mb-0">{{ day }}</p>
                  </v-btn>
                  <v-switch
                    class="my-1"
                    dense
                    v-model="editRdiForm.reminder_repeatable"
                    :disabled="this.editRdiForm.reminder.length === 0"
                    hide-details
                  >
                    <template slot="label">
                      <p class="mb-0 caption">Recordar Semanalmente</p>
                    </template>
                  </v-switch>
                  <p class="mb-1 caption text--secondary">
                    Recuerda confirmar para que este recordatorio tome efecto
                  </p>
                </div>
              </v-menu>
            </div>
          </div>
          <v-divider class="my-2"></v-divider>
          <v-btn
            class="mt-2"
            color="#515d78"
            raised
            small
            block
            dark
            :loading="loadingEdit"
            @click="() => modifyRdi()"
          >
            <p class="mb-0 white--text">Confirmar</p>
          </v-btn>
          <v-btn
            v-if="isMyRdi()"
            class="mt-2"
            color="#F4516C"
            outlined
            x-small
            block
            dark
            @click="() => startDeleteRdi()"
          >
            <p class="mb-0">Eliminar</p>
          </v-btn>
        </div>
      </div>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          color="#f5f5f5"
          class="item-task"
          v-bind="attrs"
          v-on="on"
          @click="sendByEmailModal = true"
        >
        <span class="material-symbols-outlined"> forward_to_inbox </span>
        </v-btn>
      </template>
      <span>Enviar por correo</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          color="#f5f5f5"
          class="item-task"
          v-bind="attrs"
          v-on="on"
          :loading="loadingPDFDownload"
          @click="downloadPDF"
        >
        <span class="material-symbols-outlined"> picture_as_pdf </span>
        </v-btn>
      </template>
      <span>Descargar en PDF</span>
    </v-tooltip>
    <AcceptCancelModal
      :acceptRejectModal="acceptRejectModal"
      :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"
    />
    <SendContentByEmailModal
      :sendContentByEmailModal="sendByEmailModal"
      :closeModal="() => (sendByEmailModal = false)"
      :sendingType="2"
    />
  </div>
</template>

<script>
import {
  updateRdi,
  downloadPDF,
  deleteRdi,
  changeVisualizationStatus,
} from "@/helpers/api/rdi";
import SendContentByEmailModal from "@/components/Globals/Modals/General/SendContentByEmailModal";
import AcceptCancelModal from "@/components/Globals/Modals/General/AcceptCancelModal";

export default {
  name: "InteractionButtonsRdi",
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  components: { SendContentByEmailModal, AcceptCancelModal },
  computed: {
    currentRDI() {
      return this.$store.rdiStore.state.currentRDI;
    },
  },
  watch: {
    currentRDI() {
      this.setFormData();
    },
  },
  data: () => ({
    loadingEdit: false,
    datePicker: false,
    datePickerRemider: false,
    sendByEmailModal: false,
    loadingPDFDownload: false,
    acceptRejectModalData: null,
    acceptRejectModal: false,
    editRdiForm: {
      priority: null,
      critical_date: null,
      subscribe_status: false,
      subscribe_status_mail: false,
      reminder: [],
      reminder_repeatable: false,
    },
    weekDays: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
  }),
  mounted() {
    this.setFormData();
  },
  methods: {
    setFormData() {
      const rdi = this.$store.rdiStore.state.currentRDI;
      if (rdi && !rdi.loading && rdi.id) {
        const rdi = this.$store.rdiStore.state.currentRDI;
        this.editRdiForm.subscribe_status = !!rdi.user_subscribed;
        this.editRdiForm.subscribe_status_mail =
          rdi.user_subscribed && rdi.user_subscribed.email;
        this.editRdiForm.reminder = rdi.user_reminders.map((day) => day.day_number);
        this.editRdiForm.reminder_repeatable = rdi.user_reminders.some(
          (day) => day.repeatable
        );
      }
    },
    isMyRdi() {
      return (
        this.$store.userStore.state.userInfo.id ===
        this.$store.rdiStore.state.currentRDI.created_by_user.id
      );
    },
    iAmTaskResponsible() {
      return (
        this.$store.consultasStore.state.currentTarea.responsable ==
        this.$store.userStore.state.userInfo.id
      );
    },
    changeInSubscription(value) {
      this.editRdiForm.subscribe_status_mail = value;
    },
    async modifyRdi() {
      this.loadingEdit = true;
      this.editRdiForm.id = this.$store.rdiStore.state.currentRDI.id;
      const rdiUpdate = await updateRdi(this.editRdiForm);
      if (rdiUpdate.code == 200) {
        this.$store.rdiStore.commit("setCurrentRDI", rdiUpdate.rdi);
        this.getRDIList();
        const data = {
          message: "Se ha modificado correctamente la RDI",
          title: "RDI modificada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
      this.loadingEdit = false;
    },
    async getRDIList() {
      let project_id = null;
      if (this.$store.projectsStore.state.selectedProject) {
        project_id = this.$store.projectsStore.state.selectedProject.id;
      } else {
        const id = localStorage.getItem("selectedProjectID");
        project_id = parseInt(id);
      }
      if (project_id) {
        this.$store.rdiStore.dispatch("getRDI", { project_id });
      }
    },
    minimizeRdi() {
      const data = {
        task_id: this.$store.rdiStore.state.currentRDI.id,
        title: this.$store.rdiStore.state.currentRDI.title,
        type: "RDI",
        type_id: 2,
        number: this.$store.rdiStore.state.currentRDI.number,
      };
      this.$store.globalStore.commit("addTaskToTasksHandler", data);
      this.closeModal();
    },
    async downloadPDF() {
      this.loadingPDFDownload = true;
      const pdf_url = await downloadPDF({
        rdi_id: this.$store.rdiStore.state.currentRDI.id,
      });
      if (pdf_url.code == 200) {
        window.open(pdf_url.pdf_url);
      }
      this.loadingPDFDownload = false;
    },
    startDeleteRdi() {
      this.acceptRejectModalData = {
        title:
          "¿Eliminar la RDI Nº " + this.$store.rdiStore.state.currentRDI.number + "?",
        content_a: "Por favor confirma si deseas elliminar la RDI indicada.",
      };
      this.acceptRejectModal = true;
    },
    async deleteRdi() {
      const task = await deleteRdi({ rdi_id: this.$store.rdiStore.state.currentRDI.id });
      if (task.code === 200) {
        this.getRDIList();
        this.$store.rdiStore.commit("setCurrentRDI", null);
        const data = {
          message: "Se ha eliminado correctamente la RDI",
          title: "RDI eliminada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
    },
    closeModalAcceptCancel(status = false) {
      this.acceptRejectModal = false;
      if (status) {
        this.deleteRdi();
      }
      this.acceptRejectModalData = null;
    },
    addWeekDay(index) {
      const found = this.editRdiForm.reminder.findIndex(
        (itemReminder) => itemReminder === index
      );
      if (found !== -1) {
        this.editRdiForm.reminder.splice(found, 1);
      } else {
        this.editRdiForm.reminder.push(index);
      }
      if (this.editRdiForm.reminder.length === 0) {
        this.editRdiForm.reminder_repeatable = false;
      }
    },
    isWeekDaySelected(index) {
      return this.editRdiForm.reminder.includes(index);
    },
    async changeVisualizationStatus(newStatus) {
      let rdi = this.$store.rdiStore.state.currentRDI;
      if (!this.isLoadingChangeVisualizationStatus(rdi.id)) {
        this.$store.rdiStore.commit("addLoadingChangeVisualizationStatus", rdi.id);
        const data = {
          rdi_id: rdi.id,
          status: newStatus,
        };
        const statusUpdate = await changeVisualizationStatus(data);
        if (statusUpdate.code === 200) {
          // Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
          let newTaskData = Object.assign({}, rdi);
          newTaskData.seen_by_me.seen_status = newStatus;
          this.$store.rdiStore.dispatch("updateRdiLocally", newTaskData);
        }
        this.$store.rdiStore.commit("removeLoadingChangeVisualizationStatus", rdi.id);
      }
    },
    isLoadingChangeVisualizationStatus(rdiID) {
      const found = this.$store.rdiStore.state.loadingChangeVisualizationStatus.find(
        (rdi_id) => rdi_id === rdiID
      );
      return !!found;
    },
  },
};
</script>

<style scoped>
.bg-light-blue-text {
  background-color: #515d78;
}
.icon-top {
  padding: 2px;
  margin: 0 2px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.icon-top.high {
  background-color: #f4516c;
}
.icon-top.medium {
  background-color: #fb8c00;
}
.icon-top.normal {
  background-color: #3ebfa3;
}
.icon-top.primary {
  background-color: #515d78;
}
.icon-top.light-blue {
  background-color: #515d78;
}
.high--text {
  color: #f4516c !important;
  caret-color: #f4516c !important;
}
.high {
  background-color: #f4516c !important;
  border-color: #f4516c !important;
  color: white;
}
.item-task {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 5px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item-task:hover {
  background-color: #e5e9f2 !important;
}
/* icons */
.material-symbols-outlined {
  color: #54607a;
}
</style>
