<template>
	<div class="text-center" v-if="sendContentByEmailModal">
		<v-dialog
			v-model="sendContentByEmailModal"
			max-width="500"
			scrollable
			persistent
		>
			<v-card>
				<v-card-text class="pa-0">
					<div class="py-4 px-8">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row">
								<p class="mb-0 font-weight-bold text-primary title">Enviar por correo</p>
							</div>
						</div>
						<v-row align="center" class="my-2">
							<v-col cols="12">
								<p class="mb-2 text-primary">Selecciona a los usuarios a los que se enviará la {{this.sendingType == 1 ? 'Tarea/Restricción':'RDI'}}</p>
								<v-combobox
									v-model="copy_to"
									:loading="$store.usersStore.state.loadingUserListForItems"
									:items="$store.usersStore.state.usersListForItems.users"
									item-text="name"
									item-value="id"
									single-line
									outlined
									dense
									required
									multiple
									label="Enviar copia por correo"
									hint="Se enviará una copia en PDF de la RDI a los usuarios que indiques aquí. Puedes ingresar un correo que no esté en el listado, escríbelo y presiona enter."
									persistent-hint
									return-object
									taggable
								>
									<template v-slot:item="data">
										<v-list-item-avatar>
											<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
											<v-icon v-else>mdi-account</v-icon>
										</v-list-item-avatar>
										<v-list-item-content>
											<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
											<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
										</v-list-item-content>
									</template>
								</v-combobox>

								<v-textarea
									v-model="message"
									solo
									rows="2"
									label="Mensaje (Opcional)"
									class="mt-3 mb-5"
									hide-details=""
								></v-textarea>
								<v-checkbox
									v-model="attachFiles"
									:label="'Adjuntar archivos de la ' + (sendingType == 1 ? 'Tarea/Restricción':'RDI')"
									hint="Los adjuntos estarán disponibles por 7 días para su descarga"
									persistent-hint
									dense
								></v-checkbox>
							</v-col>
						</v-row>
						<v-row align="center">
							<v-col cols="12">
								<div class="d-flex flex-row justify-space-between">
									<v-btn
										class="mx-2 my-1"
										rounded
										color="white"
										raised
										:loading="loading"
										@click="closeModalSelf()"
									>
										<p class="mb-0 text-primary">Cancelar</p>
									</v-btn>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#515d78"
										raised
										dark
										:loading="loading"
										@click="sendByMail()"
									>
										<p class="mb-0">Aceptar</p>
									</v-btn>
								</div>
							</v-col>
						</v-row>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {sendRDIMail} from  '@/helpers/api/rdi'
import {sendTaskMail} from  '@/helpers/api/tasks'

export default {
	name: 'SendContentByEmailModal',
	props: {
		closeModal: {
			type: Function,
		},
		sendContentByEmailModal: {
			type: Boolean,
			default: false
		},
		sendingType: {
			type: Number,
			default: 1 //1: Tarea , 2: RDI
		}
	},
	watch: {
		sendContentByEmailModal(newData) {
			if(newData){
				if(this.sendingType === 1){ this.projectID = this.$store.consultasStore.state.currentTarea.project_id }
				else if(this.sendingType === 2){ this.projectID = this.$store.rdiStore.state.currentRDI.project_id }
				this.checkUsers()
			}
		}
	},
	data: () => ({
		loading: false,
		copy_to: null,
		attachFiles: false,
		message: '',
		projectID: null
	}),
	mounted(){
	},
	methods: {
		checkUsers(){
			if(!this.$store.usersStore.state.loadingUserListForItems &&
					this.$store.usersStore.state.usersListForItems.project_id !== this.projectID
				){
				this.getUserListforItems()
			}
		},
		getUserListforItems(){
			const data = {
				project_id: this.projectID,
				start: 0,
				limit: 9999
			}
			this.$store.usersStore.dispatch('getUsersListForItems', data)
		},
		closeModalSelf(){
			this.copy_to = null
			this.closeModal()
		},
		async sendByMail(){
			this.loading = true
			const emails = this.copy_to.map((email) => {
				let emailData = null
				if(email.email){
					emailData = {email: email.email, name: email.name}
				}
				else{
					emailData = {email, name: ''}
				}
				return emailData
			})

			let data = {}
			let email = null
			if(this.sendingType == 1){
				data = {
					emails: JSON.stringify(emails),
					task_id: this.$store.consultasStore.state.currentTarea.id,
					attach_files: this.attachFiles,
					message: this.message
				}
				email = await sendTaskMail(data)
			}
			else{
				data = {
					emails: JSON.stringify(emails),
					rdi_id: this.$store.rdiStore.state.currentRDI.id,
					attach_files: this.attachFiles,
					message: this.message
				}
				email = await sendRDIMail(data)
			}
			
			if(email && email.code == 200){
				const type = this.sendingType == 1 ? 'Tarea/Restricción':'RDI'
				const data = {
					title: type + ' Enviada',
					message: 'Se ha enviado la ' + type + ' por correo a los usuarios ingresados',
					created: new Date(),
					type: 'success',
					icon: 'mdi-check-circle',
					color: '#3EBFA3'
				}
				this.$store.globalStore.dispatch('addMessage', data)
				this.closeModalSelf();
			}
			this.loading = false
		}
	}
};
</script>

<style scoped>
	.light-blue-text{color: #515d78;}
</style>