<template>
	<v-container pa-0 my-2 fill-height>
		<v-card class="fill-height pa-0 project-card">
			<v-card-text class="pa-0">
				<v-row class="img-title" style="overflow: hidden">
					<v-col cols="12" sm="12" v-if="info.project_image != ''">
						<img class="img-info-project" @error="handleImageError" :src="info.project_image"
							style="border-top-left-radius: 4px; border-top-right-radius: 4px">
					</v-col>
				</v-row>
				
				<v-row class="project-detail-card" v-if="info">

					<v-col cols="12" sm="12">
						<v-row class="info-title d-flex" style="padding-top: 15px; padding-left: 15px">
							<v-col class="laot-circle-blue" cols="1" sm="1" xs="12">

							</v-col>
							<v-col cols="11" sm="11" xs="12" style="margin-left: -10px">
								<div class="text-proyect">
									<span>Abrir BIM <v-icon>mdi-map</v-icon></span>
									<p class="mb-0 font-weight-bold" style="font-size: 1em">
										{{ info.name }}
									</p>
									<p class="font-weight-regular" style="font-size: 0.8em; color: #606060">
										Empresa LaOT
									</p>
								</div>
							</v-col>
						</v-row>

					</v-col>
				</v-row>
				<v-row class="advanced-circle">
					<span class="chart-span">Tarea:</span>

					<span style="height: 45px; width: 45px;" v-for="(graph, index) in circleGraphs" :key="index">

						<span>
							<DoughnutChart :chartdata="{
						datasets: [
							{
								backgroundColor: [graph.color, '#D9D9D9'],
								data: [graph.value, 100 - graph.value],
							}
						]
					}" :centerText="graph.center_text" :options="{
						tooltips: { enabled: false },
						cutoutPercentage: 75,
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							center: {
								text: graph.center_text
							}
						}
					}" />
						</span>
					</span>

				</v-row>

				<v-row class="advanced-circle">
					<span class="chart-span">Rdi:</span>

					<span style="height: 45px; width: 45px;" v-for="(graph, index) in circleGraphsRDI" :key="index">

						<span>
							<DoughnutChart :chartdata="{
						datasets: [
							{
								backgroundColor: [graph.color, '#D9D9D9'],
								data: [graph.value, 100 - graph.value],
							}
						]
					}" :centerText="graph.center_text" :options="{
						tooltips: { enabled: false },
						cutoutPercentage: 75,
						responsive: true,
						maintainAspectRatio: true,
						elements: {
							center: {
								text: graph.center_text
							}
						}
					}" />
						</span>
					</span>

				</v-row>
<!-- 
				<v-row style="display: flex; margin-top: -30px; padding-left: 15px">

					<v-col cols="2" class="px-0">
						<DoughnutChart
							:chartdata="{
								datasets: [
									{
									backgroundColor: ['#EAB261', '#D9D9D9'],
									data: [75, 25],
									},
								],
							}"
							:centerText="'50'"
							:options="{
							tooltips: { enabled: false },
							cutoutPercentage: 75,
							responsive: true,
							maintainAspectRatio: true,
							elements: {
								center: {
								text: '75',
								},
							},
							}"
						/>
					</v-col>

					<v-col cols="2" class="px-0">
						<DoughnutChart
							:chartdata="{
							datasets: [
								{
								backgroundColor: ['#34BFA3', '#D9D9D9'],
								data: [50, 50],
								},
							],
							}"
							:centerText="'50%'"
							:options="{
							tooltips: { enabled: false },
							cutoutPercentage: 75,
							responsive: true,
							maintainAspectRatio: true,
							elements: {
								center: {
								text: '50',
								},
							},
							}"
						/>
					</v-col>

					<v-col cols="2" class="px-0">
						<DoughnutChart
							:chartdata="{
							datasets: [
								{
								backgroundColor: ['#F45771', '#D9D9D9'],
								data: [25, 75],
								},
							],
							}"
							:centerText="'25%'"
							:options="{
							tooltips: { enabled: false },
							cutoutPercentage: 75,
							responsive: true,
							maintainAspectRatio: true,
							elements: {
								center: {
								text: '25',
								},
							},
							}"
						/>
					</v-col>
				</v-row> -->
			</v-card-text>
		</v-card>
	</v-container>
</template>

<script>
import DoughnutChart from "../../Charts/Doughnut";
import { getDashboard, getDashboardRDI } from '@/helpers/api/dashboard'
// import LineChart from '../../Charts/Line'
export default {
	name: "ProjectListItemHome",
	components: {
		DoughnutChart,
		// LineChart
	},
	// props: ["info", "projectIndex"],
	props: {
		info: {
			type: Object,
			default: null
		},
		projectIndex: {
			type: Number,
			default: null
		},
	},
	data() {
		return {
			circleGraphs: [],
			circleGraphsRDI: []
		};
	},
	async created() {
		// console.log('created info', this.info)
		try {
			await this.getDashboardTasks(this.info.id);
			await this.getDashboardRDI(this.info.id);

			// this.circleGraphs = response.circle_graphs;
		} catch (error) {
			console.error('Error loading dashboard tasks:', error);
		}
	},
	mounted() { },
	methods: {
		handleImageError(event) {
			// Se ejecuta cuando la imagen no se puede cargar
			console.log('No se pudo cargar la imagen');
			// Establecer la URL de la imagen por defecto
			event.target.src = 'https://storage.googleapis.com/bucket-db-favric/ms_bim/bg-default-bin.png';
		},
		changeProject() {
			this.$store.projectsStore.commit("setSelectedProject", this.info);
			this.getConsultasList(this.info.id);
			this.getDashboardInfo();
			window.scrollTo({
				top: 0,
				left: 0,
				behavior: "smooth",
			});
		},
		async getConsultasList(project_id) {
			if (project_id) {
				this.$store.consultasStore.dispatch("getMyTasks", { project_id });
			}
		},
		getDashboardInfo() {
			this.$store.projectsStore.dispatch("getDashboardInfo");
		},
		async getDashboardTasks(project_id) {
			// console.log('getDashboardTasks', project_id)
			const dashboard = await getDashboard({ project_id })
			if (dashboard.code === 200) {
				let chart = {
					title: 'Consultas y Restricciones',
					semaforo_consultas: dashboard.semaforo_consultas,
					total_title: 'Total Consultas',
					circle_graphs: [
						{
							value: dashboard.total_tasks_percent,
							title: 'Descripción',
							color: '#EAB261',
							center_text: dashboard.total_tasks.toString()
						},
						{
							value: dashboard.ended_tasks_percent,
							title: 'Descripción',
							color: '#34BFA3',
							center_text: dashboard.ended_tasks.toString()
						},
						{
							value: dashboard.pending_tasks_percent,
							title: 'Descripción',
							color: '#F45771',
							center_text: dashboard.pending_tasks.toString()
						}
					],
					speed_solution: { value: dashboard.solution_speed, suffix: 's/d' },
					general_status: 'success',
					adicionales: {
						title: 'Promedio',
						total_title: 'Adicionales',
						speed_solution: { value: dashboard.waiting_time, suffix: 'dias' },
						speed_solution_last: { value: dashboard.waiting_time_last, suffix: 'dias' },
						general_status: 'warning',
						graphs: {
							created_by_week: dashboard.created_by_week,
							solved_by_week: dashboard.solved_by_week
						},
					}
				}

				this.circleGraphs = chart.circle_graphs;
				// console.log('chart', this.circleGraphs)
			}
		},
		async getDashboardRDI(project_id) {
			// console.log('getDashboardTasks', project_id)
			const dashboard = await getDashboardRDI({ project_id })
			// console.log('dash rdi', dashboard)
			// console.log('dash rdi 2', this.$store.projectsStore.state.selectedRdiInfo)
			if (dashboard.code === 200) {
				let chart = {
					title: 'RDI',
					semaforo_rdis: dashboard.semaforo_rdis,
					total_title: 'Total RDI',
					circle_graphs: [
						{
							value: dashboard.total_rdis_percent,
							title: 'Descripción',
							color: '#EAB261',
							center_text: dashboard.total_rdis.toString()
						},
						{
							value: dashboard.ended_rdis_percent,
							title: 'Descripción',
							color: '#34BFA3',
							center_text: dashboard.ended_rdis.toString()
						},
						{
							value: dashboard.pending_rdis_percent,
							title: 'Descripción',
							color: '#F45771',
							center_text: dashboard.pending_rdis.toString()
						}
					],
					speed_solution: { value: dashboard.solution_speed, suffix: 's/d' },
					general_status: 'success',
					adicionales: {
						title: 'Promedio',
						total_title: 'Adicionales',
						speed_solution: { value: dashboard.waiting_time, suffix: 'dias' },
						speed_solution_last: { value: dashboard.waiting_time_last, suffix: 'dias' },
						general_status: 'warning',
						graphs: {
							created_by_week: dashboard.created_by_week,
							solved_by_week: dashboard.solved_by_week
						},
					}
				}

				this.circleGraphsRDI = chart.circle_graphs;
				// console.log('chart rdi', this.circleGraphsRDI)
			}
		},
		startDeleteProject() {
			this.$store.projectsStore.commit("setCloseOpenProjectModal", {
				status: true,
				info: this.info,
			});
		},
	},
};
</script>

<style scoped>
.project-detail-card,
.project-detail-card p {
	color: #515d78;
}

.info-icon {
	border-radius: 100%;
	background: #515d78;
}

.icon-project-select {
	position: absolute;
	bottom: 5px;
	right: 5px;
}

.project-card {
	height: 350px;
	margin: 10px;
	background-color: #f8f8f8;
	border-radius: 4px !important;
	border-left: 5px #515d78 solid !important;
	width: 100%;
}

.img-info-project {
	width: 100%;
	height: 150px;
	overflow: hidden;
	object-fit: cover;
}

.img-circle {
	width: 40% !important;
	max-width: 45px !important;
	height: auto;
}

.advanced-circle {
	display: flex;
	height: 40px;
	margin: 0 !important;
	justify-content: space-evenly;
	align-items: center;

	.chart-span {
		width: 50px;
	}
}

.laot-circle-blue {
	display: flex;
	justify-content: center;
}

.text-proyect{
	margin-top: -20px;
	margin-bottom: -10px;
	margin-left: -10px;
}

@media (max-width: 600px) {
	.text-proyect {
		font-size: 16px;
		margin-left: 8px;
	}

	.project-card {
		height: 350px !important;
	}

	.img-circle {
		width: 60% !important;
		max-width: 50px !important;
	}
}

@media (min-width: 600px) and (max-width: 768px) {
	.project-card {
		height: 340px;
		width: 100%;
		margin: 10px;
	}

	.img-circle {
		width: 30% !important;
		max-width: 40px !important;
	}
}
</style>
