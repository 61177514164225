<template>
	<v-container fluid pa-0>
		<v-text-field outlined clearable dense rounded hide-details label="Buscar" type="text" class="search-input"
			v-model="searchInput" @keydown="sendToSearch">
			<template v-slot:prepend-inner>
				<v-btn class="search-button" fab  @click="sendToSearch">
					<v-icon dark>mdi-magnify</v-icon>
				</v-btn>
			</template>
			<template v-slot:label>
				<p class="mb-0 ml-5">Buscar</p>
			</template>
		</v-text-field>
		<div class="search-container" :class="$vuetify.breakpoint.mobile ? 'mobile' : ''">
			<SearchBlock v-if="showResults" :searching="searching" :searchResult="searchResult"
				:closeResults="closeResults" />
		</div>
	</v-container>
</template>

<script>

import { search } from '@/helpers/api/general'
import SearchBlock from './SearchBlock'

export default {
	name: 'SearchBarHeader',
	components: {
		SearchBlock
	},
	data: () => ({
		searchInput: '',
		showResults: false,
		searching: false,
		searchResult: []
	}),
	mounted() {
	},
	methods: {
		closeResults() {
			this.searchInput = ''
			this.showResults = false
			this.searching = false
			this.searchResult = []
		},
		sendToSearch(event) {
			if (event instanceof KeyboardEvent && event.keyCode == 13) {
				this.searchForString(this.searchInput)
			}
			else if (event instanceof PointerEvent) {
				this.searchForString(this.searchInput)
			}
		},
		async searchForString(searchString) {
			this.searching = true
			this.showResults = true
			const data = {
				search: searchString,
				project_id: this.$store.projectsStore.state.selectedProject.id
			}
			const searchResult = await search(data)
			if (searchResult.code == 200) {
				this.searchResult = searchResult
			}
			this.searching = false
		}
	}
};
</script>

<style scoped>
.search-input {
	position: relative;
	background-color: white;
	/* border: 2px solid rgb(180, 180, 180, 0.5);
	*{
		border: none;
	} */
	/* .v-input__control {
		border: 2px solid rgb(180, 180, 180, 0.5);
	} */
	fieldset{
		border: 2px solid rgb(180, 180, 180, 0.5);
	}
}

.v-btn--is-elevated.v-btn--fab {
    box-shadow: none;
	width: 30px;
	height: 30px;
	background-color: white;
	margin: 6px;
	color: #B5B5C3;
}



.v-text-field--rounded {
	border-radius: 12px;
}



/deep/ .search-input input {
	margin-left: 20px;
}

.search-button {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 2;
}

.search-container {
	position: absolute;
	width: 400px;
	z-index: 999;
}

.search-container.mobile {
	left: 0;
	padding: 0 5px;
	max-width: 100%;
}
</style>
