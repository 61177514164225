<template>
    <v-menu offset-y>
      <template v-slot:activator="{ on: onMenu }">
        <!-- <v-btn color="primary" > Slide Y Transition </v-btn>  -->
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn v-if="userObj" class="config-btn" :style="getColorStyles(userObj)" fab small v-on="{ ...onMenu, ...onTooltip }">
              <img :src="userObj.info.avatar" class="user-avatar"
                :class="$store.menuStore.state.sidebarMini ? 'mini' : ''" v-if="userObj.info.avatar" />
              <!-- <img v-else src="../../../assets/Svg/user-icon-default.svg" alt="Avatar" /> -->
              <div class="bg-default" v-else>{{ getInitials(userObj.name) }}</div>

            </v-btn>
          </template>
          <span>{{ userObj.email }}</span>
        </v-tooltip>

      </template>
      <v-list dense class="py-0">
        <v-list-item v-for="(item, i) in items" :key="i" @click="handleUserOptionClick(item.action)">
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
</template>

<script>
export default {
  name: 'AvatarUser',
  props: {
    user: {
      default: false //../../assets/Svg/user-icon-default.svg Valor predeterminado si initial_state no se proporciona
    }
  },
  data() {
    return {
      userObj: this.user,
      items: [
        { title: 'Mi Perfil', action: 'profile' },
        { title: 'Cerrar Sesión', action: 'logout' }
      ],
      backgroundColors: ['#F29D41', '#39855E', '#656F84', '#5046A4', '#46A1BC', '#F29D41', '#39855E', '#656F84', '#5046A4', '#46A1BC'],
      textColors: ['#111111', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#111111', '#111111', '#FFFFFF', '#FFFFFF', '#FFFFFF', '#111111']
    };
  },
  watch: {
    user(newVal) {
      console.log('newVal', newVal)
      if (newVal) {
        this.userObj = newVal;
      }
    }
  },
  mounted() {
    this.userObj = this.$store.userStore.state.userInfo;
  },
  methods: {
    handleUserOptionClick(action) {
      console.log('action', action)
      if (action == 'profile') {
        this.editUser(this.userObj);
      }
      else if (action == 'logout') {
        this.logout();
      }
    },
    editUser(user) {
      let user_item = this.$store.usersStore.state.usersList.find(obj => obj.id === user.id);
      this.$store.usersStore.commit('setSelectedUserInfo', user_item)
      this.$store.usersStore.commit('setEditUserModal', true)
    },
    getInitials(name) {
      // Separar el nombre completo en palabras
      const words = name.split(' ');

      // Tomar las iniciales de las primeras dos palabras
      let initials = '';
      for (let i = 0; i < 2 && i < words.length; i++) {
        initials += words[i].charAt(0).toUpperCase();
      }

      return initials;
    },
    getColorStyles(user) {

      if(user.info.avatar){
        return '';
      }

      const number = user.id;
      const index = number % 10; // Último dígito del número
      const backgroundColor = this.backgroundColors[index];
      const textColor = this.textColors[index];
      return {
        'background-color': backgroundColor,
        'color': textColor
      };
    },
    logout() {
      this.$store.userStore.commit("logoutUser");
      this.$router.push({ name: "Login" });
      // window.location.reload();
    }
  }
};
</script>

<style scoped>
.config-btn {
  background-color: #DDDDDD;
  width: auto;
  height: 100%;
  min-width: 30px;
  min-height: 30px;
  max-width: 40px;
  max-height: 40px;
  color: #fcfcfc;
  /* margin: auto; */

  img {
    /* width: 100%;
    height: 100%; */
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.bg-default {
  /* position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex: 1 1 100%;
  background-color: #525d78; */
}

.v-btn--is-elevated {
    box-shadow: none;
}
</style>
