import {endpoints} from './endpoints'
import request from '../request'

export function tetDataenc(data) {
	return new Promise((resolve, reject) => {
		request({
			url: '/auth/setJobtype',
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function create(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.createProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}
export function update(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectByID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectByID,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectTypes(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectTypes,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectsByUser() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectsByUser,
			method: 'post'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getClosedProjectsByUser() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getClosedProjectsByUser,
			method: 'post'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectsByUserID(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectsByUserID,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function searchUsersByEmail(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.searchUsersByEmail,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getProjectSubjects(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getProjectSubjects,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function updateProjectBoard(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.updateProjectBoard,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function closeProject(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.closeProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function openProject(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.openProject,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}