<template>
	<v-container fluid class="px-0" id="listado-consultas">

		<v-card class="mb-2 py-1">
			<v-card-text class="header-table-card py-2">
				<v-row>
					<v-col cols="2">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;" @click="toggleOrder">
							<v-icon color="#515d78" small class="mr-1">{{getIconOrder()}}</v-icon>
							<p class="mb-0 font-weight-bold body-2 text-capitalize">
								{{getNameOrder()}}</p>
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-menu v-model="creatorMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Creador</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="creatorFilter">
									<v-list-item
										style="min-height: auto;"
										v-for="(user, index) in $store.usersStore.state.usersList"
										:key="index"
										:value="user.id">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{user.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2" @click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
					<v-col cols="1">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize mr-1">Estado</p>
							<v-tooltip bottom v-if="$store.projectsStore.state.selectedConsultasInfo">
								<template v-slot:activator="{ on, attrs }">
									<v-icon :color="$options.filters.getColorStatusModulo($store.projectsStore.state.selectedConsultasInfo.semaforo_consultas)" small
										class="status-icon"
										v-bind="attrs"
										v-on="on">mdi-circle</v-icon>
								</template>
								<p class="caption mb-0">Estado general del módulo: <span class="font-weight-bold">{{$options.filters.getTextStatusModulo($store.projectsStore.state.selectedConsultasInfo.semaforo_consultas)}}</span></p>
							</v-tooltip>
						</v-btn>
					</v-col>
					<v-col cols="3">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Tarea</p>
							<!-- <v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon> -->
						</v-btn>
					</v-col>
					<v-col cols="2">
						<v-menu v-model="responsableMenu" :offset-y="true" :close-on-content-click="false">
							<template v-slot:activator="{ on, attrs }">
								<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" block style="height: auto;"
									v-bind="attrs"
									v-on="on">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Responsable</p>
									<v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon>
								</v-btn>
							</template>

							<v-list dense class="filter-selector-list">
								<v-list-item dense style="min-height: auto;">
									<div style="width: 100%" class="mb-2">
										<p class="mb-0 font-weight-bold caption text-primary-color text-capitalize">Filtrar Por:</p>
										<v-divider></v-divider>
									</div>
								</v-list-item>
								<v-list-item-group multiple v-model="responsableFilter">
									<v-list-item
										style="min-height: auto;"
										v-for="(user, index) in $store.usersStore.state.usersList"
										:key="index"
										:value="user.id">
										<template v-slot:default="{ active }">
											<v-list-item-title class="my-0 d-flex flex-row align-center">
												<v-checkbox
													dense
													hide-details
													class="caption my-0 py-0"
													:input-value="active"></v-checkbox>
												<p class="mb-0 caption">{{user.name}}</p>
											</v-list-item-title>
										</template>
									</v-list-item>
								</v-list-item-group>
								<v-list-item dense style="min-height: auto;">
									<v-btn x-small block color="#515d78" dark class="mt-2" @click="filterList()">
										<v-icon color="white" small class="mr-1">mdi-filter-outline</v-icon>
										<p class="mb-0 caption text-capitalize">Aplicar</p>
									</v-btn>
								</v-list-item>
							</v-list>
						</v-menu>
					</v-col>
					<v-col cols="1">
						<v-btn text class="table-header-button d-flex flex-row pa-0 justify-start" style="height: auto;">
							<p class="mb-0 font-weight-bold body-2 text-capitalize">Estado</p>
							<!-- <v-icon color="#515d78" small class="ml-1">mdi-plus-circle</v-icon> -->
						</v-btn>
					</v-col>
					<v-col cols="1" class="d-flex flex-row justify-end align-center py-0 icon-container">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div class="icon-top total"
									v-bind="attrs"
									v-on="on">
									<span class="white--text caption">{{$store.adicionalesStore.state.listadoPosibles.total_additional}}</span>
								</div>
							</template>
							<span>Total de tareas</span>
						</v-tooltip>
					</v-col>
				</v-row>
			</v-card-text>
			<v-progress-linear
				v-if="$store.adicionalesStore.state.listadoPosibles.status === 99"
				class="listing-loading-bar"
				indeterminate
				color="#515d78"
			></v-progress-linear>
		</v-card>
		<div v-if="$store.adicionalesStore.state.listadoPosibles.status === 1 || $store.adicionalesStore.state.listadoPosibles.count > 0" class="listing-scroll">
			<v-card class="tarea-card mb-2" v-for="(tarea, index) in $store.adicionalesStore.state.listadoPosibles.data" :key="index"
			style="overflow: hidden;"
			@click.stop="viewTarea(tarea.id, $options.filters.seenByMe(tarea.seen_by_me))">
				<v-tooltip bottom
							v-if="tarea.private">
					<template v-slot:activator="{ on, attrs }">
						<div class="incognito-container"
							v-bind="attrs"
							v-on="on">
							<v-icon color="white" small>mdi-incognito</v-icon>
						</div>
					</template>
					<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
				</v-tooltip>
				<v-card-text class="header-table-card py-2"
					:class="tarea.status === 3 ? 'status-ended':''">
					<v-row align="center">
						<v-col cols="2">
							<div class="d-flex flex-column">
								<div v-if="tarea.status === 3">
									<v-chip
										class="mb-1"
										color="#34BFA3"
										text-color="white"
										x-small
									>
										Tarea Finalizada
									</v-chip>
								</div>
								<div class="d-flex flex-row icon-container">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top number"
												v-bind="attrs"
												v-on="on">
												<p class="mb-0 white--text text-task-number caption">{{tarea.number}}</p>
											</div>
										</template>
										<span>Tarea número {{tarea.number}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="white"
												class="icon-top"
												v-bind="attrs"
												v-on="on"
												:class="getTareaPriorityColor(tarea)" small>mdi-exclamation</v-icon>
										</template>
										<span>Prioridad {{getTareaPriorityText(tarea)}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<div class="icon-top d-flex justify-center align-center" :class="$options.filters.seenByMeColor(tarea.seen_by_me)"
												v-bind="attrs"
												v-on="on"
												v-if="canISeeBell(tarea)"
												@click.stop="changeVisualizationStatus(tarea)">
												<v-icon color="white"
													:class="$options.filters.seenByMeIconAnimation(tarea.seen_by_me)"
													v-if="!isLoadingChangeVisualizationStatus(tarea.id)"
													small>mdi-bell</v-icon>
												<v-progress-circular
													v-else
													:width="2"
													:size="16"
													color="white"
													indeterminate
												></v-progress-circular>
											</div>
										</template>
										<span>{{$options.filters.seenByMeText(tarea.seen_by_me)}}</span>
									</v-tooltip>
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="white"
												class="icon-top"
												:class="!tarea.seen ? 'high':'success'"
												v-bind="attrs"
												v-on="on"
												small>mdi-check</v-icon>
										</template>
										<span>El responsable {{!tarea.seen ? 'no':''}} ha visualizado esta tarea</span>
									</v-tooltip>
								</div>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<div class="d-flex flex-row align-center">
									<p class="mb-0 body-2 text--secondary">
										{{tarea.created_by_user.name}}
									</p>

									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-icon color="#515d78" small
												class="ml-1"
												v-bind="attrs"
												v-on="on"
												v-if="tarea.created_by_user.id === $store.userStore.state.userInfo.id">mdi-account</v-icon>
										</template>
										<span>Tú creaste esta tarea</span>
									</v-tooltip>
								</div>
								<p class="mb-0 font-weight-bold body-2 text-primary-color">{{tarea.userjob.userjobtype.name}}</p>
							</div>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-center align-center">
								<v-img :src="setLights(tarea.lightstatus)"
									max-height="50"
									max-width="35"
									contain
									class="mr-3"/>
								<!-- <div class="mb-0 body-2 text--secondary text-tarea-field" style="width: 100%;" v-html="tarea.description"></div> -->
								<p class="mb-0 font-weight-bold body-2 text-primary-color" style="width: 100%;">{{tarea.title}}</p>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<p class="mb-0 body-2 text--secondary">{{tarea.responsable_user.name}}</p>
								<p class="mb-0 font-weight-bold body-2 text-primary-color">{{tarea.responsablejob.userjobtype.name}}</p>
							</div>
						</v-col>
						<v-col cols="2">
							<div class="status-container d-flex flex-row align-center pl-3">
								<v-tooltip bottom v-if="checkIfNearDeadline(tarea)">
									<template v-slot:activator="{ on, attrs }">
										<v-icon color="white"
											class="icon-status icon-top medium"
											v-bind="attrs"
											v-on="on"
											small v-if="!tarea.seen">mdi-flag-outline</v-icon>
									</template>
									<span>Se acerca la fecha crítica {{tarea.critical_date | toDate}}</span>
								</v-tooltip>
								<div class="d-flex flex-column flex-grow-1">
									<p class="mb-0 body-2 text--secondary caption">Espera: {{ tarea.waitingtime }}</p>
									<p class="mb-0 font-weight-bold caption text-primary-color d-flex flex-row">
										{{tarea.created_at | toDate}}

										<v-tooltip bottom v-if="tarea.critical_date">
											<template v-slot:activator="{ on, attrs }">
												<div class="d-flex flex-row critical-date-container px-1 mx-1 rounded"
													v-bind="attrs"
													v-on="on">
													<v-icon x-small color="white" class="mr-1">mdi-calendar-range-outline</v-icon>
													<p class="mb-0 white--text">{{tarea.critical_date | toDate}}</p>
												</div>
											</template>
											<span>Fecha crítica: {{tarea.critical_date | toDate}}</span>
										</v-tooltip>
									</p>
								</div>
								<!-- <v-btn rounded color="#515d78" dark small @click="viewTarea(tarea.id, tarea.seen_by_me)">
									<p class="mb-0 font-weight-bold body-2 text-capitalize">Ver Consulta</p>
								</v-btn> -->
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
			<v-row align="center">
				<v-col cols="12">
					<v-pagination
						v-model="page"
						color="#515d78"
						:length="Math.ceil($store.adicionalesStore.state.listadoPosiblesTotal/50)"
						:total-visible="7"
						@input="changePage"
					></v-pagination>
				</v-col>
			</v-row>
		</div>
		<div v-else>
			<v-card class="mb-2 py-2" v-for="i in 5" :key="i">
				<v-card-text class="py-2">
					<v-row>
						<v-col cols="1">
							<div class="d-flex flex-row">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="4">
							<div class="d-flex flex-row justify-center align-center">
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="2">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
						<v-col cols="3">
							<div>
								<v-skeleton-loader class="mx-auto" min-width="100%" type="list-item" ></v-skeleton-loader>
							</div>
						</v-col>
					</v-row>
				</v-card-text>
			</v-card>
		</div>
		
	</v-container>
</template>

<script>
import moment from 'moment'
import {getTareaByID, changeVisualizationStatus} from '@/helpers/api/tasks'

export default {
	name: 'ListadoAdditionalLikely',
	components: {},
	props: {
		ended:{
			type: Boolean,
			default: false
		}
	},
	data: () => ({
		creatorFilter: [],
		responsableFilter: [],
		responsableMenu: false,
		creatorMenu: false,
		page: 1,
	}),
	mounted(){
	},
	methods: {
		canISeeBell(tarea){
			if( !(this.$store.userStore.state.userInfo.id == tarea.responsable || this.$store.userStore.state.userInfo.id == tarea.created_by) &&
					(
						this.$store.projectsStore.state.selectedProject.job_type.id == 3 ||
						this.$store.projectsStore.state.selectedProject.job_type.id == 4 ||
						this.$store.projectsStore.state.selectedProject.job_type.id == 6
					)
				){
				return false
			}
			return true
		},
		async changeVisualizationStatus(task){
			if(!this.isLoadingChangeVisualizationStatus(task.id)){
				let newStatus = 0
				if(!task.seen_by_me){
					newStatus = 1
				}
				else if(task.seen_by_me.seen_status < 2){
					newStatus = task.seen_by_me.seen_status + 1
				}
				else if(task.seen_by_me.seen_status === 2){
					newStatus = 0
				}

				this.$store.consultasStore.commit('addLoadingChangeVisualizationStatus', task.id)
				const data = {
					task_id: task.id,
					status: newStatus
				}
				const statusUpdate = await changeVisualizationStatus(data)
				if(statusUpdate.code === 200){
					// Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
					let newTaskData = Object.assign({}, task)
					newTaskData.seen_by_me.seen_status = newStatus
					this.$store.consultasStore.dispatch('updateTaskLocally', newTaskData)
				}
				this.$store.consultasStore.commit('removeLoadingChangeVisualizationStatus', task.id)
			}
		},
		isLoadingChangeVisualizationStatus(taskID){
			const found = this.$store.consultasStore.state.loadingChangeVisualizationStatus.find((task_id) => task_id === taskID)
			return found
		},
		getIconOrder(){
			if(this.$store.consultasStore.state.listingOrder === 1 || this.$store.consultasStore.state.listingOrder === 3){
				return 'mdi-arrow-down-bold'
			}
			else if(this.$store.consultasStore.state.listingOrder === 2 || this.$store.consultasStore.state.listingOrder === 4){
				return 'mdi-arrow-up-bold'
			}
			return 'mdi-swap-vertical-bold'
		},
		toggleOrder(){
			this.$store.consultasStore.commit('setListingOrder')
			this.getAdditionalLikelyList()
		},
		getNameOrder(){
			if(this.$store.consultasStore.state.listingOrder === 1 || this.$store.consultasStore.state.listingOrder === 2){
				return 'Prioridad'
			}
			else if(this.$store.consultasStore.state.listingOrder === 3 || this.$store.consultasStore.state.listingOrder === 4){
				return 'Número'
			}
		},
		clearFilters(){
			this.creatorFilter = []
			this.responsableFilter = []
		},
		async getAdditionalLikelyList(){
			let project_id = null
			const page = this.page
			if(this.$store.projectsStore.state.selectedProject){
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else{
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			if(project_id){
				this.$store.adicionalesStore.dispatch('getAdditionalsLikely',
					{project_id, page,
					filtered_creator_users: this.creatorFilter, filtered_resp_users: this.responsableFilter})
			}
		},
		getTareaPriorityColor(tarea){
			if(tarea.priority === 3){
				return 'high'
			}
			else if(tarea.priority === 2){
				return 'medium'
			}
			return 'normal'
		},
		getTareaPriorityText(tarea){
			if(tarea.priority === 3){
				return 'Inmediata'
			}
			else if(tarea.priority === 2){
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(tarea){
			if(!tarea.critical_date){ return false }
			const now = moment()
			const tarea_date = moment(tarea.critical_date)
			return tarea_date.diff(now, 'days') <= 2
		},
		setLights(data){
			return require('@/assets/Svg/VistaListado/Semaforo'+data+'.svg')
		},
		filterList(){
			this.getAdditionalLikelyList()
			this.creatorMenu = false
			this.responsableMenu = false
		},
		async viewTarea(task_id, seen_by_me){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true, is_posible_additional: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		changePage(){
			this.page
			this.getAdditionalLikelyList()
		}
	}
};
</script>

<style scoped>
	#listado-consultas{align-self: stretch;}
	.text-primary-color{
		color: #515d78;
	}
	.light-blue-text{color: #515d78;}
	.inner-text{
		font-size: 10px;
	}
	.status-container{
		position: relative;
	}
	.icon-top.icon-status{
		position: absolute;
		width: 20px;
		height: 20px;
		left: -20px;
	}
	.icon-top{
		padding: 2px;
		margin: 0 2px;
		border-radius: 100%;
	}
	.icon-top.high{
		background-color: #F4516C;
	}
	.icon-top.medium{
		background-color: #fb8c00;
	}
	.icon-top.normal{
		background-color: #3EBFA3;
	}
	.icon-top.primary{
		background-color: #515d78;
	}
	.icon-top.light-blue{
		background-color: #515d78;
	}
	.counter-tareas{
		background-color: #F4516C;
		border-radius: 100%;
		padding: 5px;
		width: 30px;
		height: 30px;
		line-height: 20px;
		color: white;
		font-size: 20px;
		text-align: center;
	}
	.status-icon{
		border: 1px solid #515d78;
		border-radius: 100%;
	}
	/deep/ .status-icon .inner{
		border: 1px solid #f2f2f2;
		border-radius: 100%;
		width: 14px;
		height: 14px;
	}
	/deep/ .status-icon .inner.danger{
		background-color: #F4516C;
	}
	/deep/ .header-table-card .table-header-button:hover:before{
		opacity: 0;
	}
	.text-tarea-field{
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3; /* number of lines to show */
		-webkit-box-orient: vertical;
		max-height: 50px;
	}
	/deep/ .text-tarea-field h1,/deep/ .text-tarea-field h2,/deep/ .text-tarea-field h3{
		font-size: 1em!important;
	}
	.icon-container{flex-wrap: wrap;}
	.icon-container .icon-top{
		flex: 1 0 30%;
		width: 24px;
		max-width: 24px;
		height: 24px;
		max-height: 24px;
		margin-bottom: 2px;
	}
	.icon-container .icon-top.number{
		background-color: #515d78;
		text-align: center;
		line-height: 20px;
	}
	.text-task-number.caption{ font-size: 10px!important; }
	.icon-container .icon-top.total{
		background-color: #515d78;
		text-align: center;
		line-height: 20px;
	}
	.critical-date-container{
		background-color: #515d78;
	}
	.listing-loading-bar{
		position: absolute;
		bottom: 0;
	}
	.header-table-card{
		border: 1px solid white;
	}
	.status-ended{
		border: 1px solid #4caf50;
	}
/*	.listing-scroll{
		overflow-y: scroll;
		height: 100%;
	}*/
	.tarea-card{
		position: relative;
	}
	.incognito-container{
		width: 0;
		height: 0;
		border-top: 33px solid #616161;
		border-right: 33px solid transparent;
		border-radius: 0!important;
		position: absolute;
		left: 0px;
	}
	.incognito-container .v-icon{
		position: absolute;
		top: -32px;
		left: 1px;
	}
</style>