<template>
  <!-- <v-card :style="'background: url(' + $store.projectsStore.state.selectedProject.project_image + ') no-repeat center center; background-size: cover; width: 100%; height: 100%;  border-radius: 4px;'">
    <div></div>
    <v-card-text>
      <v-row class="project-detail-card">
        <v-col cols="12">
        </v-col>
      </v-row>
    </v-card-text>
  </v-card> -->
  <v-card style="width: 100%; height: 100%; overflow: hidden;" class="bg-project"
    :style="{ 'background-image': 'url(' + getBackgroundImage() + ')' }">
    <!-- <div id="forgeViewer"></div> -->
    <ViewBimItem :urn="urn"></ViewBimItem>
  </v-card>
</template>

<script>
// import { getAutodeskAccessToken } from '../../../../helpers/api/autodesk/index'; // Ajusta la ruta según tu estructura de carpetas
import { getFilesParams } from '../../../../helpers/api/bim/index'; // Ajusta la ruta según tu estructura de carpetas
import ViewBimItem from '../../Planimetria/components/ViewBimItem.vue'

export default {
  name: "ProjectDetailInfo",
  components: { ViewBimItem },
  props: ["info"],
  data: () => ({
    urn: null
  }),
  async mounted() {

    setTimeout(() => {
      this.getRenders(true);
    }, 1000);
  },

  methods: {
    getBackgroundImage() {
      const image = this.$store.projectsStore.state.selectedProject.project_image;
      // Verificar si la URL de la imagen contiene una extensión
      if (/\.(jpeg|jpg|gif|png)$/i.test(image)) {
        return image; // Si tiene una extensión válida, devolver la URL original
      } else {
        return 'https://storage.googleapis.com/bucket-db-favric/ms_bim/bg-default-bin.png'; // Si no tiene extensión, devolver la imagen por defecto
      }
    },
    async getRenders(render = true) {
      console.log('data docs', render);

      try {
        let params = {
          'external_id': this.$store.projectsStore.state.selectedProject.id,
          'role': 'model',
          'is_favorite': '1'
        };
        let items = await getFilesParams(params);
        if (items) {
          let item = items[0];
          this.urn = item.external_urn
        }

        console.log('renders', items);
      } catch (error) {

        console.error('Error fetching files:', error);
      }
    },
  },
};
</script>

<style scoped>
.pointer-cursor {
  cursor: pointer;
}

.bg-project {
  background-size: cover;
  background-position: center;
}
</style>
