<template>
	<v-container class="d-flex flex-column align-start pa-8" pa-0 fluid fill-height>
		<!-- <FullHeader /> -->

		<v-container fluid>
			<v-row cols="12">
				<v-col cols="12" sm="6" xs="3" class="text-left">
					<div>
						<v-icon class="mr-2">mdi-view-dashboard</v-icon>
						<span>{{ leftText }}</span>
						<span v-if="$store.projectsStore.state.selectedProject" class="text-description">Proyecto / {{
							$store.projectsStore.state.selectedProject.name }}</span>
					</div>
				</v-col>
				<v-col cols="12" sm="6" xs="3" class="text-right">
					<span>{{ rightText }}</span>
				</v-col>
			</v-row>
		</v-container>

		<ProjectDetail cols="12" sm="6" xs="3"
			v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject != 1" />
		<NoSelectedProject cols="12" v-else />

		<!-- <ProjectDetail v-if="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject != 1" /> -->
		<!-- <NoSelectedProject v-else /> -->
	</v-container>
</template>

<script>
// import FullHeader from '../../Globals/Headers/FullHeader'
import ProjectDetail from './ProjectDetails/ProjectDetail'
import NoSelectedProject from './NoSelectedProject'
export default {
	name: 'DashboardIndex',
	components: { ProjectDetail, NoSelectedProject },
	data: () => ({
		leftText: 'Panel de Control',
		rightText: 'Creado 10/06/2022'
	}),
	mounted() {
	}
};
</script>

<style scoped>
.text-left {
	font-size: 0.85rem;
	font-weight: 500;
	color: #424242;
	text-align: left;
}

.text-right {
	font-size: 0.8rem;
	font-weight: 600;
	color: #727171;
	text-align: right;
}

.text-description {
	margin-left: 15px;
	font-size: 0.8rem;
	font-weight: 600;
	color: #C0C3C7;
}

@media (max-width: 600px) {
	.v-application .pa-8 {
		padding: 15px !important;
	}

	.text-description{
		padding-right: 8px;
	}

	.v-application .text-right {
		text-align: left !important;
		
	}
}
</style>
