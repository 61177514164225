import { render, staticRenderFns } from "./NewPaymentStatusAdditionalForm.vue?vue&type=template&id=088530c8&scoped=true"
import script from "./NewPaymentStatusAdditionalForm.vue?vue&type=script&lang=js"
export * from "./NewPaymentStatusAdditionalForm.vue?vue&type=script&lang=js"
import style0 from "./NewPaymentStatusAdditionalForm.vue?vue&type=style&index=0&id=088530c8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "088530c8",
  null
  
)

export default component.exports