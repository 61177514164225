import { endpoints } from './endpoints'
import request from './request'
// import axios from 'axios';

export function getFiles(params) {
	console.log('getFiles', params);
	console.log('endpoitns', endpoints.docs);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.docs + params.guid,
			method: 'get',
			params: {}
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getFilesParams(params) {
	console.log('getRenders', params);
	console.log('endpoitns', endpoints.docs);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.docs,
			method: 'get',
			params: params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}


// export function uploadFile(file) {
//     const formData = new FormData();
//     formData.append('attachment', file);
//     formData.append('role', 'doc');

//     return axios.post(endpoints.docs, formData, {
//         headers: {
//             'Content-Type': 'multipart/form-data'
//         }
//     });


// }

export function uploadFile(file, project_id) {
	const formData = new FormData();
	formData.append('attachment', file);
	formData.append('role', 'doc');
	formData.append('external_id', project_id);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.docs,
			method: 'post',
			data: formData,
			onUploadProgress: true,
			timeout: 3600000
		})
			.then(async function (response) {
				console.log('then', response);
				resolve(response)
			})
			.catch(function (error) {
				console.log('error', error);
				reject(error)
			})
	})
}


// update file model
export function updateFile(guid, form) {
	console.log('updateFile', guid, form);
	// const formData = new FormData();
	// formData.append('attachment', file);
	// formData.append('role', 'doc');
	// formData.append('external_id', project_id);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.docs + guid + '/',
			method: 'put',
			data: form,
			onUploadProgress: true,
			timeout: 360000
		})
			.then(async function (response) {
				console.log('then', response);
				resolve(response)
			})
			.catch(function (error) {
				console.log('error', error);
				reject(error)
			})
	})
}

export function checkFileExist(file, onUploadProgress = null) {
	const formData = new FormData();
	formData.append('file', file);

	return new Promise((resolve, reject) => {
		request({
			url: endpoints.fileExist,
			method: 'post',
			data: formData,
			onUploadProgress,
			timeout: 360000
		})
			.then(async function (response) {
				console.log('then', response);
				resolve(response)
			})
			.catch(function (error) {
				console.log('error', error);
				reject(error)
			})
	})
}


export function addFileUpload(guid, folder_guid, file, onUploadProgress = null) {
	console.log('formdata2', guid, folder_guid, file);
	const formData = new FormData();
	formData.append('attachment', file);
	formData.append('folder_guid', folder_guid);

	return new Promise((resolve, reject) => {
		request({
			url: endpoints.docsAdd + guid + '/',
			method: 'post',
			data: formData,
			onUploadProgress,
			timeout: 3600000
		})
			.then(async function (response) {
				console.log('then', response);
				resolve(response)
			})
			.catch(function (error) {
				console.log('error', error);
				reject(error)
			})
	})
}



// autodesk BIM

export function uploadFileBim(file, project_id) {
	const formData = new FormData();
	formData.append('attachment', file);
	formData.append('project_id', project_id);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.adStart,
			method: 'post',
			data: formData,
			onUploadProgress:true,
			timeout: 36000000
		})
			.then(async function (response) {
				console.log('then', response);
				resolve(response)
			})
			.catch(function (error) {
				console.log('error', error);
				reject(error)
			})
	})
}

export function getStatus(params) {
	console.log('param status', params);
	console.log('endpoitns', endpoints.adStatus);
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.adStatus,
			method: 'get',
			params: params
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}





