<script>
import { Doughnut } from 'vue-chartjs'

export default {
  extends: Doughnut,
  props: {
    chartdata: {
      type: Object,
      default: null
    },
    options: {
      type: Object,
      default: null
    },
    centerText: {
      type: String,
      default: ''
    }
  },
  mounted() {
    this.addPlugin({
      id: 'center-label',
      beforeDraw: (chart) => {
        var width = chart.width;
        var height = chart.height;
        var ctx = chart.ctx;

        ctx.restore();
        var fontSize = (height / 70).toFixed(2);
        ctx.font = 'bold ' + fontSize + "em Arial";
        ctx.textBaseline = "middle";

        var text = this.centerText ? this.centerText : '';
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 1.7;

        ctx.fillText(text, textX, textY);
        ctx.save();

      }
    });
    this.renderChart(this.chartdata, this.options)
  },
  methods: {
  }
}

</script>