<template>
	<v-app>
		<v-main>
			<HeaderMain v-if="$store.userStore.state.userInfo"></HeaderMain>
			<Main></Main>
			<div class="caption py-1 px-2" style="position:fixed; top: -6px; right: 20px; height: 30px; opacity: 0.8; ">
				<img style="max-height: 100%;" src="./assets/Imgs/barline-fv.png">
			</div>
		</v-main>
	</v-app>
</template>

<script>
import HeaderMain from './components/Globals/Headers/HeaderMain.vue'
import Main from './components/Layout/Main'
import {getRdiByID} from '@/helpers/api/rdi'
import {getTareaByID} from '@/helpers/api/tasks'

export default {
	name: 'App',
	components: {
		Main, HeaderMain
	},
	computed: {
		userAuth() {
			return this.$store.userStore.state.isAuthenticated
		}
	},
	watch: {
		userAuth(newData, oldData) {
			if(newData && !oldData){
				this.getUserInfo()
				this.getSelectedProject()
				this.getNotifications()
				this.getReminders()
				this.getSystemCurrencies()
			}
		}
	},
	data: () => ({
		//
	}),
	mounted(){
		this.setCloseListener()
		this.$store.menuStore.commit('setMenuSidebarStatus', !this.$vuetify.breakpoint.mobile)
		if(this.$store.userStore.state.isAuthenticated){
			this.getUserInfo()
			this.getSelectedProject()
			this.getNotifications()
			this.getReminders()
			this.getSystemCurrencies()
			this.checkLink()
		}
		this.$store.projectsStore.commit('setSelectedAvancesInfo', {
			title: 'Control de Avances',
			total_title: 'Total de Avances',
			circle_graphs: [
				{value: 50, title: 'Descripción', color: '#EAB261'},
				{value: 75, title: 'Descripción', color: '#34BFA3'},
				{value: 60, title: 'Descripción', color: '#F45771'},
				{value: 85, title: 'Descripción', color: '#DCD615'}
			],
			speed_solution: {value: 40, suffix: 's/d'},
			response_time: {value: 40, suffix: 's/d'},
			general_status: 'success',
			line_graph: {
				labels: [1, 2, 3, 4, 5, 6, 7, 8],
				datasets: [
				{
					label: 'Prime and Fibonacci',
					fill: false,
					borderColor: '#515d78',
					pointBackgroundColor: ['#DCD615','#EAB261','#34BFA3','#F45771','#DCD615','#EAB261','#34BFA3','#F45771'],
					pointBorderColor: ['#DCD615','#EAB261','#34BFA3','#F45771','#DCD615','#EAB261','#34BFA3','#F45771'],
					pointHoverBackgroundColor: '#55bae7',
					pointHoverBorderColor: '#55bae7',
					data: [2, 5, 7, 11, 13, 19, 14, 10]
				}
				]
			}
		})
		this.$store.projectsStore.commit('setSelectedAdicionalesInfo', {
			title: 'Adicionales o Aumento de Obra',
			total_title: 'Total de Avances',
			circle_graphs: [
				{value: 50, title: 'Descripción', color: '#EAB261'},
				{value: 75, title: 'Descripción', color: '#34BFA3'},
				{value: 60, title: 'Descripción', color: '#F45771'},
				{value: 50, title: 'Descripción', color: '#EAB261'},
				{value: 75, title: 'Descripción', color: '#34BFA3'},
			],
			speed_solution: {value: 40, suffix: 's/d'},
			response_time: {value: 40, suffix: 's/d'},
			general_status: 'success',
			bottom: {
				graph_title: 'Programa',
				general_status: 'success',
				graphs: [
					{value: 60, title: 's1 - ', color: '#DCD615'},
					{value: 50, title: 's2 - ', color: '#EAB261'},
					{value: 75, title: 's3 - ', color: '#34BFA3'},
					{value: 60, title: 's4 - ', color: '#F45771'},
				],
			}
		})
		// this.$store.projectsStore.commit('setSelectedRdiInfo', {
		// 	title: 'RDI',
		// 	total_title: 'Total RDI',
		// 	circle_graphs: [
		// 		{value: 50, title: 'Descripción', color: '#EAB261'},
		// 		{value: 75, title: 'Descripción', color: '#34BFA3'},
		// 		{value: 60, title: 'Descripción', color: '#F45771'},
		// 	],
		// 	speed_solution: {value: 40, suffix: 's/d'},
		// 	response_time: {value: 40, suffix: 's/d'},
		// 	general_status: 'success',
		// 	bottom: {
		// 		graph_title: 'Adicionales',
		// 		general_status: 'error',
		// 		graphs: [
		// 			{value: 60, title: 's1 - ', color: '#DCD615'},
		// 			{value: 50, title: 's2 - ', color: '#EAB261'},
		// 			{value: 75, title: 's3 - ', color: '#34BFA3'},
		// 			{value: 60, title: 's4 - ', color: '#F45771'},
		// 		],
		// 	}
		// })
	},
	methods: {
		setCloseListener(){
			window.onbeforeunload = function (e) {
				if( Object.keys(this.$store && this.$store.globalStore.state.filesToUploadCenter).length > 0 ){
					e = e || window.event
					if (e) { e.returnValue = 'Sure?' }
					return 'Sure?'
				}
			};
		},
		checkLink(){
			if(this.$route.query.rdi){
				const query = {...this.$route.query}
				this.$router.replace({name: this.$route.name})
				this.viewRDI(query.rdi, false)
			}
			if(this.$route.query.task){
				const query = {...this.$route.query}
				this.$router.replace({name: this.$route.name})
				this.viewTarea(query.task, false)
			}
		},
		getSystemCurrencies(){
			this.$store.generalStore.dispatch('getSystemCurrencies')
		},
		getUserInfo(){
			this.$store.userStore.dispatch('getUserInfo')
		},
		async getSelectedProject(){
			await this.$store.projectsStore.dispatch('getSelectedProject')
			let companyID = null
			if(this.$store.projectsStore.state.selectedProject){
				companyID = this.$store.projectsStore.state.selectedProject.company_id
				this.getDashboardInfo()
				this.getProjectUserList()
			}
			this.setCompanyPermissions(companyID)
		},
		setCompanyPermissions(companyID){
			if(this.$store.userStore.state.userCompanies.length > 0){
				let companyInfo = null
				if(companyID){
					companyInfo = this.$store.userStore.state.userCompanies.find((company) => {
						return company.company_id = companyID
					})
				}
				else{
					companyInfo = this.$store.userStore.state.userCompanies[0]
				}
				this.$store.userStore.commit('setCompanyPermissions', companyInfo.capabilities)
				this.$store.userStore.commit('setSelectedCompany', companyInfo)
			}
		},
		getDashboardInfo(){
			this.$store.projectsStore.dispatch('getDashboardInfo')
			this.$store.fileManagerStore.dispatch('getProjectStorageSize', {
				project_id: this.$store.projectsStore.state.selectedProject.id
			})
		},
		getNotifications(){
			this.$store.notificationsStore.dispatch('getNotifications')
		},
		getReminders(){
			this.$store.notificationsStore.dispatch('getReminders')
		},
		getProjectUserList(){
			const data = {
				project_id: this.$store.projectsStore.state.selectedProject.id,
				start: 0,
				limit: 20
			}
			this.$store.usersStore.dispatch('getUsersList', data)
		},
		async viewRDI(rdi_id, seen_by_me){
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = seen_by_me
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
			else if(rdi.code === 403){
				this.$store.rdiStore.commit('setCurrentRDI', null)
				const data = {
					message: rdi.message,
					title: 'Error',
					created: new Date(),
					type: 'danger',
					icon: 'mdi-close-octagon',
					color: '#F4516C'
				}
				this.$store.globalStore.dispatch('addMessage', data)
			}
		},
		async viewTarea(task_id, seen_by_me){
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
	}
};
</script>