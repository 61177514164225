import Vue from 'vue'
import Vuex from 'vuex'
import {getNotifications, getReminders} from '@/helpers/api/general'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		loadingNotifications: true,
		badgeText: '--',
		badgeColor: '#cacaca',
		notifications: {
			tasks: [],
			rdis: [],
		},
		reminders: []
	},
	mutations: {
		setNotifications(state, notifications){
			state.notifications = notifications
		},
		setReminders(state, reminders){
			state.reminders = reminders
		},
		setLoadingNotifications(state, status){
			state.loadingNotifications = status
		},
		setBadgeText(state, text){
			state.badgeText = text
		},
		setBadgeColor(state, color){
			state.badgeColor = color
		},
	},
	actions: {
		async getNotifications(context){
			context.commit('setLoadingNotifications', true)
			const notifications = await getNotifications()
			if(notifications.code === 200){
				context.commit('setNotifications', notifications.notifications)
				context.commit('setLoadingNotifications', false)
				context.commit('setBadgeText', notifications.total_count.toString())

        let badge_color = 'success'
        if(notifications.total_count >= 5){
            badge_color = '#fb8c00'
        }
        if(notifications.total_count >= 10){
            badge_color = '#F4516C'
        }

				context.commit('setBadgeColor', badge_color)
			}
		},
		async getReminders(context){
			const reminders = await getReminders()
			if(reminders.code === 200){
				context.commit('setReminders', reminders.reminders)
			}
		}
	}
})

export default store