<template>
	<v-container fluid class="px-0">

		<v-row align="start">
			<v-col cols="12" sm="6" md="4">
				<v-card class="mb-2 py-2 creadas" @click="toogleTasks(0)">
					<v-card-text class="title-card py-2">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center">
								<h3 class="title-section py-0 mr-2">Creadas</h3>
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn color="#515d78" dark v-bind="attrs" v-on="on" small icon>
											<v-icon class="mr-1" small>mdi-sort</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item-group v-model="myCreatedSelection" @change="myCreatedOrderChange">
											<v-list-item :value="1">
												<v-list-item-title class="text-primary-color caption">Orden por
													Defecto</v-list-item-title>
											</v-list-item>
											<v-list-item :value="2">
												<v-list-item-title
													class="text-primary-color caption">Prioridad</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
											</v-list-item>
											<v-list-item :value="3">
												<v-list-item-title
													class="text-primary-color caption">Prioridad</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
											</v-list-item>
											<v-list-item :value="4">
												<v-list-item-title class="text-primary-color caption">Fecha
													creación</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
											</v-list-item>
											<v-list-item :value="5">
												<v-list-item-title class="text-primary-color caption">Fecha
													creación</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-menu>
							</div>
							<div class="counter-tareas caption">
								<span
									v-if="$store.consultasStore.state.misTareasInfoCreadas.status === 1">{{ $store.consultasStore.state.misTareasInfoCreadas.count }}</span>
								<v-progress-circular v-else :size="12" :width="2" color="white"
									indeterminate></v-progress-circular>
							</div>
						</div>
					</v-card-text>
				</v-card>
				<div v-if="taskCardState[0]">
					<v-card class="creadas mb-1"
						v-for="(tarea, index) in $store.consultasStore.state.misTareasInfoCreadas.data" :key="index"
						@click="viewTarea(tarea.id, $options.filters.seenByMe(tarea.seen_by_me))" link
						:class="tarea.status === 3 ? 'status-ended' : ''">
						<v-tooltip bottom v-if="tarea.private">
							<template v-slot:activator="{ on, attrs }">
								<div class="incognito-container" v-bind="attrs" v-on="on">
									<v-icon color="white" x-small>mdi-incognito</v-icon>
								</div>
							</template>
							<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
						</v-tooltip>
						<v-card-text class="tarea-card py-2 px-2">
							<v-row align="center" no-gutters>
								<v-col cols="2">
									<div class="d-flex flex-row icon-container">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top number" v-bind="attrs" v-on="on">
													<p class="mb-0 white--text text-task-number caption">{{ tarea.number }}
													</p>
												</div>
											</template>
											<span>Tarea número {{ tarea.number }}</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top d-flex justify-center align-center"
													:class="$options.filters.seenByMeColor(tarea.seen_by_me)" v-bind="attrs"
													v-on="on" @click.stop="changeVisualizationStatus(tarea)">
													<v-icon color="white"
														:class="$options.filters.seenByMeIconAnimation(tarea.seen_by_me)"
														v-if="!isLoadingChangeVisualizationStatus(tarea.id)"
														small>mdi-bell</v-icon>
													<v-progress-circular v-else :width="2" :size="16" color="white"
														indeterminate></v-progress-circular>
												</div>
											</template>
											<span>{{ $options.filters.seenByMeText(tarea.seen_by_me) }} Tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top"
													:class="!tarea.seen ? 'high' : 'success'" v-bind="attrs" v-on="on"
													small>mdi-check</v-icon>
											</template>
											<span>El responsable {{ !tarea.seen ? 'no' : '' }} ha visualizado esta tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top medium" v-bind="attrs" v-on="on" small
													v-if="checkIfNearDeadline(tarea)">mdi-flag-outline</v-icon>
											</template>
											<span>Se acerca la fecha crítica {{ tarea.critical_date | toDate }}</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="7">
									<div class="d-flex flex-row">
										<p class="mb-0">{{ tarea.title }}</p>
										<div v-if="tarea.status === 3">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<v-icon class="icon-top" color="#34BFA3" v-bind="attrs" v-on="on"
														small>mdi-check-circle</v-icon>
												</template>
												<span>Tarea Finalizada</span>
											</v-tooltip>
										</div>
									</div>
									<p class="inner-text text-creadas-color mb-0">
										<v-chip v-if="tarea.code" x-small
											outlined>
											{{ tarea.code }}
										</v-chip>
									</p>
									<p class="inner-text text-creadas-color mb-0">
										Creado por: {{ tarea.created_by_user.name }} |
										Responsable: {{ tarea.responsable_user.name }}
									</p>

									<!-- <p class="inner-text text-creadas-color mb-0">Creado por: {{ tarea.created_by_user.name }}
									</p>
									<p class="inner-text text-creadas-color mb-0">Responsable:
										{{ tarea.responsable_user.name }}</p> -->
								</v-col>
								<v-col cols="3" class="text-right">
									<div class="d-flex flex-row align-center justify-end">
										<div v-if="tarea.critical_date">
											<p class="inner-text text-creadas-color mb-0 font-weight-bold force-width-te">
												TE: {{ tarea.waitingtime }}</p>
										</div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top" v-bind="attrs" v-on="on"
													:class="getTareaPriorityColor(tarea)" small>mdi-exclamation</v-icon>
											</template>
											<span>Prioridad {{ getTareaPriorityText(tarea) }}</span>
										</v-tooltip>
									</div>
									<p class="inner-text text-creadas-color mb-0 font-weight-bold ellipsis-te"
										v-if="!tarea.critical_date">TE: {{ tarea.waitingtime }}</p>
									<p class="mb-0 inner-text">
										<v-icon x-small>mdi-calendar-range-outline</v-icon>
										{{ tarea.created_at | toDate }}
									</p>
									<v-tooltip bottom v-if="tarea.critical_date">
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row align-center justify-end" v-bind="attrs" v-on="on">
												<v-icon x-small color="#515d78"
													class="mr-1">mdi-calendar-range-outline</v-icon>
												<p class="mb-0 inner-text light-blue-text">{{ tarea.critical_date | toDate }}
												</p>
											</div>
										</template>
										<span>Fecha crítica: {{ tarea.critical_date | toDate }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-row align="center" v-show="$store.consultasStore.state.misTareasInfoCreadas.count > 0">
						<v-col cols="12">
							<v-pagination v-model="pageCreadas" color="#515d78"
								:length="Math.ceil($store.consultasStore.state.misTareasInfoCreadas.count / 50)"
								:total-visible="7" @input="changePage(1)"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card class="mb-2 py-2 pendientes" @click="toogleTasks(1)">
					<v-card-text class="title-card py-2">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center">
								<h3 class="title-section py-0 mr-2">Pendientes</h3>
								<v-menu offset-y>
									<template v-slot:activator="{ on, attrs }">
										<v-btn color="#515d78" dark v-bind="attrs" v-on="on" small icon>
											<v-icon class="mr-1" small>mdi-sort</v-icon>
										</v-btn>
									</template>
									<v-list dense>
										<v-list-item-group v-model="myPendingSelection" @change="myPendingOrderChange">
											<v-list-item :value="1">
												<v-list-item-title class="text-primary-color caption">Fecha
													creación</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
											</v-list-item>
											<v-list-item :value="2">
												<v-list-item-title class="text-primary-color caption">Fecha
													creación</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
											</v-list-item>
											<v-list-item :value="3">
												<v-list-item-title
													class="text-primary-color caption">Prioridad</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
											</v-list-item>
											<v-list-item :value="4">
												<v-list-item-title
													class="text-primary-color caption">Prioridad</v-list-item-title>
												<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
											</v-list-item>
										</v-list-item-group>
									</v-list>
								</v-menu>
							</div>
							<div class="counter-tareas caption">
								<span
									v-if="$store.consultasStore.state.misTareasInfoPendientes.status === 1">{{ $store.consultasStore.state.misTareasInfoPendientes.count }}</span>
								<v-progress-circular v-else :size="12" :width="2" color="white"
									indeterminate></v-progress-circular>
							</div>
						</div>
					</v-card-text>
				</v-card>
				<div v-if="taskCardState[1]">
					<v-card class="pendientes mb-1"
						v-for="(tarea, index) in $store.consultasStore.state.misTareasInfoPendientes.data" :key="index"
						@click="viewTarea(tarea.id, $options.filters.seenByMe(tarea.seen_by_me))" link>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div class="incognito-container" v-if="tarea.private" v-bind="attrs" v-on="on">
									<v-icon color="white" x-small>mdi-incognito</v-icon>
								</div>
							</template>
							<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
						</v-tooltip>
						<v-card-text class="tarea-card py-2 px-2">
							<v-row align="center" no-gutters>
								<v-col cols="2">
									<div class="d-flex flex-row icon-container">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top number" v-bind="attrs" v-on="on">
													<p class="mb-0 white--text text-task-number caption">{{ tarea.number }}
													</p>
												</div>
											</template>
											<span>Tarea número {{ tarea.number }}</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top d-flex justify-center align-center"
													:class="$options.filters.seenByMeColor(tarea.seen_by_me)" v-bind="attrs"
													v-on="on" @click.stop="changeVisualizationStatus(tarea)">
													<v-icon color="white"
														:class="$options.filters.seenByMeIconAnimation(tarea.seen_by_me)"
														v-if="!isLoadingChangeVisualizationStatus(tarea.id)"
														small>mdi-bell</v-icon>
													<v-progress-circular v-else :width="2" :size="16" color="white"
														indeterminate></v-progress-circular>
												</div>
											</template>
											<span>{{ $options.filters.seenByMeText(tarea.seen_by_me) }} Tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top"
													:class="!tarea.seen ? 'high' : 'success'" v-bind="attrs" v-on="on"
													small>mdi-check</v-icon>
											</template>
											<span>El responsable {{ !tarea.seen ? 'no' : '' }} ha visualizado esta tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top medium" v-bind="attrs" v-on="on" small
													v-if="checkIfNearDeadline(tarea)">mdi-flag-outline</v-icon>
											</template>
											<span>Se acerca la fecha crítica {{ tarea.critical_date | toDate }}</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="7">
									<p class="mb-0">{{ tarea.title }}</p>
									<p class="inner-text text-creadas-color mb-0">
										<v-chip v-if="tarea.code" x-small
											outlined>
											{{ tarea.code }}
										</v-chip>
									</p>
									<p class="inner-text text-creadas-color mb-0">
										Creado por: {{ tarea.created_by_user.name }} |
										Responsable: {{ tarea.responsable_user.name }}
									</p>
									<!-- <p class="inner-text text-creadas-color mb-0">Creado por: {{ tarea.created_by_user.name }}
									</p>
									<p class="inner-text text-creadas-color mb-0">Responsable:
										{{ tarea.responsable_user.name }}</p> -->
								</v-col>
								<v-col cols="3" class="text-right">
									<div class="d-flex flex-row align-center justify-end">
										<div v-if="tarea.critical_date">
											<p class="inner-text text-creadas-color mb-0 font-weight-bold force-width-te">
												TE: {{ tarea.waitingtime }}</p>
										</div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top" v-bind="attrs" v-on="on"
													:class="getTareaPriorityColor(tarea)" small>mdi-exclamation</v-icon>
											</template>
											<span>Prioridad {{ getTareaPriorityText(tarea) }}</span>
										</v-tooltip>
									</div>
									<p class="inner-text text-creadas-color mb-0 font-weight-bold ellipsis-te"
										v-if="!tarea.critical_date">TE: {{ tarea.waitingtime }}</p>
									<p class="mb-0 inner-text">
										<v-icon x-small>mdi-calendar-range-outline</v-icon>
										{{ tarea.created_at | toDate }}
									</p>
									<v-tooltip bottom v-if="tarea.critical_date">
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row align-center justify-end" v-bind="attrs" v-on="on">
												<v-icon x-small color="#515d78"
													class="mr-1">mdi-calendar-range-outline</v-icon>
												<p class="mb-0 inner-text light-blue-text">{{ tarea.critical_date | toDate }}
												</p>
											</div>
										</template>
										<span>Fecha crítica: {{ tarea.critical_date | toDate }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-row align="center" v-show="$store.consultasStore.state.misTareasInfoPendientes.count > 0">
						<v-col cols="12">
							<v-pagination v-model="pagePendientes" color="#515d78"
								:length="Math.ceil($store.consultasStore.state.misTareasInfoPendientes.count / 50)"
								:total-visible="7" @input="changePage(2)"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-col>
			<v-col cols="12" sm="6" md="4">
				<v-card class="mb-2 py-2 finalizadas" @click="toogleTasks(2)">
					<v-card-text class="title-card py-2">
						<div class="d-flex flex-row align-center justify-space-between">
							<div class="d-flex flex-row align-center ">
								<div class="d-flex flex-row align-center mr-2">
									<h3 class="title-section py-0 mr-2">Finalizadas</h3>
									<v-menu offset-y>
										<template v-slot:activator="{ on, attrs }">
											<v-btn color="#515d78" dark v-bind="attrs" v-on="on" small icon>
												<v-icon class="mr-1" small>mdi-sort</v-icon>
											</v-btn>
										</template>
										<v-list dense>
											<v-list-item-group v-model="myEndedSelection" @change="myEndedOrderChange">
												<v-list-item :value="1">
													<v-list-item-title class="text-primary-color caption">Fecha
														creación</v-list-item-title>
													<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
												</v-list-item>
												<v-list-item :value="2">
													<v-list-item-title class="text-primary-color caption">Fecha
														creación</v-list-item-title>
													<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
												</v-list-item>
												<v-list-item :value="3">
													<v-list-item-title
														class="text-primary-color caption">Prioridad</v-list-item-title>
													<v-icon class="ml-1" small>mdi-sort-descending</v-icon>
												</v-list-item>
												<v-list-item :value="4">
													<v-list-item-title
														class="text-primary-color caption">Prioridad</v-list-item-title>
													<v-icon class="ml-1" small>mdi-sort-ascending</v-icon>
												</v-list-item>
											</v-list-item-group>
										</v-list>
									</v-menu>
								</div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<v-icon color="#40C2A8" v-bind="attrs" v-on="on" small>mdi-information</v-icon>
									</template>
									<div>
										<p class="mb-0 font-weight-bold">Indicadores últimos 5 días</p>
										<p class="mb-0 caption">Mi velocidad de solución:
											{{ $store.consultasStore.state.misTareasInfoFinalizadas.solution_speed |
												oneDecimalsNumber }}/día</p>
										<p class="mb-0 caption">Tiempo de espera promedio de mis tareas:
											{{ $store.consultasStore.state.misTareasInfoFinalizadas.waiting_time |
												oneDecimalsNumber }} días</p>
									</div>
								</v-tooltip>
							</div>
							<div class="counter-tareas caption">
								<span
									v-if="$store.consultasStore.state.misTareasInfoFinalizadas.status === 1">{{ $store.consultasStore.state.misTareasInfoFinalizadas.count }}</span>
								<v-progress-circular v-else :size="12" :width="2" color="white"
									indeterminate></v-progress-circular>
							</div>
						</div>
					</v-card-text>
				</v-card>
				<div v-if="taskCardState[2]">
					<v-card class="finalizadas mb-1"
						v-for="(tarea, index) in $store.consultasStore.state.misTareasInfoFinalizadas.data" :key="index"
						@click="viewTarea(tarea.id, $options.filters.seenByMe(tarea.seen_by_me))" link>
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<div class="incognito-container" v-if="tarea.private" v-bind="attrs" v-on="on">
									<v-icon color="white" x-small>mdi-incognito</v-icon>
								</div>
							</template>
							<span>Tarea privada: sólo la puedes ver tú y el responsable</span>
						</v-tooltip>
						<v-card-text class="tarea-card py-2 px-2">
							<v-row align="center" no-gutters>
								<v-col cols="2">
									<div class="d-flex flex-row icon-container">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top number" v-bind="attrs" v-on="on">
													<p class="mb-0 white--text text-task-number caption">{{ tarea.number }}
													</p>
												</div>
											</template>
											<span>Tarea número {{ tarea.number }}</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div class="icon-top d-flex justify-center align-center"
													:class="$options.filters.seenByMeColor(tarea.seen_by_me)" v-bind="attrs"
													v-on="on" @click.stop="changeVisualizationStatus(tarea)">
													<v-icon color="white"
														:class="$options.filters.seenByMeIconAnimation(tarea.seen_by_me)"
														v-if="!isLoadingChangeVisualizationStatus(tarea.id)"
														small>mdi-bell</v-icon>
													<v-progress-circular v-else :width="2" :size="16" color="white"
														indeterminate></v-progress-circular>
												</div>
											</template>
											<span>{{ $options.filters.seenByMeText(tarea.seen_by_me) }} Tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top"
													:class="!tarea.seen ? 'high' : 'success'" v-bind="attrs" v-on="on"
													small>mdi-check</v-icon>
											</template>
											<span>El responsable {{ !tarea.seen ? 'no' : '' }} ha visualizado esta tarea</span>
										</v-tooltip>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top medium" v-bind="attrs" v-on="on" small
													v-if="checkIfNearDeadline(tarea)">mdi-flag-outline</v-icon>
											</template>
											<span>Se acerca la fecha crítica {{ tarea.critical_date | toDate }}</span>
										</v-tooltip>
									</div>
								</v-col>
								<v-col cols="7">
									<p class="mb-0">{{ tarea.title }}</p>
									<p class="inner-text text-creadas-color mb-0">
										<v-chip v-if="tarea.code" x-small
											outlined>
											{{ tarea.code }}
										</v-chip>
									</p>
									<p class="inner-text text-creadas-color mb-0">
										Creado por: {{ tarea.created_by_user.name }} |
										Responsable: {{ tarea.responsable_user.name }}
									</p>
									<!-- <p class="inner-text text-creadas-color mb-0">Creado por: {{ tarea.created_by_user.name }}
									</p>
									<p class="inner-text text-creadas-color mb-0">Responsable:
										{{ tarea.responsable_user.name }}</p> -->
								</v-col>
								<v-col cols="3" class="text-right">
									<div class="d-flex flex-row align-center justify-end">
										<div v-if="tarea.critical_date">
											<p class="inner-text text-creadas-color mb-0 font-weight-bold force-width-te">
												TE: {{ tarea.waitingtime }}</p>
										</div>
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<v-icon color="white" class="icon-top" v-bind="attrs" v-on="on"
													:class="getTareaPriorityColor(tarea)" small>mdi-exclamation</v-icon>
											</template>
											<span>Prioridad {{ getTareaPriorityText(tarea) }}</span>
										</v-tooltip>
									</div>
									<p class="inner-text text-creadas-color mb-0 font-weight-bold ellipsis-te"
										v-if="!tarea.critical_date">TE: {{ tarea.waitingtime }}</p>
									<p class="mb-0 inner-text">
										<v-icon x-small>mdi-calendar-range-outline</v-icon>
										{{ tarea.created_at | toDate }}
									</p>
									<v-tooltip bottom v-if="tarea.critical_date">
										<template v-slot:activator="{ on, attrs }">
											<div class="d-flex flex-row align-center justify-end" v-bind="attrs" v-on="on">
												<v-icon x-small color="#515d78"
													class="mr-1">mdi-calendar-range-outline</v-icon>
												<p class="mb-0 inner-text light-blue-text">{{ tarea.critical_date | toDate }}
												</p>
											</div>
										</template>
										<span>Fecha crítica: {{ tarea.critical_date | toDate }}</span>
									</v-tooltip>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
					<v-row align="center" v-show="$store.consultasStore.state.misTareasInfoFinalizadas.count > 0">
						<v-col cols="12">
							<v-pagination v-model="pageFinalizadas" color="#515d78"
								:length="Math.ceil($store.consultasStore.state.misTareasInfoFinalizadas.count / 50)"
								:total-visible="7" @input="changePage(3)"></v-pagination>
						</v-col>
					</v-row>
				</div>
			</v-col>
		</v-row>

	</v-container>
</template>

<script>
import moment from 'moment'
import { getTareaByID, changeVisualizationStatus } from '@/helpers/api/tasks'

export default {
	name: 'MisTareas',
	components: {},
	data: () => ({
		pageCreadas: 1,
		pagePendientes: 1,
		pageFinalizadas: 1,
		myCreatedSelection: 1,
		myPendingSelection: 2,
		myEndedSelection: 1,
		taskCardState: [true, true, true]
	}),
	mounted() {
	},
	methods: {
		toogleTasks(type) {
			let state = [...this.taskCardState]
			state[type] = !state[type]
			this.taskCardState = state
		},
		async changeVisualizationStatus(task) {
			if (!this.isLoadingChangeVisualizationStatus(task.id)) {
				let newStatus = 0
				if (!task.seen_by_me) {
					newStatus = 1
				}
				else if (task.seen_by_me.seen_status < 2) {
					newStatus = task.seen_by_me.seen_status + 1
				}
				else if (task.seen_by_me.seen_status === 2) {
					newStatus = 0
				}

				this.$store.consultasStore.commit('addLoadingChangeVisualizationStatus', task.id)
				const data = {
					task_id: task.id,
					status: newStatus
				}
				const statusUpdate = await changeVisualizationStatus(data)
				if (statusUpdate.code === 200) {
					// Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
					let newTaskData = Object.assign({}, task)
					newTaskData.seen_by_me.seen_status = newStatus
					this.$store.consultasStore.dispatch('updateTaskLocally', newTaskData)
				}
				this.$store.consultasStore.commit('removeLoadingChangeVisualizationStatus', task.id)
			}
		},
		isLoadingChangeVisualizationStatus(taskID) {
			const found = this.$store.consultasStore.state.loadingChangeVisualizationStatus.find((task_id) => task_id === taskID)
			return found
		},
		changePage(type) {
			let project_id = null
			if (this.$store.projectsStore.state.selectedProject) {
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else {
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}

			if (type === 1) {
				this.$store.consultasStore.dispatch('getMyNextCreatedTasks', { project_id, page: this.pageCreadas })
			}
			else if (type === 2) {
				this.$store.consultasStore.dispatch('getMyNextPendingTasks', { project_id, page: this.pagePendientes })
			}
			else if (type === 3) {
				this.$store.consultasStore.dispatch('getMyNextEndedTasks', { project_id, page: this.pageFinalizadas })
			}
		},
		getTareaPriorityColor(tarea) {
			if (tarea.priority === 3) {
				return 'high'
			}
			else if (tarea.priority === 2) {
				return 'medium'
			}
			return 'normal'
		},
		getTareaPriorityText(tarea) {
			if (tarea.priority === 3) {
				return 'Inmediata'
			}
			else if (tarea.priority === 2) {
				return 'Media'
			}
			return 'Estándar'
		},
		checkIfNearDeadline(tarea) {
			if (!tarea.critical_date) { return false }
			const now = moment()
			const tarea_date = moment(tarea.critical_date)
			return tarea_date.diff(now, 'days') <= 2
		},
		async viewTarea(task_id, seen_by_me) {
			this.$store.consultasStore.commit('setCurrentTarea', { loading: true })
			const tarea = await getTareaByID({ task_id })
			if (tarea.code === 200) {
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = seen_by_me
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		myCreatedOrderChange() {
			let project_id = null
			if (this.$store.projectsStore.state.selectedProject) {
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else {
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			this.$store.consultasStore.dispatch('getMyNextCreatedTasks', { project_id, page: this.pageCreadas, order: this.myCreatedSelection })
		},
		myPendingOrderChange() {
			let project_id = null
			if (this.$store.projectsStore.state.selectedProject) {
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else {
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			this.$store.consultasStore.dispatch('getMyNextPendingTasks', { project_id, page: this.pagePendientes, order: this.myPendingSelection })
		},
		myEndedOrderChange() {
			let project_id = null
			if (this.$store.projectsStore.state.selectedProject) {
				project_id = this.$store.projectsStore.state.selectedProject.id
			}
			else {
				const id = localStorage.getItem('selectedProjectID')
				project_id = parseInt(id)
			}
			this.$store.consultasStore.dispatch('getMyNextEndedTasks', { project_id, page: this.pagePendientes, order: this.myEndedSelection })
		}
	}
};
</script>

<style scoped>
.text-primary-color {
	color: #515d78;
}

.light-blue-text {
	color: #515d78;
}

/deep/ .creadas .title-section {
	color: #515d78;
}

/deep/ .creadas .tarea-card {
	border-left: 3px solid #515d78;
}

/deep/ .creadas.status-ended .tarea-card {
	border-radius: 0 !important;
	border-left: 3px solid #4caf50;
}

/deep/ .creadas .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #515d78;
}

/deep/ .pendientes .title-section {
	color: #dcd614;
}

/deep/ .pendientes .tarea-card {
	border-left: 3px solid #dcd614;
}

/deep/ .pendientes .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #dcd614;
}

/deep/ .finalizadas .title-section {
	color: #40C2A8;
}

/deep/ .finalizadas .tarea-card {
	border-left: 3px solid #40C2A8;
}

/deep/ .finalizadas .title-card {
	border-radius: 0 !important;
	border-left: 3px solid #40C2A8;
}

.text-creadas-color {
	color: #515d78;
}

.inner-text {
	font-size: 10px;
}

.icon-top {
	padding: 2px;
	margin: 0 2px;
	border-radius: 100%;
}

.icon-top.high {
	background-color: #F4516C;
}

.icon-top.medium {
	background-color: #fb8c00;
}

.icon-top.normal {
	background-color: #3EBFA3;
}

.icon-top.primary {
	background-color: #515d78;
}

.icon-top.light-blue {
	background-color: #515d78;
}

.counter-tareas {
	background-color: #515d78;
	border-radius: 100%;
	padding: 5px;
	width: 30px;
	height: 30px;
	line-height: 20px;
	color: white;
	font-size: 20px;
	text-align: center;
}

.icon-container {
	flex-wrap: wrap;
}

.icon-container .icon-top {
	flex: 1 0 30%;
	width: 24px;
	max-width: 24px;
	height: 24px;
	max-height: 24px;
	margin-bottom: 2px;
}

.icon-container .icon-top.number {
	background-color: #515d78;
	text-align: center;
	line-height: 20px;
}

.text-task-number.caption {
	font-size: 10px !important;
}

.critical-date-container {
	background-color: #515d78;
}

.incognito-container {
	width: 0;
	height: 0;
	border-top: 25px solid #616161;
	border-right: 25px solid transparent;
	border-radius: 0 !important;
	position: absolute;
	left: 3px;
}

.incognito-container .v-icon {
	position: absolute;
	top: -22px;
	left: 1px;
}

.force-width-te {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	max-width: 100px;
}

.ellipsis-te {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}
</style>