<template>
  <div style="min-width: 168px" class="d-flex align-center order-0 order-sm-1">
    <v-menu offset-y :close-on-content-click="false" min-width="300" max-width="300">
      <template v-slot:activator="{ on: onMenu }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              fab
              dark
              x-small
              color="#f5f5f5"
              class="item-task"
              v-on="{ ...onMenu, ...onTooltip }"
            >
              <!-- <v-icon style="color: #54607a">mdi-pencil</v-icon> -->
              <span class="material-symbols-outlined"> edit_note </span>
            </v-btn>
          </template>
          <span>Modificar Tarea</span>
        </v-tooltip>
      </template>
      <div style="background: white">
        <div class="bg-light-blue-text py-1 px-2">
          <p class="caption mb-0 font-weight-bold white--text">Modificar Tarea</p>
        </div>
        <div class="pa-2">
          <div>
            <p class="mb-0 caption light-blue-text font-weight-bold">Cambiar Código</p>
            <div class="d-flex flex-row">
              <v-select 
													v-model="selectedCode"
													:items="codeburned" 
													label="Seleccione Código" 
													required
													outlined
													dense													
												>													
												</v-select>
            </div>
          </div>
          
          <div v-if="checkPermissions(['change_priority_task'])">
            <p class="mb-0 caption light-blue-text font-weight-bold">Cambiar Prioridad</p>
            <div class="d-flex flex-row">
              <v-btn
                class="mx-2 my-1"
                color="#F4516C"
                dark
                small
                :outlined="editTaksForm.priority === 3"
                @click="
                  () => {
                    editTaksForm.priority != 3
                      ? (editTaksForm.priority = 3)
                      : (editTaksForm.priority = null);
                  }
                "
                v-if="$store.consultasStore.state.currentTarea.priority != 3"
              >
                <p class="mb-0">! Inmediata</p>
              </v-btn>
              <v-btn
                class="mx-2 my-1"
                color="warning"
                dark
                small
                :outlined="editTaksForm.priority === 2"
                @click="
                  () => {
                    editTaksForm.priority != 2
                      ? (editTaksForm.priority = 2)
                      : (editTaksForm.priority = null);
                  }
                "
                v-if="$store.consultasStore.state.currentTarea.priority != 2"
              >
                <p class="mb-0">! Media</p>
              </v-btn>
              <v-btn
                class="mx-2 my-1"
                color="#3EBFA3"
                dark
                small
                :outlined="editTaksForm.priority === 1"
                @click="
                  () => {
                    editTaksForm.priority != 1
                      ? (editTaksForm.priority = 1)
                      : (editTaksForm.priority = null);
                  }
                "
                v-if="$store.consultasStore.state.currentTarea.priority != 1"
              >
                <p class="mb-0">! Estándar</p>
              </v-btn>
            </div>
          </div>
          <div v-if="checkPermissions(['change_critical_date_task'])">
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Agregar/modificar Fecha Crítica
            </p>
            <v-menu v-model="datePicker" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  :value="editTaksForm.critical_date"
                  label="Fecha Crítica"
                  prepend-inner-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  dense
                  hide-details
                  class="remove-bottom-details date-limit-input mt-2"
                  @click:clear="date = null"
                ></v-text-field>
              </template>
              <v-date-picker
                locale="es"
                v-model="editTaksForm.critical_date"
                @change="datePicker = false"
              ></v-date-picker>
            </v-menu>
          </div>
          <div v-if="isMyTask() || iAmTaskResponsible()">
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Tarea Privada
            </p>
            <div class="d-flex flex-row">
              <v-switch class="my-0" dense v-model="editTaksForm.private" hide-details>
                <template slot="label">
                  <p class="mb-0 caption">
                    {{ editTaksForm.private ? "Es Privada" : "No es Privada" }}
                  </p>
                </template>
              </v-switch>
            </div>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Estado Visualización
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Maneja con que estado quieres ver la campana asociada a esta
                  tarea.</span
                >
              </v-tooltip>
            </p>
            <div>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="success"
                :outlined="
                  $options.filters.seenByMeColor(currentTarea.seen_by_me) !== 'success'
                "
                :loading="isLoadingChangeVisualizationStatus(currentTarea.id)"
                @click.stop="changeVisualizationStatus(1)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="warning"
                :outlined="
                  $options.filters.seenByMeColor(currentTarea.seen_by_me) !== 'warning'
                "
                :loading="isLoadingChangeVisualizationStatus(currentTarea.id)"
                @click.stop="changeVisualizationStatus(2)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
              <v-btn
                fab
                x-small
                class="mx-2"
                color="high"
                :outlined="
                  $options.filters.seenByMeColor(currentTarea.seen_by_me) !== 'high'
                "
                :loading="isLoadingChangeVisualizationStatus(currentTarea.id)"
                @click.stop="changeVisualizationStatus(0)"
              >
                <v-icon small>mdi-bell</v-icon>
              </v-btn>
            </div>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Suscribir a la tarea
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Si te suscribes a una tarea recibirás notificaciones en tu correo
                  cuando ocurran cambios importantes: Prioridad, Responsable, Fechas,
                  Cierre.
                </span>
              </v-tooltip>
            </p>
            <div>
              <v-switch
                class="my-0"
                dense
                v-model="editTaksForm.subscribe_status"
                @change="changeInSubscription"
                hide-details
              >
                <template slot="label">
                  <p class="mb-0 caption">
                    {{
                      editTaksForm.subscribe_status
                        ? "Recibirás notificaciones"
                        : "No recibirás notificaciones"
                    }}
                  </p>
                </template>
              </v-switch>
              <v-switch
                class="my-0"
                dense
                v-model="editTaksForm.subscribe_status_mail"
                :disabled="!editTaksForm.subscribe_status"
                hide-details
              >
                <template slot="label">
                  <p class="mb-0 caption">
                    {{
                      editTaksForm.subscribe_status_mail
                        ? "Recibirás correos de esta tarea"
                        : "No recibirás correos de esta tarea"
                    }}
                  </p>
                </template>
              </v-switch>
            </div>
          </div>
          <div>
            <p class="mt-2 mb-0 caption light-blue-text font-weight-bold">
              Recordatorio
              <v-tooltip bottom max-width="400">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon color="#515d78" v-bind="attrs" v-on="on" small
                    >mdi-information</v-icon
                  >
                </template>
                <span
                  >Selecciona uno o más días de la semana en los que quieras que te
                  recordemos esta tarea. Aparecerá un recordatorio al ingresar a tu panel
                  LaOT.</span
                >
              </v-tooltip>
            </p>
            <div>
              <v-menu
                v-model="datePickerRemider"
                offset-y
                :close-on-content-click="false"
                max-width="290"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark small block color="#515d78" v-bind="attrs" v-on="on">
                    <v-icon dark small>mdi-clipboard-edit-outline</v-icon>
                    <p class="mb-0 ml-2">Seleccionar Días</p>
                  </v-btn>
                </template>
                <div class="grey lighten-4 pa-2">
                  <v-btn
                    v-for="(day, index) in weekDays"
                    :key="index"
                    dark
                    x-small
                    color="#515d78"
                    :outlined="!isWeekDaySelected(index)"
                    class="mx-1"
                    @click="addWeekDay(index)"
                  >
                    <p class="mb-0">{{ day }}</p>
                  </v-btn>
                  <v-switch
                    class="my-1"
                    dense
                    v-model="editTaksForm.reminder_repeatable"
                    :disabled="this.editTaksForm.reminder.length === 0"
                    hide-details
                  >
                    <template slot="label">
                      <p class="mb-0 caption">Recordar Semanalmente</p>
                    </template>
                  </v-switch>
                  <p class="mb-1 caption text--secondary">
                    Recuerda confirmar para que este recordatorio tome efecto
                  </p>
                </div>
              </v-menu>
            </div>
          </div>
          <v-divider class="my-2"></v-divider>
          <v-btn
            color="#515d78"
            raised
            small
            block
            dark
            :loading="loadingEdit"
            @click="() => modifyTarea()"
          >
            <p class="mb-0 white--text">Confirmar</p>
          </v-btn>
          <v-btn
            v-if="isMyTask()"
            class="mt-2"
            color="#F4516C"
            outlined
            x-small
            block
            dark
            @click="() => startDeleteTask()"
          >
            <p class="mb-0">Eliminar</p>
          </v-btn>
        </div>
      </div>
    </v-menu>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          color="#f5f5f5"
          class="item-task"
          v-bind="attrs"
          v-on="on"
          @click="sendByEmailModal = true"
        >
          <!-- <v-icon style="color: #54607a">mdi-email</v-icon> -->
          <span class="material-symbols-outlined"> forward_to_inbox </span>
        </v-btn>
      </template>
      <span>Enviar por correo</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
          x-small
          color="#f5f5f5"
          class="item-task"
          v-bind="attrs"
          v-on="on"
          :loading="loadingPDFDownload"
          @click="downloadPDF"
        >
          <!-- <v-icon style="color: #54607a">mdi-arrow-down-circle</v-icon> -->
          <span class="material-symbols-outlined"> picture_as_pdf </span>
        </v-btn>
      </template>
      <span>Descargar en PDF</span>
    </v-tooltip>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-if="checkPermissions(['create_rdi'])"
          fab
          x-small
          color="#f5f5f5"
          class="item-task"
          v-bind="attrs"
          v-on="on"
          @click="() => sendToRDI()"
        >
          <!-- <v-icon style="color: #54607a">mdi-send</v-icon> -->
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.00001 15.2058L18 9.52347L6.00001 3.84116V15.2058ZM8.00001 12.3646V10.4705L11 9.52347L8.00001 8.57642V6.68231L14.5 9.52347L8.00001 12.3646ZM5.00001 21.8351C4.45001 21.8351 3.97917 22.2061 3.58751 21.8351C3.19584 21.4642 3.00001 16.6737 3.00001 16.1528V2.8941C3.00001 2.37323 3.19584 1.92732 3.58751 1.55639C3.97917 1.18546 4.45001 1 5.00001 1H19C19.55 1 20.0208 1.18546 20.4125 1.55639C20.8042 1.92732 21 2.37323 21 2.8941V16.1528C21 16.6737 20.8042 21.4642 20.4125 21.8351C20.0208 22.2061 19.55 21.8351 19 21.8351H11.5H5.00001ZM5.00001 16.1528H19V2.8941H5.00001V16.1528Z"
              fill="#525D78"
            />
            <path
              d="M3 17H21V22C21 22.5523 20.5523 23 20 23H4C3.44772 23 3 22.5523 3 22V17Z"
              fill="#525D78"
            />
            <path
              d="M7.38086 17.7344H8.97168C9.29785 17.7344 9.57812 17.7832 9.8125 17.8809C10.0488 17.9785 10.2305 18.123 10.3574 18.3145C10.4844 18.5059 10.5479 18.7412 10.5479 19.0205C10.5479 19.249 10.5088 19.4453 10.4307 19.6094C10.3545 19.7715 10.2461 19.9072 10.1055 20.0166C9.9668 20.124 9.80371 20.21 9.61621 20.2744L9.33789 20.4209H7.95508L7.94922 19.7354H8.97754C9.13184 19.7354 9.25977 19.708 9.36133 19.6533C9.46289 19.5986 9.53906 19.5225 9.58984 19.4248C9.64258 19.3271 9.66895 19.2139 9.66895 19.085C9.66895 18.9482 9.64355 18.8301 9.59277 18.7305C9.54199 18.6309 9.46484 18.5547 9.36133 18.502C9.25781 18.4492 9.12793 18.4229 8.97168 18.4229H8.25977V22H7.38086V17.7344ZM9.76562 22L8.79297 20.0986L9.72168 20.0928L10.7061 21.959V22H9.76562ZM12.5342 22H11.6055L11.6113 21.3145H12.5342C12.7646 21.3145 12.959 21.2627 13.1172 21.1592C13.2754 21.0537 13.3945 20.9004 13.4746 20.6992C13.5566 20.498 13.5977 20.2549 13.5977 19.9697V19.7617C13.5977 19.543 13.5742 19.3506 13.5273 19.1846C13.4824 19.0186 13.415 18.8789 13.3252 18.7656C13.2354 18.6523 13.125 18.5674 12.9941 18.5107C12.8633 18.4521 12.7129 18.4229 12.543 18.4229H11.5879V17.7344H12.543C12.8281 17.7344 13.0889 17.7832 13.3252 17.8809C13.5635 17.9766 13.7695 18.1143 13.9434 18.2939C14.1172 18.4736 14.251 18.6885 14.3447 18.9385C14.4404 19.1865 14.4883 19.4629 14.4883 19.7676V19.9697C14.4883 20.2725 14.4404 20.5488 14.3447 20.7988C14.251 21.0488 14.1172 21.2637 13.9434 21.4434C13.7715 21.6211 13.5654 21.7588 13.3252 21.8564C13.0869 21.9521 12.8232 22 12.5342 22ZM12.1035 17.7344V22H11.2246V17.7344H12.1035ZM16.0615 17.7344V22H15.1855V17.7344H16.0615Z"
              fill="#EAEAEA"
            />
          </svg>
        </v-btn>
      </template>

      <span>Enviar a RDI</span>
    </v-tooltip>
    <AcceptCancelModal
      :acceptRejectModal="acceptRejectModal"
      :acceptRejectModalData="acceptRejectModalData"
      :closeModal="closeModalAcceptCancel"
    />
    <SendContentByEmailModal
      :sendContentByEmailModal="sendByEmailModal"
      :closeModal="() => (sendByEmailModal = false)"
      :sendingType="1"
    />
  </div>
</template>

<script>
import {
  updateTask,
  downloadPDF,
  deleteTask,
  changeVisualizationStatus,
} from "@/helpers/api/tasks";
import SendContentByEmailModal from "@/components/Globals/Modals/General/SendContentByEmailModal";
import AcceptCancelModal from "@/components/Globals/Modals/General/AcceptCancelModal";

export default {
  name: "HaderButtons",
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  components: { SendContentByEmailModal, AcceptCancelModal },
  computed: {
    currentTarea() {
      return this.$store.consultasStore.state.currentTarea;
    },
  },
  watch: {
    currentTarea() {
      this.setFormData();
    },
  },
  data: () => ({
    codeburned: [
			'Accidente', 
			'Auditoria',
			'BIM',
			'Compras',
			'Contratación de personal',
			'Control de áreas',
			'Control documentos',
			'Desvinculación de personal',
			'Ensayo de calidad',
			'Entrega de turno',
			'Estados de Pago Cliente',
			'Estados de Pago Proveedor',
			'Equipos y Herramientas',
			'Hallazgo de calidad',
			'Hallazgo de seguridad',
			'Inducción',
			'Informes',
			'Kick-Off',
			'Nota de cambio',
			'Permiso de trabajo',
			'Presupuesto Cliente',
			'Presupuesto Proveedor',
			'Procedimiento de trabajo',
			'Programa',
			'Restricción',
			'Seguridad',
			'Solicitud interna de materiales',
			'Soporte técnico',
			'Subcontratos'			
				], 
    loadingEdit: false,
    selectedCode:null,
    datePicker: false,
    datePickerRemider: false,
    sendByEmailModal: false,
    loadingPDFDownload: false,
    acceptRejectModalData: null,
    acceptRejectModal: false,
    editTaksForm: {
      priority: null,
      critical_date: null,
      private: false,
      subscribe_status: false,
      subscribe_status_mail: false,
      reminder: [],
      reminder_repeatable: true,
    },
    weekDays: ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"],
  }),
  mounted() {
    this.setFormData();
  },
  methods: {
    setFormData() {
      const tarea = this.$store.consultasStore.state.currentTarea;
      if (tarea && !tarea.loading && tarea.id) {
        this.editTaksForm.private = tarea.private;
        this.editTaksForm.subscribe_status = !!tarea.user_subscribed;
        this.editTaksForm.subscribe_status_mail =
          tarea.user_subscribed && tarea.user_subscribed.email;
        this.editTaksForm.reminder = tarea.user_reminders.map((day) => day.day_number);
        this.editTaksForm.reminder_repeatable = tarea.user_reminders.some(
          (day) => day.repeatable
        );
      }
    },
    isMyTask() {
      return (
        this.$store.consultasStore.state.currentTarea.created_by ==
        this.$store.userStore.state.userInfo.id
      );
    },
    iAmTaskResponsible() {
      return (
        this.$store.consultasStore.state.currentTarea.responsable ==
        this.$store.userStore.state.userInfo.id
      );
    },
    changeInSubscription(value) {
      this.editTaksForm.subscribe_status_mail = value;
    },
    async modifyTarea() {
      this.loadingEdit = true;
      this.editTaksForm.id = this.$store.consultasStore.state.currentTarea.id;
      this.editTaksForm.code = this.selectedCode;
      const taskUpdate = await updateTask(this.editTaksForm);
      if (taskUpdate.code == 200) {
        this.$store.consultasStore.commit("setCurrentTarea", taskUpdate.task);
        this.getConsultasList();
        const data = {
          message: "Se ha modificado correctamente la tarea",
          title: "Tarea modificada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
      this.loadingEdit = false;
    },
    async getConsultasList() {
      let project_id = null;
      if (this.$store.projectsStore.state.selectedProject) {
        project_id = this.$store.projectsStore.state.selectedProject.id;
      } else {
        const id = localStorage.getItem("selectedProjectID");
        project_id = parseInt(id);
      }
      if (project_id) {
        this.$store.consultasStore.dispatch("getMyTasks", { project_id });
      }
    },
    minimizeTask() {
      const data = {
        task_id: this.$store.consultasStore.state.currentTarea.id,
        title: this.$store.consultasStore.state.currentTarea.title,
        type: "Consulta/Restricción",
        type_id: 1,
        number: this.$store.consultasStore.state.currentTarea.number,
      };
      this.$store.globalStore.commit("addTaskToTasksHandler", data);
      this.closeModal();
    },
    async downloadPDF() {
      this.loadingPDFDownload = true;
      const pdf_url = await downloadPDF({
        task_id: this.$store.consultasStore.state.currentTarea.id,
      });
      if (pdf_url.code == 200) {
        window.open(pdf_url.pdf_url);
      }
      this.loadingPDFDownload = false;
    },
    startDeleteTask() {
      this.acceptRejectModalData = {
        title:
          "¿Eliminar la tarea Nº " +
          this.$store.consultasStore.state.currentTarea.number +
          "?",
        content_a: "Por favor confirma si deseas elliminar la tarea indicada.",
      };
      this.acceptRejectModal = true;
    },
    async deleteTask() {
      const task = await deleteTask({
        task_id: this.$store.consultasStore.state.currentTarea.id,
      });
      if (task.code === 200) {
        this.getConsultasList();
        this.$store.consultasStore.commit("setCurrentTarea", null);
        const data = {
          message: "Se ha eliminado correctamente la tarea",
          title: "Tarea eliminada",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", data);
        this.$store.notificationsStore.dispatch("getReminders");
      }
    },
    closeModalAcceptCancel(status = false) {
      this.acceptRejectModal = false;
      if (status) {
        this.deleteTask();
      }
      this.acceptRejectModalData = null;
    },
    addWeekDay(index) {
      const found = this.editTaksForm.reminder.findIndex(
        (itemReminder) => itemReminder === index
      );
      if (found !== -1) {
        this.editTaksForm.reminder.splice(found, 1);
      } else {
        this.editTaksForm.reminder.push(index);
      }
      if (this.editTaksForm.reminder.length === 0) {
        this.editTaksForm.reminder_repeatable = false;
      }
    },
    isWeekDaySelected(index) {
      return this.editTaksForm.reminder.includes(index);
    },
    async changeVisualizationStatus(newStatus) {
      const task = this.$store.consultasStore.state.currentTarea;
      if (!this.isLoadingChangeVisualizationStatus(task.id)) {
        this.$store.consultasStore.commit("addLoadingChangeVisualizationStatus", task.id);
        const data = {
          task_id: task.id,
          status: newStatus,
        };
        const statusUpdate = await changeVisualizationStatus(data);
        if (statusUpdate.code === 200) {
          // Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
          let newTaskData = Object.assign({}, task);
          newTaskData.seen_by_me.seen_status = newStatus;
          this.$store.consultasStore.dispatch("updateTaskLocally", newTaskData);
        }
        this.$store.consultasStore.commit(
          "removeLoadingChangeVisualizationStatus",
          task.id
        );
      }
    },
    isLoadingChangeVisualizationStatus(taskID) {
      const found = this.$store.consultasStore.state.loadingChangeVisualizationStatus.find(
        (task_id) => task_id === taskID
      );
      return !!found;
    },
    sendToRDI() {
      const data = {
        title: this.$store.consultasStore.state.currentTarea.title,
        description: this.$store.consultasStore.state.currentTarea.description,
        task_id: this.$store.consultasStore.state.currentTarea.id,
      };
      this.$store.rdiStore.commit("setInitialDataRDI", data);
      this.$store.rdiStore.commit("setCreateRDIModal", true);
    },
  },
};
</script>

<style scoped>
.bg-light-blue-text {
  background-color: #515d78;
}
.icon-top {
  padding: 2px;
  margin: 0 2px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.icon-top.high {
  background-color: #f4516c;
}
.icon-top.medium {
  background-color: #fb8c00;
}
.icon-top.normal {
  background-color: #3ebfa3;
}
.icon-top.primary {
  background-color: #515d78;
}
.icon-top.light-blue {
  background-color: #515d78;
}
.high--text {
  color: #f4516c !important;
  caret-color: #f4516c !important;
}
.high {
  background-color: #f4516c !important;
  border-color: #f4516c !important;
  color: white;
}

.item-task {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  margin: 5px;
  box-shadow: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.item-task:hover {
  background-color: #e5e9f2 !important;
}
/* icons */
.material-symbols-outlined {
  color: #54607a;
}
</style>
