import Vue from 'vue'
import Vuex from 'vuex'
import {getSystemCurrencies} from '@/helpers/api/general'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		systemCurrencies: {
			loading: false,
			currencies: []
		}
	},
	mutations: {
		setSystemCurrencies(state, currencies){
			state.systemCurrencies = currencies
		}
	},
	actions: {
		async getSystemCurrencies(context){
			context.commit('setSystemCurrencies', {
				loading: true,
				currencies: []
			})
			const currencies = await getSystemCurrencies()
			context.commit('setSystemCurrencies', {
				loading: false,
				currencies: currencies.code == 200 ? currencies.currencies:[]
			})
		}
	},
})

export default store