<template>
	<div class="payment-status-container pa-2"
			:style="disabled ? 'opacity: 0.5':''"
			v-if="paymentStatus">
		<v-menu
			absolute
			offset-y
			:close-on-content-click="false"
			style="max-width: 600px"
		>
			<template v-slot:activator="{ on, attrs }">
				<div class="caption"
					v-bind="attrs"
					v-on="on">
					<div class="font-weight-bold"><span :class="statusColorClass(paymentStatus)">{{paymentStatus.statustext}}</span></div>
					<div class="d-flex flex-row align-center mr-1">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="#515d78" class="mr-1" small
									v-bind="attrs"
									v-on="on">mdi-progress-check</v-icon>
							</template>
							<span>Porcentaje de avance informado</span>
						</v-tooltip>
						<v-progress-linear
							:value="paymentStatus.progress*100"
							color="primary"
							height="12"
							rounded
						>
							<small :class="paymentStatus.progress < 0.43 ? '':'white--text'">{{ Math.ceil(paymentStatus.progress*100) }}%</small>
						</v-progress-linear>
					</div>
					<div class="d-flex flex-row align-center mt-1 mr-1">
						<v-tooltip bottom>
							<template v-slot:activator="{ on, attrs }">
								<v-icon color="#515d78" class="mr-1" small
									v-bind="attrs"
									v-on="on">mdi-currency-usd</v-icon>
							</template>
							<span>Monto negociado para el estado de pago</span>
						</v-tooltip>
						<p class="mb-0 mr-2 normal font-weight-bold">{{additional.last_revision.currency_info.code}}${{paymentStatus.amount}}</p>
						<v-progress-circular
							:size="20"
							:width="6"
							:value="paymentStatus.amount*100/additional.last_revision.total_requested"
							color="#3EBFA3"
						/>
					</div>
				</div>
			</template>
			<PaymentStatusInteraction :paymentStatus="paymentStatus" :additional="additional" />
		</v-menu>
	</div>
</template>

<script>
import PaymentStatusInteraction from './PaymentStatusInteraction'
export default {
	name: 'AdditionalPaymentStatusIncluded',
	components: {PaymentStatusInteraction},
	props: {
		additional: {
			type: Object,
			required: true
		},
		additional_included: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	computed: {
		paymentStatus(){
			const found = this.additional_included.find((additional_included) => {
				return additional_included.additional_id == this.additional.id
			})
			return found
		}
	},
	data: () => ({
	}),
	methods: {
		statusColorClass(additional){
			if([3, 6].includes(additional.status)){ return 'normal' }
			else if([1, 2].includes(additional.status)){ return 'medium' }
			return 'high'
		},
	}
}
</script>


<style scoped>
	#listado-consultas{align-self: stretch;}
	.text-primary-color{
		color: #515d78;
	}
	.light-blue-text{color: #515d78;}
	.bg-normal{ background-color: #3EBFA3; }
	.inner-text{
		font-size: 10px;
	}
	.status-container{
		position: relative;
	}
	.icon-top.icon-status{
		position: absolute;
		width: 20px;
		height: 20px;
		left: -20px;
	}
	.icon-top{
		padding: 2px;
		margin: 0 2px;
		border-radius: 100%;
	}
	.high{
		color: #F4516C;
	}
	.medium{
		color: #fb8c00;
	}
	.normal{
		color: #3EBFA3;
	}
	.payment-status-container:hover{
		box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
	}
</style>