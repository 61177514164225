import {endpoints} from './endpoints'
import request from '../../request'

export function search(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.search,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getNotifications(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getNotifications,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function markNotificationAsReaded(data) {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.markNotificationAsReaded,
			method: 'post',
			data
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getReminders() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getReminders,
			method: 'post'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}

export function getSystemCurrencies() {
	return new Promise((resolve, reject) => {
		request({
			url: endpoints.getSystemCurrencies,
			method: 'get'
		})
			.then(async function (response) {
				resolve(response)
			})
			.catch(function (error) {
				reject(error)
			})
	})
}