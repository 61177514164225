<template>
	<v-container class="d-flex flex-column align-start" fluid fill-height>
		
		<!-- <v-btn class="mx-2 my-1 createProyect" rounded color="#34BFA3" dark small
			
			@click="() => $store.projectsStore.commit('setNewProjectModal', true)">
			<v-icon dark small>mdi-square-edit-outline</v-icon>
			<p class="mb-0 ml-2">Nuevo Proyecto</p>
		</v-btn> -->
		<FullHeader :headerType="2"/>
		<ProjectListIndex  />
	</v-container>
</template>

<script>
import FullHeader from '../../Globals/Headers/FullHeader'
import ProjectListIndex from './ProjectListIndex'

export default {
	name: 'ProjectsIndex',
	components: {FullHeader,ProjectListIndex},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>
