<template>
	<v-container fluid pa-0>
		<v-row align="center" :gutter="8" v-if="!$vuetify.breakpoint.mobile">
			<v-col cols="4">
				<v-btn
					rounded
					color="#515d78"
					dark
					small
					block
					@click="() => $store.projectsStore.commit('setListingType', 1)"
				>
					<v-icon dark small class="mr-2">mdi-grid-large</v-icon>
					<p class="mb-0">Vista Cuadrícula</p>
				</v-btn>
			</v-col>
			<v-col cols="4">
				<v-btn
					rounded
					color="#515d78"
					dark
					small
					block
					@click="() => $store.projectsStore.commit('setListingType', 2)"
				>
					<v-icon dark small class="mr-2">mdi-format-list-checkbox</v-icon>
					<p class="mb-0">Vista Lista</p>
				</v-btn>
			</v-col>
			<!-- <v-col cols="4">
				<v-btn
					rounded
					color="#515d78"
					dark
					small
					block
					@click="() => $store.projectsStore.commit('setListingType', 3)"
				>
					<v-icon dark small class="mr-2">mdi-table</v-icon>
					<p class="mb-0">Vista Gerencial</p>
				</v-btn>
			</v-col> -->
		</v-row>
    <v-menu offset-y v-else>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
					rounded
					color="#515d78"
					dark
					small
					block
          v-bind="attrs"
          v-on="on"
        >
          Seleccione Vista
        </v-btn>
      </template>
      <v-list>
        <v-list-item
					@click="() => $store.projectsStore.commit('setListingType', 1)">
          <v-list-item-title class="d-flex">
						<v-icon small class="mr-2">mdi-grid-large</v-icon>
						<p class="mb-0">Vista Cuadrícula</p>
					</v-list-item-title>
        </v-list-item>
        <v-list-item
					@click="() => $store.projectsStore.commit('setListingType', 2)">
          <v-list-item-title class="d-flex">
						<v-icon small class="mr-2">mdi-format-list-checkbox</v-icon>
						<p class="mb-0">Vista Lista</p>
					</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item
					@click="() => $store.projectsStore.commit('setListingType', 3)">
          <v-list-item-title class="d-flex">
						<v-icon small class="mr-2">mdi-table</v-icon>
						<p class="mb-0">Vista Gerencial</p>
					</v-list-item-title>
        </v-list-item> -->
      </v-list>
    </v-menu>
	</v-container>
</template>

<script>

export default {
	name: 'HeaderSelectListingType',
	components: {
	},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.info-container{
		color: #606060;
	}
	.info-container b{
		color: #515d78;
	}
	.info-icon{
		background-color: #515d78;
		border-radius: 25px;
		padding: 3px;
		width: 25px;
		height: 25px;
	}
	.info-img{
		width: 100%;
		max-height: 50px;
		object-fit: contain;
	}
</style>
