<template>
	<v-container class="d-flex flex-row justify-end task-manager-container pa-0">
		<div class="task-manager-inner-container py-1 pl-4 pr-6 mx-1"
			v-for="(task, index) in $store.globalStore.state.openTasksHandler"
			:key="index"
			@click="handleView(task, index)">
			<p class="caption mb-0 light-blue-text font-weight-bold"><span v-if="task.number">#{{task.number}} - </span>{{task.title}}</p>
			<p class="mb-0 text--secondary type-text">{{task.type}}</p>
			<v-icon class="close-icon" color="#515d78" small @click.stop="closeTask(index)">mdi-close-circle</v-icon>
		</div>
	</v-container>
</template>

<script>
import {getTareaByID} from '@/helpers/api/tasks'
import {getRdiByID} from '@/helpers/api/rdi'
export default {
	name: 'TasksManager',
	components: { },
	filters: {
	},
	data: () => ({}),
	mounted(){
	},
	methods: {
		closeTask(index){
			this.$store.globalStore.commit('removeTaskFromTasksHandler', index)
		},
		handleView(task, index){
			if(task.type_id == 1){
				this.viewTarea(task.task_id, index)
			}
			else if(task.type_id == 2){
				this.viewRDI(task.task_id, index)
			}
			else if(task.type_id == 3){
				this.$store.consultasStore.commit('setBorradorTarea', task.newTaskForm)
				this.$store.consultasStore.commit('setCreateTareaModal', true)
				this.closeTask(index)
			}
			else if(task.type_id == 4){
				this.$store.rdiStore.commit('setBorradorRDI', task.newRDIForm)
				this.$store.rdiStore.commit('setCreateRDIModal', true)
				this.closeTask(index)
			}
		},
		async viewTarea(task_id,index){
			this.closeTask(index)
			this.$store.consultasStore.commit('setCurrentTarea', {loading: true})
			const tarea = await getTareaByID({task_id})
			if(tarea.code === 200){
				let tareaData = tarea.task
				tareaData.prev_seen_by_me = false
				this.$store.consultasStore.commit('setCurrentTarea', tareaData)
			}
		},
		async viewRDI(rdi_id, index){
			this.closeTask(index)
			this.$store.rdiStore.commit('setCurrentRDI', {loading: true})
			const rdi = await getRdiByID({rdi_id})
			if(rdi.code === 200){
				let rdiData = rdi.rdi
				rdiData.prev_seen_by_me = false
				this.$store.rdiStore.commit('setCurrentRDI', rdiData)
			}
		}
	}
};
</script>

<style scoped>
	.text-primary{color: #515d78;}
	.light-blue-text{color: #515d78;}
	.task-manager-container{
		position: fixed;
		bottom: 0;
		right: 30px;
		max-height: 50px;
	}
	.task-manager-inner-container{
		cursor: pointer;
		position: relative;
		background-color: white;
		border: 1px solid #cacaca;
		border-bottom: 0;
		border-top-left-radius: 8px;
		border-top-right-radius: 8px;
		min-width: 170px;
	}
	.close-icon{
		position: absolute;
		top: 1px;
		right: 1px;
		z-index: 99;
	}
	.type-text{
		font-size: 10px;
	}
</style>