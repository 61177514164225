<template>
  <v-navigation-drawer v-model="$store.menuStore.state.menuSidebarStatus" fixed
    :mini-variant="$store.menuStore.state.sidebarMini" :width="$store.menuStore.state.sidebarMini ? '50' : '230'">
    <v-icon dark class="toggle-button pa-1" @click="toggleMenu">mdi-menu</v-icon>
    <div style="width: 100%; background-color: #f4f5f7" class="fill-height">
      <v-row no-gutters justify="center">
        <v-col class="head-sidebar text-center pa-4">
          <!-- <img :src="require('../../assets/Svg/logoH.svg')" style="max-width: 110px; width: 100%;"/> -->
          <div v-if="$store.userStore.state.userInfo && !$store.menuStore.state.sidebarMini">
            <div v-if="$store.projectsStore.state.selectedProject">
              <v-row class="d-flex">
                <v-col class="d-flex" cols="2">
                  <v-btn class="mr-2" depressed fab small>
                    <div class="img-contain">
                      <img v-if="$store.projectsStore.state.selectedProject"
                        @error="handleImageError"
                        :src="$store.projectsStore.state.selectedProject.project_image" class="project-avatar-sidebar"
                        :class="$store.menuStore.state.sidebarMini ? 'mini' : ''" />
                      <img v-else src="../../assets/Svg/panel-laot.svg" alt="Avatar laot" />
                    </div>
                  </v-btn>
                </v-col>
                <v-col class="text-start align-center pl-5" cols="10">
                  <p class="mb-0 txt1">PROYECTO</p>
                  <p class="mb-0 txt2" v-if="$store.projectsStore.state.selectedProject">
                    {{ $store.projectsStore.state.selectedProject.name }}
                  </p>
                </v-col>
              </v-row>
            </div>

            <div class="sidebar-avatars-container">
              <v-row class="d-flex">
                <v-col class="d-flex" cols="2" style="align-items: center">
                  <AvatarUser :user="$store.userStore.state.userInfo"></AvatarUser>
                </v-col>
                <v-col class="text-start pl-5" cols="10">
                  <p class="mb-0 txt1">
                    {{ $store.userStore.state.userInfo.name }}
                  </p>
                  <p class="mb-0 txt2" v-if="$store.projectsStore.state.selectedProject &&
    $store.projectsStore.state.selectedProject.job_type
    ">
                    {{ $store.projectsStore.state.selectedProject.job_type.name }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </div>
          <div v-else>
            <div class="project-sidebar-mini">
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed fab small v-bind="attrs" v-on="on">
                    <div class="img-contain">
                      <img v-if="$store.projectsStore.state.selectedProject"
                        :src="$store.projectsStore.state.selectedProject.project_image" class="project-avatar-sidebar"
                        :class="$store.menuStore.state.sidebarMini ? 'mini' : ''" />
                      <img v-else src="../../assets/Svg/panel-laot.svg" alt="Avatar laot" />
                    </div>
                  </v-btn>
                </template>
                <p class="mb-0 caption font-weight mr-1 body-2" v-if="$store.projectsStore.state.selectedProject">
                  {{ $store.projectsStore.state.selectedProject.name }}
                </p>
              </v-tooltip>
            </div>
          </div>
        </v-col>
      </v-row>
      <Menu></Menu>
      <div class="mt-5 px-1">
        <!-- <v-btn color="#515d78" dark block :small="$store.menuStore.state.sidebarMini" @click="logout()">
          <v-icon>mdi-power</v-icon>
          <p class="mb-0 ml-2 body-2" v-show="!$store.menuStore.state.sidebarMini">
            Salir de laOT
          </p>
        </v-btn> -->
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import Menu from "./Menu/Index";
import AvatarUser from "../Layout/Ui/AvatarUser.vue"
export default {
  name: "Sidebar",
  components: { Menu, AvatarUser },
  data: () => ({}),
  methods: {
    handleImageError(event) {
			// Se ejecuta cuando la imagen no se puede cargar
			console.log('No se pudo cargar la imagen');
			// Establecer la URL de la imagen por defecto
			event.target.src = 'https://storage.googleapis.com/bucket-db-favric/ms_bim/bg-default-bin.png';
		},
    toggleMenu() {
      if (this.$vuetify.breakpoint.mobile) {
        this.$store.menuStore.commit(
          "setMenuSidebarStatus",
          !this.$store.menuStore.state.menuSidebarStatus
        );
        return null;
      }
      this.$store.menuStore.commit("toggleSidebarMini");
    },
    logout() {
      this.$store.userStore.commit("logoutUser");
      this.$router.push({ name: "Login" });
      window.location.reload();
    },
    async changeProject(index) {
      const projectID = this.$store.projectsStore.state.projectsList[index].id;
      const companyID = this.$store.projectsStore.state.projectsList[index].company_id;
      await this.$store.projectsStore.dispatch("getSelectedProject", projectID);
      if (this.$store.userStore.state.userCompanies.length > 0) {
        const found = this.$store.userStore.state.userCompanies.find((company) => {
          return (company.company_id = companyID);
        });
        if (found) {
          this.$store.userStore.commit("setCompanyPermissions", found.capabilities);
          this.$store.userStore.commit("setSelectedCompany", found);
        }
      }

      this.getConsultasList(projectID);
      this.getRdiList(projectID);
      // this.getAdditionalList(projectID);
      this.getDashboardInfo();
      this.getProjectUserList();
    },
    async getConsultasList(project_id) {
      if (project_id) {
        this.$store.consultasStore.dispatch("getMyTasks", { project_id });
      }
    },
    async getRdiList(project_id) {
      if (project_id) {
        this.$store.rdiStore.dispatch("getRDI", { project_id });
      }
      if (this.$store.rdiStore.state.selectedType == 4) {
        this.$store.rdiStore.dispatch("getRdiGraphsData", { project_id });
      } else {
        this.$store.rdiStore.dispatch("getRDI", { project_id });
      }
    },
    async getAdditionalList(project_id) {
      if (!project_id) {
        return null;
      }
      if (this.$store.adicionalesStore.state.selectedType == 4) {
        this.$store.adicionalesStore.dispatch("getAdditionalsLikely", { project_id });
      }
      if (this.$store.adicionalesStore.state.selectedType == 3) {
        this.$store.adicionalesStore.dispatch("getAdditionalInformativeTable", {
          project_id,
        });
      } else {
        this.$store.adicionalesStore.dispatch("getAdditionals", { project_id });
      }
    },
    getDashboardInfo() {
      this.$store.projectsStore.dispatch("getDashboardInfo");
      this.$store.fileManagerStore.dispatch("getProjectStorageSize", {
        project_id: this.$store.projectsStore.state.selectedProject.id,
      });
    },
    getProjectUserList() {
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        start: 0,
        limit: 20,
      };
      this.$store.usersStore.dispatch("getUsersList", data);
    },
    editUser(user) {
      let user_item = this.$store.usersStore.state.usersList.find(obj => obj.id === user.id);
      this.$store.usersStore.commit('setSelectedUserInfo', user_item)
      this.$store.usersStore.commit('setEditUserModal', true)
    }
  },
};
</script>
<style>
.v-navigation-drawer {
  margin-top: 70px;
}

.head-sidebar {
  margin: 15px 0 15px 10px;
}

.toggle-button {
  position: absolute !important;
  top: 0;
  right: 0;
  background: #515d78;
}

.v-skeleton-loader__bone {
  margin-right: auto;
  margin-left: auto;
}

.sidebar-avatars-container {
  display: flex;
  justify-content: start;
  margin-top: 20px !important;
  position: relative;
  margin: 0;
}

/* 
.user-avatar {
	width: 55px;
	height: auto;
	border-radius: 50%;
	object-fit: cover;
} */

.txt1 {
  font-size: 0.8rem !important;
  color: #525D78 !important;
  font-weight: 600 !important;
  margin-left: 3px !important;
}

.txt2 {
  font-size: 0.8rem !important;
  color: #b6b9bc !important;
  font-weight: 400 !important;
  margin-left: 3px !important;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

/* .user-avatar.mini {
	width: 30px;
	height: 30px;
	border-radius: 30px;
} */

.user-avatar.icon {
  background-color: #f0f2f8;
}

.info-icon {
  background-color: #515d78;
  border-radius: 25px;
  padding: 3px;
  width: 22px !important;
  height: 22px !important;
}

.img-contain{
  width: 40px;
  height: 40px;
}


.project-avatar-sidebar {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
}

.project-sidebar-mini {
  margin: 15px 0 -20px -20px !important;
}
</style>
