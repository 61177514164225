<template>
	<div>
		<v-list-item link class="menu-item-inner d-flex align-center mb-1" :class="currentRouteName === item.routeName ? 'active':''"
			:to="{name: item.routeName}" :style="!item.routeName ? 'opacity: 0.4':''" :inactive="!item.routeName">
			<v-icon class="menu-item-icon" style="color: #515d78">{{item.icon}}</v-icon>
			<p class="mb-0 ml-2 menu-item-text body-2">{{item.name}}</p>
		</v-list-item>
		<div v-if="showSubItem && checkCompanyLevelPermissions(['status_project'])">
			<v-list-item v-for="(subitem, index) in item.subMenu" :key="index" link class="menu-item-inner submenu d-flex align-center" :class="currentRouteName === subitem.routeName ? 'active':''"
				:to="{name: subitem.routeName}" :style="!subitem.routeName ? 'opacity: 0.4':''" :inactive="!subitem.routeName">
				<v-icon class="menu-item-icon">{{subitem.icon}}</v-icon>
				<p class="mb-0 ml-2 menu-item-text body-2">{{subitem.name}}</p>
			</v-list-item>
		</div>
	</div>
</template>

<script>
export default {
	name: 'MenuItem',
	props: ['item'],
	data: () => ({}),
	computed: {
    currentRouteName() {
        return this.$route.name;
    },
		showSubItem(){
			const parent = this.currentRouteName === this.item.routeName
			const sub = this.item.subMenu && this.item.subMenu.some((menu) => {
				return this.currentRouteName === menu.routeName
			})
			return parent || sub
		}
	}
};
</script>

<style scoped>
	.menu-item-inner{
		position: relative;
		padding-left: 5px;
		width: 100%;
	}
	.menu-item-inner:hover, .menu-item-inner.active{
		/* background-color: #B1BCD8!important; */
		background-color: #e3e8f0 !important;
		border-radius: 4px;
		border-right: 5px #515d78 solid;
	}
	.menu-item-inner.submenu{
		background-color: #e8efff;
	}
	.menu-item-inner:hover:before, .menu-item-inner.active:before{
		content: '';
		width: 5px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		/* background-color: #515d78; */
		/* opacity: 1!important; */
	}
	.menu-item-inner{
		padding-left: 10%;
	}
	.menu-item-inner.submenu{
		padding-left: 14%;
	}
	.menu-item-inner .menu-item-text{
		/* color: #606060; */
		color: #515d78 !important;
		font-weight: bold;
	}
	.menu-item-inner:hover .menu-item-icon,
	.menu-item-inner:hover .menu-item-text,
	.menu-item-inner.active .menu-item-icon,
	.menu-item-inner.active .menu-item-text{
		color: #515d78 ;
	}
	.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before{display: none;}
</style>
