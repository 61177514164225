import Vue from 'vue'
import Vuex from 'vuex'
import {list, getRDIGraphs} from '@/helpers/api/rdi'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		listadoRDI: {
			status: 99,
			count: 0,
			total_rdi: 0,
			data: []
		},
		listadoRDITotal: 0,
		createRDIModal: false,
		listingOrder: 1,
		selectedType: 2,
		currentRDI: null,
		initialDataRDI: null,
		borradorRDI: null,
		rdiGraphsDataCountBySpecialty: {
			status: 99,
			data: null
		},
		loadingChangeVisualizationStatus: []
	},
	mutations: {
		setListadoRDI(state, rdis){
			state.listadoRDI = rdis
			state.listadoRDITotal = rdis.total_rdi
		},
		setCurrentRDI(state, rdi){
			state.currentRDI = rdi
		},
		setBorradorRDI(state, rdi){
			state.borradorRDI = rdi
		},
		setSelectedType(state, type){
			state.selectedType = type
		},
		setListingOrder(state){
			let currentStatus = state.listingOrder
			if(currentStatus === 4){
				currentStatus = 1
			}
			else{
				currentStatus++
			}
			state.listingOrder = currentStatus
		},
		setCreateRDIModal(state, status){
			state.createRDIModal = status
		},
		setInitialDataRDI(state, data){
			state.initialDataRDI = data
		},
		setRdiGraphsDataCountBySpecialty(state, data){
			state.rdiGraphsDataCountBySpecialty = data
		},
		addLoadingChangeVisualizationStatus(state, newItemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const found = changingStatus.find((item) => item === newItemID)
			if(!found){
				changingStatus.push(newItemID)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		},
		removeLoadingChangeVisualizationStatus(state, itemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const foundIndex = changingStatus.findIndex((item) => item === itemID)
			if(foundIndex !== -1){
				changingStatus.splice(foundIndex, 1)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		}
	},
	actions: {
		async getRdiGraphsData(context, query){
			const data = {
				project_id: query.project_id
			}
			let graphsData = Object.assign(context.state.rdiGraphsDataCountBySpecialty, {status: 99})
			context.commit('setRdiGraphsDataCountBySpecialty', graphsData)
			const graphs = await getRDIGraphs(data)
			if(graphs.code === 200){
				context.commit('setRdiGraphsDataCountBySpecialty', {
					status: 1,
					data: {
						specialties: graphs.specialties,
						time_average: graphs.time_average,
						rdis_count: graphs.rdis_count,
						rdis_pending: graphs.rdis_pending,
						rdis_ended: graphs.rdis_ended,
					}
				})
			}
		},
		async getRDI(context, query){
			let status = 1
			let page = 1
			let per_page = 50
			if(context.state.selectedType === 3){
				status = 3
			}
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				status,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page,
				filtered_specialties: query.filtered_specialties,
				filtered_subjects: query.filtered_subjects,
				filtered_responsable: query.filtered_responsable,
			}

			let listing = Object.assign(context.state.listadoRDI, {status: 99})
			context.commit('setListadoRDI', listing)
			const rdis = await list(data)
			console.log('rdis', rdis);
			if(rdis.code === 200){
				context.commit('setListadoRDI', {
					status: 1,
					count: rdis.rdi.length,
					data: rdis.rdi,
					total_rdi: rdis.total_rdi,
				})
			}
		},
		updateRdiLocally(context, newRdiData){
			const rdiIndex = context.state.listadoRDI.data.findIndex((rdi) => { return rdi.id === newRdiData.id })
			if(rdiIndex !== -1){
				const rdis = [...context.state.listadoRDI.data]
				rdis[rdiIndex] = newRdiData
				context.commit('setListadoRDI', {
					status: 1,
					count: context.state.listadoRDI.count,
					data: rdis,
					total_rdi: context.state.listadoRDI.total_rdi,
				})
			}
		}
	},
	getters: {
		currentTarea: state => {
			return state.currentTarea
		}
	}
})

export default store