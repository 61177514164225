<template>
  <div
    class="d-flex justify-end align-center order-0 order-sm-1 bts-closes-minimize"
  >
    <v-btn class="mx-2" fab dark x-small color="#fb8c00" @click="minimizeRdi">
      <v-icon dark>mdi-window-minimize</v-icon>
    </v-btn>
    <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "MinimizeCloseBtn",
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    currentRDI() {
      return this.$store.rdiStore.state.currentRDI;
    },
  },
  methods: {
    minimizeRdi(){
      const data = {
        task_id: this.$store.rdiStore.state.currentRDI.id,
        title: this.$store.rdiStore.state.currentRDI.title,
        type: 'RDI',
        type_id: 2,
        number: this.$store.rdiStore.state.currentRDI.number
      }
      this.$store.globalStore.commit('addTaskToTasksHandler', data)
      this.closeModal()
    },

  }
};
</script>

<style scoped>
.bts-closes-minimize{
  min-width: 168px; 
  margin-top: -20px; 
  margin-right: -10px;
}
.bg-light-blue-text {
  background-color: #515d78;
}
.icon-top {
  padding: 2px;
  margin: 0 2px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.icon-top.high {
  background-color: #f4516c;
}
.icon-top.medium {
  background-color: #fb8c00;
}
.icon-top.normal {
  background-color: #3ebfa3;
}
.icon-top.primary {
  background-color: #515d78;
}
.icon-top.light-blue {
  background-color: #515d78;
}
.high--text {
  color: #f4516c !important;
  caret-color: #f4516c !important;
}
.high {
  background-color: #f4516c !important;
  border-color: #f4516c !important;
  color: white;
}

@media (max-width:600px){
  .bts-closes-minimize{
    min-width: 10px;
    margin-top: 10px;
    margin-right: 15px;
  }
}
</style>
