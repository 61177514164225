<template>
	<v-container fluid pa-0>
		<v-row align="center" no-gutters
			v-if="$store.projectsStore.state.selectedProject == 1">
				<div class="info-container d-flex flex-row text-center justify-center align-center py-1" style="width: 100%">
					<v-progress-circular
						:size="15"
						:width="2"
						color="#515d78"
						indeterminate
					></v-progress-circular>
					<p class="mb-0 body-2 ml-2">Cargando información</p>
				</div>
		</v-row>
		<v-row align="center" no-gutters
			v-else-if="!$store.projectsStore.state.selectedProject">
				<div class="info-container d-flex flex-column text-center align-center py-1" style="width: 100%">
					<p class="mb-0 body-2 ml-2">No hay proyectos seleccionados</p>
				</div>
		</v-row>
		<v-row align="center" no-gutters v-else-if="$store.projectsStore.state.selectedProject">
			<v-col cols="12">
				<div class="info-container d-flex align-center text-center justify-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-office-building-outline</v-icon>
					<p class="mb-0 body-2 ml-2 font-weight-bold text-primary">{{$store.projectsStore.state.selectedProject.name}}</p>
				</div>
			</v-col>
		</v-row>
		<v-row align="center" no-gutters v-else>
			<v-col cols="12" md="5">
				<div class="info-container d-flex align-center py-1">
					<v-icon color="white" x-small class="info-icon">mdi-office-building-outline</v-icon>
					<v-skeleton-loader type="list-item" class="skeleton-loader-custom"></v-skeleton-loader>
				</div>
			</v-col>
		</v-row>
	</v-container>
</template>

<script>

export default {
	name: 'HeaderProjectInfoMobile',
	components: {
	},
	data: () => ({
		//
	}),
	mounted(){
	}
};
</script>

<style scoped>
	.info-container{
		color: #606060;
	}
	.info-container b{
		color: #515d78;
	}
	.info-icon{
		background-color: #515d78;
		border-radius: 25px;
		padding: 3px;
		width: 25px;
		height: 25px;
	}
	.info-img{
		width: 100%;
		max-height: 50px;
		object-fit: contain;
	}
	.skeleton-loader-custom{width: 100%;}
	/deep/ .skeleton-loader-custom > div.v-skeleton-loader__list-item{background-color: transparent!important;}
</style>
