import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'es6-promise/auto'

import store from './stores/index'
import router from './router'
import filters from './filters/filters'
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
import {checkPermissions, checkCompanyLevelPermissions} from '@/helpers/permissions'
import {initFirebaseService} from '@/helpers/firebase/index'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import 'weather-icons/css/weather-icons.css';
// import LottieVuePlayer from "@lottiefiles/vue-lottie-player"
// import LottieAnimation from 'lottie-web-vue'

initFirebaseService()

Vue.config.productionTip = false

Object.keys(filters).forEach(key => {
	Vue.filter(key, filters[key])
})
Vue.use(CoolLightBox)
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})
// Vue.use(LottieVuePlayer)
// Vue.use(LottieAnimation)
Vue.mixin({
  methods: {
    checkPermissions,
    checkCompanyLevelPermissions
  }
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
