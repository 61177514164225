<template>
    
    <v-container class="d-flex flex-column align-start pa-8" pa-0 fluid fill-height>
        <v-container fluid >
            <v-icon class="mr-2 icon-left">mdi-map-outline</v-icon>
			<span class="text-left">{{ leftText }}</span>
            <span class="text-description"> {{ Description }}</span>
        </v-container>
        <!-- <PlanimetriaDetail></PlanimetriaDetail> -->
        <ViewBim></ViewBim>
        <viewDocs></viewDocs>

    </v-container>
</template>

<script>
// import PlanimetriaDetail from './PlanimetriaDetail'
import ViewBim from './components/ViewBim.vue'
import ViewDocs from './components/ViewDocs'

export default {
    name: 'PlanimetriaIndex',
    components: {
        // PlanimetriaDetail, 
        ViewBim,
        ViewDocs},
    data: () => ({
        leftText: 'Planimetría',
        Description: 'Documentación / Planos',
        textPlanning: 'Planificaciones'
    }),
    methods: {
    }
};


</script>

<style scoped>

.guid {
  display: flex;
  align-items: center;
  justify-content: left;
  font-size: 0.7rem;
  color: #454d61;
  font-weight: 400;
}

.icon-left {
    margin-left: 20px;
	font-size: 25px;
	font-weight: bold;
	color: #8B8B8B;
}

.text-left {
	font-size: 18px;
	font-weight: bold;
	color: #8B8B8B;
}

.text-description {
	margin-left: 18px;
	font-size: 0.9rem;
	font-weight: 600;
	color: #C0C3C7;
}
</style>