<template>
  <div
    class="d-flex justify-end align-center order-0 order-sm-1 bts-closes-minimize"
  >
    <v-btn class="mx-2" fab dark x-small color="#fb8c00" @click="minimizeTask">
      <v-icon dark>mdi-window-minimize</v-icon>
    </v-btn>
    <v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModal">
      <v-icon dark>mdi-close</v-icon>
    </v-btn>
  </div>
</template>

<script>
import { changeVisualizationStatus } from "@/helpers/api/tasks";

export default {
  name: "MinimizeCloseBtn",
  props: {
    closeModal: {
      type: Function,
      required: true,
    },
  },
  computed: {
    currentTarea() {
      return this.$store.consultasStore.state.currentTarea;
    },
  },
  data: () => ({
    loadingEdit: false,
  }),
  methods: {
    minimizeTask() {
      const data = {
        task_id: this.$store.consultasStore.state.currentTarea.id,
        title: this.$store.consultasStore.state.currentTarea.title,
        type: "Consulta/Restricción",
        type_id: 1,
        number: this.$store.consultasStore.state.currentTarea.number,
      };
      this.$store.globalStore.commit("addTaskToTasksHandler", data);
      this.closeModal();
    },
    closeModalAcceptCancel(status = false) {
      this.acceptRejectModal = false;
      if (status) {
        this.deleteTask();
      }
      this.acceptRejectModalData = null;
    },
    async changeVisualizationStatus(newStatus) {
      const task = this.$store.consultasStore.state.currentTarea;
      if (!this.isLoadingChangeVisualizationStatus(task.id)) {
        this.$store.consultasStore.commit("addLoadingChangeVisualizationStatus", task.id);
        const data = {
          task_id: task.id,
          status: newStatus,
        };
        const statusUpdate = await changeVisualizationStatus(data);
        if (statusUpdate.code === 200) {
          // Actualizo el estado de forma local para no tener que pedir todo de nuevo a la api por cada actualización de estado
          let newTaskData = Object.assign({}, task);
          newTaskData.seen_by_me.seen_status = newStatus;
          this.$store.consultasStore.dispatch("updateTaskLocally", newTaskData);
        }
        this.$store.consultasStore.commit(
          "removeLoadingChangeVisualizationStatus",
          task.id
        );
      }
    },
    isLoadingChangeVisualizationStatus(taskID) {
      const found = this.$store.consultasStore.state.loadingChangeVisualizationStatus.find(
        (task_id) => task_id === taskID
      );
      return !!found;
    },
  },
};
</script>

<style scoped>
.bts-closes-minimize{
  min-width: 168px; 
  margin-top: -20px; 
  margin-right: -10px;
}

.bg-light-blue-text {
  background-color: #515d78;
}
.icon-top {
  padding: 2px;
  margin: 0 2px;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}
.icon-top.high {
  background-color: #f4516c;
}
.icon-top.medium {
  background-color: #fb8c00;
}
.icon-top.normal {
  background-color: #3ebfa3;
}
.icon-top.primary {
  background-color: #515d78;
}
.icon-top.light-blue {
  background-color: #515d78;
}
.high--text {
  color: #f4516c !important;
  caret-color: #f4516c !important;
}
.high {
  background-color: #f4516c !important;
  border-color: #f4516c !important;
  color: white;
}
@media (max-width: 600px){
  .bts-closes-minimize{
    min-width: 10px;
    margin-top: 15px;
    margin-right: 30px;
  }
}
</style>
