<template>
  <v-container class="d-flex flex-column align-start pa-8" pa-0 fluid>
    <v-expansion-panels class="expansion-panel-parent" :value="opened">
      <v-expansion-panel class="expansion-panel-child">
        <v-expansion-panel-header class="expansion-panel-header">
          <div style="display: flex; align-items: center">
            <v-icon class="mr-2">mdi-strategy</v-icon>
            <v-progress-linear :active="loaderUpload" :indeterminate="loaderUpload" color="blue-grey" absolute
              bottom></v-progress-linear>
            <span class="card-title">Documentos </span>
            <v-row style="display: flex; justify-content: flex-end; margin-right: 15px">
              <div class="d-flex">
                <v-col class="d-flex justify-end align-end">
                  <v-progress-circular v-if="loaderUpload" color="blue-grey" indeterminate></v-progress-circular>

                </v-col>
              </div>
            </v-row>
          </div>
        </v-expansion-panel-header>


        <div v-if="isData">
          <v-alert v-if="errorMessage" type="warning" variant="outlined" prominent>
            {{ errorMessage }}
          </v-alert>
          <!-- <TreeView :filesAndFolders="docs.metadata.files[0].files"></TreeView> -->
          <!-- <TreeView
              :filesAndFolders="(docs.metadata.files.length == 1 ? docs.metadata.files[0].files : docs.metadata.files)">
            </TreeView> -->
          <!-- v-for="item in (docs.metadata.files.length == 1 ? docs.metadata.files[0].files : docs.metadata.files)" -->
          <input name="fileInput" type="file"
            accept="image/jpeg,image/png,application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            ref="fileInput" style="display: none" @change="handleAdd(docs.guid, $event)" />
          <v-expansion-panel-content class="expansion-panel-content">
            <v-row>
              <v-col class="d-flex justify-end align-end">
                <div class="btn-import" v-if="checkPermissions(['upload_doc'])">
                  <input ref="fileImport" accept=".zip" style="display: none;" type="file" @change="handleFileUpload" />
                  <v-btn class="btn-right" :disabled="loaderUpload" small @click.prevent="openFileInput()">Importar
                    Documentos</v-btn>
                </div>
              </v-col>
            </v-row>

            <div v-if="docs">
              <v-expansion-panels class="panel"
                v-for="item in (docs.metadata.files.length == 1 ? docs.metadata.files[0].files : docs.metadata.files)"
                :key="item.guid">
                <v-expansion-panel v-if="item.type === 'folder'">
                  <v-expansion-panel-header>
                    <div class="row row-detail">
                      <div class="color-square-ar"></div>
                      <div class="text-title">{{ item.filename }}</div>

                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row>
                      <v-col class="d-flex justify-end align-end">
                        <v-btn class="btn-plus" :disabled="loaderUpload" small @click.prevent="addFile(item)"
                          style="z-index: 9">
                          <v-icon>mdi-plus</v-icon>
                        </v-btn>
                      </v-col>
                    </v-row>
                    <!-- Item -->
                    <div class="content-detail">
                      <TreeView @open-item-modal="handleCustomEvent" :filesAndFolders="item.files"></TreeView>
                    </div>
                    <!-- /item -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- guid -->
              <div class="guid">
                GUID:{{ docs.guid }}-{{ this.$store.projectsStore.state.selectedProject.id }}
              </div>
            </div>
          </v-expansion-panel-content>
        </div>
      </v-expansion-panel>
    </v-expansion-panels>

    <!-- Dialog Modal -->
    <v-dialog v-model="imageDialog" :width="dialogWidth" scrollable persistent @click:outside="closeModal">
      <v-card class="container-media" v-if="itemActive">
        <v-card-title>{{ itemActive.filename }}</v-card-title>
        <v-card-text>
          <div ref="contentContainer" class="content-container">
            <embed class="pdf-embed" :src="itemActive.url" :type="itemActive.mime" style="width: 100%; height: 100%;">

          </div>
          <v-row>
            <!-- <v-col>
            <embed :src="itemActive.url" :type="itemActive.mime" style="width: 100%; height: 800px;">
          </v-col> -->
          </v-row>
        </v-card-text>
        <div class="content-tools">
          <v-btn icon class="weather-modal-expand-button" @click="toggleExpand">
            <v-icon>{{ isExpanded ? 'mdi-arrow-collapse' : 'mdi-arrow-expand' }}</v-icon>
          </v-btn>
          <!-- <v-btn icon class="weather-modal-expand-button" @click="zoomIn">
            <v-icon>mdi-magnify-plus</v-icon>
          </v-btn>
          <v-btn icon class="weather-modal-expand-button" @click="zoomOut">
            <v-icon>mdi-magnify-minus</v-icon>
          </v-btn> -->
          <a :href="itemActive.url" target="_blank" :download="itemActive.filename">
            <v-icon>mdi-open-in-new</v-icon>
          </a>

          <a v-if="dwgUrl" :href="dwgUrl" target="_blank">
            <v-icon>mdi-download</v-icon>
          </a>

        </div>
        <v-btn icon class="weather-modal-close-button" @click="closeModal">
          <v-icon class="red--text">mdi-close</v-icon>
        </v-btn>

      </v-card>
    </v-dialog>

  </v-container>



</template>

<script>

import { getFilesParams, uploadFile, addFileUpload, checkFileExist } from '../../../../helpers/api/bim/index'; // Ajusta la ruta según tu estructura de carpetas
import TreeView from '../views/TreeView.vue'

export default {
  name: "ViewDocs",
  components: {
    TreeView
  },
  data: () => ({
    tab: null,
    loaderUpload: false,
    textPlanning: "Planimetria",
    favorite: false,
    opened: 0,
    accessToken: null,
    docs: null,
    itemActive: null,
    isData: Boolean,
    imageDialog: false,
    isExpanded: false,
    dialogWidth: '70%',
    dialogHeight: '500px',
    zoomLevel: 1,
    isDragging: false,
    lastX: 0,
    lastY: 0,
    folderActive: null,
    errorMessage: null,
    dwgUrl: null,
    viewExtensions: ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.pdf', '.webp'],
  }),
  // mounted() {
  //   this.renderAutodesk();

  // },
  // async mounted() {
  //   this.showDocs();
  // },
  async mounted() {
    this.loaderUpload = true;
    setTimeout(() => {
      this.showDocs();
    }, 2000);

  },
  methods: {
    async convertToDwg(url) {
      console.log('URL de la imagen:', url);

      // Obtener la extensión del archivo original
      const parts = url.split('.');
      const extension = parts[parts.length - 1];

      // Reemplazar la extensión por .dwg
      const newUrl = url.replace(`.${extension}`, '.dwg');
      const response = await checkFileExist(newUrl);
      console.log('response check', response);
      if (response) {
        if (response.status == true) {
          console.log('Si es valido!')
          this.dwgUrl = newUrl;
        }
      }
      // this.dwgUrl = newUrl;
      // // Verificar si el archivo existe
      // const response = await fetch(newUrl, { method: 'HEAD' });
      // if (response.ok) {
      //   console.error('El archivo existe:', newUrl);
      // } else {
      //   console.error('El archivo no existe:', newUrl);
      // }
    },
    startDrag(event) {
      this.isDragging = true;
      this.lastX = event.clientX;
      this.lastY = event.clientY;
    },
    drag(event) {
      if (this.isDragging) {
        const deltaX = event.clientX - this.lastX;
        const deltaY = event.clientY - this.lastY;
        this.$refs.contentContainer.style.transform = `translate(${deltaX}px, ${deltaY}px)`;
        this.lastX = event.clientX;
        this.lastY = event.clientY;
      }
    },
    endDrag() {
      this.isDragging = false;
    },
    zoomIn() {
      console.log('zoomin');
      if (this.zoomLevel < 3) { // Limitar el zoom a 300%
        this.zoomLevel += 0.1; // Incrementar el nivel de zoom
        this.updateZoom();
      }
    },
    zoomOut() {
      console.log('zoomout');
      if (this.zoomLevel > 0.5) { // Limitar el zoom a 50%
        this.zoomLevel -= 0.1; // Decrementar el nivel de zoom
        this.updateZoom();
      }
    },
    updateZoom() {
      const embed = document.querySelector('.pdf-embed');
      console.log('updateZoom', embed);
      embed.style.transform = `scale(${this.zoomLevel})`; // Aplicar el zoom al embed
    },
    toggleExpand() {
      this.isExpanded = !this.isExpanded;
      console.log('this.isExpanded', this.isExpanded);
      this.dialogWidth = this.isExpanded ? '100%' : '70%';
      this.dialogHeight = this.isExpanded ? '100vh' : '500px';
    },
    handleCustomEvent(item) {
      console.log('Event recibido', item);
      this.dwgUrl = null;
      if (this.viewExtensions.includes('.' + item.extension.toLowerCase())) {
        this.itemActive = item;
        this.convertToDwg(this.itemActive.url);
        this.showImageDialog();
      } else {
        this.downloadFile(item);
      }

      // Manejar el evento aquí
    },
    showImageDialog() {
      this.imageDialog = true;
    },
    closeModal() {
      this.itemActive = null;
      this.imageDialog = false;
    },
    openFileInput() {

      if (this.$refs.fileImport) {
        this.$refs.fileImport.click();
      }
    },
    async handleAdd(guid, event) {
      // console.log('handleAdd', event)
      const file = event.target.files[0];

      console.log('this.docs.guid', guid)
      console.log('file', file)
      console.log('this.folderActive', this.folderActive)

      // console.log('handleAdd', formData)
      if (file) {
        try {
          this.loaderUpload = true;
          this.errorMessage = false;
          // this.docs = null;
          const response = await addFileUpload(this.docs.guid, this.folderActive, file);
          if (response) {
            this.docs = response;
            console.log('Respuesta de uploadFile:', response);
            this.loaderUpload = false;
          }
          // Aquí puedes hacer algo con la respuesta, por ejemplo, mostrarla en la consola

        } catch (error) {
          // Manejo de errores, por ejemplo, mostrar un mensaje de error
          console.error('Error al subir el archivo:', error);
          this.loaderUpload = false;
          this.errorMessage = 'Error al subir el archivo: ' + String(error)
        }
      }
    }
    ,
    async handleFileUpload(event) {
      const file = event.target.files[0];
      if (file) {
        try {
          this.loaderUpload = true;
          this.errorMessage = false;
          this.docs = null;
          const response = await uploadFile(file, this.$store.projectsStore.state.selectedProject.id);
          if (response) {
            this.docs = response;
            this.loaderUpload = false;
          }
          // Aquí puedes hacer algo con la respuesta, por ejemplo, mostrarla en la consola
          console.log('Respuesta de uploadFile:', response);
        } catch (error) {
          // Manejo de errores, por ejemplo, mostrar un mensaje de error
          console.error('Error al subir el archivo:', error);
          this.loaderUpload = false;
          this.errorMessage = 'Error al subir el archivo: ' + String(error)
        }
      }
    },
    async showDocs() {
      console.log('data docs');
      this.loaderUpload = true;
      try {
        let params = {
          'external_id': this.$store.projectsStore.state.selectedProject.id,
          'role': 'doc',
          'ordering': '-id'
        };
        let docs = await getFilesParams(params);
        if (docs != null) {
          if (docs.length > 0) {
            this.isData = true;
            this.docs = docs[0];
          }

        }
        this.loaderUpload = false;
        console.log('docs1', docs);
      } catch (error) {
        this.loaderUpload = false;
        this.errorMessage = 'Error fetching files: ' + String(error);
        console.error('Error fetching files:', error);
      }
    },
    async downloadFile(item) {
      console.log('download', item)
      const url = item.url;
      const filename = item.filename;

      try {
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        link.target = '_blank';
        // Simulate a click on the element <a>
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error al descargar el archivo:', error);
      }
    },
    async addFile(item) {

      console.log('addFile', item.guid);
      this.folderActive = item.guid;
      if (this.$refs.fileInput) {
        this.$refs.fileInput.click();
      }


      // if (file) {
      //   try {
      //     this.loaderUpload = true;
      //     this.errorMessage = false;
      //     this.docs = null;
      //     const response = await this.addFile(this.docs.guid, formData);
      //     if (response) {
      //       this.docs = response;
      //       this.loaderUpload = false;
      //     }
      //     // Aquí puedes hacer algo con la respuesta, por ejemplo, mostrarla en la consola
      //     console.log('Respuesta de uploadFile:', response);
      //   } catch (error) {
      //     // Manejo de errores, por ejemplo, mostrar un mensaje de error
      //     console.error('Error al subir el archivo:', error);
      //     this.loaderUpload = false;
      //     this.errorMessage = 'Error al subir el archivo: ' + String(error)
      //   }
      // }
    }
  },

};
</script>

<style scoped>
.container-media {
  width: 100%;
  height: 100%;
}

#container-docs {
  background-color: aqua;
}

/* .card-background {
  background-color: white;
  border-radius: 4px;
} */


.card-title {
  font-weight: 700;
  font-size: 18px;
  color: #454d61;
}

.btn-right {
  border-radius: 25px;
  background-color: #525d78 !important;
  color: white;
  font-size: 10px;
}

.btn-plus {
  border-radius: 15px;
  background-color: #525d78 !important;
  color: white;
  font-size: 10px;
  margin: 8px;
}


.panel {
  margin-bottom: 15px;
}

.row-detail {
  padding: 12px;
  display: flex;
  align-items: center;
  /* border-bottom: 1px solid #e8e8e8; */
}

.embed {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
}


.content-detail {

  /* display: flex; */
  /* Esto permite que los elementos se envuelvan a una nueva línea */
  justify-content: flex-start;
  /* Alínea los elementos a la izquierda */
  align-items: flex-start;
  /* Alínea los elementos arriba */
  width: 400px;
  /* Ancho del contenedor */
  /* border: 1px solid #ccc; */
  /* Borde para visualización */
  border-radius: 4px;
  background-color: #ececec;
  width: 100%;
}

.content-detail>div {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  /* Alínea los elementos a la izquierda */
  align-items: flex-start;
  max-width: 100%;
  max-height: 100%;
  height: 100%;
  white-space: nowrap;
  /* overflow: auto; */
  padding: 20px;

}

.btn-import {
  margin: 16px;
}


.text-row {
  margin-top: 30px;
  display: flex;
  justify-content: center;
}

.text-title {
  margin-left: 10px;
  font-size: 15px;
  font-weight: 500;
}

.color-square-ar {
  width: 12px;
  height: 18px;
  background-color: #61679e;
}

.color-square-sa {
  width: 12px;
  height: 18px;
  background-color: #e2b46e;
}

.color-square-el {
  width: 12px;
  height: 18px;
  background-color: #46aa62;
}

.text-area {
  font-size: 13px;
  font-weight: bold;
  color: #454d61;
}

.popup-image {
  height: 100%;
  width: auto;
}

.weather-modal-close-button {
  position: absolute;
  top: 8px;
  /* Adjust the distance from the top */
  right: 8px;
  /* Adjust the distance from the right */
}

.weather-modal-close-button {
  position: absolute;
  top: 8px;
  /* Adjust the distance from the top */
  right: 8px;
  /* Adjust the distance from the right */
}


.zoom-controls {
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 9999;
}

.zoom-controls v-icon {
  cursor: pointer;
  margin-left: 10px;
  z-index: 9999;
}


.content-container {
  position: relative;
  width: 100%;
  height: 80vh;
  overflow: hidden;
}

.pdf-embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>
