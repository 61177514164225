<template>
  <v-container
    fluid
    pa-0
    style="background-color: #f4f5f7"
    fill-height
    class="d-flex flex-row"
  >
    <Sidebar v-if="$store.userStore.state.isAuthenticated" />
    <!-- <RouterContainerMobile v-if="false"/> -->
    <RouterContainer />
    <ProjectSidebar v-if="$store.userStore.state.isAuthenticated" />
    <GlobalModalsIndex v-if="$store.userStore.state.isAuthenticated" />
    <FileUploadCenter />
    <TasksManager />
    <AlertMessageCenter />
    <FirebaseController
      v-if="$store.userStore.state.isAuthenticated && $store.userStore.state.userInfo"
    />
    <!-- <Reminders /> -->
  </v-container>
</template>

<script>
import Sidebar from "./Sidebar";
import RouterContainer from "./RouterContainer";
// import RouterContainerMobile from "./RouterContainerMobile.vue";
import ProjectSidebar from './ProjectSidebar/ProjectSidebarIndex'
import GlobalModalsIndex from "../Globals/Modals/GlobalModalsIndex";
import FileUploadCenter from "@/components/Globals/Helpers/FileUploadCenter";
import TasksManager from "@/components/Globals/Helpers/TasksManager";
import AlertMessageCenter from "@/components/Globals/Helpers/AlertMessageCenter";
import FirebaseController from "@/components/Globals/Helpers/FirebaseController";
// import Reminders from "@/components/Globals/Helpers/Reminders";

export default {
  name: "MainLayout",
  components: {
    Sidebar,
    RouterContainer,
    ProjectSidebar,
    GlobalModalsIndex,
    FileUploadCenter,
    TasksManager,
    AlertMessageCenter,
    FirebaseController,
    // Reminders,
  },
  data: () => ({}),
};
</script>
