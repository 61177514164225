<template>
  <div class="text-center" ref="createTareaRef" id="text-test">
    <v-dialog
      v-model="modalStatus"
      max-width="800"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <v-icon color="white" small class="info-icon mr-2">mdi-file-document-multiple-outline</v-icon>
                <p class="mb-0 font-weight-bold text-primary title"
									:class="$vuetify.breakpoint.mobile ? 'body-2':''">Nueva RDI</p>
              </div>
							<div class="d-flex flex-row align-center">
								<v-btn class="mx-2" fab dark x-small color="#fb8c00" @click="minimizeForm">
									<v-icon dark>mdi-window-minimize</v-icon>
								</v-btn>
								<v-btn class="mx-2" fab dark small color="#F4516C" @click="closeModalSelf()">
									<v-icon dark>mdi-close</v-icon>
								</v-btn>
							</div>
            </div>
            <v-row align="center" class="mt-2">
              <v-col cols="12">
                <p class="mb-0 body-2"><b class="text-primary">La RDI será emitida a tu nombre:</b> {{$store.userStore.state.userInfo.name}}</p>
								<p class="mb-0 body-2"><b class="text-primary">En el proyecto:</b> {{$store.projectsStore.state.selectedProject.name}}</p>
              </v-col>
            </v-row>
            <v-form ref="form" v-model="validform">
              <v-row align="center">
                <v-col cols="12">
                  <v-text-field
                    v-model="newRDIForm.title"
                    label="Título"
                    placeholder="Ingrese título de la RDI"
                    outlined
                    required
                    hide-details
                    :rules="requiredRule"
										@keydown="sendToSearch"
                  >
										<template slot="append">
											<v-progress-circular
												v-if="searching"
												:size="20"
												:width="2"
												color="#515d78"
												indeterminate
											></v-progress-circular>
											<v-chip
												v-else-if="searchResultsCount > 0"
												dark
												small
												color="#515d78"
												@click="showResults = true">
												<v-avatar
													left
													class="primary darken-4"
												>
													{{searchResultsCount}}
												</v-avatar>
												<p class="mb-0">Elementos similares</p>
												<v-icon>mdi-chevron-down</v-icon>
											</v-chip>
										</template>
									</v-text-field>
									<SearchBlockForms v-if="showResults" :searching="searching" :searchResult="searchResult" :closeResults="closeResults"/>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Enviar Copia</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
											<v-combobox
												v-model="copy_to"
												:loading="$store.usersStore.state.loadingUserList"
												:items="this.$store.usersStore.state.usersList"
												item-text="name"
												item-value="id"
												single-line
												outlined
												dense
												required
												multiple
												label="Enviar copia por correo"
												hint="Se enviará una copia en PDF de la RDI a los usuarios que indiques aquí. Puedes ingresar un correo que no esté en el listado, escríbelo y presiona enter."
												persistent-hint
												return-object
												taggable
											>
												<template v-slot:item="data">
													<v-list-item-avatar>
														<img v-if="data.item.info && data.item.info.avatar" :src="data.item.info.avatar">
														<v-icon v-else>mdi-account</v-icon>
													</v-list-item-avatar>
													<v-list-item-content>
														<v-list-item-title>{{data.item.name}} <span class="light-blue-text">{{data.item.projectjob.userjobtype.name}}</span></v-list-item-title>
														<v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
													</v-list-item-content>
												</template>
											</v-combobox>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Especialidades</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <div class="d-flex flex-row">
                        <v-select
                          v-model="especialidades"
                          :items="$store.projectsStore.state.selectedProject.specialties"
                          item-text="name"
                          item-value="id"
                          label="Seleccione Especialidades"
                          single-line
                          hint="Selecciona el proyecto en el que deseas editar los permisos del usuario"
                          :rules="requiredRule"
                          persistent-hint
                          hide-details
                          multiple
                          outlined
                          dense
                          required
                        ></v-select>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Plano Referencia</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <div class="d-flex flex-row">                    
                        
                        <v-text-field 
                          label="Plano Referencia"
                          v-model="newRDIForm.doc_ref"                          
                          placeholder="Ingrese un Plano Referencia"
                          outlined
                          hide-details
                          @keydown="sendToSearch"
                        >

                        </v-text-field>


                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mb-0 body-1 font-weight-bold">Prioridad</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <div class="d-flex" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
                        <v-btn
                          class="mx-2 my-1"
                          color="#F4516C"
                          dark
                          small
                          :outlined="newRDIForm.priority === 3"
                          @click="() => newRDIForm.priority = 3"
                        >
                          <p class="mb-0">! Inmediata</p>
                        </v-btn>
                        <v-btn
                          class="mx-2 my-1"
                          color="warning"
                          dark
                          small
                          :outlined="newRDIForm.priority === 2"
                          @click="() => newRDIForm.priority = 2"
                        >
                          <p class="mb-0">! Media</p>
                        </v-btn>
                        <v-btn
                          class="mx-2 my-1"
                          color="#3EBFA3"
                          dark
                          small
                          :outlined="newRDIForm.priority === 1"
                          @click="() => newRDIForm.priority = 1"
                        >
                          <p class="mb-0">! Estándar</p>
                        </v-btn>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-row>
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Materia</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <div class="d-flex flex-row">
                        <v-combobox
                          v-model="newRDIForm.subject"
                          :loading="$store.projectsStore.state.loadingProjectSubjects"
                          :items="$store.projectsStore.state.projectSubjects"
                          item-text="name"
                          item-value="id"
                          item-disabled="false"
                          cache-items
                          single-line
                          outlined
                          dense
                          required
                          :rules="requiredRule"
                          label="Ingresa materia para la RDI"
                          hint="Puedes crear una nueva ingresando el nombre. O escribir para buscar un elemento del listado."
                          persistent-hint
                        >
                        </v-combobox>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row align="center">
                <v-col cols="12">
                  <v-row align="center">
                    <v-col cols="12" sm="3">
                      <h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold">Fecha Crítica</h2>
                    </v-col>
                    <v-col cols="12" sm="9">
                      <v-menu
                        v-model="datePicker"
                        :close-on-content-click="false"
                        max-width="290"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            :value="newRDIForm.critical_date"
                            label="Fecha Crítica"
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                            outlined
                            dense
                            class="remove-bottom-details date-limit-input"
                            @click:clear="date = null"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          locale="es"
                          v-model="newRDIForm.critical_date"
                          @change="datePicker = false"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
							<v-row>
								<v-col cols="12">
									<v-row>
										<v-col cols="12" sm="3">
											<v-tooltip bottom>
												<template v-slot:activator="{ on, attrs }">
													<h2 class="ml-2 mr-4 mb-0 body-1 font-weight-bold"
														v-bind="attrs"
														v-on="on">Recordatorio</h2>
												</template>
												<span>Se agregará esta RDI a tus recordatorios semanales</span>
											</v-tooltip>
										</v-col>
										<v-col cols="12" sm="9">
											<div>
												<v-btn
													v-for="(day, index) in weekDays"
													:key="index"
													dark
													x-small
													color="#515d78"
													:outlined="!isWeekDaySelected(index)"
													class="mx-1"
													@click="addWeekDay(index)"
												>
													<p class="mb-0">{{day}}</p>
												</v-btn>
											</div>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
              <v-row align="center">
                <v-col cols="12">
                  <h2 class="ml-2 mb-2 body-1 font-weight-bold">Descripción</h2>
                  <tiptap-vuetify
                    v-model="newRDIForm.description"
                    :extensions="extensions"
                    required
                    :rules="requiredRule"
                    id="create-tarea-text-editor"
                  />
                  <div class="my-2 d-flex flex-row" style="width: 100%">
                    <div class="d-flex flex-row justify-space-between" style="width: 100%">
                      <div class="d-flex flex-row align-center flex-wrap" style="flex: 1;">
                        <div v-for="(file,index) in files" :key="index" class="ma-1" style="flex: 1 0 45%;">
                          <div v-if="filesPreview[index] && filesPreview[index].src" class="preview-img-container">
                            <v-btn
                              icon
                              color="#515d78"
                              x-small
                              class="button-preview-img"
                              @click="removeAttachment(index)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <img :src="filesPreview[index].src" class="preview-img"/>
                          </div>
                          <div v-else class="d-flex flex-row align-center justify-start">
                            <v-btn
                              icon
                              color="#515d78"
                              x-small
                              @click="removeAttachment(index)"
                            >
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            {{file.name}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
              <v-row align="center" class="mt-0">
                <v-col cols="12" sm="6" v-if="!$vuetify.breakpoint.mobile">
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="btns-task d-flex flex-row justify-end" style="width: 100%">
										<div class="d-block">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="mx-2 my-1"
                            color="#515d78"
                            text
                            v-bind="attrs"
                            v-on="on"
                            :disabled="disabledAttachSelector"
                            @click="() => attachFiles = !attachFiles"
                            
                          >
                            <v-icon small class="mr-1">{{attachFiles ? 'mdi-check-circle-outline':'mdi-checkbox-blank-circle-outline'}}</v-icon>
                            <p class="mb-0 text-primary text-capitalize caption">Enviar adjuntos</p>
                          </v-btn>
                        </template>
                        <span>Se adjuntarán los archivos en el correo de notificación</span>
                      </v-tooltip>
                    </div>
                    <div class="d-flex justify-space-between" style="width: 100%" :class="$vuetify.breakpoint.mobile ? 'flex-column':'flex-row'">
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="white"
                        raised
                        @click="onButtonClick"
                      >
                        <p class="mb-0 text-primary">Adjuntar Archivo</p>
                      </v-btn>
                      <input
                        ref="uploader"
                        class="d-none"
                        type="file"
                        @change="onFileChanged"
                      >
                      <v-btn
                        class="mx-2 my-1"
                        rounded
                        color="#515d78"
                        raised
                        :loading="loadingData"
                        @click="() => preCreateRdi()"
                      >
                        <p class="mb-0 white--text">{{confirmButtonText}}</p>
                      </v-btn>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-form>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { TiptapVuetify, Heading, Bold, Italic, Strike, Underline, Code, Paragraph, BulletList, OrderedList, ListItem, Link,
	Blockquote, HardBreak, HorizontalRule, History } from 'tiptap-vuetify'
// import moment from 'moment'
import { v4 as uuidv4 } from 'uuid'
import _debounce from 'lodash/debounce'
import {create} from '@/helpers/api/rdi'
import {search} from '@/helpers/api/general'
import SearchBlockForms from '@/components/Globals/Headers/SearchBlockForms'

export default {
  name: 'CreateRDIModal',
  components: { TiptapVuetify, SearchBlockForms },
  props: {
    modalStatus:{
      type: Boolean,
      default: false
    },
    closeModal: {
      type: Function,
      required: true
    }
  },
  watch: {
    searchUsers (val) {
      if(val && val.length > 5){
        // this.searchUsersBD(val)
        this.searchUsersLocal(val)
      }
    },
		files () {
			this.checkFilesAttach()
		}
  },
  data: () => ({
    validform: true,
    newRDIForm: {
      title: '',
      priority: 1,
      description: '',
      especialidades: null,
      critical_date: null,
      files: [],
      copy_to: null,
      doc_ref: null,
			reminder: [],
			reminder_repeatable: true
    },
		attachFiles: false,
		disabledAttachSelector: true,
    loadingSubjects: false,
    projectSubjects: [{name: 'Test', id: 1}, {name: 'Testito', id: 2}],
    especialidades: [],
    copy_to: [],
    datePicker: false,
    requiredRule: [
      v => !!v || 'Campo requerido'
    ],
    newTarea: '',
    subtareas: [],
    files: [],
    filesPreview: [],
    extensions: [
      History,
			// Table,
			// TableCell,
			// TableHeader,
			// TableRow,
      Blockquote,
      Link,
      Underline,
      Strike,
      Italic,
      ListItem,
      BulletList,
      OrderedList,
      [Heading, {
        options: {
          levels: [1, 2, 3]
        }
      }],
      Bold,
      Code,
      HorizontalRule,
      Paragraph,
      HardBreak
    ],
    content: '',
    loadingData: false,
    searchUsers: null,
		showResults: false,
		searchResultsCount: 0,
		searching: false,
		searchResult: {},
		cancelTimer: 7,
		inCancelTime: false,
		cancelInterval: null,
		confirmButtonText: 'Confirmar',
		weekDays: ['Lunes','Martes','Miércoles','Jueves','Viernes','Sábado','Domingo']
  }),
	beforeDestroy(){

	},
  mounted(){
		if(this.$store.rdiStore.state.borradorRDI){
			this.newRDIForm = this.$store.rdiStore.state.borradorRDI
			this.$store.consultasStore.commit('setBorradorRDI', null)
		}
    setTimeout(() => {
      this.pasteListener()
    }, 1000);
    if(this.$store.projectsStore.state.projectSubjects.length == 0){
      this.getProjectSubjects()
    }
    if(this.$store.usersStore.state.usersList.length === 0){
      this.getUsersList()
    }
    if(this.$store.rdiStore.state.initialDataRDI){
      this.newRDIForm = {
        task_id: this.$store.rdiStore.state.initialDataRDI.task_id,
        title: this.$store.rdiStore.state.initialDataRDI.title,
        priority: 1,
        description: this.$store.rdiStore.state.initialDataRDI.description,
        especialidades: null,
        critical_date: null,
        files: [],
        copy_to: null,
        doc_ref: null,
				reminder: [],
				reminder_repeatable: true
      }
      this.$store.rdiStore.commit('setInitialDataRDI', null)
    }
  },
  methods: {
		closeResults(){
			this.showResults = false
			this.searching = false
		},
    sendToSearch: _debounce(function(){ this.searchForString(this.newRDIForm.title) }, 800),
		async searchForString(searchString){
			this.searching = true
			const data = {
				search: searchString,
				project_id: this.$store.projectsStore.state.selectedProject.id,
				exclude_description: true
			}
			const searchResult = await search(data)
			if(searchResult.code == 200){
				this.searchResult = searchResult
				this.searchResultsCount = searchResult.tasks.length + searchResult.rdis.length
			}
			this.searching = false
		},
		addWeekDay(index){
			const found = this.newRDIForm.reminder.findIndex((itemReminder) => itemReminder === index)
			if(found !== -1){
				this.newRDIForm.reminder.splice(found, 1)
			}
			else{
				this.newRDIForm.reminder.push(index)
			}
			if(this.newRDIForm.reminder.length === 0){
				this.newRDIForm.reminder_repeatable = false
			}
		},
		isWeekDaySelected(index){
			return this.newRDIForm.reminder.includes(index)
		},
		minimizeForm(){
			let title = 'Borrador RDI: '
			if(this.newRDIForm.title && this.newRDIForm.title != ''){
				title += this.newRDIForm.title
			}
			else{
				title += 'Sin Título'
			}
      const data = {
        task_id: uuidv4(),
        title,
        type: 'RDI',
        type_id: 4,
        number: null,
				newRDIForm: this.newRDIForm
      }
      this.$store.globalStore.commit('addTaskToTasksHandler', data)
      this.closeModalSelf()
    },
		closeModalSelf(){
			this.closeModal()
			this.newRDIForm = {
				title: '',
				priority: 1,
				description: '',
				especialidades: null,
				critical_date: null,
				files: [],
				copy_to: null,
				doc_ref: null,
				reminder: [],
				reminder_repeatable: true
			}
		},
    pasteListener(){
      const textEditor = document.querySelector('#create-tarea-text-editor .ProseMirror')
      if(textEditor){
        textEditor.addEventListener('paste', async (event) => {
          const dT = event.clipboardData || window.clipboardData
          const file = dT.files[0]
          if(file){
            this.files.push(file)
            if(file.type.includes('image')){
              const base64File = await this.toBase64(file)
              this.filesPreview.push({src: base64File})
            }
            else{
              this.filesPreview.push({noImage: true})
            }
          }
        })
      }
    },
    async getProjectSubjects(){
      this.$store.projectsStore.dispatch('getProjectSubjects', this.$store.projectsStore.state.selectedProject.id)
    },
    async getUsersList(){
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        start: 0,
        limit: 20
      }
      this.$store.usersStore.dispatch('getUsersList', data)
    },
    searchUsersLocal(searchString){
      this.loadingUsers = true
      const search = this.$store.usersStore.state.usersList
      this.usersToSelect = search.filter((user) =>{
        return user.name.includes(searchString) || user.email.includes(searchString) 
      })
      this.loadingUsers = false
    },
    usersFilter(item, queryText) {
      const name = item.name.toLowerCase()
      const email = item.email.toLowerCase()
      const searchText = queryText.toLowerCase()

      return name.indexOf(searchText) > -1 || email.indexOf(searchText) > -1
    },
    async getRdiList(){
      let project_id = null
      if(this.$store.projectsStore.state.selectedProject){
        project_id = this.$store.projectsStore.state.selectedProject.id
      }
      else{
        const id = localStorage.getItem('selectedProjectID')
        project_id = parseInt(id)
      }
      if(project_id){
				this.$store.rdiStore.dispatch('getRDI', {project_id})
      }
    },
    getColorStatus(){
      if(this.$store.consultasStore.state.currentTarea.status === 1){
        return '#3EBFA3'
      }
      return '#F4516C'
    },
    onButtonClick() {
      this.$refs.uploader.click()
    },
    async onFileChanged(e) {
      const newFile = e.target.files[0]
      this.files.push(newFile)
      if(newFile.type.includes('image')){
        const base64File = await this.toBase64(newFile)
        this.filesPreview.push({src: base64File})
      }
      else{
        this.filesPreview.push({noImage: true})
      }
    },
    removeAttachment(index){
      this.files.splice(index, 1)
      this.filesPreview.splice(index, 1)
    },
		checkFilesAttach(){
			if(this.files.length > 0){
				this.disabledAttachSelector = false
			}
			else{
				this.disabledAttachSelector = true
				this.attachFiles = false
			}
		},
    toBase64(file) {
      return new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
          resolve(reader.result)
        };
        reader.onerror = function () {
          resolve(null)
        };
      })
    },
    preCreateRdi(){
			if(this.inCancelTime){
				clearInterval(this.cancelInterval)
				this.confirmButtonText = 'Confirmar'
				this.inCancelTime = false
				return null
			}
      if(this.newRDIForm.description === '' || !this.newRDIForm.description){
        const datas = {
         message: 'Por favor escribe una descripción. Este campo no puede estar vacio.',
         title: 'Error',
          created: new Date(),
          type: 'success',
          icon: 'mdi-alert-circle',
          color: '#fb8c00'
        }
        this.$store.globalStore.dispatch('addMessage', datas)
        return false
      }
      if(this.$refs.form.validate()){
				this.inCancelTime = true
				this.cancelTimer = 7
				this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
				this.cancelInterval = setInterval(() => {
					this.cancelTimer--
					this.confirmButtonText = 'Cancelar (' + this.cancelTimer + ')'
					if(this.cancelTimer == 0){
						this.inCancelTime = false
						clearInterval(this.cancelInterval)
						this.createRdi()
					}
				}, 1000);
      }
    },
    async createRdi(){
			this.loadingData = true
			this.confirmButtonText = 'Confirmar'
			if(this.especialidades.length > 0){
				this.newRDIForm.especialidades = JSON.stringify(this.especialidades)
			}
			if(this.copy_to.length > 0){
				const emails_copy = this.copy_to.map((email) => {
					let emailData = null
					if(email.email){
						emailData = {email: email.email, name: email.name}
					}
					else{
						emailData = {email, name: ''}
					}
					return emailData
				})
				this.newRDIForm.copy_to = JSON.stringify(emails_copy)
			}
			if(this.newRDIForm.subject.id){
				this.newRDIForm.subject_id = this.newRDIForm.subject.id
			}
			else if(this.newRDIForm.subject){
				this.newRDIForm.new_subject = this.newRDIForm.subject
			}

			const hasReminders = this.newRDIForm.reminder.length > 0
			this.newRDIForm.reminder = JSON.stringify(this.newRDIForm.reminder)
			this.newRDIForm.project_id = this.$store.projectsStore.state.selectedProject.id
      console.log('this.newRDIForm', this.newRDIForm);
			const formData = new FormData()
			Object.keys(this.newRDIForm).forEach((key) => {
				if(this.newRDIForm[key]){
					formData.append(key, this.newRDIForm[key])
				}
			})
			const biggerFiles = []
			this.files.forEach((file) => {
				if(file && file.size < 100000){
					formData.append('files[]', file)
				}
				else if(file){
					biggerFiles.push({
						type: 3,
						payload: null,
						description: this.newRDIForm.title,
						total: file.size,
						uuid: uuidv4(),
						file,
						attach_files: this.attachFiles,
						copy_to: this.newRDIForm.copy_to
					})
				}
			})
			const rdi = await create(formData)
			if(rdi.code === 200){
				if(biggerFiles.length > 0){
					biggerFiles.map(file => {
						file.item_id = rdi.rdi.id
						file.payload = {rdi_id: rdi.rdi.id, project_id: this.$store.projectsStore.state.selectedProject.id}
						this.$store.globalStore.commit('setFilesToUploadCenter', file)
					})
				}
				else {
					// Envío correo con adjuntos, función verifica que no se esté subiendo algún archivo aún
					const checkSendEmailData = {
						type: 3,
						item_id: rdi.rdi.id,
						copy_to: this.newRDIForm.copy_to,
						attach_files: this.attachFiles
					}
					this.$store.globalStore.dispatch('checkSendingEmail', checkSendEmailData)
				}
				await this.getRdiList()
				this.$store.projectsStore.dispatch('getDashboardInfo')
				this.closeModalSelf()
				this.setMessage(rdi.rdi, biggerFiles.length > 0)
				if(rdi.task){
					this.checkReloadTask(rdi.task)
				}
				hasReminders && this.$store.notificationsStore.dispatch('getReminders')
			}
			this.loadingData = false
    },
		checkReloadTask(task){
			if(this.$store.consultasStore.state.currentTarea && this.$store.consultasStore.state.currentTarea.id == task.id){
        this.$store.consultasStore.commit('setCurrentTarea', task)
			}
		},
    setMessage(rdi, filesToUC = false){
      let message = 'Haz click aquí para visualizarla.'
			if(filesToUC){
				message += ' Hay algunos archivos que se están cargando.'
			}
      const data = {
        message,
        title: 'Se ha creado la RDI ' + rdi.number,
        created: new Date(),
        type: 'success',
        icon: 'mdi-check-circle',
        color: '#3EBFA3',
        action: 'rdi',
        task_id: rdi.id
      }
      this.$store.globalStore.dispatch('addMessage', data)
    },
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #515d78;
  }
  .info-icon{
    background-color: #515d78;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #515d78;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .date-limit-input{
    max-width: 250px;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 4px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
  /deep/ .remove-bottom-details .v-text-field__details{display: none;}
  /deep/ .remove-bottom-details .v-input__slot{margin-bottom: 0;}
/*  /deep/ .text-editor.tiptap-vuetify-editor .ProseMirror{
    margin: 0!important;
  }*/

@media (max-width: 600px){
  .btns-task{
    display: inline-block !important;
    text-align: center;
  }
}
</style>