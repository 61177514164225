<template>
  <div>
    <div v-if="!urn" class="not-found">
      {{ notFound }}
    </div>
    <div id="forgeViewer">
     
    </div>
  </div>
</template>

<script>

import { getAutodeskAccessToken } from '../../../../helpers/api/autodesk/index';

export default {
  name: "ViewBimItem",
  props: {
    urn: {
      type: String,
      default: null
    }
  },
  components: {
  },
  data() {
    return {
      // loadExternalScript: null,
      urnItem: this.urn,
      accessToken: null,
      viewer: null,
      htmlDiv: null,
      notFound: 'Aún no tienes modelos cargados.'
    };
  },
  watch: {
    urn(newVal) {
      console.log('VBI.renderAutodesk newVal', newVal);
      this.cleanupAutodeskViewer();
      this.urnItem = newVal;
      if (this.urnItem) {
        if (this.viewer) {
          this.renderBim(this.urnItem, this.htmlDiv);
        } else {
          this.renderAutodesk(this.urnItem);
        }

      }
    }
  },
  methods: {
    async renderAutodesk(urn = null) {
      console.log('VBI.renderAutodesk', urn);
      if (urn == null) {
        return false;
      }
      try {
        // Llama a la función para obtener el token de acceso
        this.accessToken = await getAutodeskAccessToken();
        console.log('token: ', this.accessToken);
      } catch (error) {
        console.error('Error al obtener el token de acceso:', error);
        return false;
      }

      // Render 3D
      setTimeout(() => {

        let loadExternalScript = document.createElement("script");
        loadExternalScript.setAttribute(
          "src",
          "https://developer.api.autodesk.com/modelderivative/v2/viewers/7.*/viewer3D.min.js"
        );
        console.log('this.loadExternalScript', this.loadExternalScript)
        this.htmlDiv = document.getElementById("forgeViewer");
        this.htmlDiv.appendChild(loadExternalScript);
        loadExternalScript.onload = () => {
          //// INIT VIEWER
          // El script se ha cargado exitosamente
          console.log('loadExternalScript.onload 1');
          this.renderBim(urn, this.htmlDiv);
        };



      }, 500); // Espera 2000 milisegundos (2 segundos)
    },
    renderBim(urn, htmlDiv) {
      const Autodesk = window.Autodesk;
      const $ = this;
      // var viewer;
      var options = {
        env: "AutodeskProduction2",
        api: "streamingV2",
        getAccessToken: function (onTokenReady) {
          var token = $.accessToken;
          var timeInSeconds = 3600;
          onTokenReady(token, timeInSeconds);
        },
      };
      console.log('loadExternalScript.onload 2');

      // var documentId = "urn:dXJuOmFkc2sub2JqZWN0czpvcy5vYmplY3Q6YXV0b2Rlc2stbGFvdC0yNC9Fc3BhY2lvLnppcA";
      var documentId = "urn:" + urn;

      Autodesk.Viewing.Initializer(options, function () {
        console.log('loadExternalScript.onload 3');

        const viewerOptions = { extensions: ['', 'Autodesk.DocumentBrowser'] };

        /* if ($.viewer) {
          console.log('kill?', $.viewer)
          // $.viewer.stop();
          // $.viewer = null;
          $.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, viewerOptions);
        } else {
          $.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, viewerOptions);
          $.viewer.start();
        } */
        $.viewer = new Autodesk.Viewing.GuiViewer3D(htmlDiv, viewerOptions);
        $.viewer.start();


        Autodesk.Viewing.Document.load(
          documentId,
          onDocumentLoadSuccess,
          onDocumentLoadFailure
        );

        function onDocumentLoadSuccess(viewerDocument) {
          // Choose the default viewable - most likely a 3D model, rather than a 2D sheet.
          var defaultModel = viewerDocument.getRoot().getDefaultGeometry();
          $.viewer.loadDocumentNode(viewerDocument, defaultModel);
        }

        function onDocumentLoadFailure() {
          console.error("Failed fetching Forge manifest");
        }

        console.log("Initialization complete, loading a model next...");

      });
      //// END VIEWER
    },
    clearCookiesForDomain(domain) {
      // Obtener todas las cookies
      const cookies = document.cookie.split(';');

      // Iterar sobre las cookies
      cookies.forEach(cookie => {
        // Obtener el nombre de la cookie y limpiar espacios
        const cookieName = cookie.split('=')[0].trim();

        // Si la cookie pertenece al dominio especificado, eliminarla
        if (cookieName && cookieName.startsWith(domain)) {
          document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=${domain}`;
        }
      });
    },
    cleanupAutodeskViewer() {
      console.log('cleanupAutodeskViewer destruido');
      // Eliminar el script cargado anteriormente
      const existingScript = document.querySelector('script[src*="viewer3D.min.js"]');
      if (existingScript) {
        existingScript.remove();
      }

      document.querySelectorAll('script').forEach(script => {
        if (script.src && script.src.startsWith('https://developer.api.autodesk.com/modelderivative/')) {
          script.remove();
        }
      });

      if (this.viewer && this.viewer.doOperation) {
        console.log('Deteniendo el visor existente destruido');
        //$.viewer.finish(); // O 
        this.viewer.tearDown();
        this.viewer = null;
      }

      // Eliminar cualquier instancia del visor Autodesk
      if (window.Autodesk && window.Autodesk.Viewing) {
        window.Autodesk.Viewing.shutdown();
      }

      const forgeViewerDiv = document.getElementById('forgeViewer');
      if (forgeViewerDiv) {
        forgeViewerDiv.innerHTML = '';
      }

      this.clearCookiesForDomain('developer.api.autodesk.com');
      this.clearCookiesForDomain('.autodesk.com');
    }
  },
  beforeDestroy() {
    console.log('El componente está a punto de ser destruido.');
    this.cleanupAutodeskViewer();
    // Realizar tareas de limpieza o liberación de recursos aquí
  }
};
</script>

<style scoped>
#forgeViewer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  /* Asegura que no supere el ancho del contenedor padre */
}
.not-found{
  width: 100%;
  text-align: center;
  font-size: 0.8rem;
}
</style>
