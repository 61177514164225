<template>
  
  <v-container fluid class="flex-grow-1">
    <v-row
      align="center"
      no-gutters
      style="height: 100%"
      class="flex-grow-1"
      v-if="loading"
    >
      <div
        class="info-container d-flex flex-column text-center align-center py-1"
        style="width: 100%"
      >
        <v-progress-circular
          :size="50"
          :width="5"
          color="#515d78"
          indeterminate
        ></v-progress-circular>
        <p class="mb-0 body-2 ml-2">Cargando proyectos</p>
      </div>
    </v-row>
    <v-row align="start" v-if="$store.projectsStore.state.listingType === 1 && !loading">
      <v-col 
        cols="12"
        sm="3"
        v-for="(project, index) in $store.projectsStore.state.projectsList"
        :key="index"
      >
        <ProjectGridItem :info="project" />
      </v-col>
    </v-row>
    <v-row align="start" v-if="$store.projectsStore.state.listingType === 2 && !loading">
      <v-col cols="12">
        <ProjectListItem
          v-for="(project, index) in $store.projectsStore.state.projectsList"
          :info="project"
          :key="index"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import ProjectListItem from "./ProjectListItem";
import ProjectGridItem from "./ProjectGridItem";
import { getProjectsByUser } from "@/helpers/api/project";

export default {
  name: "ProjectsIndex",
  components: { ProjectGridItem, ProjectListItem },
  data: () => ({
    loading: false,
  }),
  mounted() {
    if (!this.$store.projectsStore.state.projectsList) {
      this.getProjectList();
    }
  },
  methods: {
    async getProjectList() {
      this.loading = true;
      const projects = await getProjectsByUser();
      if (projects.code === 200) {
        this.$store.projectsStore.commit("setProjectsList", projects.projects);
      }
      this.loading = false;
    },
  },
};
</script>
