<template>
  <v-container fluid class="flex-grow-1">
    <v-row
      align="center"
      no-gutters
      style="height: 100%"
      class="flex-grow-1"
      v-if="$store.usersStore.state.loadingUserList"
    >
      <div
        class="info-container d-flex flex-column text-center align-center py-1"
        style="width: 100%"
      >
        <v-progress-circular
          :size="50"
          :width="5"
          color="#515d78"
          indeterminate
        ></v-progress-circular>
        <p class="mb-0 body-2 ml-2">Cargando usuarios</p>
      </div>
    </v-row>
    <v-row v-else>
      <v-col
        sm="6"
        md="4"
        lg="3"
        xl="2"
        v-for="(user, index) in $store.usersStore.state.usersList"
        :key="index"
      >
        <v-card class="fill-height pa-0" style="width: 100%">
          <v-card-text class="pa-4">
            <div class="d-flex flex-column align-center justify-center">
              <img v-if="user.info.avatar" :src="user.info.avatar" class="user-avatar" />
              <div
                class="mt-2 d-flex flex-column justify-center align-center"
                style="width: 100%"
              >
                <div>
                  <p class="mb-1">
                    <v-icon small class="mr-2">mdi-account-outline</v-icon>
                    {{ user.name }}
                  </p>
                  <p class="mb-1">
                    <v-icon small class="mr-2">mdi-briefcase-variant-outline</v-icon>
                    <span style="color: #515d78">{{
                      user.projectjob.userjobtype.name
                    }}</span>
                  </p>
                  <p class="mb-1">
                    <v-icon small class="mr-2">mdi-email-outline</v-icon> {{ user.email }}
                  </p>
                  <p class="mb-1">
                    <v-icon small class="mr-2">mdi-calendar-range</v-icon>
                    {{ user.created_at | toDate }}
                  </p>
                </div>
              </div>
              <div v-if="checkPermissions(['edit_users'])">
                <v-row class="mt-1">
                  <v-col cols="12" md="6">
                    <v-btn
                      rounded
                      color="#E6A23D"
                      dark
                      x-small
                      block
                      @click="editUser(user)"
                    >
                      <v-icon dark small class="mr-2">mdi-account-edit-outline</v-icon>
                      <p class="mb-0">Editar</p>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-btn
                      rounded
                      color="#DD5050"
                      dark
                      x-small
                      block
                      @click="confirmDelete(user)"
                    >
                      <v-icon dark small class="mr-2">mdi-trash-can-outline</v-icon>
                      <p class="mb-0">Borrar</p>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row class="mt-1">
                  <v-col cols="12" lg="6">
                    <v-btn
                      rounded
                      color="#515d78"
                      dark
                      x-small
                      block
                      v-if="checkCompanyLevelPermissions(['set_user_projects'])"
                      @click="editUserProjects(user)"
                    >
                      <v-icon dark small class="mr-2">mdi-account-lock-outline</v-icon>
                      <p class="mb-0">Proyectos</p>
                    </v-btn>
                  </v-col>
                  <v-col cols="12" lg="6">
                    <v-menu offset-y v-if="checkIfAdmin()">
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          rounded
                          color="#515d78"
                          dark
                          x-small
                          block
                          v-bind="attrs"
                          v-on="on"
                          v-if="checkCompanyLevelPermissions(['set_user_permissions'])"
                        >
                          <v-icon dark small class="mr-2"
                            >mdi-account-lock-outline</v-icon
                          >
                          <p class="mb-0">Permisos</p>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item dense @click="editPermisosEmpresaUser(user)">
                          <v-list-item-title>Permisos Empresa</v-list-item-title>
                        </v-list-item>
                        <v-list-item dense @click="editPermisosUser(user)">
                          <v-list-item-title>Permisos Proyecto</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <div v-else>
                      <v-btn
                        rounded
                        color="#515d78"
                        dark
                        x-small
                        block
                        @click="editPermisosUser(user)"
                        v-if="checkCompanyLevelPermissions(['set_user_permissions'])"
                      >
                        <v-icon dark small class="mr-2">mdi-account-lock-outline</v-icon>
                        <p class="mb-0">Permisos</p>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { removeUserFromProject } from "@/helpers/api/user";

export default {
  name: "UsersList",
  components: {},
  data: () => ({
    loading: false,
  }),
  mounted() {},
  methods: {
    checkIfAdmin() {
      if (
        this.$store.userStore.state.userInfo &&
        this.$store.userStore.state.userInfo.info &&
        (this.$store.userStore.state.userInfo.info.default_job == 1 ||
          this.$store.userStore.state.userInfo.info.default_job == 8)
      ) {
        return true;
      } else {
        return false;
      }
    },
    async getUsersList() {
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        start: 0,
        limit: 20,
      };
      this.$store.usersStore.dispatch("getUsersList", data);
    },
    async removeProjectUser(user_id) {
      const data = {
        project_id: this.$store.projectsStore.state.selectedProject.id,
        user_id,
      };
      const user = await removeUserFromProject(data);
      if (user.code == 200) {
        const datas = {
          message: "El usuario ha sido eliminado del proyecto.",
          title: "Usuario eliminado",
          created: new Date(),
          type: "success",
          icon: "mdi-check-circle",
          color: "#3EBFA3",
        };
        this.$store.globalStore.dispatch("addMessage", datas);
        this.getUsersList();
      }
    },
    editUser(user) {
      this.$store.usersStore.commit("setSelectedUserInfo", user);
      this.$store.usersStore.commit("setEditUserModal", true);
    },
    editUserProjects(user) {
      this.$store.usersStore.commit("setSelectedUserInfo", user);
      this.$store.usersStore.commit("setEditUserProjectsModal", true);
    },
    editPermisosUser(user) {
      this.$store.usersStore.commit("setSelectedUserInfo", user);
      this.$store.usersStore.commit("setEditPermisosUserModal", true);
    },
    editPermisosEmpresaUser(user) {
      this.$store.usersStore.commit("setSelectedUserInfo", user);
      this.$store.usersStore.commit("setEditPermisosEmpresaUserModal", true);
    },
    confirmDelete(user) {
      if (window.confirm("¿Estás seguro de que quieres eliminar este usuario?")) {
        this.removeProjectUser(user.id);
      }
    },
  },
};
</script>
