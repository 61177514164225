<template>
  <v-container fluid fill-height pa-0 :class="setClases()">
    <template>
      
    </template>
    <div class="container-main">
      <router-view></router-view>
    </div>
  </v-container>
</template>

<script>


export default {
  name: "RouterContainer",
  computed: {
    // Computed property para determinar si se debe mostrar el menú hamburguesa
    // shouldShowBurgerMenu() {
    //   return window.innerWidth <= 600; // Muestra el menú hamburguesa en pantallas menores o iguales a 600px
    // },
    currentRouteName() {
      return this.$route.name;
    },
  },
  methods:{
    setClases() {
			let classToAdd = "";
			if (this.$store.menuStore.state.sidebarMini) {
				classToAdd += "mini";
			}
			if (!["Login", "ResetPassRequest", "ResetPass"].includes(this.currentRouteName)) {
				classToAdd += " router-container";
			}
			if (this.$vuetify.breakpoint.mobile) {
				classToAdd += " mobile";
			}
			return classToAdd;
		},
  }
};
</script>
<style scoped>
/* ToolBar */
.v-app-bar.v-app-bar--fixed {
  z-index: 6;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

/* Toolbar / */

.container-main {
  background-color: #f5f5f6;
  height: 100%;
  width: 100%;
  margin: auto;
  border-radius: 8px;
  white-space: nowrap;
}

.router-container {
  flex: 1;
  width: calc(100% - 210px);
  margin-left: 220px;
  margin-right: 0;
}

.router-container.mini {
  width: calc(100% - 56px);
  margin-left: 56px;
}

.router-container.mobile {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.router-container.mini {
  width: calc(100% - 56px);
  margin-left: 56px;
}

.config-btn {
  width: 30px;
  height: 30px;
  max-height: 30px;
  color: #525d78;
  margin: 0 0 0 13px;

  img {
    width: 30px;
    height: 30px;
  }
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}

.v-btn--is-elevated.v-btn--fab {
  box-shadow: none;
}

.img-header {
  margin-top: 10px;
  width: 130px;
}

.text {
  color: #515d78 !important;
  text-transform: none;
  font-weight: bold;
}

v-app-bar {
  color: white !important;
}

v-btn.text,
v-toolbar-title.text,
v-text-field.text {
  color: #525e78 !important;
}

@media (max-width: 600px) {
  .v-toolbar__title {
    display: none;
  }

  v-app-bar {
    color: white !important;
  }
}

/* @media () */
</style>
