<template>
  <v-container fluid style="background-color: #515d78" fill-height class="d-flex flex-column justify-center">
    <v-snackbar v-model="showMessage" vertical bottom center :color="messageColor">
      <p class="mb-0">{{ message }}</p>
    </v-snackbar>
    <!-- <img :src="require('../../assets/Svg/logoWhite.svg')" style="max-width: 250px; width: 100%;"/> -->
    <div class="login-form-container d-flex justify-center mt-8">
      <v-card class="login-card">
        <img class="img-header" src="../../assets/Svg/logo-dark.svg" alt="Avatar" />
        <v-card-title class="text-h3">Bienvenido! </v-card-title>
        <p class="caption">Sistema de gestión de proyectos</p>
        <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column flex-grow-1"
          style="width: 100%; width: 100%; padding: 15px">
          <v-text-field v-model="email" :rules="emailRules" label="Email" required
            @keyup.enter="loginUser"></v-text-field>
          <v-text-field v-model="userPassword" :rules="passwordRules" label="Contraseña" required
            :append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'" @click:append="() => (passType = !passType)"
            :type="passType ? 'password' : 'text'" @keyup.enter="loginUser"></v-text-field>
          <v-btn :disabled="!valid" color="#515d78" style="margin-top: 15px" dark :loading="loadingLogin"
            @click="loginUser">
            Ingresar
          </v-btn>
          
          <!-- <v-btn
					:disabled="!valid || loadingLogin"
					color="515d78"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Register'})"
				>
					Registra tu Cuenta
				</v-btn>
				<v-btn
					:disabled="!valid || loadingLogin"
					color="515d78"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'ResetPassRequest'})"
				>
					Reestablecer Contraseña
				</v-btn> -->
        </v-form>
        <p @click="resetPassword" class="text-center mr-3 reset-pass">Restablecer Contraseña</p>

      </v-card>

      <!-- <v-card class="login-card">
				<v-form
				ref="form"
				v-model="valid"
				lazy-validation
				class="d-flex flex-column flex-grow-1"
				style="max-width: 400px;width: 100%;">
				<v-text-field
					v-model="email"
					:rules="emailRules"
					label="Email"
					required
					dark
				></v-text-field>
				<v-text-field
					v-model="userPassword"
					:rules="passwordRules"
					label="Contraseña"
					dark
					required
					:append-icon="passType ? 'mdi-eye' : 'mdi-eye-off'"
					@click:append="() => (passType = !passType)"
					:type="passType ? 'password' : 'text'"
				></v-text-field>
				<v-btn
					:disabled="!valid"
					color="#515d78"
					dark
					:loading="loadingLogin"
					@click="loginUser"
				>
					Ingresar
				</v-btn>
				<v-btn
					:disabled="!valid || loadingLogin"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'Register'})"
				>
					Registra tu Cuenta
				</v-btn>
				<v-btn
					:disabled="!valid || loadingLogin"
					color="white"
					class="mt-4"
					small
					text
					@click="$router.push({name: 'ResetPassRequest'})"
				>
					Reestablecer Contraseña
				</v-btn>
			</v-form>
				<v-card-title class="text-h3">Bienvenido!</v-card-title>
				<v-card-text>
					<p class="caption">Sistema de gestión de proyectos</p>
					<v-text-field v-model="email" label="Email" outlined></v-text-field>
					<v-text-field
					v-model="password"
					:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
					:type="showPassword ? 'text' : 'password'"
					@click:append="showPassword = !showPassword"
					label="Password"
					outlined
					></v-text-field>
					<v-layout row wrap align-center justify-end>
						<v-flex  xs12><p @click="resetPassword" class="text-end mr-3">Restablecer Contraseña</p></v-flex>
					</v-layout>
				</v-card-text>
				<v-card-actions>
					<v-btn class="login-btn"  @click="loginUser">Ingresar </v-btn>
				</v-card-actions>
				
			</v-card> -->
      
    </div>
  </v-container>
</template>

<script>
import { login } from "@/helpers/api/user";

export default {
  name: "Login",
  data: () => ({
    valid: true,
    showMessage: false,
    messageColor: "error",
    message: "",
    email: "",
    emailRules: [
      (v) => !!v || "Email es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "Email debe ser válido",
    ],
    userPassword: "",
    passwordRules: [(v) => !!v || "Contraseña es requerida"],
    passType: true,
    loadingLogin: false,
    showPassword: false,
  }),
  mounted() {
    localStorage.removeItem("selectedProjectID");
    this.$store.userStore.commit("setIsAuthenticated", false);
  },
  methods: {
    validate() {
      this.$refs.form.validate();
    },
    reset() {
      this.$refs.form.reset();
    },
    resetPassword() {
      this.$router.push('/reset-pass-request');
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
    setCompanyPermissions() {
      if (this.$store.projectsStore.state.selectedProject) {
        const companyID = this.$store.projectsStore.state.selectedProject.company_id;
        if (this.$store.userStore.state.userCompanies.length > 0) {
          const found = this.$store.userStore.state.userCompanies.find((company) => {
            return (company.company_id = companyID);
          });
          if (found) {
            this.$store.userStore.commit("setCompanyPermissions", found.capabilities);
            this.$store.userStore.commit("setSelectedCompany", found);
          }
        }
      }
    },
    loginUser() {
      this.loadingLogin = true;
      login(this.email, this.userPassword)
        .then(async () => {
          this.loadingLogin = false;
          this.$store.userStore.commit("setIsAuthenticated", true);
          this.$router.push({ name: "Dashboard" });
          await this.$store.projectsStore.dispatch("getSelectedProject");
          this.setCompanyPermissions();
        })
        .catch((error) => {
          this.loadingLogin = false;
          this.message =
            "Lo sentimos, ha ocurrido un error inesperado. Por favor inténtalo nuevamente.";
          if (error.response && error.response.status == 401) {
            this.message =
              "Email o contraseña incorrectos. Por favor verifica los datos ingresados.";
          }
          this.messageColor = "warning";
          this.showMessage = true;
        });
    },
  },
};
</script>
<style scoped>
.login-form-container {
  width: 100%;
}

.reset-pass{
  color: #525e78 !important;
  cursor: pointer;
  &:hover{
    color: #2a447b !important;
  }
}

.login-card {
  padding: 40px;
  border-radius: 15px;
  width: 550px;
  height: 500px;
  margin: 20px;
  color: white;

  .caption {
    padding-left: 15px;
    color: #080b36;
  }

  .text-h3 {
    margin-left: -3px;
    font-weight: 500;
    color: #080b36;

    @media screen and (max-width: 768px) {
      font-size: 30px !important;
    }
  }

  .text-end {
    cursor: pointer;
  }

  .login-btn {
    width: 97%;
    height: 50px;
    margin-left: 10px;
    background-color: #444d63;
    color: white;
  }
}

.theme--dark.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: #525e78 !important;
}

.img-header {
  margin-left: 15px;
  margin-top: 10px;
  width: 140px;
}
</style>
