<template>
  <div class="text-center" v-if="acceptRejectModal && acceptRejectModalData">
    <v-dialog
      v-model="acceptRejectModal"
      max-width="500"
      scrollable
      persistent
    >
      <v-card>
        <v-card-text class="pa-0">
          <div class="py-4 px-8">
            <div class="d-flex flex-row align-center justify-space-between">
              <div class="d-flex flex-row align-center">
                <p class="mb-0 font-weight-bold text-primary title">{{acceptRejectModalData.title}}</p>
              </div>
            </div>
            <v-row align="center" class="my-2">
              <v-col cols="12">
                <p class="mb-0 text-primary">{{acceptRejectModalData.content_a}}</p>
                <p class="mb-0 text-primary" v-if="acceptRejectModalData.content_b">{{acceptRejectModalData.content_b}}</p>
							</v-col>
						</v-row>
            <v-row align="center">
              <v-col cols="12">
                <div class="d-flex flex-row justify-space-between">
									<v-btn
										class="mx-2 my-1"
										rounded
										color="white"
										raised
										@click="closeModal()"
									>
										<p class="mb-0 text-primary">Cancelar</p>
									</v-btn>
									<v-btn
										class="mx-2 my-1"
										rounded
										color="#515d78"
										raised
										dark
										@click="closeModal(true)"
									>
										<p class="mb-0">Aceptar</p>
									</v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: 'AcceptCancelModal',
	props: {
		closeModal: {
			type: Function,
		},
		acceptRejectModal: {
			type: Boolean,
			default: false
		},
		acceptRejectModalData: {
			type: Object,
			default: null
		}
	},
  data: () => ({
  }),
  mounted(){
  },
  methods: {
  }
};
</script>

<style scoped>
  .info-container{
    color: #606060;
  }
  .text-primary{
    color: #515d78;
  }
  .info-icon{
    background-color: #515d78;
    border-radius: 25px;
    padding: 3px;
    width: 35px;
    height: 35px;
  }
  .info-icon.small-icon{
    width: 25px;
    height: 25px;
  }
  .info-img{
    width: 100%;
    max-height: 50px;
    object-fit: contain;
  }
  .adjuntos-container{
    background-color: #EFEFEF;
    max-height: 95px;
    overflow-y: scroll;
  }
  .interaction-container{
    width: 100%;
    background-color: #E5E9F2;
    border-radius: 5px;
    position: relative;
    padding: 12px;
  }
  .interaction-container.response{
    background-color: #CAE7CC;
  }
  .user-avatar.small{
    width: 25px;
    height: 25px;
  }
  .light-blue-text{color: #515d78;}
  .interaction-container .icon-arrow{
    font-size: 80px;
    position: absolute;
    top: 20px;
    line-height: inherit;
  }
  .interaction-container.right .icon-arrow{
    left: -46px;
  }
  .interaction-container.left .icon-arrow{
    right: -46px;
  }
  .icon-top{
    padding: 2px;
    margin: 0 2px;
    border-radius: 100%;
  }
  .icon-top.high{
    background-color: #F4516C;
  }
  .icon-top.medium{
    background-color: #fb8c00;
  }
  .icon-top.normal{
    background-color: #3EBFA3;
  }
  .icon-top.primary{
    background-color: #515d78;
  }
  .icon-top.light-blue{
    background-color: #515d78;
  }
  .parent-subtareas{
    display: flex;
    flex-wrap: wrap;
  }
  .parent-subtareas .subtareas-checkbox{
    flex: 1 0 30%;
  }
  .notification-img{
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }
  .preview-img-container{
    position: relative;
  }
  .preview-img{
    width: 50px;
    height: 50px;
    border-radius: 4px;
    object-fit: contain;
  }
  .button-preview-img{
    position: absolute;
    top: -1px;
    left: -1px;
    background-color: white;
  }
</style>