import axios from 'axios'

// token bim
const token = '2afb0e5bc86cebbc2a3f86198b1682d2dbcc1711';
// const BASE = 'http://localhost:8899/api/' 
const BASE = 'https://bim.favric.cl/api/' 


const service = axios.create({ baseURL: BASE })

service.defaults.timeout = 20000

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    const contentType = config.data instanceof FormData ? 'multipart/form-data':'application/json'
    console.log('contentType', contentType)
    if (token) {
      config.headers['Authorization'] = 'Token ' + token
    }
    config.headers['Content-Type'] = contentType
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    console.log('CONFIG', config);
    return config
  },
  error => {
    // do something with request error
    console.log('API: ', error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response
    return res.data
  },
  async error => {
    console.warn('Error BIM request')
    return Promise.reject(error.response)
  }
)

export default service