<template>
	<v-container >
		
		<CoolLightBox :items="images.map((item) => {
				return {
					src: item.path,
					autoplay: true
				}
			})" :index="selectedImageIndex" :useZoomBar="true" @close="selectedImageIndex = null">
		</CoolLightBox>
		
		<div class="images-gallery-wrapper d-flex flex-row flex-wrap">
			<div class="image-gallery-item-container pa-1" v-for="(image, imageIndex) in images" :key="imageIndex">
				<AudioRep :file="image.path" color="#515d78" downloadable flat small
					v-if="image.mimetype.includes('audio') || image.mimetype.includes('3gp')"></AudioRep>
				<div style="width: 100%; height: 100%" v-else @click="selectedImageIndex = imageIndex">
					<div class="video-container" v-if="image.mimetype.includes('video')">
						<video class="image-item" preload="metadata">
							<source :src="image.path" :type="image.mimetype">
						</video>
						<v-icon dark large color="#515d78" class="play-button">mdi-play</v-icon>
					</div>
					<div class="image-item" :style="{ backgroundImage: 'url(' + image.path + ')' }" v-else></div>
				</div>
			</div>
		</div>

		
	</v-container>
</template>

<script>
import AudioRep from '@/components/Globals/Helpers/AudioRep'
export default {
	name: 'MediaGallery',
	props: {
		images: {
			type: Array,
			default: null
		}
	},
	components: { AudioRep },
	data: () => ({
		selectedImageIndex: null,
	})
};
</script>

<style scoped>
.content-container {
	overflow-y: scroll;
}

.image-gallery-item-container {
	height: 100px;
	width: 20%;
}

.image-item {
	width: 100%;
	height: 100%;
	cursor: pointer;
	object-fit: contain;
	background-size: contain;
	background-position: center;
	background-color: rgb(0 0 0 / 5%);
}

.video-container {
	width: 100%;
	height: 100%;
	position: relative;
	cursor: pointer;
}

.video-container .play-button {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
}


</style>