<template>
	<div>
		<v-app-bar app fixed height="70" width="100%" style="padding: 0 12px; z-index: 8; transform: none" >
			<!-- <v-app-bar-nav-icon></v-app-bar-nav-icon> -->
			<v-toolbar-title class="text">
				<!-- <router-link to="/">
				<img class="img-header" src="../../assets/Svg/logoLaot.svg" alt="Avatar" />
				</router-link> -->
				<!-- <router-link to="/" v-slot="{ href, navigate }">
				<a :href="href" @click="navigate">
					<img class="img-header" src="../../assets/Svg/logoLaot.svg" alt="Avatar" />
				</a>
				</router-link> -->
	
				<div class="cont-img">
					<router-link to="/" custom v-slot="{ navigate }">
							<a @click="navigate">
								<img class="img-header" src="../../../assets/Svg/logoLaot.svg" alt="Avatar" />
							</a>					
					</router-link>	
				</div>
	
				<!-- <router-link to="/" custom v-slot="{ navigate }">
				<span @click="navigate" @keypress.enter="navigate" role="link">About Us</span>
				</router-link> -->
	
			</v-toolbar-title>
			<v-spacer></v-spacer>
	
			
			<div class="menu-items" v-if="$vuetify.breakpoint.width > 1164" >
				<!-- Menú items -->
				<router-link to="/" custom v-slot="{ navigate }">
					<v-btn text class="text" @click="navigate">
						Inicio
					</v-btn>
				</router-link>

				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" class="text">
							Proyectos
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list dense class="py-0">
						<v-list-item-group v-if="$store.userStore.state.userInfo">
							<v-list-item v-for="(project, index) in $store.projectsStore.state.projectsList"
								v-show="$store.projectsStore.state.selectedProject && $store.projectsStore.state.selectedProject.id != project.id"
								:key="index" dense @click="changeProject(index)">
								<v-list-item-title dense>
									{{ project.name }}
								</v-list-item-title>
							</v-list-item>
						</v-list-item-group>
					</v-list>
				</v-menu>
		
				<!-- RDI ITEM -->
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" class="text">
							RDI
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list dense class="py-0">
						<v-list-item v-for="option in rdiOptions" :key="option.title"
							@click="handleRDIOptionClick(option.title)">
							<v-list-item-title>{{ option.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
		
				<!-- TAREAS ITEM -->
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" class="text">
							Tareas
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list dense class="py-0">
						<v-list-item v-for="option in tareasOption" :key="option.title"
							@click="handleTareasOptionClick(option.title)">
							<v-list-item-title>{{ option.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
		
				<!-- REPORTES ITEM -->
				<v-menu offset-y>
					<template v-slot:activator="{ on }">
						<v-btn text v-on="on" class="text">
							Reportes
							<v-icon>mdi-menu-down</v-icon>
						</v-btn>
					</template>
					<v-list dense class="py-0">
						<v-list-item v-for="option in reportesOption" :key="option.title"
							@click="handleProjectOptionClick(option.title)">
							<v-list-item-title>{{ option.title }}</v-list-item-title>
						</v-list-item>
					</v-list>
				</v-menu>
			</div>
			<!-- PROYECTOS ITEM-->
	
			<v-btn text v-for="item in menuItems" :key="item.title" @click="menuItemClicked(item.title)" class="text">
				{{ item.title }}
				<v-icon>mdi-menu-down</v-icon>
			</v-btn>
	
			<v-spacer></v-spacer>
			<!-- <v-text-field
						v-model="search"
						label="Buscar"
						hide-details
						class="text"
						append-icon="mdi-magnify"
					></v-text-field> -->
			<v-col class="bar-search" cols="12" sm="3">
				<SearchBarHeader />
			</v-col>
			<!-- <v-btn icon class="text"> -->
			<!-- <v-icon>mdi-bell</v-icon> -->
			<NotificationHeader />
			<Reminders />
			<!-- </v-btn> -->
			<!-- <v-btn icon class="text">
					<v-icon>mdi-cog</v-icon>
					</v-btn> -->
			<!-- <v-btn class="config-btn" fab small>
				<v-icon dark>mdi-cog-outline</v-icon>
			</v-btn> -->
	
			<v-btn fab class="config-btn" small v-if="checkCompanyLevelPermissions(['edit_project'])"
				@click="() => $store.projectsStore.commit('setEditProjectModal', true)">
				<v-icon dark>mdi-cog-outline</v-icon>
			</v-btn>
			<div class="config-btn">
				<AvatarUser :user="$store.userStore.state.userInfo"></AvatarUser>
			</div>
	
			<v-btn 
				icon 
				@click="showComponent()" 
				class="menu-burger"
				v-if="$vuetify.breakpoint.width < 1164"
			>
				<v-icon>mdi-menu</v-icon>
			</v-btn>
		</v-app-bar>

		<Sidebar ref="sidebar" />

		<!-- <v-container fluid fill-height pa-0 :class="setClases()">
			<v-navigation-drawer v-model="drawer" app>
				
			</v-navigation-drawer>
		</v-container> -->
	</div>
</template>

<script>
import SearchBarHeader from "../../Globals/Headers/SearchBarHeader";
import NotificationHeader from "../../Globals/Headers/NotificationHeader";
import Reminders from "../../Globals/Helpers/Reminders";
import { checkPermissions } from "@/helpers/permissions";
import AvatarUser from "../../Layout/Ui/AvatarUser.vue";
import Sidebar from "../../Layout/Sidebar.vue";


export default {
	name: 'HeaderMain',
	components: {
		SearchBarHeader,
		NotificationHeader,
		Reminders,
		AvatarUser,
		Sidebar
	},
	props: {
	},
	data: () => ({
		search: "",
		actionButtonsType: 1,
		menuItems: [
			// { title: 'Reportes' }
		],
		projectOptions: [{ title: "Nuevo Proyecto" }, { title: "Editar Proyecto" }],
		rdiOptions: [
			{ title: "Crear RDI" },
			{ title: "RDIs Pendientes" },
			{ title: "RDI Finalizadas" },
		],
		tareasOption: [
			{ title: "Crear Tarea" },
			{ title: "Mis Tareas" },
			{ title: "Tareas Pendientes" },
			{ title: "Tareas Finalizadas" },
			{ title: "Tareas por usuario" },
		],
		reportesOption: [{ title: "No hay reportes" }],
		drawer: false,
	}),
	mounted() {
	},
	computed: {
		currentRouteName() {
			return this.$route.name;
		}
	},
	methods: {
		checkPermissions,
		menuItemClicked(title) {
			console.log(`Clic en ${title}`);
		},
		handleProjectOptionClick(optionTitle) {
			if (optionTitle === "Editar Proyecto") {
				if (this.checkCompanyLevelPermissions(["edit_project"])) {
					this.$store.projectsStore.commit("setEditProjectModal", true);
				}
			} else if (optionTitle === "Nuevo Proyecto") {
				if (this.checkCompanyLevelPermissions(["create_project"])) {
					this.$store.projectsStore.commit("setNewProjectModal", true);
				}
			}
		},
		handleTareasOptionClick(optionTitle) {
			const optionActions = {
				"Crear Tarea": () => this.checkAndOpenCreateTareaModal(),
				"Mis Tareas": () => this.toTareas(2),
				"Tareas Pendientes": () => this.toTareas(3),
				"Tareas Finalizadas": () => this.toTareas(4),
				"Tareas por usuario": () => this.toTareas(5),
			};

			const action = optionActions[optionTitle];
			if (action) {
				action();
			}
		},
		handleRDIOptionClick(optionTitle) {
			const optionActions = {
				"Crear RDI": () => this.checkAndOpenCreateRDIModal(),
				"RDIs Pendientes": () => this.toRDI(2),
				"RDI Finalizadas": () => this.toRDI(3),
			};

			const action = optionActions[optionTitle];
			if (action) {
				action();
			}
		},
		checkAndOpenCreateRDIModal() {
			if (this.checkPermissions(["create_rdi"])) {
				this.$store.rdiStore.commit("setCreateRDIModal", true);
			}
		},
		async changeProject(index) {
			const projectID = this.$store.projectsStore.state.projectsList[index].id
			const companyID = this.$store.projectsStore.state.projectsList[index].company_id
			await this.$store.projectsStore.dispatch('getSelectedProject', projectID)
			if (this.$store.userStore.state.userCompanies.length > 0) {
				const found = this.$store.userStore.state.userCompanies.find((company) => {
					return company.company_id = companyID
				})
				if (found) {
					this.$store.userStore.commit('setCompanyPermissions', found.capabilities)
					this.$store.userStore.commit('setSelectedCompany', found)
				}
			}
			window.location.reload();

			// this.getConsultasList(projectID)
			// this.getRdiList(projectID)
			// // this.getAdditionalList(projectID)
			// this.getDashboardInfo()
			// this.getProjectUserList()

		},
		checkAndOpenCreateTareaModal() {
			if (this.checkPermissions(["create_tasks"])) {
				this.$store.consultasStore.commit("setCreateTareaModal", true);
			}
		},
		toTareas(taskType) {
			this.$store.consultasStore.commit("setSelectedType", taskType);
			if (this.$route.name != "Consultas") {
				this.$router.push({ name: "Consultas" });
			} else {
				const project_id = this.$store.projectsStore.state.selectedProject.id;
				this.$store.consultasStore.dispatch("getMyTasks", { project_id });
			}
		},
		toRDI(taskType) {
			this.$store.rdiStore.commit("setSelectedType", taskType);
			if (this.$route.name != "Rdi") {
				this.$router.push({ name: "Rdi" });
			} else {
				const project_id = this.$store.projectsStore.state.selectedProject.id;
				this.$store.rdiStore.dispatch("getRDI", { project_id });
			}
		},
		showComponent(){
			this.$refs.sidebar.toggleMenu();
		}
	}
};
</script>
<style scoped>

.config-btn {
  width: 30px;
  height: 30px;
  max-height: 30px;
  color: #525d78;
  margin: 0 0 0 13px;
  box-shadow: none;

  img {
    width: 30px;
    height: 30px;
  }
}

.text {
	color: #515d78 !important;
	text-transform: none;
	font-weight: bold;
}

.img-header {
	margin-top: 10px;
	width: 130px;
	max-width: 100%;
}

/* ToolBar */
.v-app-bar.v-app-bar--fixed {
  z-index: 999;
}

.theme--light.v-app-bar.v-toolbar.v-sheet {
  background-color: #ffffff;
}

.v-sheet.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

.v-sheet.v-app-bar.v-toolbar:not(.v-sheet--outlined) {
  box-shadow: 0px 1px 1px -1px rgba(0, 0, 0, 0.1), 0px 1px 1px 0px rgba(0, 0, 0, 0.1),
    0px 1px 1px 0px rgba(0, 0, 0, 0.1);
}

v-btn.text,
v-toolbar-title.text,
v-text-field.text {
	color: #525e78 !important;
}

@media (min-width: 600px) and (max-width: 1164px){
	.bar-search{
		min-width: 35%;
		max-width: 38%;
	}
}

@media (max-width:600px){
	.bar-search{
		max-width: 30%;
	}
}

</style>