import Vue from 'vue'
import Vuex from 'vuex'
import {listMyTasks, list, listByUser, nextCreatedTasks, nextPendingTasks, nextEndedTasks} from '@/helpers/api/tasks'
import {listAdditionalLikely} from '@/helpers/api/additionals'

Vue.use(Vuex)

const store = new Vuex.Store({
	state: {
		misTareasInfoCreadas: {
			status: 99,
			count: 0,
			data: [],
		},
		misTareasInfoPendientes: {
			status: 99,
			count: 0,
			data: [],
		},
		misTareasInfoFinalizadas: {
			status: 99,
			count: 0,
			data: [],
			solution_speed: null,
			waiting_time: null,
		},
		tareasbyUser: {
			status: 99,
			data: []
		},
		listadoTareas: {
			status: 99,
			count: 0,
			total_tasks: 0,
			data: []
		},
		listadoTareasTotal: 0,
		createTareaModal: false,
		creatingPossibleAdditional: false,
		listingOrder: 1,
		selectedType: 2,
		currentTarea: null,
		borradorTarea: null,
		loadingChangeVisualizationStatus: [],
	},
	mutations: {
		setMisTareasInfoCreadas(state, tareas){
			state.misTareasInfoCreadas = tareas
		},
		setMisTareasInfoPendientes(state, tareas){
			state.misTareasInfoPendientes = tareas
		},
		setMisTareasInfoFinalizadas(state, tareas){
			state.misTareasInfoFinalizadas = tareas
		},
		setListadoTareas(state, tareas){
			state.listadoTareas = tareas
			state.listadoTareasTotal = tareas.total_tasks
		},
		setTareasbyUser(state, tareas){
			state.tareasbyUser = tareas
		},
		setCurrentTarea(state, tarea){
			console.log('tarea', tarea, 'state:', state);
			state.currentTarea = tarea
		},
		setBorradorTarea(state, tarea){
			state.borradorTarea = tarea
		},
		setSelectedType(state, type){
			state.selectedType = type
		},
		setListingOrder(state){
			let currentStatus = state.listingOrder
			if(currentStatus === 4){
				currentStatus = 1
			}
			else{
				currentStatus++
			}
			state.listingOrder = currentStatus
		},
		setCreateTareaModal(state, status){
			state.createTareaModal = status
		},
		addLoadingChangeVisualizationStatus(state, newItemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const found = changingStatus.find((item) => item === newItemID)
			if(!found){
				changingStatus.push(newItemID)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		},
		removeLoadingChangeVisualizationStatus(state, itemID){
			const changingStatus = [...state.loadingChangeVisualizationStatus]
			const foundIndex = changingStatus.findIndex((item) => item === itemID)
			if(foundIndex !== -1){
				changingStatus.splice(foundIndex, 1)
			}
			state.loadingChangeVisualizationStatus = changingStatus
		}
	},
	actions: {
		async getMyTasks(context, query){
			let status = 1
			let page = 1
			let per_page = 50
			if(context.state.selectedType === 4){
				status = 3
			}
			if(query.page){
				page = query.page
			}

			let data = {
				project_id: query.project_id,
				status,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page,
				filtered_creator_users: null,
				filtered_resp_users: null,
				filtered_code: null
			}
			if(context.state.selectedType === 2){
				context.commit('setMisTareasInfoCreadas', Object.assign(context.state.misTareasInfoCreadas, {status: 99}))
				context.commit('setMisTareasInfoPendientes', Object.assign(context.state.misTareasInfoPendientes, {status: 99}))
				context.commit('setMisTareasInfoFinalizadas', Object.assign(context.state.misTareasInfoFinalizadas, {status: 99}))
				const tareas = await listMyTasks(data)
				if(tareas.code === 200){
					context.commit('setMisTareasInfoCreadas', {
						status: 1,
						count: tareas.my_created_tasks_total,
						data: tareas.my_created_tasks,
					})
					context.commit('setMisTareasInfoPendientes', {
						status: 1,
						count: tareas.my_tasks_total,
						data: tareas.my_tasks,
					})
					context.commit('setMisTareasInfoFinalizadas', {
						status: 1,
						count: tareas.my_ended_tasks_total,
						data: tareas.my_ended_tasks,
						solution_speed: tareas.my_solution_speed,
						waiting_time: tareas.my_waiting_time_last
					})
				}
			}
			else if(context.state.selectedType === 3 || context.state.selectedType === 4){
				let listing = Object.assign(context.state.listadoTareas, {status: 99})
				context.commit('setListadoTareas', listing)
				data.filtered_creator_users = query.filtered_creator_users
				data.filtered_resp_users = query.filtered_resp_users
				data.filtered_code = query.filtered_code

				console.log('data', data)
				const taskList = await list(data)
				if(taskList.code === 200){
					context.commit('setListadoTareas', {
						status: 1,
						count: taskList.tasks.length,
						data: taskList.tasks,
						total_tasks: taskList.total_tasks,
					})
				}
			}
			else if(context.state.selectedType === 5){
				let listing = Object.assign(context.state.tareasbyUser, {status: 99})
				context.commit('setTareasbyUser', listing)
				const taskList = await listByUser(data)
				if(taskList.code === 200){
					context.commit('setTareasbyUser', {
						status: 1,
						data: taskList.tasks_indicators
					})
				}
			}

		},
		async getMyNextCreatedTasks(context, query){
			let page = 1
			let per_page = 10
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				order: query.order,
				start: per_page * (page - 1),
				limit: per_page,
			}
			context.commit('setMisTareasInfoCreadas', Object.assign(context.state.misTareasInfoCreadas, {status: 99}))
			const tareas = await nextCreatedTasks(data)
			if(tareas.code === 200){
				context.commit('setMisTareasInfoCreadas', {
					status: 1,
					count: tareas.my_created_tasks_total,
					data: tareas.my_created_tasks,
				})
			}	
		},
		async getMyNextPendingTasks(context, query){
			let page = 1
			let per_page = 10
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				order: query.order,
				start: per_page * (page - 1),
				limit: per_page,
			}
			context.commit('setMisTareasInfoPendientes', Object.assign(context.state.misTareasInfoPendientes, {status: 99}))
			const tareas = await nextPendingTasks(data)
			if(tareas.code === 200){
				context.commit('setMisTareasInfoPendientes', {
					status: 1,
					count: tareas.my_tasks_total,
					data: tareas.my_tasks,
				})
			}	
		},
		async getMyNextEndedTasks(context, query){
			let page = 1
			let per_page = 10
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				order: query.order,
				start: per_page * (page - 1),
				limit: per_page,
			}
			context.commit('setMisTareasInfoFinalizadas', Object.assign(context.state.misTareasInfoFinalizadas, {status: 99}))
			const tareas = await nextEndedTasks(data)
			if(tareas.code === 200){
				context.commit('setMisTareasInfoFinalizadas', {
					status: 1,
					count: tareas.my_ended_tasks_total,
					data: tareas.my_ended_tasks,
				})
			}	
		},
		async getAdditionalsLikely(context, query){
			let page = 1
			let per_page = 50
			if(query.page){
				page = query.page
			}

			const data = {
				project_id: query.project_id,
				statuses: query.statuses,
				order: context.state.listingOrder,
				start: per_page * (page - 1),
				limit: per_page
			}

			let listing = Object.assign(context.state.listadoTareas, {status: 99})
			context.commit('setListadoTareas', listing)
			const additionals = await listAdditionalLikely(data)
			if(additionals.code === 200){
				context.commit('setListadoTareas', {
					status: 1,
					count: additionals.additional.length,
					data: additionals.additional,
					total_tasks: additionals.total_additional,
				})
			}
		},
		updateTaskLocally(context, newTaskData){
			const creada = context.state.misTareasInfoCreadas.data.findIndex((task) => { return task.id === newTaskData.id })
			const pendiente = context.state.misTareasInfoPendientes.data.findIndex((task) => { return task.id === newTaskData.id })
			const finalizada = context.state.misTareasInfoFinalizadas.data.findIndex((task) => { return task.id === newTaskData.id })
			const list = context.state.listadoTareas.data.findIndex((task) => { return task.id === newTaskData.id })
			if(creada !== -1){
				let creadas = [...context.state.misTareasInfoCreadas.data]
				creadas[creada] = newTaskData
				context.commit('setMisTareasInfoCreadas', {
					status: 1,
					count: context.state.misTareasInfoCreadas.count,
					data: creadas
				})
			}
			if(pendiente !== -1){
				let pendientes = [...context.state.misTareasInfoPendientes.data]
				pendientes[pendiente] = newTaskData
				context.commit('setMisTareasInfoPendientes', {
					status: 1,
					count: context.state.misTareasInfoPendientes.count,
					data: pendientes
				})
			}
			if(finalizada !== -1){
				let finalizadas = [...context.state.misTareasInfoFinalizadas.data]
				finalizadas[finalizada] = newTaskData
				context.commit('setMisTareasInfoFinalizadas', {
					status: 1,
					count: context.state.misTareasInfoFinalizadas.count,
					data: finalizadas
				})
			}
			if(list !== -1){
				let lists = [...context.state.listadoTareas.data]
				lists[list] = newTaskData
				context.commit('setListadoTareas', {
					status: 1,
					count: context.state.listadoTareas.count,
					data: lists,
					total_tasks: context.state.listadoTareas.total_tasks
				})
			}
		}
	},
	getters: {
		currentTarea: state => {
			return state.currentTarea
		}
	}
})

export default store