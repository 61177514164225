<template>
    
    <v-container class="d-flex flex-column align-start pa-8" pa-0 fluid fill-height>
        <v-container fluid >
            <v-icon class="mr-2 text-left">mdi-map-outline</v-icon>
			<span class="text-left">{{ leftText }}</span>
            <span class="text-description"> {{ Description }}</span>
        </v-container>

        <PlanningDetail></PlanningDetail>
    </v-container>

    

</template>

<script>
import PlanningDetail from './PlanningDetail'
export default {
    name: 'ControlIndex',
    components: {PlanningDetail},
    data: () => ({
        leftText: 'Planificación',
        Description: 'Control de avance / Importación',
        textPlanning: 'Planificaciones'
    }),
    methods: {
            handleclick:() => {
                console.log("btn pressed");
            }
    }
};


</script>

<style scoped>
.text-left {
	font-size: 0.9rem;
	font-weight: bold;
	color: #8B8B8B;
	
}

.text-description {
	margin-left: 20px;
	font-size: 0.9rem;
	font-weight: 600;
	color: #C0C3C7;
}

</style>